import React from 'react'

const PromoLabel = ({ children }) => {
  return (
    <div className="row h-100 px-3 w-100 shareable-label">
      {children}
    </div>
  )
}

export default PromoLabel
