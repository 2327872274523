import React from 'react';
import PromoBlock from '../PromoBlock/exports';
import ReferFriendHead from './components/ReferFriendHead';
import ReferFriendForm from './components/ReferFriendForm';
import ReferFriendBody from './components/ReferFriendBody';

import 'styles/ShareYourCode.scss';

const ReferFriendPromo = () => {
    const promoURL =
        process.env.REACT_APP_API_ENVIRONMENT !== 'prod'
            ? 'https://tuh.d3v.net.au/refer-a-friend'
            : 'https://tuh.com.au/refer-a-friend';
    return (
        <PromoBlock>
            <ReferFriendHead />
            <ReferFriendForm />
            <ReferFriendBody promoURL={promoURL} />
        </PromoBlock>
    );
};

export default ReferFriendPromo;
