import BenefitCalculator from 'components/elements/buttons/BenefitCalculator';
import ShowAll from 'components/elements/buttons/ShowAll';
import { PlaceHolderCard } from 'components/elements/etc/PlaceHolderCard';
import LabelledContent from 'components/elements/labels/LabelledContent';
import LabelledTitle from 'components/elements/labels/LabelledTitle';
import ExtrasLimitsModal from 'components/modals/ExtrasLimitsModal/ExtrasLimitsModal';
import ExtrasUsageTile from 'components/sections/overview/ExtrasUsageTile';
import { benefitLimitHelpers } from 'libs/benefitLimitHelpers';
import { convertToArray, guaranteeArray } from 'libs/helpers';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import gtagEvent from 'libs/gtagEvent';
import {
  setBenefitCalculatorModalVisible,
  thunkUpdatePreferences,
} from 'store';
import { hasCover } from 'libs/booleanHelpers';
import { MdChevronRight } from 'react-icons/md';

const OutputExtraTiles = ({ extrasData, handleExtraClick }) => {
  const [topLevelExtras, setTopLevelExtras] = useState([]);
  const selectedUserId = useSelector((state) => state.selectedUser);
  const preferences = useSelector((state) => state.userSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log({ selectedUserId });
    console.log('UPDATING BENEFIT');

    const limits = benefitLimitHelpers(
      extrasData,
    ).getMovableGridCurrentUserBenefits(selectedUserId, preferences);

    console.log('limits', limits);

    if (limits) setTopLevelExtras(limits);
  }, [selectedUserId, extrasData]);

  const updateList = (newState) => {
    if (newState.length === 0) return;
    setTopLevelExtras(newState);

    // Now send list back to api
    let orderedCodes = [];
    newState.forEach((benefit) => {
      orderedCodes.push(benefit.SpecialityClassCode);
    });
    dispatch(thunkUpdatePreferences({ extrasGridOrder: orderedCodes }));
  };

  return (
    <>
      <ReactSortable
        list={topLevelExtras}
        setList={updateList}
        className="row no-gutters pt-3">
        {topLevelExtras.map((item) => {
          if (
            item.SpecialityClassCode !== 'Opt' &&
            item.SpecialityClassCode !== 'Den'
          ) {
            return (
              <ExtrasUsageTile
                name={item.Description}
                benefitLimit={item?.ClaimableTotal}
                benefitUsed={item?.ClaimedAmount}
                key={`extra_${item.SpecialityClassCode}`}
                onClick={() => handleExtraClick(item, item.Description)}
              />
            );
          } else {
            return <></>;
          }
        })}
      </ReactSortable>
    </>
  );
};

const WithExtras = ({ product }) => {
  const dispatch = useDispatch();
  const selectedUserId = useSelector((state) => state.selectedUser);
  const benefitLimitArray = useSelector((state) => state.benefitLimits);

  const [currentUserBenefits, setCurrentUserBenefits] = useState(false);
  const [extrasModalVisible, setExtrasModalVisible] = useState(false);
  const [currentExtraBenefit, setCurrentExtraBenefit] = useState(false);
  const [topExtrasBars, setTopExtrasBars] = useState(null);

  useEffect(() => {
    console.log({ benefitLimitArray });
    if (benefitLimitArray?.BenefitLimits?.length) {
      setCurrentUserBenefits(false);
      const allLimits = benefitLimitArray.BenefitLimits;
      const userLimit = allLimits.find(
        (limits) => limits.PersonId === selectedUserId,
      );

      const allSpecialtyClass = allLimits?.SpecialityClasses;

      const userSpecialtyClass = userLimit?.SpecialityClasses;
      console.log({ userSpecialtyClass, allSpecialtyClass });

      if (userSpecialtyClass) {
        setCurrentUserBenefits(userSpecialtyClass);
        return;
      }
      if (allSpecialtyClass) {
        setCurrentUserBenefits(allSpecialtyClass);
        return;
      }
    }
  }, [selectedUserId, benefitLimitArray]);

  const getSpecialityByClass = (benefits, str) => {
    if (benefits?.length < 1) return null;

    return guaranteeArray(benefits)?.find(
      (limits) => limits.SpecialityClassCode.trim() === str,
    );
  };

  useEffect(() => {
    if (currentUserBenefits) {
      const top = {};
      const dental = getSpecialityByClass(currentUserBenefits, 'Den');

      console.log(dental, 'dental');

      if (dental?.HasGeneralDental) {
        top.generalDental = dental?.BenefitLimitStatuses.find(
          (limits) =>
            limits.BenefitLimitCodeShort === '086' ||
            limits.BenefitLimitCodeShort === '400',
        );
        top.generalDental.SpecialityClassCode = 'Den';
        top.generalDental.BenefitLimitStatuses = {};
        top.generalDental.BenefitLimitStatuses =
          dental?.BenefitLimitStatuses.filter(
            (limits) =>
              limits.BenefitLimitCodeShort === '086' ||
              limits.BenefitLimitCodeShort === '400',
          );
      }
      if (dental?.HasMajorDental) {
        top.majorDental = dental?.BenefitLimitStatuses.find(
          (limits) => limits.BenefitLimitCodeShort === '087',
        );
        top.majorDental.SpecialityClassCode = 'Den';
        top.majorDental.BenefitLimitStatuses = {};
        top.majorDental.BenefitLimitStatuses =
          dental?.BenefitLimitStatuses.filter(
            (limits) =>
              limits.BenefitLimitCodeShort !== '086' &&
              limits.BenefitLimitCodeShort !== '400',
          );
      }
      console.log({ top });
      if (dental?.HasOverallDental) {
        top.dental = dental;
        top.dental.overall = convertToArray(dental?.BenefitLimitStatuses).find(
          (limits) =>
            limits.BenefitLimitCodeShort === '500' ||
            limits.BenefitLimitCodeShort === '400',
        );
        if (top.dental.overall) {
          top.dental.ClaimedAmount = top.dental.overall.ClaimedAmount;
          top.dental.ClaimableAmount = top.dental.overall?.ClaimableAmount;
          top.dental.ClaimableTotal = top.dental.overall?.ClaimableTotal;
        }
      }
      if (
        !dental?.HasGeneralDental &&
        !dental?.HasMajorDental &&
        !dental?.HasOverallDental
      ) {
        top.dental = dental;
      }

      const optical = getSpecialityByClass(currentUserBenefits, 'Opt');
      top.optical = optical;

      console.log('top', top);

      setTopExtrasBars(top);
    } else {
      setTopExtrasBars(null);
    }
  }, [selectedUserId, currentUserBenefits]);

  const handleExtraClick = (extra, name) => {
    console.log('EVENT_TITLE: ', { extra });
    const eventName = 'extra_cover';
    setExtrasModalVisible(true);
    if (name) {
      extra.name = name;
      extra.eventName = name.toLowerCase().replace(' ', '_') || eventName;
    }
    gtagEvent({
      screen: 'overview',
      action: extra.eventName + '_tile',
      label: `Clicked on a ${extra.name} button`,
      type: 'modal_open',
    });
    setCurrentExtraBenefit(extra);
  };

  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <Card className="p-4">
            <LabelledTitle label="Extras Cover" title={product?.extrasName} />
            {console.log({ topExtrasBars })}
            {Object.keys(benefitLimitArray).length !== 0 &&
            benefitLimitArray.constructor === Object &&
            topExtrasBars ? (
              <div
                className="row no-gutters"
                style={{
                  marginRight: '5px',
                }}>
                {/*
                                Output General Dental
                                Major Dental
                                Optical
                                */}
                {!topExtrasBars?.dental?.HasOverallDental ? (
                  <>
                    {topExtrasBars?.generalDental ? (
                      <ExtrasUsageTile
                        name={'General Dental'}
                        data={topExtrasBars?.generalDental}
                        size={'large'}
                        key={`extra_${topExtrasBars?.generalDental?.BenefitLimitCodeShort}`}
                        onClick={() =>
                          handleExtraClick(
                            topExtrasBars?.generalDental,
                            'General Dental',
                          )
                        }
                      />
                    ) : (
                      <ExtrasUsageTile
                        name={'General Dental'}
                        benefitLimit={0}
                        benefitUsed={0}
                        // data={topExtrasBars?.dental}
                        size={'large'}
                        key={`extra_${topExtrasBars?.dental?.SpecialityClassCode}_1`}
                        onClick={() =>
                          handleExtraClick(
                            topExtrasBars?.dental,
                            'General Dental',
                          )
                        }
                      />
                    )}

                    {topExtrasBars?.majorDental ? (
                      <ExtrasUsageTile
                        name={'Major Dental'}
                        size={'large'}
                        data={topExtrasBars?.majorDental}
                        key={`extra_${topExtrasBars?.majorDental?.BenefitLimitCodeShort}`}
                        onClick={() =>
                          handleExtraClick(
                            topExtrasBars?.majorDental,
                            'Major Dental',
                          )
                        }
                      />
                    ) : (
                      topExtrasBars?.dental && <ExtrasUsageTile
                        // data={topExtrasBars?.dental}
                        name={'Major Dental'}
                        benefitLimit={topExtrasBars?.dental?.ClaimableTotal}
                        benefitUsed={topExtrasBars?.dental?.ClaimedAmount}
                        size={'large'}
                        key={`extra_${topExtrasBars?.dental?.SpecialityClassCode}_2`}
                        onClick={() =>
                          handleExtraClick(
                            topExtrasBars?.dental,
                            'Major Dental',
                          )
                        }
                      />
                    )}
                  </>
                ) : (
                  <>
                    {topExtrasBars?.dental?.HasOverallDental && (
                      <ExtrasUsageTile
                        name={'Dental'}
                        size={'large'}
                        benefitLimit={
                          topExtrasBars?.dental?.overall?.ClaimableTotal
                        }
                        benefitUsed={
                          topExtrasBars?.dental?.overall?.ClaimedAmount
                        }
                        key={`extra_${topExtrasBars?.dental?.overall?.BenefitLimitCodeShort}`}
                        onClick={() =>
                          handleExtraClick(topExtrasBars?.dental, 'Dental')
                        }
                      />
                    )}
                  </>
                )}

                {topExtrasBars?.optical && (
                  <ExtrasUsageTile
                    name={'Optical'}
                    data={topExtrasBars?.optical}
                    size={'large'}
                    key={`extra_${topExtrasBars?.optical?.SpecialityClassCode}`}
                    onClick={() =>
                      handleExtraClick(topExtrasBars?.optical, 'Optical')
                    }
                  />
                )}
              </div>
            ) : (
              <ReactPlaceholder
                showLoadingAnimation
                rows={3}
                color={'#e5e4ed'}
              />
            )}

            <div className="">
              {currentUserBenefits && (
                <>
                  <OutputExtraTiles
                    extrasData={currentUserBenefits}
                    handleExtraClick={handleExtraClick}
                  />
                </>
              )}
              <div
                style={{
                  fontSize: '14px',
                  margin: '10px 0',
                  fontWeight: 500,
                }}>
                Click the{' '}
                <span>
                  <MdChevronRight
                    className="text-primary"
                    size={20}
                    style={{ verticalAlign: 'middle' }}
                  />
                </span>{' '}
                to view the limits and sub-limits within the category
              </div>
              <div
                style={{ fontSize: '14px', fontWeight: 500 }}
                className="text-center ">
                Check your benefits with the{' '}
                <BenefitCalculator
                  onClick={() => {
                    gtagEvent({
                      screen: 'overview',
                      action: 'benefit_calculator',
                      label: 'Clicked benefit calculator button',
                      type: 'modal_open',
                    });
                    dispatch(setBenefitCalculatorModalVisible(true));
                  }}
                />
              </div>
            </div>

            <div className="mt-2 d-none">
              <ShowAll />
            </div>
          </Card>
        </div>
      </div>
      <ExtrasLimitsModal
        title={
          currentExtraBenefit.name
            ? currentExtraBenefit.name
            : currentExtraBenefit.Description
        }
        show={extrasModalVisible}
        handleClose={() => {
          gtagEvent({
            screen: currentExtraBenefit.eventName,
            action: 'cancel',
            label: 'Cancelled or closed modal',
            type: 'modal',
          });
          setExtrasModalVisible(false);
          setCurrentExtraBenefit({});
        }}
        currentExtraBenefit={currentExtraBenefit}></ExtrasLimitsModal>
    </div>
  );
};

const NoExtras = () => {
  return (
    <div>
      <div className="row mt-4">
        <div className="col">
          <Card className="">
            <LabelledContent label="Extras Cover" className="p-4">
              <h1 className="text-label-gray-no">No Extras</h1>
            </LabelledContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

const OverviewCoverExtrasSection = () => {
  const product = useSelector((state) => state.product);
  const membership = useSelector((state) => state.membership);

  function showExtrasCard() {
    if (!product?.base?.type) {
      return <PlaceHolderCard className="py-4 mt-4 px-4" rows={10} />;
    }

    if (membership?.HealthCover?.CoverStatus !== 'Active') {
      return <NoExtras product={product} />;
    }

    if (product && hasCover(product, 'extras')) {
      return <WithExtras product={product} />;
    } else {
      return <NoExtras product={product} />;
    }
  }

  return <>{showExtrasCard()}</>;
};

export default OverviewCoverExtrasSection;
