import RebateFormActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';

export const setRebateFormShow = (payload) => ({
  type: RebateFormActionTypes.SET_REBATE_FORM_SHOW,
  payload: payload,
});

export const setRebateFormStep = (payload) => ({
  type: RebateFormActionTypes.SET_REBATE_FORM_STEP,
  payload: payload,
});

export const setRebateFormTier = (payload) => ({
  type: RebateFormActionTypes.SET_REBATE_FORM_TIER,
  payload: payload,
});

export const setRebateFormStartDate = (payload) => ({
  type: RebateFormActionTypes.SET_REBATE_FORM_START_DATE,
  payload: payload,
});

export const setRebateFormHTML = (payload) => ({
  type: RebateFormActionTypes.SET_REBATE_FORM_HTML,
  payload: payload,
});

export const setRebateFormReset = () => ({
  type: RebateFormActionTypes.SET_REBATE_FORM_RESET,
});

export const thunkGetRebateFormShow = () => (dispatch) => {
  // const state = getState();
  const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
  const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

  api
    .medicare(CONFIG, token)
    .showForm(memberId)
    .then((response) => {
      dispatch(setRebateFormShow(response));
      // console.log(response)
    });
};
