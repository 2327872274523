import { combineReducers } from 'redux';
import patients from './patients/reducer';
import claims from './claims/reducers';
import user from './user/reducers';
import membership from './membership/reducers';
import rebateRates from './rebateRates/reducers';
import premium from './premium/reducers';
import product from './product/reducers';
import lhc from './lhc/reducers';
import selectedUser from './selectedUser/reducers';
import hra from './hra/reducers';
import ui from './ui/reducers';
import titles from './titles/reducers';
import relationships from './relationships/reducers';
import hospital from './hospital/reducers';
import benefitLimits from './benefitLimits/reducers';
import healthFunds from './healthFunds/reducers';
import claimSpeciality from './claimSpeciality/reducers';
import lastProviders from './lastProviders/reducers';
import userSettings from './userSettings/reducers';
import settings from './settings/reducers';
import fastClaim from './fastClaim/reducers';
import changeCover from './changeCover/reducers';
import ahb from './ahb/reducers';
import rebateForm from './rebateForm/reducers';
import emailConfirmation from './emailConfirmation/reducers';
import paymentMethods from './paymentMethods/reducers'

export default combineReducers({
    patients,
    claims,
    user,
    membership,
    rebateRates,
    premium,
    product,
    lhc,
    selectedUser,
    hra,
    ui,
    titles,
    relationships,
    hospital,
    healthFunds,
    benefitLimits,
    claimSpeciality,
    lastProviders,
    userSettings,
    settings,
    fastClaim,
    changeCover,
    ahb,
    rebateForm,
    emailConfirmation,
    paymentMethods,
});

export * from './patients/actions';
export * from './claims/actions';
export * from './membership/actions';
export * from './rebateRates/actions';
export * from './premium/actions';
export * from './product/actions';
export * from './lhc/actions';
export * from './user/actions';
export * from './selectedUser/actions';
export * from './hra/actions';
export * from './ui/actions';
export * from './titles/actions';
export * from './relationships/actions';
export * from './hospital/actions';
export * from './healthFunds/actions';
export * from './benefitLimits/actions';
export * from './claimSpeciality/actions';
export * from './lastProviders/actions';
export * from './userSettings/actions';
export * from './settings/actions';
export * from './fastClaim/actions';
export * from './changeCover/actions';
export * from './ahb/actions';
export * from './rebateForm/actions';
export * from './emailConfirmation/actions';
export * from './paymentMethods/actions';
