import React from 'react';
import styled from 'styled-components';

const Rule = styled.div`
    background-color: #efeff5;
    height: 2px;
    width: 99%;
    margin-left: auto;
    margin-right: auto;
`;

const CardHorizontalRule = () => {
    return (
        <Rule/>
    )
};

export default CardHorizontalRule;
