import actionTypes from './actions-types';
import {changeCoverConst} from "./actions";
const INITIAL_STATE = {
    coverType: null,
    groupCoverType: null,
    onboarding: {
        step: 1
    },
    mustCover: null,
    mustCoverExtras: null,
    hospitalMustChooseExtras: false,
    showDetails: false,
    showLimitsModal: false,
    limitsModalOptions: null,
    // Use filtering later to tell what is hospital/ extras/ bundle
    products: [],
    // There are no suggestions for bundles
    suggested: {
        extras: [],
        hospital: []
    },
    selected: {
        extras: null,
        hospital: null,
        bundle: null
    },
    selectedProducts: {
        extras: null,
        hospital: null,
        bundle: null
    },
    pricing: {
        extras: null,
        hospital: null,
        bundle: null
    },
    // How often to pay premiums
    paymentFrequency: changeCoverConst.PAYMENT_FREQ_FORTNIGHT
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_COVER_RESET:
            return INITIAL_STATE
        case actionTypes.SET_CHANGE_COVER_TYPE:
            return {
                ...state,
                coverType: action.payload
            };
        case actionTypes.SET_CHANGE_COVER_GROUP_TYPE:
            return {
                ...state,
                groupCoverType: action.payload
            }
        case actionTypes.SET_CHANGE_COVER_MUST_COVER:
            return {
                ...state,
                mustCover: action.payload
            }
        case actionTypes.SET_CHANGE_COVER_MUST_COVER_EXTRAS:
            return {
                ...state,
                mustCoverExtras: action.payload
            }
        case actionTypes.SET_CHANGE_COVER_HOSPITAL_MUST_CHOOSE_EXTRAS:
            return {
                ...state,
                hospitalMustChooseExtras: action.payload
            }
        case actionTypes.SET_CHANGE_COVER_ONBOARDING_STEP:
            return {
                ...state,
                onboarding: {
                    ...state.onboarding,
                    step: action.payload
                }
            }
        case actionTypes.SET_CHANGE_COVER_UI_SHOW_DETAILS:
            return {
                ...state,
                showDetails: action.payload
            }
        case actionTypes.SET_CHANGE_COVER_UI_SHOW_LIMITS_MODAL:
            return {
                ...state,
                showLimitsModal: action.payload
            }
        case actionTypes.SET_CHANGE_COVER_UI_LIMITS_MODAL_OPTIONS:
            return {
                ...state,
                limitsModalOptions: action.payload
            }
        case actionTypes.SET_CHANGE_COVER_PRODUCTS:
            return {
                ...state,
                products: action.payload
            }
        case actionTypes.SET_CHANGE_COVER_SUGGESTED_EXTRAS:
            return {
                ...state,
                suggested: {
                    ...state.suggested,
                    extras: action.payload
                }
            }
        case actionTypes.SET_CHANGE_COVER_SUGGESTED_HOSPITAL:
            return {
                ...state,
                suggested: {
                    ...state.suggested,
                    hospital: action.payload
                }
            }
        case actionTypes.SET_CHANGE_COVER_SELECTED_HOSPITAL_PRODUCT:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    hospital: action.payload
                }
            }
        case actionTypes.SET_CHANGE_COVER_SELECTED_EXTRAS_PRODUCT:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    extras: action.payload
                }
            }
        case actionTypes.SET_CHANGE_COVER_SELECTED_BUNDLE_PRODUCT:
            return {
                ...state,
                selected: {
                    ...state.selected,
                    bundle: action.payload
                }
            }
        case actionTypes.SET_CHANGE_COVER_SELECTED_PRODUCT_HOSPITAL_PRODUCT:
            return {
                ...state,
                selectedProducts: {
                    ...state.selectedProducts,
                    hospital: action.payload
                }
            }
        case actionTypes.SET_CHANGE_COVER_SELECTED_PRODUCT_EXTRAS_PRODUCT:
            return {
                ...state,
                selectedProducts: {
                    ...state.selectedProducts,
                    extras: action.payload
                }
            }
        case actionTypes.SET_CHANGE_COVER_SELECTED_PRODUCT_BUNDLE_PRODUCT:
            return {
                ...state,
                selectedProducts: {
                    ...state.selectedProducts,
                    bundle: action.payload
                }
            }
        case actionTypes.SET_CHANGE_COVER_PRICING_HOSPITAL:
            return {
                ...state,
                pricing: {
                    ...state.pricing,
                    hospital: action.payload
                }
            }
        case actionTypes.SET_CHANGE_COVER_PRICING_EXTRAS:
            return {
                ...state,
                pricing: {
                    ...state.pricing,
                    extras: action.payload
                }
            }
        case actionTypes.SET_CHANGE_COVER_PRICING_BUNDLE:
            return {
                ...state,
                pricing: {
                    ...state.pricing,
                    bundle: action.payload
                }
            }
        case actionTypes.SET_CHANGE_COVER_PAYMENT_FREQ:
            return {
                ...state,
                paymentFrequency: action.payload
            }
        default:
            return state;
    }
};
