import React from 'react';
import { SubmitButton, TruthDeclaration } from '../components';

export const SubmitForm = ({ loading, edit = false }) => {
    return (
        <div className="col pt-3 d-flex">
            <SubmitButton loading={loading} edit={edit} />
            <TruthDeclaration />
        </div>
    );
};

export default SubmitForm;
