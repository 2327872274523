import UserSettingsActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';

export const setUserSettings = (settings) => ({
    type: UserSettingsActionTypes.SET_USER_SETTINGS,
    payload: settings,
});

export const thunkGetUserSettings = () => (dispatch, _getState) => {
    const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    api.user(CONFIG, token)
        .settings.getSettings(memberId)
        .then((response) => {
            if (!response?.message) {
                dispatch(setUserSettings(response));
            }
            // console.log(response)
        });
};

export const thunkUpdatePreferences =
    (diffObject) => async (dispatch, getState) => {
        // First update local prefs
        return new Promise((resolve, reject) => {
            const state = getState();
            const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
            const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);
            const newPreferences = {
                ...state.userSettings,
                ...diffObject,
            };
            api.user(CONFIG, token)
                .settings.updateSettings(memberId, newPreferences)
                .then((response) => {
                    if (response?.status === 'success') {
                        dispatch(setUserSettings(response.data));
                        resolve();
                    } else {
                        reject();
                    }
                })
                .catch(() => {
                    reject();
                });
        });
    };
