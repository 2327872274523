import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const PartnerAuthority = () => {
    const { values, handleChange } = useFormikContext();
    return (
        <div className="col pb-2 modal-box-shadow">
            <div className="font-weight-semibold text-black pt-2 font-14">
                Partner Authority
            </div>
            <div>
                <Form.Check
                    inline
                    type="radio"
                    id={`partner-auth-1`}
                    label={`Only myself`}
                    name={`partnerAuth`}
                    value="0"
                    checked={values.partnerAuth === '0'}
                    onChange={handleChange}
                    className="text-label-gray font-weight-medium font-13"
                />
                <Form.Check
                    inline
                    type="radio"
                    id={`partner-auth-2`}
                    label={`Myself and my spouse`}
                    name={`partnerAuth`}
                    value="1"
                    checked={values.partnerAuth === '1'}
                    onChange={handleChange}
                    className="text-label-gray font-weight-medium font-13"
                />
            </div>
        </div>
    );
};

export default PartnerAuthority;
