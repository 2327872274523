import React, { useMemo } from 'react';

const LabelRebate = ({ membership }) => {
  if (!membership?.Contribution?.RebateStatus) {
    return <div>-</div>;
  }

  const renderedTier = useMemo(() => {
    const tier = membership.Contribution?.RebateTier;
    switch (tier) {
      case 0:
        return 'Base Tier';
      case 1:
        return 'Tier 1';
      case 2:
        return 'Tier 2';
      case 3:
        return 'Tier 3';
    }
  }, [membership.Contribution?.RebateTier])

  return (
    <>
      {membership?.Contribution?.RebateStatus === 'Unregistered' ? (
        <div>Unregistered</div>
      ) : (
        <div>
          {renderedTier} -{' '}
          <strong>{membership?.Contribution?.RebatePercentage}%</strong>
        </div>
      )}
    </>
  );
};

export default LabelRebate;
