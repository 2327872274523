import BenefitLimitsActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';

export const setBenefitLimits = (limits) => ({
    type: BenefitLimitsActionTypes.SET_BENEFIT_LIMITS,
    payload: limits,
});

export const thunkGetBenefitLimits = () => (dispatch, _getState) => {
    const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    api.user(CONFIG, token)
        .benefits.getBenefitLimits(memberId)
        .then((response) => {
            dispatch(setBenefitLimits(response));
            // console.log(response)
        });
};
