import TitlesActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';

export const setTitles = (titles) => ({
    type: TitlesActionTypes.SET_TITLES,
    payload: titles,
});

export const thunkGetTitles = () => (dispatch, _getState) => {
    // const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    api.user(CONFIG, token)
        .person.getTitles()
        .then((response) => {
            dispatch(setTitles(response));
            // console.log(response)
        });
};
