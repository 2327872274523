import { TabItem } from './components/TabItem';
import React from 'react';

export const Tabs = ({ updateTab, updateSchema }) => {
    return (
        <div className="d-flex align-items-center bg-primary-dark-1 tab-frame">
            <div
                onClick={() => {
                    updateTab.setSelectedTab(1);
                }}>
                <TabItem
                    title="Home Address"
                    active={updateTab.selectedTab === 1}
                />
            </div>
            {updateSchema.usePostalSchema && (
                <div
                    onClick={() => {
                        updateTab.setSelectedTab(0);
                    }}>
                    <TabItem
                        title="Postal Address"
                        active={updateTab.selectedTab === 0}
                    />
                </div>
            )}
        </div>
    );
};

export default Tabs;
