import React from 'react';

export const Terms = () => {
    return (
        <div className="row modal-box-shadow">
            <div className="py-3  ">
                <div className="row px-3">
                    <div className="d-flex px-3">
                        <div className="text-secondary flex-shrink-1">^</div>
                        <div className="flex-grow-1 pl-2 font-14">
                            The offer is available to members whose premium
                            payments are up-to-date at the date of referral. The
                            referred family member/friend cannot currently be a
                            Union Health or TUH member or dependant of a Union
                            Health or TUH member coming off a parent&apos;s
                            policy. The $100 eGift Card will be sent within 30
                            days of receiving the first premium of the new
                            member. This offer is not available in conjunction
                            with any other offer.
                        </div>
                    </div>
                </div>
                <div className="row px-3">
                    <div className="d-flex px-3">
                        <div className="text-secondary flex-shrink-1">*</div>
                        <div className="flex-grow-1 pl-2 font-14">
                            <a
                                href="https://tuh.com.au/jump-into-2021-terms"
                                target="_blank"
                                rel="noopener noreferrer">
                                Terms and conditions&nbsp;
                            </a>
                            apply.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Terms;
