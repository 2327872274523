import React from 'react';

const HeroImage = ({ image }) => {
    return (
        <div className="col-12 col-md-5 order-first order-md-last d-flex align-self-center justify-content-center">
            {image}
        </div>
    );
};

export default HeroImage;
