import moment from 'moment';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { cleanDate } from '../../helpers';

export const JoinDate = ({ loading, edit = false }) => {
    const { touched, errors, values, handleBlur, setFieldValue } =
        useFormikContext();

    const INITIAL_DATE = cleanDate(values?.joindate ?? '');

    return (
        <Form.Group controlId={`joindate`} className="col-6 text-label-gray">
            <Form.Label>Join Date</Form.Label>
            <DatePicker
                wrapperClassName={
                    touched.joindate &&
                    errors.joindate &&
                    !loading &&
                    'is-invalid'
                }
                className={
                    touched.joindate && errors.joindate && !loading
                        ? 'is-invalid form-control'
                        : 'form-control'
                }
                dateFormat="dd/MM/yyyy"
                selected={INITIAL_DATE}
                readOnly={edit}
                minDate={moment().toDate()}
                maxDate={moment().add(1, 'month').toDate()}
                onChange={(e) => {
                    setFieldValue('joindate', e);
                }}
                onBlur={handleBlur('joindate')}
            />

            {touched.joindate && errors.joindate && !loading && (
                <Form.Control.Feedback type={'invalid'}>
                    {errors.joindate}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
};

export default JoinDate;
