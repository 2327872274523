import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const FromAnotherFund = () => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <Form.Group controlId={`previousfund-transfering`}>
            <Form.Label className="d-block">
                Person transfering from another fund?
            </Form.Label>
            <Form.Check
                inline
                type="radio"
                id={`previoushealthtransfer-1`}
                label={`Yes`}
                name={`previoushealthtransfer`}
                className={'text-label-gray font-weight-medium'}
                style={{
                    fontSize: '13px',
                }}
                checked={values.previoushealthtransfer === true}
                onChange={() => {
                    setFieldValue('previoushealthtransfer', true);
                }}
            />
            <Form.Check
                inline
                type="radio"
                id={`previoushealthtransfer-2`}
                label={`No`}
                name={`previoushealthtransfer`}
                className={'text-label-gray font-weight-medium'}
                style={{
                    fontSize: '13px',
                }}
                checked={values.previoushealthtransfer === false}
                onChange={() => {
                    setFieldValue('previoushealthtransfer', false);
                }}
            />
        </Form.Group>
    );
};

export default FromAnotherFund;
