import moment from 'moment';
import { mainPerson } from 'libs/helpers';

export const generatePayload = (values, user, membership) => {
    const memberId = user.memberId;
    const primaryPerson = mainPerson(membership);
    const primaryPersonName = `${primaryPerson.Person.GivenName} ${primaryPerson.Person.Surname}`;

    const payload = {
        memberid: memberId,
        name: primaryPersonName,
        declare: 'safe_key',
        flight_cruise_number_start: '',
        departureDate: '',
        flight_cruise_number_end: '',
        return_date: '',
        all_people: 'safe_key',
        person_1: '',
        person_2: '',
        person_3: '',
        person_4: '',
        partner_not_traveling: '',
        partner_auth: '',
        email: '',
        mobile: '',
    };

    values.departingNumber &&
        (payload.flight_cruise_number_start = values.departingNumber);

    values.startDate &&
        (payload.departure_date = moment(values.startDate).format(
            'YYYY-MM-DD',
        ));

    values.returningNumber &&
        (payload.flight_cruise_number_end = values.returningNumber);

    values.endDate &&
        (payload.return_date = moment(values.endDate).format('YYYY-MM-DD'));

    if (!values.everyoneOnMembershipIsTravelling) {
        payload.all_people = 'safe_key1';

        if (values.additionalPeople.length > 0) {
            payload.person_1 = values.additionalPeople[0];
        }

        if (values.additionalPeople.length > 1) {
            payload.person_2 = values.additionalPeople[1];
        }

        if (values.additionalPeople.length > 2) {
            payload.person_3 = values.additionalPeople[2];
        }

        if (values.additionalPeople.length > 3) {
            payload.person_4 = values.additionalPeople[3];
        }
    }

    if (values.partnerOrDepNotTravelling) {
        payload.partner_not_travelling = 'safe_key';
        payload.partner_auth = 'safe_key';
    }

    values.overseasContactEmail &&
        (payload.email = values.overseasContactEmail);

    values.overseasContactPhone &&
        (payload.phone = values.overseasContactPhone);

    return payload;
};
