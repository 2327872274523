import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const Surname = ({ loading }) => {
    const { touched, errors, getFieldProps } = useFormikContext();

    return (
        <Form.Group
            controlId={`surname`}
            className="flex-grow-1 text-label-gray">
            <Form.Label>Surname</Form.Label>
            <Form.Control
                {...getFieldProps('lastname')}
                type="text"
                className={
                    errors.lastname &&
                    touched.lastname &&
                    !loading &&
                    'is-invalid'
                }
            />
            {touched.lastname && errors.lastname && !loading && (
                <Form.Control.Feedback type={'invalid'}>
                    {errors.lastname}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
};

export default Surname;
