import React from 'react';

export const StatusBadge = ({ status }) => {
    if (!status) {
        return null;
    }

    switch (status) {
        case 'Paid':
            return (
                <span className="font-weight-bold text-center claim-status-badge paid">
                    PAID
                </span>
            );
        case 'Verified':
            return (
                <span className="font-weight-bold text-center claim-status-badge">
                    AWAITING PAYMENT
                </span>
            );
        case 'Received':
        case 'Assessed':
            return (
                <span className="font-weight-bold text-center claim-status-badge">
                    PROCESSING
                </span>
            );
        default:
            return null;
    }
};

export default StatusBadge;
