import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';

export const usePlacesAutocomplete = (type = 'home') => {
  const [placesSdk, setPlacesSdk] = useState(null);
  const inputRef = useRef(null);
  const places = window.google.maps.places;
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!places || !inputRef.current) return;
    setPlacesSdk(
        new places.Autocomplete(inputRef.current, {
          types: ['address'],
          componentRestrictions: { country: 'au' },
        }),
      );
  }, [places]);

  useEffect(() => {
    if (!placesSdk) return;

    placesSdk.addListener('place_changed', async () => {
      const autocomplete = await handlePlaceSelect(placesSdk, type);
      for (const field in autocomplete) {
        setFieldValue(field, autocomplete[field]);
      }
    });
  }, [placesSdk]);

  return inputRef;
};

const handlePlaceSelect = async (placesSDK, type) => {
  if (!placesSDK) return;
  const addressObject = placesSDK.getPlace();

  // Full address object
  const placeAddress = addressObject.address_components;

  // These fields can be safely undefined
  const subPremise = placeAddress.find((component) =>
    component.types.includes('subpremise'),
  )?.long_name;

  // These fields will throw if not defined
  const postCode = placeAddress.find((component) =>
    component.types.includes('postal_code'),
  ).long_name;
  const suburb = placeAddress.find((component) =>
    component.types.includes('locality'),
  ).short_name;
  const state = placeAddress.find((component) =>
    component.types.includes('administrative_area_level_1'),
  ).short_name;
  const streetName = placeAddress.find((component) =>
    component.types.includes('route'),
  ).long_name;
  const streetNumber = placeAddress.find((component) =>
    component.types.includes('street_number'),
  ).long_name;

  // Addresses might not have an appt number
  const fullStreetNumber = subPremise
    ? `${subPremise}/${streetNumber}`
    : streetNumber;

  return {
    [`${type}Line1`]: `${fullStreetNumber} ${streetName}`,
    [`${type}Suburb`]: suburb,
    [`${type}State`]: state,
    [`${type}Postcode`]: postCode,
  };
};
