import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const MemberNumber = () => {
    const { values, getFieldProps } = useFormikContext();

    return (
        <Form.Group
            controlId={`previousfund-number`}
            className="col-6 text-label-gray"
            style={{
                display: values.previoushealthtransfer ? 'block' : 'none',
            }}>
            <Form.Label>Previous Membership Number</Form.Label>
            <Form.Control
                type="text"
                {...getFieldProps('previoushealthfundno')}
            />
        </Form.Group>
    );
};

export default MemberNumber;
