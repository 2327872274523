import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const ReturnDetails = () => {
    const { values, setFieldValue, touched, errors, setFieldTouched } =
        useFormikContext();

    return (
        <>
            <div className="row end-date-row mb-1">
                <div className="col-sm-6">
                    <Form.Group controlId={`suspend-end-date`}>
                        <Form.Label className="text-label-gray">
                            Return Date
                        </Form.Label>
                        <Form.Control
                            type="date"
                            value={values.endDate}
                            onChange={(e) => {
                                setFieldTouched('endDate', true);
                                setFieldValue('endDate', e.target.value);
                            }}
                            error={touched.endDate && errors.endDate}
                            required
                        />
                        {errors.endDate && (
                            <small className="text-danger form-text">
                                {errors.endDate}
                            </small>
                        )}
                    </Form.Group>
                </div>
                <div className="col-sm-6">
                    <Form.Group
                        controlId={`returning-flight-'returningNumber'`}>
                        <Form.Label className="text-label-gray">
                            Returning Flight or Cruise Number
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={values.returningNumber}
                            onChange={(e) => {
                                setFieldTouched('returningNumber', true);
                                setFieldValue(
                                    'returningNumber',
                                    e.target.value,
                                );
                            }}
                            error={
                                touched.returningNumber &&
                                errors.returningNumber
                            }
                            label={'Returning Flight/Cruise Number'}
                        />
                        {errors.returningNumber && (
                            <small className="text-danger form-text">
                                {errors.returningNumber}
                            </small>
                        )}
                    </Form.Group>
                </div>
            </div>
        </>
    );
};

export default ReturnDetails;
