import PremiumActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';

export const setPremium = (premium) => ({
    type: PremiumActionTypes.SET_PREMIUM,
    payload: premium,
});

export const thunkGetPremium = () => (dispatch, _getState) => {
    const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    api.user(CONFIG, token)
        .membership.premium.getPremium(memberId)
        .then((response) => {
            if (!response?.RateQuote) {
                dispatch(setPremium(null));
            } else {
                dispatch(
                    setPremium(response.RateQuote),
                );
            }
            // console.log(response)
        });
};
