import AHBActionTypes from './actions-types';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case AHBActionTypes.SET_AHB:
            console.log(`SET_AHB with payload ${action.payload}`);
            return action.payload;
        default:
            return state;
    }
};
