import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(
        'You must enter your current password.',
    ),
    newPassword: Yup.string()
        .required('A new password is required.')
        .min(8, 'Password must be at least 8 characters.')
        .max(20, 'Password must be no longer than 20 characters.')
        .matches(
            /[A-Z]/,
            'Password must contain at least one uppercase character.',
        )
        .matches(
            /[a-z]/,
            'Password must contain at least one lowercase character.',
        )
        .matches(/[0-9]/, 'Password must contain at least one number.')
        .test({
            name: 'no-illegal',
            message: 'Password cannot contain <, >, &, # or spaces.',
            test: (value) => {
                if (!value) return true;
                return !(
                    value.includes('#') ||
                    value.includes('&') ||
                    value.includes('<') ||
                    value.includes('>') ||
                    value.includes(' ')
                );
            },
        }),
    newPasswordConfirm: Yup.string()
        .required('You must confirm your password.')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match.'),
});

export default validationSchema;
