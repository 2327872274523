export const safariDateScrub = (datePart) => {
    /**
     * The safari browser does not except single digits of days or months
     * ie: if the month is January - September and/or the date is the 1st - 9th,
     * the date part must have a leading 0. [eg: Feb = 02 not 2]
     */

    if (datePart < 10) return `0${datePart}`;
    return `${datePart}`;
};
