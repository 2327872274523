import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useTracking = (trackingId = process.env.REACT_APP_GTAG_ID) => {
    const location = useLocation();

    useEffect(() => {
        if (!window.gtag) return;
        if (!trackingId) {
            console.log(
                'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.',
            );
            return;
        }
        console.log('FIRE GTAG EVENT', { page_path: location.pathname });
        window.gtag('config', trackingId, { page_path: location.pathname });
        window.gtag('event', 'page_view', {
            page_location: location.pathname,
            page_path: location.pathname,
            page_title: location.pathname,
        });
    }, [trackingId, location]);
};
