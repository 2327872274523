import { Link, useRouteMatch } from 'react-router-dom';
import React from 'react';

interface Props {
    to: string;
    children: React.ReactNode;
    textClass?: string;
    onClick: () => void;
}

export const HeaderLink: React.FC<Props> = ({ to: linkTo, children, textClass, onClick }) => {
    let match = useRouteMatch({
        path: linkTo,
        exact: true,
    });

    return (
        <Link
            className={`nav-link d-flex align-items-center ${
                match ? 'active' : ''
            }`}
            to={linkTo}
            onClick={onClick}>
            <span className={textClass}>{children}</span>
        </Link>
    );
};
