import React from 'react';
import { ReactComponent as SrcSvg } from './src.svg';

interface Props {
    style?: React.CSSProperties;
}

const IconError: React.FC<Props> = ({ style }) => {
    // alt={'Error'} not used?
    return <SrcSvg  style={style} />;
};

export default IconError;
