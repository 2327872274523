import React, { useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { useAsyncFn } from 'react-use';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { Formik } from 'formik';
import validationSchema from './validationSchema';
import Fade from 'react-reveal/Fade';
import gtagEvent from 'libs/gtagEvent';
import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';

export function ChangePassword() {
    const [message, setMessage] = useState('');
    const [outcome, setOutcome] = useState<string | null>(null);

    const idPrefix = 'change-password';

    const submitCallback = async (values, resetForm): Promise<void> => {
        console.log(values);

        const access_token = getAccessToken();
        const memberid = getMembershipId();

        try {
            if (!memberid) {
                throw new Error('No member id found');
            }
            const response = await api
                .user(CONFIG, access_token)
                .changePassword(
                    memberid,
                    values.currentPassword,
                    values.newPassword,
                );

            if (response?.status === 'error') {
                throw new Error(response.message);
            } else {
                setMessage(
                    'Password changed. It will take about 2 minutes for new password to take effect',
                );
                setOutcome('success');

                setTimeout(function () {
                    setMessage('');
                    setOutcome(null);
                }, 5000);
            }
        } catch (response) {
            if (response instanceof Error) {
                console.log('ERROR');
                console.log(response.message);
                setMessage(response.message);
            }
            setOutcome('fail');
            setTimeout(function () {
                setMessage('');
                setOutcome(null);
            }, 5000);
        }

        resetForm();
    }

    const [onSubmitState, onSubmit] = useAsyncFn<any,any>(submitCallback);

    return (
        <Card className="p-4">
            <h1 className={'text-primary text-uppercase'}>Change Password</h1>
            <Formik
                validateOnChange={true}
                validateOnBlur={true}
                validateOnMount={true}
                initialValues={{
                    currentPassword: '',
                    newPassword: '',
                    newPasswordConfirm: '',
                }}
                onSubmit={(values, { resetForm }) => {
                    gtagEvent({
                        screen: 'membership',
                        action: 'update_password',
                        label: 'Submitted new password',
                    });
                    onSubmit(values, resetForm);
                }}
                validationSchema={validationSchema}>
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isValid,
                    } = props;
                    return (
                        <Form>
                            <Form.Group
                                controlId={`${idPrefix}-current-password`}>
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="currentPassword"
                                    className={
                                        errors.currentPassword &&
                                        touched.currentPassword &&
                                        !onSubmitState.loading
                                            ? 'is-invalid'
                                            : ''
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.currentPassword}
                                    autoComplete="off"
                                />
                                {touched.currentPassword &&
                                errors.currentPassword &&
                                !onSubmitState.loading ? (
                                    <Form.Control.Feedback type={'invalid'}>
                                        {errors.currentPassword}
                                    </Form.Control.Feedback>
                                ) : null}
                            </Form.Group>
                            <Form.Group controlId={`${idPrefix}-new-password`}>
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="newPassword"
                                    className={
                                        errors.newPassword &&
                                        touched.newPassword &&
                                        !onSubmitState.loading
                                            ? 'is-invalid'
                                            : ''
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.newPassword}
                                    autoComplete="new-password"
                                />
                                {touched.newPassword &&
                                errors.newPassword &&
                                !onSubmitState.loading ? (
                                    <Form.Control.Feedback type={'invalid'}>
                                        {errors.newPassword}
                                    </Form.Control.Feedback>
                                ) : null}
                            </Form.Group>
                            <Form.Group
                                controlId={`${idPrefix}-confirm-password`}>
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="newPasswordConfirm"
                                    className={
                                        errors.newPasswordConfirm &&
                                        touched.newPasswordConfirm &&
                                        !onSubmitState.loading
                                            ? 'is-invalid'
                                            : ''
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.newPasswordConfirm}
                                    autoComplete="new-password"
                                />
                                {touched.newPasswordConfirm &&
                                    errors.newPasswordConfirm &&
                                    !onSubmitState.loading && (
                                        <Form.Control.Feedback type={'invalid'}>
                                            {errors.newPasswordConfirm}
                                        </Form.Control.Feedback>
                                    )}
                            </Form.Group>

                            <Button
                                variant="secondary"
                                type="submit"
                                onClick={(e: React.MouseEvent<HTMLFormElement, MouseEvent>) => handleSubmit(e)}
                                disabled={!isValid}>
                                {onSubmitState.loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        as="span"
                                        size="sm"
                                    />
                                ) : (
                                    'Save Changes'
                                )}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
            <Fade bottom when={outcome !== null}>
                <StatusNotificationCard
                    status={outcome === 'fail' ? 'error' : 'success'}>
                    {message}
                </StatusNotificationCard>
            </Fade>
        </Card>
    );
}
