import React from 'react';
import CustomModal from 'components/modals/Modal';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';

const StyledTable = styled(Table)`
    td:not(:first-child),
    th:not(:first-child) {
        text-align: right;
    }
`;

const AgeBasedDiscountModal = ({ show, handleClose, align }) => {
    return (
        <CustomModal
            handleClose={handleClose}
            align={align}
            show={show}
            title={'Aged Based Discount'}>
            <div className={'text-primary font-feature row row-cols-1'}>
                <div
                    className="col text-black"
                    style={{
                        fontSize: '14px',
                    }}>
                    <p>
                        To help make private health insurance more affordable,
                        we offer a discount of up to 10% for people who join
                        selected hospital covers before the age of 30.
                    </p>

                    <p>
                        The discount applies to the full cost of the hospital
                        component on eligible covers.
                    </p>
                </div>
                <div>
                    <StyledTable
                        className=""
                        responsive
                        striped
                        style={{ marginBottom: '0px' }}>
                        <thead>
                            <tr>
                                <th>Age when joined</th>
                                <th>Discount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ADB_DISCOUNT.map((row, idx) => (
                                <tr
                                    key={`claim_row_${idx}`}
                                    className={'table-link'}>
                                    <td className={'d-flex align-items-center'}>
                                        {row.age}
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                        {row.discount}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </StyledTable>
                </div>
                <div
                    className="col text-black"
                    style={{
                        boxShadow: 'inset 0 2px 0 0 #e5e4ea',
                        fontSize: '14px',
                        paddingTop: '15px',
                    }}>
                    <p>
                        As long as you retain an eligible policy the discount
                        applies until you turn 41, then decreases by 2% per
                        year.
                    </p>
                    <p>
                        For more info{' '}
                        <a
                            href="https://tuh.com.au/information/government-initiatives/age-based-discount"
                            target="_blank"
                            rel="noopener noreferrer">
                            click here
                        </a>
                        .
                    </p>
                </div>
            </div>
        </CustomModal>
    );
};

export default AgeBasedDiscountModal;

const ADB_DISCOUNT = [
    {
        age: 'Under 26',
        discount: '10%',
    },
    {
        age: '26',
        discount: '8%',
    },
    {
        age: '27',
        discount: '6%',
    },
    {
        age: '28',
        discount: '4%',
    },
    {
        age: '29',
        discount: '2%',
    },
];
