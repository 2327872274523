import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const TruthDeclaration = () => {
    const { getFieldProps } = useFormikContext();

    return (
        <div className="align-self-center">
            <Form.Check
                type="checkbox"
                id={`checkbox-declare`}
                label={`I declare the information provided is correct`}
                className={'text-label-gray font-weight-medium'}
                style={{
                    fontSize: '13px',
                }}
                {...getFieldProps('declare')}
            />
        </div>
    );
};

export default TruthDeclaration;
