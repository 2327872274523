import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import moment from 'moment';

const RegisterDob: React.FC = () => {
    const {
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        getFieldProps,
        isSubmitting,
    } = useFormikContext();
    const dobKey = 'dob';

    return (
        <Form.Group className={'col-12 col-lg-6'} controlId="member-dob">
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control
                {...getFieldProps(dobKey)}
                type="date"
                placeholder="dd/mm/yyyy"
                max={moment().format('YYYY-MM-DD')}
                onChange={(e) => {
                    setFieldTouched(dobKey, true);
                    setFieldValue(dobKey, e.target.value);
                }}
                autoComplete="new-field"
            />
            {touched[dobKey] && errors[dobKey] && !isSubmitting && (
                <small className="text-danger form-text">
                    {errors[dobKey]}
                </small>
            )}
        </Form.Group>
    );
};

export default RegisterDob;
