import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';

const MobileDetect = require('mobile-detect');
const goHome = () => {window.location.href = '/'};

export const ResetPasswordPage = () => {
    useEffect(() => {
        // Get the data object from the query string
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const base64Data = urlParams.get('data');
        if (!base64Data) {
            alert('no data param');
            return goHome();
        }

        // Attempt decode
        let asciiData;
        try {
            asciiData = atob(base64Data);
            /** atob is deprecated use this instead */
            // asciiData = Buffer.from(base64Data, 'base64');
        } catch {
            alert('failed to convert');
            return goHome();
        }

        // Attempt parse to JSON
        // Even though we wont use the JSON here, we're just checking that its well formed.
        try {
            JSON.parse(asciiData);
        } catch {
            alert('failed to parse');
            return goHome();
        }

        const mobileDetected = new MobileDetect(window.navigator.userAgent);

        if (mobileDetected.is('iOS')) {
            window.location.replace(`tuh://changepassword?data=${base64Data}`);
        } else if (mobileDetected.is('AndroidOS')) {
            window.location.href = `/changepassword?data=${base64Data}`;
        } else {
            window.location.href = `/changepassword?data=${base64Data}`;
        }
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                width: '100vw',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'space-around',
            }}>
            <Spinner animation="border" role="status" as="span" />
        </div>
    );
};
