import React from 'react';
import Visa from 'img/vector/Visa/comp';
import Mastercard from 'img/vector/Mastercard/comp';

const CardPicture = ({ type, style }) => {
  switch (type) {
    default:
      return null;
    case 'Mastercard':
      return <Mastercard style={style} />;
    case 'Visa':
      return <Visa style={style} />;
  }
};

export default CardPicture;
