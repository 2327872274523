import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const FirstName = ({ loading, edit = false }) => {
    const { touched, errors, getFieldProps } = useFormikContext();
    return (
        <Form.Group
            controlId={`firstname`}
            className="flex-grow-1 text-label-gray">
            <Form.Label>First Name</Form.Label>
            <Form.Control
                {...getFieldProps('firstname')}
                readOnly={edit}
                type="text"
                className={
                    errors.firstname &&
                    touched.firstname &&
                    !loading &&
                    'is-invalid'
                }
            />
            {touched.firstname && errors.firstname && !loading && (
                <Form.Control.Feedback type={'invalid'}>
                    {errors.firstname}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
};

export default FirstName;
