import AHBActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';

export const setAHB = (ahb) => ({
    type: AHBActionTypes.SET_AHB,
    payload: ahb,
});

export const thunkGetAHB = () => (dispatch) => {
    const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    api.user(CONFIG, token)
        .benefits.getHasAHB(memberId)
        .then((response) => {
            dispatch(setAHB(response));
        });
};
