import React from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { injectDate } from '../common/functions';
import { FastClaimTypeItem } from 'types/fastClaim';

interface ServiceDateProps {
    claimItem?: FastClaimTypeItem;
    updateClaim: (newObject: any) => void;
}

export const ServiceDate: React.FC<ServiceDateProps> = ({ claimItem, updateClaim }) => {
    return (
        <Form.Group
            controlId={`service-date`}
            className="col-6 text-label-gray">
            <Form.Label style={{ fontSize: '11px' }}>Service Date</Form.Label>
            <DatePicker
                className="form-control"
                dateFormat="dd/MM/yyyy"
                selected={claimItem?.dateOfServiceControl ?? ''}
                maxDate={moment().toDate()}
                minDate={moment().subtract(2, 'years').toDate()}
                onChange={(event) => {
                    const newObject = injectDate(claimItem, event);
                    updateClaim(newObject);
                }}
                showMonthYearDropdown
            />
        </Form.Group>
    );
};

export default ServiceDate;
