import actionType from './action-types';

export const setEmailConfirmed = (boolean) => {
    return {
        type: actionType.SET_CONFIRMATION_STATUS,
        payload: boolean,
    };
};

export const addIdWithEmailConfirmed = (personId) => {
    return {
        type: actionType.ADD_ID_WITH_EMAIL_CONFIRMED,
        payload: personId,
    };
};
export const setConfirmingPerson = (personId) => {
    return {
        type: actionType.SET_CONFIRMING_PERSON,
        payload: personId,
    };
};
