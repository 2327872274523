import React from 'react';
import { Button } from 'react-bootstrap';
import gtagEvent from 'libs/gtagEvent';

const SocialCircle = ({ color, icon, href, gtag, disabled }) => {
    const eventScreen = gtag.screen ?? 'referafriend';
    const eventAction = gtag.action ?? 'social_circle';
    const eventLabel = gtag.label ?? 'Clicked a share on social button.';
    return (
        <Button
            variant={'social-circle'}
            style={{ backgroundColor: color }}
            href={href}
            target={'_blank'}
            rel="noopener noreferrer"
            disabled={!!disabled}
            onClick={() => {
                gtagEvent({
                    screen: eventScreen,
                    action: eventAction,
                    label: eventLabel,
                });
            }}>
            {icon}
        </Button>
    );
};

export default SocialCircle;
