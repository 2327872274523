import React from 'react';
import LabelledContent from 'components/elements/labels/LabelledContent';

const Discount = ({ button, label, contents, icon }) => {
    return (
        <div className="d-flex justify-content-between mx-4">
            <div className="d-flex align-items-center">
                <div className="mr-2">{icon}</div>
                <LabelledContent label={`${label}`}>
                    <div className="font-feature">{contents}</div>
                </LabelledContent>
            </div>

            {button}
        </div>
    );
};

export default Discount;
