import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CardAltDivision from 'components/elements/divisions/CardAltDivision';
import { MdChevronRight } from 'react-icons/md';
import CoverStatusBadge from 'components/elements/badges/CoverStatusBadge';
import LabelledBoldTextBlock from 'components/elements/labels/LabelledBoldTextBlock';
import CardVerticalBorderContainer from 'components/elements/divisions/CardVerticalBorderContainer';
import PaymentMethodBadge from 'components/elements/badges/PaymentMethodBadge';
import LabelledContent from 'components/elements/labels/LabelledContent';
import { IoMdDocument } from 'react-icons/all';
import BenefitCalculator from 'components/elements/buttons/BenefitCalculator';
import MakeAContributionModal from 'components/modals/MakeAContributionModal/MakeAContributionModal';
import {
    ymdToDate,
    getPaymentMethodDebit,
    getSelectedUser,
    allPersons,
} from 'libs/helpers';
import gtagEvent from 'libs/gtagEvent';
import { setHraModalVisible, setBenefitCalculatorModalVisible } from 'store';
import { MdCreditCard, GiPerson } from 'react-icons/all';

import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import { getPaymentObj } from 'libs/premiumHelpers';

const OverviewCoverControlsSection = () => {
  const dispatch = useDispatch();
  const membership = useSelector((state) => state.membership);
  const premium = useSelector((state) => state.premium);
  const selectedUserId = useSelector((state) => state.selectedUser);
  const hraTokens = useSelector((state) => state.hra);
  const [modalVisible, setModalVisible] = useState(false);

  const users = useMemo(() => allPersons(membership), [membership])
  const currentUser = useMemo(() => getSelectedUser(users, selectedUserId), [selectedUserId, users])

  function getMembershipCard(mainPerson) {
      if (!mainPerson) {
          return;
      }

      if (!mainPerson?.MembershipCards?.MembershipCard) {
          return null;
      }

      const cards = mainPerson?.MembershipCards?.MembershipCard;
      if (cards.length > 0) {
          // It's an array
          // console.log(cards);
          let newestCard;
          for (const card of cards) {
              if (card.CardStatus !== 'Active') continue;
              if (!newestCard) {
                  newestCard = card;
              }

              const newestCardDateParts = newestCard.IssueDate.split('-');
              const newestCardDate = new Date(
                  newestCardDateParts[0],
                  newestCardDateParts[1],
                  newestCardDateParts[2],
              );

              const cardDateParts = card.IssueDate.split('-');
              const cardDate = new Date(
                  cardDateParts[0],
                  cardDateParts[1],
                  cardDateParts[2],
              );

              if (cardDate > newestCardDate) {
                  newestCard = card;
              }
          }
          // newestCard.CardNumberFormatted = newestCard.CardNumber.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/,'');
          return newestCard?.CardNumber?.replace(
              /\B(?=(\d{4})+(?!\d))/g,
              ' ',
          );
          // console.log(newestCard);
          // return newestCard;
      } else {
          // cards.CardNumberFormatted = cards.CardNumber.replace(/\B(?=(\d{4})+(?!\d))/g, " ");
          return cards?.CardNumber?.replace(/\B(?=(\d{4})+(?!\d))/g, ' ');
      }
  }

  const payment = getPaymentObj(premium, membership?.Contribution?.Frequency);

  const membershipCard = getMembershipCard(currentUser);

  function showMemebershipCard() {
      if (!membership) {
          return (
              <ReactPlaceholder
                  showLoadingAnimation
                  rows={1}
                  color="#e5e4ed"
              />
          );
      }
      if (!membershipCard && membership) {
          return '-';
      } else {
          return (
              <>
                  <MdCreditCard size="20" /> {membershipCard}
              </>
          );
      }
  }

    return (
        <>
            <Card className="pt-4 pb-2 h-100">
                <h1 className="px-4 mx-1 mb-4">
                    {currentUser?.Person?.GivenName ? (
                        <>Welcome {currentUser?.Person?.GivenName}</>
                    ) : (
                        <ReactPlaceholder
                            showLoadingAnimation
                            rows={1}
                            color="#e5e4ed"
                        />
                    )}
                </h1>

                <CardVerticalBorderContainer>
                    <div className="d-flex justify-content-between pb-2 px-4 align-items-center flex-wrap">
                        <div className={'mr-3 mt-2'}>
                            <CoverStatusBadge membership={membership} />
                        </div>

                        <div
                            className="mr-3 mt-2"
                            style={{
                                display:
                                    membership?.HealthCover?.CoverStatus !==
                                    'Active'
                                        ? 'none'
                                        : 'block',
                            }}>
                            <LabelledBoldTextBlock
                                label="Next Payment"
                                text={
                                    !payment?.NetAmount ? (
                                        <ReactPlaceholder
                                            showLoadingAnimation
                                            rows={1}
                                            color="#e5e4ed"
                                        />
                                    ) : (
                                        `$` + payment?.NetAmount
                                    )
                                }
                            />
                        </div>

                        <div
                            className="mr-3 mt-2"
                            style={{
                                display:
                                    membership?.HealthCover?.CoverStatus !==
                                    'Active'
                                        ? 'none'
                                        : 'block',
                            }}>
                            <LabelledBoldTextBlock
                                label="Due Date"
                                text={
                                    ymdToDate(
                                        membership?.Contribution?.DueDate,
                                    ) || (
                                        <ReactPlaceholder
                                            showLoadingAnimation
                                            rows={1}
                                            color="#e5e4ed"
                                        />
                                    )
                                }
                            />
                        </div>

                        <div
                            className="mr-3 mt-2"
                            style={{
                                display:
                                    membership?.HealthCover?.CoverStatus !==
                                    'Active'
                                        ? 'none'
                                        : 'block',
                            }}>
                            <LabelledBoldTextBlock
                                label="Cover Paid To"
                                text={
                                    ymdToDate(
                                        membership?.Contribution?.PaidToDate,
                                    ) || (
                                        <ReactPlaceholder
                                            showLoadingAnimation
                                            rows={1}
                                            color="#e5e4ed"
                                        />
                                    )
                                }
                            />
                        </div>
                    </div>
                </CardVerticalBorderContainer>

                {/* Membership cards and payments */}
                <div className="mx-1">
                    <CardAltDivision>
                        <div className="px-2 d-flex py-3 row">
                            <div className="col-5">
                                <LabelledContent label="TUH Membership Card No.">
                                    <div
                                        className="text-black font-weight-medium"
                                        style={{ fontSize: '14px' }}>
                                        {showMemebershipCard()}
                                    </div>
                                </LabelledContent>
                            </div>
                            <div className="col-3 px-1">
                                <LabelledContent
                                    label={
                                        membership?.Contribution?.Frequency ? (
                                            `${membership?.Contribution?.Frequency} Payments`
                                        ) : (
                                            <ReactPlaceholder
                                                rows={1}
                                                color="#e5e4ed"
                                            />
                                        )
                                    }>
                                    <PaymentMethodBadge
                                        method={
                                            getPaymentMethodDebit(
                                                membership,
                                            ) || (
                                                <ReactPlaceholder
                                                    showLoadingAnimation
                                                    rows={1}
                                                    color="#e5e4ed"
                                                />
                                            )
                                        }
                                    />
                                </LabelledContent>
                            </div>
                            <div className="col-4">
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        gtagEvent({
                                            screen: 'overview',
                                            action: 'contribution_pay',
                                            label: 'Clicked Pay Now button',
                                            type: 'modal_open',
                                        });
                                        setModalVisible(true);
                                    }}>
                                    PAY NOW
                                    <MdChevronRight
                                        size={20}
                                        style={{
                                            verticalAlign: 'middle',
                                            marginTop: '-2px',
                                        }}
                                    />
                                </Button>
                            </div>
                        </div>
                    </CardAltDivision>
                </div>

                {/* Button Row */}
                <div className="d-flex justify-content-between flex-wrap mt-3 px-4">
                    <div className="mb-2 mr-2">
                        <Link to="/member/membership#statements">
                            <Button
                                variant="link-outline-gray"
                                onClick={() => {
                                    gtagEvent({
                                        screen: 'overview',
                                        action: 'taxstatement',
                                        label: 'Clicked manage my tax statement button',
                                    });
                                }}>
                                <IoMdDocument
                                    className="mr-2"
                                    size={20}
                                    style={{
                                        verticalAlign: 'middle',
                                        marginTop: '-2px',
                                    }}
                                />
                                Get Tax Statement
                            </Button>
                        </Link>
                    </div>
                    <div className="mb-2 mr-2">
                        <BenefitCalculator
                            onClick={() => {
                                gtagEvent({
                                    screen: 'overview',
                                    action: 'benefit_calculator',
                                    label: 'Clicked benefit calculator button',
                                    type: 'modal_open',
                                });
                                dispatch(
                                    setBenefitCalculatorModalVisible(true),
                                );
                            }}
                        />
                    </div>
                    <div
                        className="mb-2"
                        style={{
                            display: hraTokens?.[selectedUserId]?.token
                                ? 'block'
                                : 'none',
                        }}>
                        <Button
                            variant="primary"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'overview',
                                    action: 'ehealthprofile',
                                    label: 'Clicked eHealth profile button',
                                    type: 'modal_open',
                                });
                                dispatch(setHraModalVisible(true));
                            }}
                            style={{ color: '#FFFFFF' }}>
                            <GiPerson style={{ verticalAlign: 'middle' }} />{' '}
                            Health-e-profile
                        </Button>
                    </div>
                </div>
            </Card>

            <MakeAContributionModal
                handleClose={() => {
                  setModalVisible(false);
                }}
                show={modalVisible}
                align={'right'}
            />

            {/*<BenefitCalculatorModal
                handleClose={
                    () => {
                        setBenefitCalculatorModalVisible(false)
                    }
                }
                show={benefitCalculatorModalVisible}
                align={'right'}
            />*/}
        </>
    );
};

export default OverviewCoverControlsSection;
