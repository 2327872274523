import { dependantHelpers } from 'libs/dependant';

export const getEditPersonDefaults = (selectedUserId, currentUser) => {
    console.log({ currentUser });
    return {
        personId: selectedUserId || '',
        joindate: currentUser?.JoinDate || '',
        relationship: currentUser?.RelationshipCode || '',
        gender: currentUser?.Person?.Gender || '',
        title: currentUser?.Person?.TitleCode || '',
        firstname: currentUser?.Person?.GivenName || '',
        lastname: currentUser?.Person?.Surname || '',
        dob: currentUser?.Person?.BirthDate || '',
        medicareno: currentUser?.Person?.MedicareCardNumber || '',
        medicareref: currentUser?.Person?.MedicareCardReferenceNumber || '',
        medicareexpirymonth: currentUser?.Person?.MedicareCardExpiryMonth || '',
        medicareexpiryyear: currentUser?.Person?.MedicareCardExpiryYear || '',
        medicarefirstname: currentUser?.Person?.MedicareCardGivenName || '',
        medicarelastname: currentUser?.Person?.MedicareCardSurname || '',
        email: currentUser?.Person?.EmailAddresses?.EmailAddress?.[0]?.EmailAddress || '',

        schoolname: currentUser?.Person?.StudentInstitutionName || '',
        schoolyear: currentUser?.Person?.StudentYear || '',
        schoolcoursestartdate: '',
        schoolcourseenddate: '',
        previoushealthfund: currentUser?.PreviousHealthFundCode?.trim() || '',
        previoushealthfundno: currentUser?.PreviousHealthFundMembershipId || '',
        previoushealthfundjoin: currentUser?.PreviousHealthFundJoinDate || '',
        schooldeclare: false,
        registerAsExtDep: false,
        agreeDisclaimers: false,
        eligibleForMedicare: false,
        declare: false,
        disablesubmit: false,
        isAlreadyRegisteredDependant:
            dependantHelpers.isAlreadyRegisteredDependant(currentUser),
    };
};
