import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Cancel from 'components/elements/buttons/Cancel';
import { useFormikContext } from 'formik';

export const SubmitButton = ({ handleClose, loading }) => {
    const { handleSubmit, isValid } = useFormikContext();

    return (
        <div className="row frm-brdr">
            <div className="col mt-3 mb-2">
                <Button
                    variant="secondary"
                    className="modal-submit"
                    disabled={!isValid || loading}
                    onClick={handleSubmit}>
                    <>
                        {loading && (
                            <Spinner
                                animation="border"
                                role="status"
                                as="span"
                                size="sm"
                            />
                        )}
                        Submit Request
                    </>
                </Button>

                <Cancel
                    style={{
                        color: '#9998A8',
                    }}
                    onClick={handleClose}
                />
            </div>
        </div>
    );
};

export default SubmitButton;
