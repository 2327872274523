import React from 'react'
import { Card } from 'react-bootstrap';
import "styles/ShareYourCode.scss"

const ShareYourCode = ({ children }) => {
  return (
    <Card className='referral-promo-card'>
      {children}
    </Card>
  )
}

export default ShareYourCode
