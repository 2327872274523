import { STRINGS } from './constants';

export const isLoggedIn = () => localStorage.getItem(STRINGS.ACCESS_TOKEN);

export const setAccessToken = (token) =>
    localStorage.setItem(STRINGS.ACCESS_TOKEN, token);
export const getAccessToken = () => localStorage.getItem(STRINGS.ACCESS_TOKEN);

export const setRefreshToken = (token) =>
    localStorage.setItem(STRINGS.REFRESH_TOKEN, token);
export const getRefreshToken = () =>
    localStorage.getItem(STRINGS.REFRESH_TOKEN);

export const setAccessTokenExpires = (epoch) =>
    localStorage.setItem(STRINGS.ACCESS_TOKEN_EXPIRES, epoch);
export const getAccessTokenExpires = () =>
    localStorage.getItem(STRINGS.ACCESS_TOKEN_EXPIRES);

export const setMembershipId = (token) =>
    localStorage.setItem(STRINGS.MEMBERSHIP_ID, token);
export const getMembershipId = () =>
    localStorage.getItem(STRINGS.MEMBERSHIP_ID);

export const setHeartbeat = (epoch) =>
    localStorage.setItem(STRINGS.HEARTBEAT, epoch);
export const getHeartbeat = () => localStorage.getItem(STRINGS.HEARTBEAT);

export const setSkipClaimStart = (status) =>
    localStorage.setItem(STRINGS.SKIP_CLAIM_START, status);
export const getSkipClaimStart = () =>
    localStorage.getItem(STRINGS.SKIP_CLAIM_START);

export const login = (response) => {
    console.log('### LOGIN RESPONSE ###');
    console.log(response);
    setAccessToken(response.access_token);
    setAccessTokenExpires(response.expires);
    setRefreshToken(response.refresh_token);
    setMembershipId(response.membershipid);
};

export const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
};
