import CustomModal from 'components/modals/Modal';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { BiCheck } from 'react-icons/all';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import { useDispatch, useSelector } from 'react-redux';
import { changeCoverActions } from 'store';
import api from '@qldtuh/tuh-uh-js-api';

const LimitsModal = ({ show, handleClose, align }) => {
    const changeCoverStore = useSelector((state) => state.changeCover);

    const dispatch = useDispatch();

    const [modalData, setModalData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (
            changeCoverStore?.limitsModalOptions?.sku &&
            changeCoverStore?.limitsModalOptions?.options
        ) {
            if (modalData === null) {
                const memberid = getMembershipId();
                const access_token = getAccessToken();
                let outValues = {};
                outValues.sku = changeCoverStore?.limitsModalOptions?.sku;
                outValues.options =
                    changeCoverStore?.limitsModalOptions?.options;
                setLoading(true);
                api.website(CONFIG, access_token)
                    .cover.getModalDetails(memberid, outValues)
                    .then((response) => {
                        // console.log(response);
                        if (response?.table) {
                            setModalData(response);
                            setLoading(false);
                        }
                    })
                    .catch((err) => {
                        console.log('ERROR', err.message);
                        setLoading(false);
                    });
            }
        }
    }, [changeCoverStore]);

    return (
        <CustomModal
            handleClose={() => {
                dispatch(changeCoverActions.ui.limitsModalOptions(null));
                setModalData(null);
                handleClose();
            }}
            align={align}
            show={show}
            title={''}
            className="limit-modal">
            {modalData === null &&
                loading &
                (
                    <ReactPlaceholder
                        showLoadingAnimation
                        rows={6}
                        color="#e5e4ed"
                    />
                )}
            {modalData === null && !loading && <div>Unable to load data</div>}
            {modalData !== null && (
                <div>
                    <div className="d-flex heading">
                        <div className="icon">
                            {modalData?.icon && <img src={modalData?.icon} />}
                        </div>
                        <div className="col title">{modalData?.title}</div>
                    </div>
                    <div className="d-flex secondlevel">
                        {modalData?.overall_limit && (
                            <div>
                                <div className="top">Overall limit</div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: modalData?.overall_limit,
                                    }}
                                    className="bottom"
                                />
                            </div>
                        )}
                        {modalData?.waiting && (
                            <div>
                                <div className="top">Waiting period</div>
                                <div className="bottom">
                                    {modalData?.waiting}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="limit-table">
                        <Table responsive>
                            <thead>
                                <tr>
                                    {modalData?.table?.header &&
                                        modalData?.table?.header?.map(
                                            (item, idx) => {
                                                return (
                                                    <th key={idx}>{item}</th>
                                                );
                                            },
                                        )}
                                </tr>
                            </thead>
                            <tbody>
                                {modalData?.table?.data &&
                                    modalData?.table?.data?.map((item, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td>
                                                    <BiCheck
                                                        style={{
                                                            verticalAlign:
                                                                'middle',
                                                        }}
                                                    />{' '}
                                                    {item[0]}
                                                </td>
                                                <td>{item[1]}</td>
                                                <td>{item[2]}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            )}
        </CustomModal>
    );
};

export default LimitsModal;
