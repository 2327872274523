import React from 'react';
import { JoinDate, Relationship } from '../components';

export const JoiningInfo = ({ loading, edit = false }) => {
    return (
        <div className="d-flex pb-2 modal-box-shadow">
            <JoinDate loading={loading} edit={edit} />
            <Relationship loading={loading} disabled={edit} />
        </div>
    );
};

export default JoiningInfo;
