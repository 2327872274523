import moment from 'moment';

const getDefaultValues = (membership, selectedTab) => {
    const frequencyParse = (frequencyString) => {
        if (frequencyString === 'SixMonthly') return '6 Monthly';
        if (frequencyString === null) return 'Fortnightly';
        return frequencyString;
    }
    const bankToCreditCard = (frequencyString) => {
        if (frequencyString === 'Fortnightly') return 'Monthly';
        if (frequencyString === 'SixMonthly') return '6 Monthly';
        if (frequencyString === null) return 'Monthly';
        return frequencyString;
    };
    return {
        bank: membership?.DebitAccount?.BankAccount?.Bank || '',
        bsb:
            membership?.DebitAccount?.BankAccount?.BsbNumber ||
            '',
        accountNumber:
            membership?.DebitAccount?.BankAccount
                ?.AccountNumber || '',
        accountName:
            (membership?.DebitAccount?.ChargeCard
                ? ''
                : membership?.DebitAccount?.AccountName) || '',
        bankPaymentFreq: frequencyParse(membership?.Contribution?.Frequency),
        bankPaymentDate: membership?.Contribution?.DebitOnDate || '',
        bankDateChange: false,
        bankNewDate: moment().add(2, 'days').toDate(),
        sameBankAccount: false,
        cardholder: membership?.DebitAccount?.AccountName || '',
        cardPaymentFreq: bankToCreditCard(membership?.Contribution?.Frequency),
        cardPaymentDate: membership?.Contribution?.DebitOnDate || '',
        ccDateChange: false,
        ccNewDate: moment().add(2, 'days').toDate(),
        selectedTab2: selectedTab,
    };
};

export default getDefaultValues;
