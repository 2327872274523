import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { allPersons } from 'libs/helpers';

export const SelectPeople = () => {
    const { values, setFieldValue, errors } = useFormikContext();
    const membership = useSelector((state) => state.membership);

    const addPerson = (id) => {
        // Calculate AOT for length check
        const newList = [...values.additionalPeople, id];
        // Limitation of the web form
        if (newList.length > 4) {
            //Alert.alert('Error', "You can only enter up to 4 people using this option.")
            alert('You can only enter up to 4 people using this option.');
        } else {
            setFieldValue('additionalPeople', newList);
        }
    };

    const removePerson = (id) => {
        setFieldValue(
            'additionalPeople',
            values.additionalPeople.filter((personId) => personId !== id),
        );
    };

    const togglePerson = (id) => {
        // Is the person already in the list?
        const inList = values.additionalPeople.find(
            (personId) => personId === id,
        );
        inList ? removePerson(id) : addPerson(id);
    };

    const allMembers = allPersons(membership);

    return (
        <div className="row members-travelling-overseas">
            {!values.everyoneOnMembershipIsTravelling &&
                values.everyoneOnMembershipIsTravelling !== '' && (
                    <div className="col">
                        <p>
                            <strong>If no,</strong> please select the names of
                            person(s) covered on your policy who will be
                            travelling overseas for the period of your overseas
                            suspension.
                        </p>
                        <div className="members-travelling-overseas--list">
                            {allMembers?.map((person, i) => {
                                return (
                                    <Form.Group
                                        key={`members-list-${i}`}
                                        controlId={`members-list-${i}`}
                                        className="checkbox-group">
                                        <Form.Control
                                            type={`checkbox`}
                                            label={`${person.Person.GivenName} ${person.Person.Surname}`}
                                            value={person.Person.PersonId}
                                            onChange={(e) => {
                                                togglePerson(e.target.value);
                                            }}
                                        />
                                        <Form.Label className="text-label-gray">{`${person.Person.GivenName} ${person.Person.Surname}`}</Form.Label>
                                    </Form.Group>
                                );
                            })}
                        </div>
                        <>
                            {errors.additionalPeople && (
                                <small className="text-danger form-text">
                                    {errors.additionalPeople}
                                </small>
                            )}
                        </>
                        <Form.Group
                            controlId={`partner-dependant-covered-not-travelling`}
                            className="checkbox-group partner-cover-check">
                            <Form.Control
                                type={`checkbox`}
                                className="checkbox-group"
                                value={values.partnerOrDepNotTravelling}
                                onChange={(e) => {
                                    setFieldValue(
                                        'partnerOrDepNotTravelling',
                                        e.target.checked,
                                    );
                                }}
                            />
                            <Form.Label className="text-label-gray">
                                My partner or a dependant who is covered on my
                                membership is NOT travelling overseas with me.
                            </Form.Label>
                        </Form.Group>
                    </div>
                )}
        </div>
    );
};

export default SelectPeople;
