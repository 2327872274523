import React from "react";
import {MdEdit} from "react-icons/all";
import LinkOutlineTextIcon from "./LinkOutlineTextIcon";

interface Props {
    onClick: () => void;
    className?: string;
}

const Edit: React.FC<Props> = ({onClick, className}) => {
    return (
        <LinkOutlineTextIcon
            className={className}
            text="EDIT"
            icon={<MdEdit/>}
            iconSide="left"
            onClick={onClick}
        />
    );
};

export default Edit;

