import SpecialOfferBannerTemplate from 'components/elements/banners/SpecialOfferBannerTemplate';
import React from 'react';
import { Button } from 'react-bootstrap';
import { MdChevronRight } from 'react-icons/md';
import { setReferAFriendModalVisible } from 'store';
import { useDispatch } from 'react-redux';
import gtagEvent from 'libs/gtagEvent';

const ReferAFriendBanner = () => {
    const dispatch = useDispatch();

    return (
        <SpecialOfferBannerTemplate
            left={
                <div className={'font-feature font-weight-medium'}>
                    Refer a friend and get a{' '}
                    <strong style={{ textDecoration: 'underline' }}>
                        $100 eGift Card!
                    </strong>
                </div>
            }
            right={
                <div>
                    {/* Pay now buttons */}
                    {/*<Button variant="secondary mx-0 p-1" href={"https://tuh.com.au/refer"} target={"_blank"}>*/}
                    <Button
                        variant="secondary mx-0 p-1"
                        onClick={() => {
                            gtagEvent({
                                screen: 'overview',
                                action: 'referafriend',
                                label: 'Clicked on Refer a Friend offer',
                            });
                            dispatch(setReferAFriendModalVisible(true));
                        }}>
                        <span className={'text-uppercase'}>
                            Refer a friend
                            <MdChevronRight
                                size={20}
                                style={{
                                    verticalAlign: 'middle',
                                    marginTop: '-2px',
                                }}
                            />
                        </span>
                    </Button>
                </div>
            }
        />
    );
};

export default ReferAFriendBanner;
