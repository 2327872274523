import HospitalActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';

export const setClinicalCategories = (categories) => ({
    type: HospitalActionTypes.SET_CLINICAL_CATEGORIES,
    payload: categories,
});

export const thunkGetClinicalCategories = () => (dispatch, _getState) => {
    const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    api.user(CONFIG, token)
        .membership.getClinicalCategories(memberId)
        .then((response) => {
            dispatch(setClinicalCategories(response));
            // console.log(response)
        });
};
