import React, { useState, useEffect } from 'react';
import { MdContentCopy, FaCheckCircle } from 'react-icons/all';
import { Button } from 'react-bootstrap';
import gtagEvent from 'libs/gtagEvent';

const CodePill = ({ shareCode, shareURL }) => {
    // const shareURL = 'https://tuh.com.au/refer-a-friend/';
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setCopied(false);
        }, 10000);
    }, [copied]);

    return (
        <div className={'row justify-content-between code-pill'}>
            <div className={'col-8 code-pill-left'}>
                {shareCode ?? 'Fetching...'}
            </div>
            <div className={'col-4  code-pill-right'}>
                <Button
                    variant={'code-pill-btn'}
                    disabled={!shareCode}
                    onClick={() => {
                        setCopied(true);
                        gtagEvent({
                            screen: 'referafriend',
                            action: 'copycode',
                            label: 'Copied friend referral code link',
                        });
                        navigator.clipboard.writeText(
                            `${shareURL}/${shareCode}`,
                        );
                    }}>
                    {copied ? (
                        <>
                            COPIED!{' '}
                            <FaCheckCircle
                                size={18}
                                className={'react-icons'}
                            />
                        </>
                    ) : (
                        <>
                            COPY{' '}
                            <MdContentCopy
                                size={18}
                                className={'react-icons'}
                            />
                        </>
                    )}
                </Button>
            </div>
        </div>
    );
};

export default CodePill;
