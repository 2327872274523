import React from 'react';
import ReferFriendContent from './ReferFriendContent';
import { Body, PromoSubtitle, PromoText } from '../../PromoBlock/exports';

const ReferFriendBody = ({ promoURL }) => {
    return (
        <Body>
            <ReferFriendContent promoURL={promoURL} />
            <PromoSubtitle>Disclaimer</PromoSubtitle>
            <PromoText>
                <p>
                    The offer is available to members whose premium payments are
                    up-to-date at the date of referral. The referred family
                    member/friend cannot currently be a Union Health or TUH
                    member or dependant of a Union Health or TUH member coming
                    off a parent&apos;s policy. The $100 eGift Card will be sent
                    within 30 days of receiving the first premium of the new
                    member. This offer is not available in conjunction with any
                    other offer.
                </p>
                <p>*Terms and conditions apply. </p>
            </PromoText>
        </Body>
    );
};

export default ReferFriendBody;
