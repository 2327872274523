export const STRINGS = {
    REFRESH_TOKEN: 'refresh_token',
    ACCESS_TOKEN: 'access_token',
    ACCESS_TOKEN_EXPIRES: 'access_token_expires',
    MEMBERSHIP_ID: 'membership_id',
    HEARTBEAT: 'heartbeat',
    SKIP_CLAIM_START: 'skip_claim_start'
};

export const CONFIG = {
    variant: 'tuh',
    platform: 'web',
    environment: `${process.env.REACT_APP_API_ENVIRONMENT}`
}

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
