import LastProvidersActionTypes from './actions-types';

const INITIAL_STATE = null;

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case LastProvidersActionTypes.SET_LAST_PROVIDERS:
            if (process.env.REACT_APP_ENVIRONMENT==="development") {
                console.log(`SET_LAST_PROVIDERS with payload ${action.payload}`);
            }
            return action.payload;
        default:
            return state;
    }
};
