import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

export const Title = ({ loading }) => {
    const { touched, errors, values, getFieldProps } = useFormikContext();
    const allTitles = useSelector((state) => state.titles);

    return (
        <Form.Group controlId={`title`} className="text-label-gray">
            <Form.Label>Title</Form.Label>
            <Form.Control
                {...getFieldProps('title')}
                as="select"
                className={
                    errors.title && touched.title && !loading && 'is-invalid'
                }>
                <option>- Select -</option>
                {allTitles?.Title &&
                    allTitles.Title.map((row, idx) => {
                        if (values.gender === 'Male' && row.IsMale) {
                            return (
                                <option key={idx} value={row?.TitleCode}>
                                    {row?.Description}
                                </option>
                            );
                        }
                        if (values.gender === 'Female' && row.IsFemale) {
                            return (
                                <option key={idx} value={row?.TitleCode}>
                                    {row?.Description}
                                </option>
                            );
                        }
                        return null;
                    })}
            </Form.Control>
            {touched.title && errors.title && !loading && (
                <Form.Control.Feedback type={'invalid'}>
                    {errors.title}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
};

export default Title;
