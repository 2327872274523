import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from 'components/modals/Modal';
import { setHraModalVisible } from 'store';
import gtagEvent from 'libs/gtagEvent';
import { StoreType } from 'store/types';

export const HRAModal = ({ align }) => {
    const dispatch = useDispatch();
    const uiData = useSelector((state: StoreType) => state.ui);
    const selectedUserId = useSelector((state: StoreType) => state.selectedUser);
    const hraModalVisible = uiData.hraModalVisible;
    const hraTokens = useSelector((state: StoreType) => state.hra);

    if (selectedUserId === null) {
        return null;
    }

    if (hraTokens && hraTokens?.[selectedUserId] === null) {
        return null;
    }

    return (
        <CustomModal
            handleClose={() => {
                gtagEvent({
                    screen: 'ehealthprofile',
                    action: 'cancel',
                    label: 'Cancelled or closed modal',
                    type: 'modal',
                });
                dispatch(setHraModalVisible(false));
            }}
            title={'Health-e-profile'}
            align={align}
            show={hraModalVisible}
            className={'hra-modal'}>
            <>
            {hraTokens?.[selectedUserId]?.token && (
                <iframe
                    src={
                        hraTokens?.[selectedUserId].link +
                        hraTokens?.[selectedUserId].token
                    }
                    width="100%"
                    height="100%"></iframe>
            )}
            </>
        </CustomModal>
    );
};

export default HRAModal;
