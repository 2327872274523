import React, { useState, useEffect } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import {
    handleProviderSearch,
    disableProviderSearch,
    injectProvider,
} from '../common/functions';
import { FastClaimTypeItem } from 'types/fastClaim';
import { ProviderType } from '@qldtuh/tuh-uh-js-api';

interface ProviderSearchProps {
    providerList: ProviderType[];
    useLastProvider: boolean;
    claimItem?: FastClaimTypeItem;
    updateClaim: (newObject: any) => void;
    autocompleteProvider: (...args: any) => Promise<void>;
    hideDropdown: boolean;
}

export const ProviderSearch: React.FC<ProviderSearchProps> = ({
    providerList,
    useLastProvider,
    claimItem,
    updateClaim,
    autocompleteProvider,
    hideDropdown,
}) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        if(hideDropdown) {
            setShow(false);
        }
    }, [hideDropdown]);

    return (
        <Form.Group
            controlId={`provider-search`}
            className="text-label-gray"
            style={{
                position: 'relative',
                display:
                    claimItem?.forceDefaultProviderOnClaim === true ||
                    useLastProvider
                        ? 'none'
                        : 'block',
            }}>
            <Form.Label>Provider Search</Form.Label>
            <Form.Control
                type="text"
                style={{
                    borderRadius: '23px',
                }}
                onChange={(event) => {
                    event.target.value = event.target.value.toUpperCase();
                    autocompleteProvider(
                        event.target.value,
                        claimItem?.serviceTypeCode,
                        claimItem,
                    );
                    const newObject = handleProviderSearch(
                        claimItem,
                        event.target.value,
                    );
                    updateClaim(newObject);
                    if(event.target.value && event.target.value !== '') {
                        setShow(true);
                        return;
                    }
                    setShow(false);
                }}
                disabled={disableProviderSearch(claimItem)}
                value={claimItem?.tempproviderid ?? ''}
            />
            {show && (
                <div className="provider-autocomplete">
                    {providerList.length > 0 ? (
                        providerList.map((provider, idx) => (
                            <div
                                key={idx}
                                onClick={() => {
                                    const newObject = injectProvider(
                                        claimItem,
                                        provider,
                                    );
                                    updateClaim(newObject);
                                    setShow(false);
                                }}>
                                <div className="top">
                                    {provider.providerid} - {provider.title}{' '}
                                    {provider.surname}
                                </div>
                                <div className="bottom">
                                    {provider.suburb}, {provider.state}
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>
                            <Spinner
                                animation="border"
                                role="status"
                                as="span"
                                size="sm"
                            />
                        </div>
                    )}
                </div>
            )}
        </Form.Group>
    );
};

export default ProviderSearch;
