import UIActionTypes from './actions-types';

export const setPaymentMethodModalVisible = (visible) => ({
    type: UIActionTypes.SET_PAYMENT_METHOD_MODAL_VISIBLE,
    payload: visible,
});
export const setEmailConfirmationModalVisible = (visible) => ({
    type: UIActionTypes.SET_EMAIL_CONFIRMATION_MODAL_VISIBLE,
    payload: visible,
});
export const setEditPersonModalVisible = (visible) => ({
    type: UIActionTypes.SET_EDIT_PERSON_MODAL_VISIBLE,
    payload: visible,
});
export const setHraModalVisible = (visible) => ({
    type: UIActionTypes.SET_HRA_MODAL_VISIBLE,
    payload: visible,
});
export const setSuccessModalVisible = (visible) => ({
    type: UIActionTypes.SET_SUCCESS_MODAL_VISIBLE,
    payload: visible,
});
export const setLogoutMessage = (message) => ({
    type: UIActionTypes.SET_LOGOUT_MESSAGE,
    payload: message,
});
export const setLogoutMessageColour = (message) => ({
    type: UIActionTypes.SET_LOGOUT_MESSAGE_COLOUR,
    payload: message,
});
export const setTerminatedModalVisible = (visible) => ({
    type: UIActionTypes.SET_TERMINATED_MODAL_VISIBLE,
    payload: visible,
});
export const setReferAFriendModalVisible = (visible) => ({
    type: UIActionTypes.SET_REFERAFRIEND_MODAL_VISIBLE,
    payload: visible,
});
export const setClaimPaymentsModalVisible = (visible) => ({
    type: UIActionTypes.SET_CLAIMPAYMENT_MODAL_VISIBLE,
    payload: visible,
});
export const setBenefitCalculatorModalVisible = (visible) => ({
    type: UIActionTypes.SET_BENEFITCALCULATOR_MODAL_VISIBLE,
    payload: visible,
});
export const setRebateFormModalVisible = (visible) => ({
    type: UIActionTypes.SET_REBATEFORM_MODAL_VISIBLE,
    payload: visible,
});
export const setClaimDisclaimerModalVisible = (visible) => ({
    type: UIActionTypes.SET_CLAIMDISCLAIMER_MODAL_VISIBLE,
    payload: visible,
});
export const setOnlineClaimServicesVisible = (visible) => ({
    type: UIActionTypes.SET_ONLINECLAIMSERVICES_MODAL_VISIBLE,
    payload: visible,
});
