import * as Yup from 'yup';
import moment from 'moment';

const BankSchema = () => {
    return Yup.object().shape({
        bank: Yup.string().required('BSB is invalid'),
        bsb: Yup.string()
            .matches(/(?:\d{3})(?:-)?(?:\d{3})/, 'Invalid BSB')
            .min(6, 'Invalid BSB')
            .max(7, 'Invalid BSB')
            .required('BSB is required'),
        accountNumber: Yup.string()
            .required('Account Number is required')
            .min(6, 'Invalid Account Number')
            .max(10, 'Invalid Account Number'),
        accountName: Yup.string().required('Account Holder is required'),
        bankNewDate: Yup.date()
            .when(['bankDateChange', 'bankPaymentFreq'], {
                is: (bankDateChange, bankPaymentFreq) =>
                    bankDateChange && bankPaymentFreq === 'Fortnightly',
                then: Yup.date()
                    .min(
                        moment().add(2, 'days').format('YYYY-MM-DD'),
                        'Date must be 2 days from now or greater',
                    )
                    .max(
                        moment().add(14, 'days').format('YYYY-MM-DD'),
                        'Date must be within a fortnight from today',
                    )
                    .required('Date required'),
            })
            .when(['bankDateChange', 'bankPaymentFreq'], {
                is: (bankDateChange, bankPaymentFreq) =>
                    bankDateChange && bankPaymentFreq !== 'Fortnightly',
                then: Yup.date()
                    .min(
                        moment().add(2, 'days').format('YYYY-MM-DD'),
                        'Date must be 2 days from now or greater',
                    )
                    .max(
                        moment().add(30, 'days').format('YYYY-MM-DD'),
                        'Date must be within a month from today',
                    )
                    .required('Date required'),
            }),
    });
};

export default BankSchema;
