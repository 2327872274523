import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import RebateCircle from 'img/vector/RebateCircle/comp';
import { setRebateFormStep } from 'store';

const RebatePrompt = () => {
    const dispatch = useDispatch();

    return (
        <div className="rebate-form-step-1">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center">
                        <RebateCircle />
                    </div>
                    <div
                        className="col-12 text-primary font-21 pb-3 font-weight-bold text-center"
                        style={{ fontSize: '21px' }}>
                        Please register for Government Rebate before continuing
                    </div>
                    <div
                        className="col-12 col-md-8 offset-md-2 text-center"
                        style={{ fontSize: '14px' }}>
                        <p>
                            To make private health insurance more affordable,
                            the Australian Government provides a rebate on
                            premiums. The rebate applies to anyone eligible for
                            Medicare benefits and depends on both your household
                            income and your age.
                        </p>
                    </div>
                    <div className="col-12 text-center">
                        <Button
                            variant="link"
                            style={{
                                textTransform: 'uppercase',
                                padding: 20,
                                color: '#AAA',
                            }}
                            onClick={() => {
                                dispatch(setRebateFormStep(6));
                            }}>
                            No Thanks
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                dispatch(setRebateFormStep(2));
                            }}>
                            Register for Rebate
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RebatePrompt;
