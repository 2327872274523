import Cancel from 'components/elements/buttons/Cancel';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const SubmitForm = ({ loading, handleClose }) => {
    const { dirty, isValid, handleSubmit } = useFormikContext();

    return (
        <div className="pb-3 row modal-box-shadow">
            <div className="col pt-3">
                <Button
                    variant="secondary"
                    className="modal-submit"
                    onClick={handleSubmit}
                    disabled={!isValid || loading || !dirty}>
                    <>
                        {loading && (
                            <Spinner
                                animation="border"
                                role="status"
                                as="span"
                                size="sm"
                            />
                        )}
                        Submit
                    </>
                </Button>

                <Cancel
                    style={{
                        color: '#9998A8',
                    }}
                    onClick={handleClose}
                />
            </div>
        </div>
    );
};

export default SubmitForm;
