export function genderDisabled(values, allRelationships) {
    if (values.relationship !== '') {
        const selectedRelationship = allRelationships?.Relationship?.find(
            (row) => row.RelationshipCode === values.relationship,
        );
        if (
            (selectedRelationship?.IsMale && !selectedRelationship?.IsFemale) ||
            (!selectedRelationship?.IsMale && selectedRelationship?.IsFemale)
        ) {
            return true;
        }
    }
}
