import RelationshipsActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';

export const setRelationships = (relationships) => ({
    type: RelationshipsActionTypes.SET_RELATIONSHIPS,
    payload: relationships,
});

export const thunkGetRelationships = () => (dispatch, _getState) => {
    // const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    api.user(CONFIG, token)
        .person.getRelationships()
        .then((response) => {
            dispatch(setRelationships(response));
            // console.log(response)
        });
};
