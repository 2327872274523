import moment from 'moment';
import {
    mainPerson,
    findPartner,
    findChildren,
    getPhoneByName,
    getAddressByType,
    addressToString,
} from 'libs/helpers';

const buildMedicareData = (membership, rebateForm) => {
    {
        /*
        {"membershipid":"60000873","firstname":"Matthew","surname":"Burns","dob":"01/01/1990","gender":"M","contactnumber":"0411222333","medicarenumber":"2123456701","medicareexpirymonth":"01","medicareexpiryyear":"2023","address1home":"282 Wickham Street","address2home":"","suburbhome":"Fortitude Valley","statehome":"QLD","postcodehome":"4006","address1post":"282 Wickham Street","address2post":"","suburbpost":"Fortitude Valley","statepost":"QLD","postcodepost":"4006","firstnamepartner":"Matthew","surnamepartner":"Burns","dobpartner":"01/01/1990","genderpartner":"M","children":[{"childsurname":"Burns","childfirstname":"Todd","childdob":"01/01/2019","childgender":"M"},{"childsurname":"Burns","childfirstname":"Toddy","childdob":"01/01/2019","childgender":"M"}],"rebatetier":"Base Tier"}
    */
    }

    if (!membership) {
        return false;
    }

    const data = {};
    data.membershipid = membership.MembershipId;
    data.start_date = rebateForm.start_date;

    // Main member
    const main = mainPerson(membership);
    data.firstname = main.Person.GivenName;
    data.surname = main.Person.Surname;
    data.dob = moment(main.Person.BirthDate).format('DD/MM/YYYY');
    data.gender = main.Person.Gender.substr(0, 1);
    data.medicarenumber = main.Person.MedicareCardNumber;
    data.medicareexpirymonth = main.Person.MedicareCardExpiryMonth;
    data.medicareexpiryyear = main.Person.MedicareCardExpiryYear;

    // Daytime phone
    const mobilephone = getPhoneByName(membership, 'Mobile1');
    const homephone = getPhoneByName(membership, 'Home');
    if (mobilephone) {
        data.contactnumber = mobilephone;
    } else if (homephone) {
        data.contactnumber = homephone;
    } else {
        data.contactnumber = '';
    }

    // Address
    const homeAddress = getAddressByType(membership, 'Home');
    const postAddress = getAddressByType(membership, 'Postal');
    const samePostalAddress =
        addressToString(homeAddress) === addressToString(postAddress);
    data.address1home = homeAddress.Line1;
    if (homeAddress?.Line2) {
        data.address2home = homeAddress.Line2;
    } else {
        data.address2home = '';
    }
    data.suburbhome = homeAddress.Suburb;
    data.statehome = homeAddress.StateCode;
    data.postcodehome = homeAddress.PostCode;
    if (!samePostalAddress && postAddress) {
        data.address1post = postAddress.Line1;
        if (postAddress?.Line2) {
            data.address2post = postAddress.Line2;
        } else {
            data.address2post = '';
        }
        data.suburbpost = postAddress.Suburb;
        data.statepost = postAddress.StateCode;
        data.postcodepost = postAddress.PostCode;
    }

    // Partner
    const partner = findPartner(membership);
    if (partner.length > 0) {
        {
            /*
            "firstnamepartner": "Matthew",
            "surnamepartner": "Burns",
            "dobpartner": "01/01/1990",
            "genderpartner": "M",
        */
        }
        data.firstnamepartner = partner[0].Person.GivenName;
        data.surnamepartner = partner[0].Person.Surname;
        data.dobpartner = moment(partner[0].Person.BirthDate).format(
            'DD/MM/YYYY',
        );
        data.genderpartner = partner[0].Person.Gender.substr(0, 1);
    }

    // Children
    const children = findChildren(membership);
    if (children.length > 0) {
        let outChildren = [];
        // {"childsurname":"Burns","childfirstname":"Toddy","childdob":"01/01/2019","childgender":"M"}
        children.forEach((child) => {
            let tempChild = {};
            tempChild.childfirstname = child.Person.GivenName;
            tempChild.childsurname = child.Person.Surname;
            tempChild.childdob = moment(child.Person.BirthDate).format(
                'DD/MM/YYYY',
            );
            tempChild.childgender = child.Person.Gender.substr(0, 1);
            outChildren.push(tempChild);
        });
        data.children = outChildren;
    }

    // Rebate Tier
    switch (rebateForm.tier) {
        case '0':
            data.rebatetier = 'Base Tier';
            break;
        case '1':
            data.rebatetier = 'Tier 1';
            break;
        case '2':
            data.rebatetier = 'Tier 2';
            break;
        case '3':
            data.rebatetier = 'Tier 3';
            break;
    }

    return JSON.stringify(data);
};

export default buildMedicareData;
