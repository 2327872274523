import * as Yup from 'yup';
import moment from 'moment';
import { isMedicareNumber } from 'libs/helpers';

export const AddPersonModalSchema = (dependantEndAge) => {
    return Yup.object().shape({
        email: Yup.string().email('Invalid email'),
        firstname: Yup.string()
            .required('First Name is required.')
            .min(1, 'First Name is too short')
            .max(500, 'First Name is too long'),
        lastname: Yup.string()
            .required('Surname is required.')
            .min(1, 'Surname is too short')
            .max(500, 'Surname is too long'),
        gender: Yup.string().required('Gender is required.'),
        title: Yup.string().required('Title is required.'),
        relationship: Yup.string().required(),
        joindate: Yup.date().required('Join date is required.'),
        dob: Yup.date()
            .required('Date of Birth is required.')
            .max(moment().add(1, 'day').toDate())

            .when('relationship', {
                is: 'Son',
                then: Yup.date().min(
                    moment().subtract(parseInt(dependantEndAge), 'years').toDate(),
                    `Dependents must be under ${dependantEndAge}.`,
                ),
            })

            .when('relationship', {
                is: 'Dtr',
                then: Yup.date().min(
                    moment().subtract(parseInt(dependantEndAge), 'years').toDate(),
                    `Dependents must be under ${dependantEndAge}.`,
                ),
            }),
        schoolcoursestartdate: Yup.date().when('schooldeclare', {
            is: true,
            then: Yup.date().required('Start date is required'),
            otherwise: Yup.date(),
        }),
        schoolcourseenddate: Yup.date().when('schooldeclare', {
            is: true,
            then: Yup.date()
                .min(
                    Yup.ref('schoolcoursestartdate'),
                    'End date must be after start date.',
                )
                .required('End date is required'),
            otherwise: Yup.date(),
        }),
        agreeDisclaimers: Yup.boolean().when(
            ['schooldeclare', 'registerAsExtDep'],
            {
                is: (stu, ext) => stu || ext,
                then: Yup.boolean().oneOf(
                    [true],
                    'You must agree to the disclaimers',
                ),
            },
        ),
        eligibleForMedicare: Yup.boolean().oneOf(
            [true],
            'Person must be eligible for Medicare',
        ),
        declare: Yup.boolean().oneOf([true], 'Must declare details are correct'),
    });
}

export const EditPersonModalSchema = Yup.object().shape({
    lastname: Yup.string()
        .required('Surname is required.')
        .min(1, 'Surname is too short')
        .max(500, 'Surname is too long'),
    agreeDisclaimers: Yup.boolean().when(
        ['schooldeclare', 'registerAsExtDep'],
        {
            is: (stu, ext) => stu || ext,
            then: Yup.boolean().oneOf(
                [true],
                'You must agree to the disclaimers',
            ),
        },
    ),
    email: Yup.string().email('Invalid email'),
    medicareno: Yup.string().test({
        name: 'no-illegal',
        message: 'Medicare number is invalid',
        test: (value) => {
            if (!value) return true;
            return isMedicareNumber(value);
        },
    }),
    schoolcoursestartdate: Yup.date().when('schooldeclare', {
        is: true,
        then: Yup.date().required('Start date is required'),
        otherwise: Yup.date(),
    }),
    schoolcourseenddate: Yup.date().when('schooldeclare', {
        is: true,
        then: Yup.date()
            .min(
                Yup.ref('schoolcoursestartdate'),
                'End date must be after start date.',
            )
            .required('End date is required'),
        otherwise: Yup.date(),
    }),
    declare: Yup.boolean().oneOf([true], 'Must declare details are correct'),
});

export const INITIAL_VALUES = {
    joindate: moment().toDate(),
    relationship: '',
    gender: '',
    title: '',
    firstname: '',
    lastname: '',
    dob: '',
    email: '',
    mobile: '',
    schoolage: false,
    schooldeclare: false,
    registerAsExtDep: false,
    agreeDisclaimers: false,
    schoolname: 'TUH school',
    schoolyear: '',
    schoolcoursestartdate: '',
    schoolcourseenddate: '',
    previoushealthtransfer: false,
    previoushealthfund: '',
    previoushealthfundno: '',
    previoushealthfundjoin: '',
    previousHealthFundStillAMember: false,
    previoushealthfundcease: '',
    eligibleForMedicare: false,
    declare: false,
    disablesubmit: false,
};
