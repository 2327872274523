import React from 'react';
import { IoMdInformationCircle } from 'react-icons/all';
import { Button } from 'react-bootstrap';

export const WarningMessage = ({
    title,
    children,
    buttonText,
    buttonOnclick,
}) => {
    return (
        <div className="d-flex flex-wrap pt-3">
            <div className="col-1">
                <IoMdInformationCircle
                    size={20}
                    style={{ verticalAlign: 'middle', color: '#E84256' }}
                />
            </div>
            <div
                className="col-11 font-weight-medium"
                style={{ fontSize: '14px' }}>
                {title}
            </div>
            <div className="col-1"></div>
            <div className="col-11" style={{ fontSize: '14px' }}>
                {children}
            </div>
            {buttonText && (
                <div className="col-12 text-center">
                    <Button variant="secondary" onClick={buttonOnclick}>
                        {buttonText}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default WarningMessage;
