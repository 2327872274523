import { Form } from 'react-bootstrap';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    HomeAddress,
    PostalAddress,
    Email,
    Tabs,
    PhoneNumbers,
    PartnerAuthority,
    SubmitForm,
} from './components';

export const MemberDetailsForm = ({
    onSubmitState,
    handleClose,
    updateSchema,
}) => {
    const product = useSelector((state) => state.product);
    const [selectedTab, setSelectedTab] = useState(1);

    return (
        <Form>
            <Email loading={onSubmitState.loading} />
            <Tabs
                updateSchema={updateSchema}
                updateTab={{ selectedTab, setSelectedTab }}
            />
            <HomeAddress
                isVisible={selectedTab === 1}
                updateSchema={updateSchema}
                setSelectedTab={setSelectedTab}
                onSubmitState={onSubmitState}
            />
            <PostalAddress
                isVisible={selectedTab === 0}
                setSelectedTab={setSelectedTab}
                onSubmitState={onSubmitState}
            />
            <PhoneNumbers loading={onSubmitState.loading} />
            {['Cpl', 'Fam', 'FamX'].includes(product?.membershipClassCode) && (
                <PartnerAuthority />
            )}
            <SubmitForm
                handleClose={handleClose}
                loading={onSubmitState.loading}
            />
        </Form>
    );
};

export default MemberDetailsForm;
