import { MembershipPersonType, MembershipType } from '@qldtuh/tuh-uh-js-api';
import { MONTHS } from 'libs/constants';
import moment from 'moment';
/**
 * Return the current year in 4 digit form
 * @returns {number}
 */
export const currentYear = () => {
  return new Date().getFullYear();
};

export const mainPerson = (membership: MembershipType): MembershipPersonType | undefined => {
    if (!membership?.MembershipPersons?.MembershipPerson) {
        return;
    }

    const person = membership.MembershipPersons.MembershipPerson;
    if (person.length > 0) {
        // It's an array
        const persons = person;
        return persons.find(
            (person2) =>
                person2.RelationshipCode === 'Membr' &&
                person2.MembershipPersonStatus === 'Active',
        );
    }
};

export const allAdults = (membership) => {
  if (!membership?.MembershipPersons?.MembershipPerson) {
    return;
  }

    const allPeople = allPersons(membership);
    let allOver: MembershipPersonType[] = [];
    allPeople.forEach((person) => {
        if (isOver18(person.Person.BirthDate)) {
            allOver.push(person);
        }
    });
    return allOver;
};

export const findPartner = (membership: MembershipType): MembershipPersonType[] | undefined => {
    if (!membership?.MembershipPersons?.MembershipPerson) {
        return;
    }

  const allPeople = allPersons(membership);
  const partner = allPeople.filter((person) => {
    return (
      (person.RelationshipCode === 'Ptnr' &&
        person.MembershipPersonStatus === 'Active') ||
      (person.RelationshipCode === 'Sps' &&
        person.MembershipPersonStatus === 'Active')
    );
  });
  return partner;
};

export const findChildren = (membership) => {
  if (!membership?.MembershipPersons?.MembershipPerson) {
    return;
  }

  const allPeople = allPersons(membership);
  const children = allPeople.filter((person) => {
    return (
      (person.RelationshipCode === 'Son' &&
        person.MembershipPersonStatus === 'Active') ||
      (person.RelationshipCode === 'FSon' &&
        person.MembershipPersonStatus === 'Active') ||
      (person.RelationshipCode === 'Dtr' &&
        person.MembershipPersonStatus === 'Active') ||
      (person.RelationshipCode === 'FDtr' &&
        person.MembershipPersonStatus === 'Active')
    );
  });
  return children;
};

export const mainCouple = (membership) => {
  if (!membership?.MembershipPersons?.MembershipPerson) {
    return;
  }

  const member = mainPerson(membership);
  const partner = findPartner(membership);

    if (!member) {
        return;
    }

    const out: MembershipPersonType[] = [];
    out.push(member);
    if (partner && typeof partner[0] !== 'undefined') {
        out.push(partner[0]);
    }
    return out;
};

export const allPersons = (membership: MembershipType): MembershipPersonType[] => {
    if (!membership?.MembershipPersons?.MembershipPerson) {
        return [];
    }

    const personList = membership?.MembershipPersons?.MembershipPerson;
    if (personList.length > 0) {
        // It's an array
        const personAll = personList.filter(
            (person) => person.MembershipPersonStatus !== 'Terminated',
        );
        const persons: MembershipPersonType[] = Object.create(Object.getPrototypeOf(personAll));
        const propNames = Object.getOwnPropertyNames(personAll);

        propNames.forEach((name) => {
            const desc = Object.getOwnPropertyDescriptor(personAll, name) ?? '';
            Object.defineProperty(persons, name, desc);
        });

        const dateOfBirthOrderedPersons = persons.sort(function (a, b) {
            const ymdPartsA = a.Person.BirthDate.split('-');
            const dateA = new Date(
                parseInt(ymdPartsA[0]),
                parseInt(ymdPartsA[1]),
                parseInt(ymdPartsA[2])
            );

            const ymdPartsB = b.Person.BirthDate.split('-');
            const dateB = new Date(
                parseInt(ymdPartsB[0]), 
                parseInt(ymdPartsB[1]), 
                parseInt(ymdPartsB[2])
            );

            return dateA.getTime() - dateB.getTime();
        });

        // console.log('dateOfBirthOrderedPersons',dateOfBirthOrderedPersons);
        const memberIndex = dateOfBirthOrderedPersons.findIndex(
            (person2) => person2.RelationshipCode === 'Membr',
        );
        let personArray: MembershipPersonType[] = [];
        personArray.push(dateOfBirthOrderedPersons[memberIndex]);
        dateOfBirthOrderedPersons.forEach((person) => {
            if (
                person.RelationshipCode !== 'Membr' &&
                person.MembershipPersonStatus !== 'Terminated'
            ) {
                personArray.push(person);
            }
        });

    // console.log('PERSONARRAY',personArray);

        return personArray;
    } else {
        return [];
    
    }
};

export const getSelectedUser = (persons, selectedUserId) => {
  if (!persons) return;
  if (!selectedUserId) return;

  return persons.find((person) =>
    [`${person.Person.PersonId}`, person.Person.PersonId].includes(
      selectedUserId,
    ),
  );
};

export const ymdToDate = (ymd) => {
  if (!ymd) {
    return;
  }

  const ymdParts = ymd.split('-');
  const dateOut = new Date(ymdParts[0], parseInt(ymdParts[1]) - 1, ymdParts[2]);

  return `${dateOut.getDate()} ${MONTHS[dateOut.getMonth()].substring(
    0,
    3,
  )} ${dateOut.getFullYear()}`;
};

export const ymdToShortDate = (ymd) => {
  if (!ymd) {
    return;
  }

  const ymdParts = ymd.split('-');
  const date = new Date(ymdParts[0], parseInt(ymdParts[1]) - 1, ymdParts[2]);

  return `${date.getDate()} ${MONTHS[date.getMonth()].substring(0, 3)}`;
};

export const ymdToSlashFormatDate = (ymd) => {
  if (!ymd) {
    return;
  }

  const ymdParts = ymd.split('-');
  const date = new Date(ymdParts[0], parseInt(ymdParts[1]) - 1, ymdParts[2]);

  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const ageToYears = (ymd) => {
    if (!ymd) {
        return -1;
    }

  const birthday = new Date(ymd);
  var ageDifMs = Date.now() - birthday.getTime();
  if (Math.sign(ageDifMs) === -1) {
    return -1;
  }
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const isOver18 = (ymd) => {
  if (!ymd) {
    return;
  }

    const currentAge = ageToYears(ymd);
    if (currentAge && currentAge >= 18) {
        return true;
    }
    return false;
};

export const ageAtCertainYear = (ymd, dateYear) => {
  if (!ymd) {
    return;
  }
  if (!dateYear) {
    return;
  }

    const age = ageToYears(ymd);
    if (!age) {
        return;
    }
    const date = new Date(dateYear).getFullYear();
    const today = new Date().getFullYear();

    const ageDiff = today - date;
    const ageAtYear = age - ageDiff;

  return ageAtYear;
};

export const getPhoneByName = (membership, type) => {
  if (!membership?.PhoneNumbers?.PhoneNumber) {
    return;
  }

  if (!type) {
    return;
  }

  const phones = membership.PhoneNumbers.PhoneNumber;

  if (phones.length > 0) {
    return phones.find((phone) => phone.PhoneNumberType === type)?.PhoneNumber;
  } else {
    // single object
    if (phones.PhoneNumberType === type) {
      return phones.PhoneNumber;
    } else {
      return null;
    }
  }
};

export const getAddressByType = (membership, type) => {
  if (!membership?.Addresses?.Address) {
    return;
  }

  if (!type) {
    return;
  }

  const addresses = membership.Addresses.Address;

  if (addresses.length > 0) {
    return addresses.find((address) => address.AddressType === type);
  } else {
    if (addresses.AddressType === type) {
      return addresses;
    } else {
      return;
    }
  }
};

export const addressToString = (address) => {
  if (!address) return null;

  if (address.Line3) {
    return `${address.Line1}, ${address.Line2}, ${address.Line3}, ${address.Suburb}, ${address.Postcode} ${address.StateCode}`;
  }
  if (address.Line2) {
    return `${address.Line1}, ${address.Line2}, ${address.Suburb}, ${address.Postcode} ${address.StateCode}`;
  }
  return `${address.Line1}, ${address.Suburb}, ${address.Postcode} ${address.StateCode}`;
};

export const getPaymentMethodDebit = (membership) => {
  if (!membership || !membership.Group) {
    return '-';
  }

  if (membership.Group.GroupType) {
    return membership.Group.GroupType.toLowerCase();
  }

  if (!membership.DebitAccount) {
    return '-';
  }

  if (typeof membership.DebitAccount.BankAccount != 'undefined') {
    return 'direct debit';
  } else {
    return 'credit card';
  }
};

export const getPaymentDetailsDebit = (membership) => {
  if (!membership) {
    return '-';
  }
  if (!membership?.DebitAccount) {
    return '-';
  }

  if (typeof membership.DebitAccount.BankAccount !== 'undefined') {
    return {
      name: membership.DebitAccount.BankAccount.Bank,
      number: membership.DebitAccount.BankAccount.AccountNumber.replace(
        /\d(?=\d{4})/g,
        '*',
      ),
    };
  } else {
    return membership.DebitAccount;
  }
};

export const ageBasedDiscount = (ageAtJoining, ageNow) => {
  let discount = 0;
  ageAtJoining = parseInt(ageAtJoining);
  ageNow = parseInt(ageNow);
  switch (ageAtJoining) {
    case 18:
    case 19:
    case 20:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
      discount = 10;
      break;
    case 26:
      discount = 8;
      break;
    case 27:
      discount = 6;
      break;
    case 28:
      discount = 4;
      break;
    case 29:
      discount = 2;
      break;
  }

  // As long as you retain an eligible policy the discount applies until you turn 41, then decreases by 2% per year.
  if (discount > 0 && ageNow > 41) {
    const ageDiffFrom41 = ageNow - 41;
    // console.log('AGEDIFF: ',ageDiffFrom41);
    if (ageDiffFrom41 > 0) {
      const lessPercentage = ageDiffFrom41 * 2;
      discount = discount - lessPercentage;
      if (discount < 0) {
        discount = 0;
      }
    }
  }
  // console.log('DISCOUNT2: ',discount);
  return discount;
};

export const expandMembershipType = (membershipClassCode) => {
  if (!membershipClassCode) {
    return;
  }

  switch (membershipClassCode) {
    case 'Sgl':
      return 'Single';
    case 'Cpl':
      return 'Couple';
    case 'Fam':
      return 'Family';
    case 'FamX':
      return 'Family - Extended Dependant';
    case 'SPFam':
      return 'Single Parent';
    case 'SPFaX':
      return 'Single Parent - Extended Dependant';
  }
};

export const convertToArray = (objectOrArray: any) => {
    if (Array.isArray(objectOrArray)) {
        // This is an array
        return objectOrArray;
    } else {
        const array: any[] = [];
        // This is an object
        array.push(objectOrArray);
        return array;
    }
};

export const guaranteeArray = (objectOrArray) => {
  if (Array.isArray(objectOrArray)) {
    // This is an array
    return objectOrArray;
  } else {
    const array: any[] = [];
    // This is an object
    for (const property in objectOrArray) {
        array.push(objectOrArray[property]);
    }
    return array;
  }
};

export const reversePriceMask = (inputString, mask) => {
  if (!RegExp('^0+.0+$').test(mask)) {
    console.warn('Invalid mask. Must be of the form: 0.00, 000.0, 0.000, etc.');
    return '';
  }
  const [preDecimalString, postDecimalString] = mask.split('.');
  const postDecimalLength = postDecimalString.length;
  const preDecimalLength = preDecimalString.length;
  const minStringLength = postDecimalLength + preDecimalLength;
  // Remove any decimals
  // Copy of input string
  let paddedInputString = inputString.replace('.', '');
  //Trim off 0's from the start
  const stringSplits = paddedInputString.split('');
  let trimmedString = '';
  let foundFirstInteger = false;
  stringSplits.forEach((split) => {
    if (foundFirstInteger) {
      trimmedString += split;
    } else if (split !== '0') {
      trimmedString = split;
      foundFirstInteger = true;
    }
  });
  // If there are not at least 3 characters, we need to prepend
  // 0's to form the price correctly.
  // For example: 3 -> 003.
  // The string must have a length of >= minStringLength to proceed
  // Add 0's
  while (trimmedString.length < minStringLength) {
    trimmedString = `0${trimmedString}`;
  }
  // Now the length of the string is >= minStringLength, we can splice in a decimal point
  const decimalSuffix = trimmedString.slice(-postDecimalLength);
  const decimalPrefix = trimmedString.slice(
    0,
    trimmedString.length - postDecimalLength,
  );
  return `${decimalPrefix}.${decimalSuffix}`;
};

export const isMedicareNumber = (val) => {
    // 2123456701
    if (!val) return false;
    if (isNaN(val)) return false;
    var raw = val.replace(/\s/g, '') + '1';
    if (raw.length === 11) {
        // var matches = raw.match(/^(\d{8})(\d)/);
        var matches = raw.match(/^([2-6]\d{7})(\d)/);
        if (!matches) {
            return false;
        }
        var base = matches[1];
        var checkDigit = matches[2];
        var weights = [1, 3, 7, 9, 1, 3, 7, 9];
        var sum = 0;
        for (var i in weights) {
            sum += parseInt(base[i]) * weights[i];
        }
        return sum % 10 === parseInt(checkDigit, 10);
    } else return false;
};

export const getHomeAddressState = (membership) => {
  if (!membership) return null;
  const home = getAddressByType(membership, 'Home');
  return home?.StateCode;
};

export const getOldestPersonAge = (membership) => {
  if (!membership) return null;
  const people = mainCouple(membership);

  let oldestDate = moment();

    people?.forEach((person) => {
        // Birth date of this person
        const birthDate = moment(person.Person.BirthDate, 'YYYY-MM-DD');

    // Are they the oldest so far?
    if (birthDate.isBefore(oldestDate)) {
      oldestDate = birthDate;
    }
  });

  return moment().diff(oldestDate, 'years');
};

/**
 *
 * Create a new object that uses only api-accepted keys
 *
 * @param pairings: Eg: {api: 'mydata', local: 'myData'}
 * @param values: Object
 * @return {{}}
 */
export const apiHelpers = {
  propertyTransform: (pairings, values) => {
    const submittable = {};
    pairings.forEach((pair) => {
      // If there is a value for this key, add it to submittable
      values[pair.in] && (submittable[pair.out] = values[pair.in]);
    });
    return submittable;
  },
};
