import React, { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { Spinner, Button, Form } from 'react-bootstrap';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';
import { useHistory } from 'react-router-dom';
import { Lock } from 'img/vector/Lock/comp';
import Fade from 'react-reveal/Fade';
import { Formik } from 'formik';
import * as Yup from 'yup';
import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import gtagEvent from 'libs/gtagEvent';
import {
    DownloadButton,
    FeatureImage,
    FeatureTitle,
    Terms,
} from '../common/components/exports';

const ChangePasswordForgotPage = () => {
    const [featureImage, setFeatureImage] = useState<JSX.Element>();
    const [featureTitle, setFeatureTitle] = useState<JSX.Element>();
    const [androidButton, setAndroidButton] = useState<JSX.Element>();
    const [iosButton, setIosButton] = useState<JSX.Element>();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [outcome, setOutcome] = useState<string | null>(null);

    const [memberId, setMemberId] = useState(null);
    const [otp, setOtp] = useState(null);

    const history = useHistory();
    // const dispatch = useDispatch();
    const [loadFeatureState, loadFeature] = useAsyncFn(async () => {
        const response = await api.app(CONFIG).settings();

        setFeatureTitle(
            <FeatureTitle
                text={
                    <>
                        Download our{' '}
                        <span className={'text-primary-dark-1'}>
                            Smart&nbsp;Claiming&nbsp;App
                        </span>{' '}
                        and submit your claim with just a few taps.
                    </>
                }
            />,
        );

        setFeatureImage(<FeatureImage src={response.portal.login.bg_img} />);

        setIosButton(
            <DownloadButton
                image={response.portal.login.app_store_img}
                link={response.portal.login.app_store_url}
                alt={'iOS App Store download button'}
                gtag={() => {
                    gtagEvent({
                        screen: 'changeforgot',
                        action: 'download_ios',
                        label: 'Download from AppStore',
                    });
                }}
            />,
        );

        setAndroidButton(
            <DownloadButton
                image={response.portal.login.play_store_img}
                link={response.portal.login.play_store_url}
                alt={'Google Play Store download button'}
                gtag={() => {
                    gtagEvent({
                        screen: 'changeforgot',
                        action: 'download_android',
                        label: 'Download from Google Play Store',
                    });
                }}
            />,
        );
    });

    useEffect(() => {
        try {
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);

            const dataParam = params.get('data') || '';
            const data = JSON.parse(atob(dataParam));
            /** atob is deprecated use this instead */

            setMemberId(data.user);
            setOtp(data.token);
        } catch {
            history.replace('/');
        }

        console.log('useAsyncFn is probably being used incorrectly: ', {
            loadFeatureState,
        });
        loadFeature();
    }, []);

    const onSubmit = (values, resetForm) => {
        if (!memberId || !otp) return;
        setLoading(true);

        gtagEvent({
            screen: 'changeforgot',
            action: 'newpassword',
            label: 'Submitted new password',
        });

        console.log(memberId, otp, values.newPassword);
        api.user(CONFIG)
            .changePasswordForgot(memberId, otp, values.newPassword)
            .then((response) => {
                resetForm();
                setLoading(false);

                if (response.status !== 'success') {
                    setOutcome('fail');
                    setMessage(
                        response?.message
                            ? response.message + '.'
                            : 'Your request could not be completed.',
                    );
                    if(response?.message==="Password has been changed. Please allow up to 2 minutes for change to take effect") {
                        setOutcome('success');
                        setMessage(response?.message);
                    }
                    setTimeout(() => {
                        setOutcome(null);
                        setMessage('');
                    }, 30000);
                } else {
                    setOutcome('success');
                    setMessage(response?.message);
                }
            });
    };

    return (
        <main className="h-100 d-flex">
            <div
                className="d-none d-lg-flex flex-column h-100 col-xl-7"
                style={{
                    backgroundColor: '#AFE8E6',
                    maxWidth: '1000px',
                }}>
                {featureImage}
                {featureTitle}
                <div
                    className={
                        'd-flex align-items-center justify-content-center'
                    }>
                    {iosButton}
                    {androidButton}
                </div>
            </div>
            <div className="bg-white col-lg-5 d-flex col-xl align-items-center justify-content-center flex-column">
                <div className={'pb-3 d-flex flex-column'}>
                    <div
                        className={
                            'w-100 d-flex align-items-center justify-content-center mb-3'
                        }>
                        <Lock />
                    </div>
                </div>

                <div
                    className={
                        'text-primary-dark-1 font-weight-bold font-feature mb-2'
                    }
                    style={{
                        fontSize: 22,
                        position: 'relative',
                    }}>
                    Change Password
                </div>

                <p
                    style={{
                        fontSize: 14,
                        position: 'relative',
                    }}>
                    Enter a new password.
                </p>

                <p
                    style={{
                        fontSize: 14,
                        position: 'relative',
                        backgroundColor: '#EFEFEF',
                        padding: '5px',
                        borderRadius: '4px',
                    }}>
                    {`Password must be at least 8 characters.`}<br />
                    {`Password must be no longer than 20 characters.`}<br />
                    {`Password must contain at least one uppercase character.`}<br />
                    {`Password must contain at least one number.`}<br />
                    {`Password cannot contain <, >, &, # or spaces.`}
                </p>

                <Formik
                    validateOnChange={true}
                    validateOnBlur={true}
                    validateOnMount={true}
                    initialValues={{
                        newPassword: '',
                        confirmPassword: '',
                    }}
                    onSubmit={(values, { resetForm }) =>
                        onSubmit(values, resetForm)
                    }
                    validationSchema={Yup.object().shape({
                        newPassword: Yup.string()
                            .required('A new password is required.')
                            .min(8, 'Password must be at least 8 characters.')
                            .max(
                                20,
                                'Password must be no longer than 20 characters.',
                            )
                            .matches(
                                /[A-Z]/,
                                'Password must contain at least one uppercase character.',
                            )
                            .matches(
                                /[a-z]/,
                                'Password must contain at least one lowercase character.',
                            )
                            .matches(
                                /[0-9]/,
                                'Password must contain at least one number.',
                            )
                            .test({
                                name: 'no-illegal',
                                message:
                                    'Password cannot contain <, >, &, # or spaces.',
                                test: (value) => {
                                    if (!value) return true;
                                    return !(
                                        value.includes('#') ||
                                        value.includes('<') ||
                                        value.includes('<') ||
                                        value.includes('>') ||
                                        value.includes(' ')
                                    );
                                },
                            }),
                        confirmPassword: Yup.string()
                            .required('You must confirm your password.')
                            .oneOf(
                                [Yup.ref('newPassword'), null],
                                'Passwords do not match.',
                            ),
                    })}>
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isValid,
                        } = props;
                        return (
                            <Form
                                style={{ maxWidth: '20rem' }}
                                className={'w-50'}>
                                <Form.Group controlId="newPassword">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="newPassword"
                                        className={
                                            errors.newPassword &&
                                            touched.newPassword &&
                                            !loading
                                                ? 'is-invalid'
                                                : ''
                                        }
                                        onChange={handleChange('newPassword')}
                                        onBlur={handleBlur('newPassword')}
                                        value={values.newPassword}
                                        autoComplete="new-password"
                                    />
                                    {touched.newPassword &&
                                    errors.newPassword &&
                                    !loading ? (
                                        <Form.Control.Feedback type={'invalid'}>
                                            {errors.newPassword}
                                        </Form.Control.Feedback>
                                    ) : null}
                                </Form.Group>
                                <Form.Group controlId="confirmPassword">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="confirmPassword"
                                        className={
                                            errors.confirmPassword &&
                                            touched.confirmPassword &&
                                            !loading
                                                ? 'is-invalid'
                                                : ''
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.confirmPassword}
                                        autoComplete="new-password"
                                    />
                                    {touched.confirmPassword &&
                                    errors.confirmPassword &&
                                    !loading ? (
                                        <Form.Control.Feedback type={'invalid'}>
                                            {errors.confirmPassword}
                                        </Form.Control.Feedback>
                                    ) : null}
                                </Form.Group>
                                <Button
                                    type={'submit'}
                                    onClick={(e: React.MouseEvent<HTMLFormElement, MouseEvent>) => handleSubmit(e)}
                                    variant={'secondary'}
                                    block
                                    className={'mt-2'}
                                    disabled={!isValid}>
                                    {loading ? (
                                        <Spinner
                                            animation="border"
                                            role="status"
                                            as="span"
                                            size="sm"
                                        />
                                    ) : (
                                        'Change Password'
                                    )}
                                </Button>
                                <div style={{ margin: '0px -100px' }}>
                                    <Fade bottom when={outcome !== null}>
                                        <StatusNotificationCard
                                            status={
                                                outcome === 'fail'
                                                    ? 'error'
                                                    : 'success'
                                            }>
                                            {message}
                                        </StatusNotificationCard>
                                    </Fade>
                                </div>
                                <div style={{marginLeft: 'auto', marginRight: 'auto', width: 'fit-content',}}>
                                    <a
                                        href={'/'}
                                    >
                                    Back to login
                                    </a>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>

            <div
                style={{
                    fontSize: 12,
                    position: 'absolute',
                    bottom: 20,
                    right: 20,
                }}
                className={'text-light-gray font-feature'}>
                <Terms />
            </div>
        </main>
    );
};

export default ChangePasswordForgotPage;