import SettingsActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from '../../libs/constants';

export const setSettings = (settings) => ({
    type: SettingsActionTypes.SET_SETTINGS,
    payload: settings,
});

export const thunkGetSettings = () => (dispatch) => {
    // const state = getState();
    // const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);

    api.app(CONFIG)
        .settings()
        .then((response) => {
            dispatch(setSettings(response));
            // console.log(response)
        });
};
