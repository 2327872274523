import React from 'react';
import CustomModal from 'components/modals/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setRebateFormModalVisible } from 'store';
import gtagEvent from 'libs/gtagEvent';
import {
    RebatePrompt,
    RegisterDetails,
    RebateDeclaration,
    AcceptRebate,
    FinishRebate,
    DeclineConfirmation,
} from './steps/exports';

export const RebateFormModal = ({ align }) => {
    const dispatch = useDispatch();
    const rebateFormModalVisible = useSelector(
        (state) => state.ui.rebateFormModalVisible,
    );
    const step = useSelector((state) => state.rebateForm.step);

    return (
        <CustomModal
            handleClose={() => {
                if (step === 5) {
                    gtagEvent({
                        screen: 'govrebate',
                        action: 'close',
                        label: 'closed rebate form on final page',
                        type: 'modal',
                    });
                  dispatch(setRebateFormModalVisible(false));
                  sessionStorage.setItem('rebatePopupShown', 'true');
                }
            }}
            align={align}
            show={rebateFormModalVisible}
            className={`rebate-form-modal rebate-form-step-${step}`}>
            {step === 1 ? <RebatePrompt /> : null}
            {step === 2 ? <RegisterDetails /> : null}
            {step === 3 ? <RebateDeclaration /> : null}
            {step === 4 ? <AcceptRebate /> : null}
            {step === 5 ? <FinishRebate /> : null}
            {step === 6 ? <DeclineConfirmation /> : null}
        </CustomModal>
    );
};

export default RebateFormModal;
