import MembershipActionTypes from './actions-types';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MembershipActionTypes.SET_MEMBERSHIP:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(`SET_MEMBERSHIP with payload ${action.payload}`);
                console.log(action.payload);
            }
            return action.payload;
        default:
            return state;
    }
};
