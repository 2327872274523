import * as Yup from 'yup';
import moment from 'moment';

export const SuspendCoverSchema = Yup.object().shape({
    startDate: Yup.date()
        .required('Start Date is required.')
        .min(
            moment().subtract(1, 'day').toDate(),
            'Start Date cannot be in the past.',
        )
        .nullable(),
    endDate: Yup.date()
        .required('End Date is required.')
        .min(
            moment().subtract(1, 'day').toDate(),
            'End Date cannot be in the past.',
        )
        .min(Yup.ref('startDate'), 'End Date cannot be before start date.')
        .test(
            'is-two-months-after-start',
            'Trip must be at least two months.',
            function (value) {
                const startDate = this.resolve(Yup.ref('startDate'));
                const minEndDate = moment(startDate).add(2, 'months').toDate();
                return moment(value).isSameOrAfter(minEndDate);
            }
        )
        .max(
            moment(Yup.ref('startDate')).add(3, 'year'),
            'Trip cannot be longer than 3 years.',
        )
        .nullable(),
    departingNumber: Yup.string()
        .required('Flight/Cruise number is required.')
        .max(20, 'Flight/Cruise number is too long.'),
        // .nullable(),
    returningNumber: Yup.string()
        // .required('Flight/Cruise number is required.')
        .max(20, 'Flight/Cruise number is too long.')
        .nullable(),
    everyoneOnMembershipIsTravelling: Yup.boolean(),
    additionalPeople: Yup.array().when('everyoneOnMembershipIsTravelling', {
        is: false,
        then: Yup.array()
            .min(1, 'You must select at least one person who is travelling.')
            .max(4, 'You can select at most 4 people who are not travelling.'),
    }),
    partnerOrDepNotTravelling: Yup.boolean(),
    overseasContactEmail: Yup.string()
        .email('Overseas contact email is not valid')
        // .required('Overseas contact email is required.')
        .max(500, 'Overseas contact email is too long')
        .nullable(),
    overseasContactPhone: Yup.string()
        .min(3, 'Overseas contact phone is too short.')
        .max(500, 'Overseas contact phone is too long.')
        // .required('Overseas contact phone is required')
        .nullable(),
    agreeDisclaimers: Yup.boolean()
        .required()
        .oneOf([true], 'You must agree to the disclaimers.'),
});

export const INITIAL_VALUES = {
    startDate: '',
    departingNumber: '',

    endDate: '',
    returningNumber: '',

    everyoneOnMembershipIsTravelling: true,
    additionalPeople: [],
    partnerOrDepNotTravelling: '',

    overseasContactEmail: '',
    overseasContactPhone: '',

    agreeDisclaimers: false,
};
