import React from 'react';
import styled from 'styled-components';
import {Button, Form} from 'react-bootstrap';

import {MdMyLocation, MdSearch} from 'react-icons/md';

const SearchBoxContainer = styled.div`
    position: absolute;
    top: 25px;
    left: 25px;
    border-radius: 12px;
`;

const SearchInput = styled(Form.Control)`
    width: 270px;
    margin-right: 10px;
`;

const SearchInputSeparator = styled.div`
    width: 1px;
    background-color: #d8d8d8;
    display: flex;
`;

const ProviderTypeFilters = styled.div`
    box-shadow: inset 0 2px 0 0 #dad8e2, inset 0 -2px 0 0 #dad8e2;
`;

const ProviderSearchBox = () => {
    return (
        <SearchBoxContainer className="shadow overflow-hidden">
            <Form onSubmit={() => alert('eita')}>
                <div id="provider-search-box" className="bg-white p-2 d-flex">
                    <div className="d-flex align-items-center">
                        <MdSearch size={24} className="text-primary mx-1"/>

                        <SearchInput
                            className="border-0 pl-1"
                            placeholder="Type your suburb or postcode"
                        />
                    </div>

                    <SearchInputSeparator/>

                    <Button className="pl-2 pr-1" variant="link">
                        <MdMyLocation size={22}/>
                    </Button>
                </div>
                <ProviderTypeFilters className="bg-body p-2"/>
                <div className="bg-white p-3"/>
            </Form>
        </SearchBoxContainer>
    );
};

export default ProviderSearchBox;
