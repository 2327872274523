import { getAccessToken, getMembershipId } from 'libs/auth';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';

const calculatePaymentAmountAsync = async (value, abortSignal) => {
    const memberid = getMembershipId();
    const access_token = getAccessToken();

    /** @type {{ paymentAmount: String, failedAttempts: String, premiumDate:String,}} */
    const CoverConfig = {};

    if (value === '') {
        CoverConfig.paymentAmount = null;
        CoverConfig.failedAttempts = 0;
        CoverConfig.premiumDate = null;
        return CoverConfig;
    }

    try {
        const response = await api
            .user(CONFIG, access_token)
            .membership.premium.premiumEstimate(memberid, value, abortSignal);

        if (response?.status === 'error') throw new Error(response);

        response?.Amount && response.Amount > 0
            ? (CoverConfig.paymentAmount = response?.Amount)
            : (CoverConfig.paymentAmount = 0);

        CoverConfig.premiumDate = `${response?.Amount}`;
        if (response?.Amount < 0) CoverConfig.premiumDate = '0';

        return CoverConfig;
    } catch (response) {
        // Continuation logic has already been skipped, so return normally
        if (response.name === 'AbortError') return;

        console.log('ERROR');
        console.log(response);
        CoverConfig.premiumDate = '--';
        console.log({ CoverConfig });

        return CoverConfig;
    }
};

export default calculatePaymentAmountAsync;
