import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import ReferAFriendBanner from 'components/elements/banners/ReferAFriendBanner';
import InitialIcon from 'components/elements/user/InitialIcon';
import { allPersons } from 'libs/helpers';
import { setSelectedUser } from 'store';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import gtagEvent from 'libs/gtagEvent';
import { StoreType } from 'store/types';

interface UserProps {
    user?: any;
    selected?: boolean;
}

/**
 * A single user in the switcher.
 * @param user
 */
const User: React.FC<UserProps> = ({ user, selected }) => {
    const [userIsHovered, setUserIsHovered] = useState(false);

    let liWidth, liMarginBottom, backgroundColorClass;

    // If mobile view
    if (useMediaQuery('(max-width: 600px)')) {
        liWidth = '100vw';
        liMarginBottom = '2px';
    }

    if (userIsHovered) {
        backgroundColorClass = 'bg-body-gray';
    } else if (selected) {
        backgroundColorClass = 'bg-body-gray';
    } else {
        backgroundColorClass = 'bg-mid-gray';
    }

    return (
        <li
            style={{
                color: 'white',
                backgroundColor: 'green',
                padding: '1rem',
                paddingRight: '1.5rem',
                paddingLeft: '1.5rem',
                display: 'flex',
                alignItems: 'center',
                width: liWidth,
                marginBottom: liMarginBottom,
            }}
            className={backgroundColorClass}
            onMouseEnter={() => {
                setUserIsHovered(true);
            }}
            onMouseLeave={() => {
                setUserIsHovered(false);
            }}
        >
                <ReactPlaceholder type="media" rows={1} ready={!!user}>
                    {user && (
                        <>
                            <InitialIcon user={user} selected={selected} />
                            <div
                                style={{
                                    padding: 0,
                                    fontSize: '1rem',
                                    color: 'black',
                                }}>
                                {user.Person.GivenName}
                            </div>
                        </>
                    )}
                </ReactPlaceholder>
        </li>
    );
};

/**
 * Contains all users in the switcher, including an special offers that float right.
 */
const UserSwitcher = () => {
  const showSpecialOffer = useMediaQuery('(min-width: 1000px)');
  const membership = useSelector((state: StoreType) => state.membership);
  const selectedUserId = useSelector((state: StoreType) => state.selectedUser);
  const dispatch = useDispatch();
  const currentUsers = allPersons(membership);

    return (
        <section
            style={{
                width: '100%',
            }}
            className={'bg-mid-gray'}>
            {/* Container */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                {/* Users list */}
                <ul
                    className="user-switcher"
                    style={{
                        listStyle: 'none',
                        marginBottom: 0,
                        display: 'flex',
                        flexWrap: 'wrap',
                        padding: 0,
                    }}>
                    {currentUsers && selectedUserId ? (
                        currentUsers.map((user, idx) => {
                            const thisUserIsSelected =
                                selectedUserId === user.Person.PersonId.toString();

                            return (
                                <div
                                    onClick={() => {
                                        // Set this user as selected
                                        if (!thisUserIsSelected) {
                                            gtagEvent({
                                                screen: 'overview',
                                                action: 'switchuser',
                                                label: 'Clicked a User Tab',
                                            });
                                            dispatch(
                                                setSelectedUser(
                                                    user.Person.PersonId,
                                                ),
                                            );
                                        }
                                    }}
                                    key={idx}>
                                    <User
                                        user={user}
                                        selected={thisUserIsSelected}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <>
                            <div>
                                <User />
                            </div>
                        </>
                    )}
                </ul>

                {/* Offer */}
                <div style={{
                    display: showSpecialOffer ? 'inline' : 'none'
                }}>
                    <ReferAFriendBanner/>
                </div>
            </div>
        </section>
    );
};

export default UserSwitcher;
