import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Spinner } from 'react-bootstrap';
import { isMedicareNumber, mainPerson } from 'libs/helpers';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAsyncFn } from 'react-use';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { setMembership } from 'store/membership/actions';
import {
  setRebateFormStep,
  setRebateFormTier,
  setRebateFormStartDate,
} from 'store';

const RegisterDetails = () => {
  const dispatch = useDispatch();

  const membership = useSelector((state) => state.membership);
  const product = useSelector((state) => state.product);
  const rebateForm = useSelector((state) => state.rebateForm);
  const membershipStartDate = mainPerson(membership)?.JoinDate;
  const joinDate = Date.parse(membershipStartDate);

  const [income, setIncome] = useState([]);
  // const [rebateTier, setRebateTier] = useState(null);
  const [currentSelectedDate, setCurrentSelectedDate] = useState(joinDate);
  const [checkBox, setCheckBox] = useState(true);

  const [disableSubmit, setDisableSubmit] = useState(true);

  // Fields
  const [medicareHide, setMedicareHide] = useState(false);
  const [medicareNumber, setMedicareNumber] = useState('');
  const [medicareNumberValid, setMedicareNumberValid] = useState(false);
  const [medicareNumberError, setMedicareNumberError] = useState('');

  const [medicareRefNumber, setMedicareRefNumber] = useState('');
  const [medicareRefNumberValid, setMedicareRefNumberValid] = useState(false);
  const [medicareRefNumberError, setMedicareRefNumberError] = useState('');

  const [medicareExpiry, setMedicareExpiry] = useState(new Date());
  const [medicareExpiryValid, setMedicareExpiryValid] = useState(true);
  const [medicareExpiryError] = useState('');

  const [medicareFirstName, setMedicareFirstName] = useState('');
  const [medicareFirstNameValid, setMedicareFirstNameValid] = useState(false);
  const [medicareFirstNameError, setMedicareFirstNameError] = useState('');

  const [medicareLastName, setMedicareLastName] = useState('');
  const [medicareLastNameValid, setMedicareLastNameValid] = useState(false);
  const [medicareLastNameError, setMedicareLastNameError] = useState('');

  useEffect(() => {
    if (checkBox) {
      setCurrentSelectedDate(joinDate);
      dispatch(
        setRebateFormStartDate(moment(joinDate).format('DD[/]MM[/]YYYY')),
      );
    } else {
      setCurrentSelectedDate(new Date());
      dispatch(
        setRebateFormStartDate(moment(new Date()).format('DD[/]MM[/]YYYY')),
      );
    }
  }, [checkBox]);

  useEffect(() => {
    if (
      !medicareNumberValid ||
      !medicareRefNumberValid ||
      !medicareExpiryValid ||
      !medicareFirstNameValid ||
      !medicareLastNameValid ||
      rebateForm.tier === null ||
      typeof rebateForm.tier === 'undefined'
    ) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [
    medicareNumberValid,
    medicareRefNumberValid,
    medicareExpiryValid,
    medicareFirstNameValid,
    medicareLastNameValid,
    rebateForm,
  ]);

  useEffect(() => {
    if (membership?.Contribution?.RebateTier) {
      dispatch(setRebateFormTier(membership?.Contribution?.RebateTier));
    }

    const membershipType = product?.membershipClassCode;
    if (membershipType === 'Sgl') {
      const incomeArray = [
        '$97,000 or less',
        '$97,001 - $113,000',
        '$113,001 - $151,000',
        '$151,001 or more',
      ];
      setIncome(incomeArray);
    } else {
      const incomeArray = [
        '$194,000 or less',
        '$194,001 - $226,000',
        '$226,001 - $302,000',
        '$302,001 or more',
      ];
      setIncome(incomeArray);
    }

    const main = mainPerson(membership);
    if (
      main?.Person?.MedicareCardNumber &&
      main?.Person?.MedicareCardReferenceNumber &&
      main?.Person?.MedicareCardExpiryMonth &&
      main?.Person?.MedicareCardExpiryYear &&
      main?.Person?.MedicareCardGivenName &&
      main?.Person?.MedicareCardSurname
    ) {
      // Check the expiry
      const medicareExpiryMonth =
        main?.Person?.MedicareCardExpiryMonth.length === 1
          ? '0' + main?.Person?.MedicareCardExpiryMonth
          : main?.Person?.MedicareCardExpiryMonth;
      const medicareExpiryString =
        main?.Person?.MedicareCardExpiryYear + '-' + medicareExpiryMonth;

      if (
        moment(medicareExpiryString)
          .endOf('month')
          .isSameOrAfter(moment().endOf('month'))
      ) {
        // if (moment(medicareExpiryString).endOf("month").isAfter(moment().endOf("month"))) {
        setMedicareHide(true);
        setMedicareNumber(main?.Person?.MedicareCardNumber);
        setMedicareNumberValid(true);
        setMedicareRefNumber(main?.Person?.MedicareCardReferenceNumber);
        setMedicareRefNumberValid(true);
        setMedicareFirstName(main?.Person?.MedicareCardGivenName);
        setMedicareFirstNameValid(true);
        setMedicareLastName(main?.Person?.MedicareCardSurname);
        setMedicareLastNameValid(true);
        setMedicareExpiry(moment(medicareExpiryString).toDate());
        setMedicareExpiryValid(true);
      } else {
        setMedicareNumber(main?.Person?.MedicareCardNumber);
        setMedicareNumberValid(true);
        setMedicareRefNumber(main?.Person?.MedicareCardReferenceNumber);
        setMedicareRefNumberValid(true);
        setMedicareFirstName(main?.Person?.MedicareCardGivenName);
        setMedicareFirstNameValid(true);
        setMedicareLastName(main?.Person?.MedicareCardSurname);
        setMedicareLastNameValid(true);
        // setMedicareExpiry(moment(medicareExpiryString).toDate());
        // setMedicareExpiryValid(true);
      }
    } else {
      if (main?.Person?.MedicareCardNumber) {
        setMedicareNumber(main?.Person?.MedicareCardNumber);
        setMedicareNumberValid(true);
      }
      if (main?.Person?.MedicareCardReferenceNumber) {
        setMedicareRefNumber(main?.Person?.MedicareCardReferenceNumber);
        setMedicareRefNumberValid(true);
      }
      if (main?.Person?.MedicareCardGivenName) {
        setMedicareFirstName(main?.Person?.MedicareCardGivenName);
        setMedicareFirstNameValid(true);
      }
      if (main?.Person?.MedicareCardSurname) {
        setMedicareLastName(main?.Person?.MedicareCardSurname);
        setMedicareLastNameValid(true);
      }
      if (
        main?.Person?.MedicareCardExpiryMonth &&
        main?.Person?.MedicareCardExpiryYear
      ) {
        const medicareExpiryMonth =
          main?.Person?.MedicareCardExpiryMonth.length === 1
            ? '0' + main?.Person?.MedicareCardExpiryMonth
            : main?.Person?.MedicareCardExpiryMonth;
        const medicareExpiryString =
          main?.Person?.MedicareCardExpiryYear + '-' + medicareExpiryMonth;
        if (
          moment(medicareExpiryString)
            .endOf('month')
            .isAfter(moment().endOf('month'))
        ) {
          setMedicareExpiry(moment(medicareExpiryString).toDate());
          setMedicareExpiryValid(true);
        }
      }
    }
  }, [membership, product]);

  const [onSubmitState, onSubmit] = useAsyncFn(async (...props) => {
    const access_token = getAccessToken();
    const memberid = getMembershipId();

    const [
      medicareHide,
      medicareNumber,
      medicareRefNumber,
      medicareExpiry,
      medicareFirstName,
      medicareLastName,
      currentSelectedDate,
    ] = props;

    console.log({
      medicareHide,

      medicareNumber,
      medicareRefNumber,
      medicareExpiry,
      medicareFirstName,
      medicareLastName,
      currentSelectedDate,
    });

    try {
      let newMembershipObject;
      if (!medicareHide) {
        const main = mainPerson(membership);
        let outValuesMedicare = {};
        outValuesMedicare.personid = main.Person.PersonId;
        outValuesMedicare.medicarenumber = medicareNumber;
        outValuesMedicare.medicareref = medicareRefNumber;
        outValuesMedicare.medicarefirstname = medicareFirstName;
        outValuesMedicare.medicarelastname = medicareLastName;
        outValuesMedicare.medicareexpirymonth =
          moment(medicareExpiry).format('M');
        outValuesMedicare.medicareexpiryyear =
          moment(medicareExpiry).format('YYYY');

        console.log({ outValuesMedicare });
        const response = await api
          .user(CONFIG, access_token)
          .person.editPerson(memberid, outValuesMedicare);
        if (response?.status === 'error') {
          throw new Error(response);
        } else {
          console.log(response);
          // dispatch(setMembership(response))
          newMembershipObject = response;
        }
      }
      if (newMembershipObject) {
        dispatch(setMembership(newMembershipObject));
      }

      dispatch(setRebateFormStep(3));
    } catch (err) {
      console.log('ERROR');
      console.log(err);
    }
  });

  return (
    <div className="rebate-form-step-2">
      <div className="container-fluid">
        <div className="row flex-wrap">
          <div className="col-12 text-center steps">
            <span className="active">1</span>
            <span>2</span>
            <span>3</span>
          </div>
          <div
            className="col-12 text-primary font-21 pb-3 font-weight-bold text-center"
            style={{ fontSize: '21px' }}>
            Register for Government Rebate
          </div>
          <div
            className="col-12 col-md-8 offset-md-2 text-center pb-3"
            style={{ fontSize: '14px' }}>
            Please fill out the fields below:
          </div>
        </div>
      </div>
      <div
        style={{
          background: '#E6E6EE',
          height: '2px',
          margin: '0px -30px',
        }}></div>
      <div
        className="container-fluid pt-3"
        style={{
          display: medicareHide ? 'none' : 'block',
        }}>
        <div className="row">
          <div
            className="col-12 col-md-10 offset-md-1 text-primary-dark-1 font-weight-medium"
            style={{ fontSize: '18px' }}>
            Medicare Details
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 d-flex flex-wrap">
            <Form.Group
              controlId={`medicare-number`}
              className="text-label-gray col-4 pr-3">
              <Form.Label>Medicare Number</Form.Label>
              <Form.Control
                type="text"
                className={
                  !medicareNumberValid && medicareNumberError !== ''
                    ? 'is-invalid'
                    : ''
                }
                onChange={(e) => {
                  if (!isNaN(e.target.value)) {
                    setMedicareNumber(e.target.value);
                  }
                }}
                value={medicareNumber}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (isMedicareNumber(value)) {
                    setMedicareNumberValid(true);
                    setMedicareNumberError('');
                  } else {
                    setMedicareNumberValid(false);
                    setMedicareNumberError('Medicare Number is invalid');
                  }
                }}
              />
              {!medicareNumberValid && medicareNumberError !== '' ? (
                <Form.Control.Feedback type={'invalid'}>
                  {medicareNumberError}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group
              controlId={`medicare-ref-number`}
              className="text-label-gray col-4 pr-3">
              <Form.Label>Reference Number</Form.Label>
              <Form.Control
                type="text"
                className={
                  !medicareRefNumberValid && medicareRefNumberError !== ''
                    ? 'is-invalid'
                    : ''
                }
                onChange={(e) => {
                  if (!isNaN(e.target.value) && e.target.value.length < 2) {
                    setMedicareRefNumber(e.target.value);
                  }
                }}
                value={medicareRefNumber}
                onBlur={(e) => {
                  if (
                    !isNaN(e.target.value) &&
                    e.target.value.length < 2 &&
                    e.target.value.length > 0
                  ) {
                    setMedicareRefNumberValid(true);
                    setMedicareRefNumberError('');
                  } else {
                    setMedicareRefNumberValid(false);
                    setMedicareRefNumberError('Reference Number is invalid');
                  }
                }}
              />
              {!medicareRefNumberValid && medicareRefNumberError !== '' ? (
                <Form.Control.Feedback type={'invalid'}>
                  {medicareRefNumberError}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group
              controlId={`medicare-expiry`}
              className="text-label-gray col-4 pr-3">
              <Form.Label>Expiry Date</Form.Label>
              <DatePicker
                wrapperClassName={''}
                className={
                  !medicareExpiryValid && medicareExpiryError !== ''
                    ? 'is-invalid form-control'
                    : 'form-control'
                }
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                selected={medicareExpiry}
                minDate={moment().toDate()}
                maxDate={moment().add(10, 'years').toDate()}
                onChange={(e) => {
                  setMedicareExpiry(e);
                  setMedicareExpiryValid(true);
                }}
                onBlur={() => {
                  setMedicareExpiryValid(true);
                }}
              />
            </Form.Group>
            <Form.Group
              controlId={`medicare-first-name`}
              className="text-label-gray col-4 pr-3">
              <Form.Label>First name on card</Form.Label>
              <Form.Control
                type="text"
                className={
                  !medicareFirstNameValid && medicareFirstNameError !== ''
                    ? 'is-invalid'
                    : ''
                }
                onChange={(e) => {
                  setMedicareFirstName(e.target.value);
                }}
                value={medicareFirstName}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (value.length > 1) {
                    setMedicareFirstNameValid(true);
                    setMedicareFirstNameError('');
                  } else {
                    setMedicareFirstNameValid(false);
                    setMedicareFirstNameError('First name is invalid');
                  }
                }}
              />
              {!medicareFirstNameValid && medicareFirstNameError !== '' ? (
                <Form.Control.Feedback type={'invalid'}>
                  {medicareFirstNameError}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group
              controlId={`medicare-last-name`}
              className="text-label-gray col-4 pr-3">
              <Form.Label>Last name on card</Form.Label>
              <Form.Control
                type="text"
                className={
                  !medicareLastNameValid && medicareLastNameError !== ''
                    ? 'is-invalid'
                    : ''
                }
                onChange={(e) => {
                  setMedicareLastName(e.target.value);
                }}
                value={medicareLastName}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (value.length > 1) {
                    setMedicareLastNameValid(true);
                    setMedicareLastNameError('');
                  } else {
                    setMedicareLastNameValid(false);
                    setMedicareLastNameError('Last name is invalid');
                  }
                }}
              />
              {!medicareLastNameValid && medicareLastNameError !== '' ? (
                <Form.Control.Feedback type={'invalid'}>
                  {medicareLastNameError}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12 col-md-10 offset-md-1">
            <div
              style={{
                borderBottom: '2px solid #E6E6EE',
              }}></div>
          </div>
        </div>
      </div>
      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col-6 col-md-5 offset-md-1 text-primary-dark-1 font-weight-medium">
            <div className="row">
              <div className="col pb-1" style={{ fontSize: '18px' }}>
                Rebate Tier
              </div>
            </div>
            <div className="row">
              <div className="col-12 pb-3">
                <Form.Group>
                  <Form.Check type={'radio'}>
                    <Form.Check.Input
                      type={'radio'}
                      name={'rebatetier'}
                      id={'rebatetier-0'}
                      value="0"
                      checked={rebateForm.tier === '0'}
                      onChange={(e) => {
                        // setRebateTier(e.target.value);
                        dispatch(setRebateFormTier(e.target.value));
                      }}
                    />
                    <Form.Check.Label
                      htmlFor="rebatetier-0"
                      className="text-label-gray font-weight-medium"
                      style={{ fontSize: '13px' }}>
                      {`Base Tier: ${income[0]}`}
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check type={'radio'}>
                    <Form.Check.Input
                      type={'radio'}
                      name={'rebatetier'}
                      id={'rebatetier-1'}
                      value="1"
                      checked={rebateForm.tier === '1'}
                      onChange={(e) => {
                        // setRebateTier(e.target.value);
                        dispatch(setRebateFormTier(e.target.value));
                      }}
                    />
                    <Form.Check.Label
                      htmlFor="rebatetier-1"
                      className="text-label-gray font-weight-medium"
                      style={{ fontSize: '13px' }}>
                      {`Tier 1: ${income[1]}`}
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check type={'radio'}>
                    <Form.Check.Input
                      type={'radio'}
                      name={'rebatetier'}
                      id={'rebatetier-2'}
                      value="2"
                      checked={rebateForm.tier === '2'}
                      onChange={(e) => {
                        // setRebateTier(e.target.value);
                        dispatch(setRebateFormTier(e.target.value));
                      }}
                    />
                    <Form.Check.Label
                      htmlFor="rebatetier-2"
                      className="text-label-gray font-weight-medium"
                      style={{ fontSize: '13px' }}>
                      {`Tier 2: ${income[2]}`}
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check type={'radio'}>
                    <Form.Check.Input
                      type={'radio'}
                      name={'rebatetier'}
                      id={'rebatetier-3'}
                      value="3"
                      checked={rebateForm.tier === '3'}
                      onChange={(e) => {
                        // setRebateTier(e.target.value);
                        dispatch(setRebateFormTier(e.target.value));
                      }}
                    />
                    <Form.Check.Label
                      htmlFor="rebatetier-3"
                      className="text-label-gray font-weight-medium"
                      style={{ fontSize: '13px' }}>
                      {`Tier 3: ${income[3]}`}
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-5 offset-md-1 text-primary-dark-1 font-weight-medium">
            <div className="row">
              <div className="col pb-1" style={{ fontSize: '18px' }}>
                Rebate Start Date
              </div>
            </div>
            <div className="row">
              <div className="col-12 pb-3">
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    id={'checkbox-use-start-date'}
                    label={'Use membership start date.'}
                    className={'text-label-gray font-weight-medium'}
                    style={{
                      fontSize: '13px',
                    }}
                    name={`use-membership-start-date`}
                    checked={checkBox}
                    onChange={(val) => {
                      setCheckBox(val.target.checked);
                    }}
                  />
                  <div style={{ paddingBottom: 12 }}></div>
                  {!checkBox && (
                    <DatePicker
                      className="form-control"
                      name="start_date"
                      dateFormat="dd/MM/yyyy"
                      disabled={checkBox}
                      selected={currentSelectedDate}
                      maxDate={moment().add(1, 'years').toDate()}
                      minDate={moment().toDate()}
                      onChange={(e) => {
                        dispatch(
                          setRebateFormStartDate(
                            moment(e).format('DD[/]MM[/]YYYY'),
                          ),
                        );
                        setCurrentSelectedDate(moment(e).toDate());
                      }}
                      showMonthYearDropdown
                    />
                  )}
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          borderBottom: '2px solid #E6E6EE',
          margin: '0px -30px 12px',
        }}></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center pb-3" style={{ fontSize: 14 }}>
            Click{' '}
            <a
              href="https://tuh.com.au/information/government-initiatives/australian-government-rebate-private-health-insurance"
              target="_blank"
              rel="noopener noreferrer">
              here
            </a>{' '}
            for more information on receiving the Australian Government Rebate
            on Private Health Insurance as a Premium Deduction.
          </div>
          <div className="col-12 text-center">
            <Button
              variant="secondary"
              onClick={() => {
                onSubmit(
                  medicareHide,
                  medicareNumber,
                  medicareRefNumber,
                  medicareExpiry,
                  medicareFirstName,
                  medicareLastName,
                  currentSelectedDate,
                );
              }}
              disabled={disableSubmit || onSubmitState.loading}>
              {onSubmitState.loading ? (
                <>
                  <Spinner
                    animation="border"
                    role="status"
                    as="span"
                    size="sm"
                  />
                  Continue
                </>
              ) : (
                'Continue'
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterDetails;
