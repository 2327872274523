import React from 'react';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const CourseEndDate = () => {
    const { values, getFieldProps } = useFormikContext();

    return (
        <Form.Group
            controlId={`schoolcourseenddate`}
            className="col-6 text-label-gray">
            <Form.Label>Course End Date</Form.Label>
            <Form.Control
                type="date"
                disabled={!values.schooldeclare}
                min={moment(values.schoolcoursestartdate).format('YYYY-MM-DD')}
                {...getFieldProps('schoolcourseenddate')}
            />
        </Form.Group>
    );
};

export default CourseEndDate;
