import LastProvidersActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';

export const setLastProviders = (lastProviders) => ({
    type: LastProvidersActionTypes.SET_LAST_PROVIDERS,
    payload: lastProviders,
});

export const thunkGetLastProviders = () => (dispatch, _getState) => {
    const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    api.user(CONFIG, token)
        .membership.claim.getLastProviders(memberId)
        .then((response) => {
            if (!response?.message) {
                dispatch(setLastProviders(response));
            }
            // console.log(response)
        });
};
