import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CustomModal from 'components/modals/Modal';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import InitialIcon from 'components/elements/user/InitialIcon';
import { mainCouple } from 'libs/helpers';

const StyledTable = styled(Table)`
    td:not(:first-child),
    th:not(:first-child) {
        text-align: right;
    }
`;

const LHCModal = ({ show, handleClose, align }) => {
    const [mainMembershipPersons, setMainMembershipPersons] = useState('');

    const membership = useSelector((state) => state.membership);
    const lhc = useSelector((state) => state.lhc);

    useEffect(() => {
        setMainMembershipPersons(mainCouple(membership));
    }, [membership]);

    return (
        <CustomModal
            handleClose={handleClose}
            align={align}
            show={show}
            title={'LHC'}>
            <div className="text-primary font-feature row row-cols-1 ">
                <div className="col text-black font-14">
                    <p>
                        <strong>Lifetime Health Cover (LHC) loading</strong> is
                        a Government initiative designed to encourage people to
                        get hospital insurance early in life and to keep their
                        cover.
                    </p>

                    <p>
                        If you take out hospital cover after 1 July following
                        your 31st birthday, your base premium (before any
                        Private Health Insurance Rebate is deducted) may
                        increase by 2% for each year you&apos;re over the age of
                        30 up to a maximum of 70%. Any other adult on your
                        policy will also have to pay LHC if they join after 1
                        July following their 31st birthday.
                    </p>
                </div>
                <div>
                    <StyledTable responsive striped>
                        <thead>
                            <tr>
                                <th>Family member</th>
                                <th>LHC</th>
                            </tr>
                        </thead>
                        <tbody>
                            {typeof mainMembershipPersons?.map === 'function' &&
                                mainMembershipPersons?.map((person, idx) => (
                                    <tr
                                        key={`claim_row_${idx}`}
                                        className={'table-link'}>
                                        <td className="d-flex align-items-center">
                                            <InitialIcon
                                                user={person}
                                                size={0.65}
                                            />
                                            <strong>
                                                {person?.Person?.GivenName}
                                                &nbsp;
                                                {person?.Person?.Surname}
                                            </strong>
                                        </td>
                                        <td style={{ verticalAlign: 'middle' }}>
                                            <strong>
                                                {lhc?.[
                                                    person?.Person?.PersonId
                                                ] ?? '0'}
                                                %
                                            </strong>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </StyledTable>
                </div>
                <div className="col text-black font-14 modal-box-shadow pt-3 pb-3">
                    For more info please&nbsp;
                    <a
                        href="https://tuh.com.au/information/government-initiatives/lifetime-health-cover-loading-lhc"
                        target="_blank"
                        rel="noopener noreferrer">
                        click here
                    </a>
                    .
                </div>
            </div>
        </CustomModal>
    );
};

export default LHCModal;
