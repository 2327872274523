import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SuccessCheck } from 'img/vector/SuccessCheck/comp';
import Warning from 'img/vector/Warning/comp';
import IconSuccess from 'img/vector/IconSuccess/comp';
import IconError from 'img/vector/IconError/comp';
import { Button, Table } from 'react-bootstrap';
import { ymdToSlashFormatDate } from 'libs/helpers';
import gtagEvent from 'libs/gtagEvent';
import { StoreType } from 'store/types';

{
    /*const Headers = ['CLAIMANT', 'SERVICE', 'PROVIDER', 'ITEM', 'FEE', 'BENEFIT', ''];*/
}
const Headers = ['CLAIMANT', 'PROVIDER', 'ITEM', 'FEE', 'BENEFIT', ''];

const LineStatus = ({ line }) => {
    if (line.ClaimLineStatus === 'Reversed/Cancelled' || line.isRejected) {
        return (
            <>
                <IconError /> {line.Message.Text}
            </>
        );
    }
    return (
        <>
            <IconSuccess /> Submitted Successfully
        </>
    );
};

export const ThankYouClaimPage = () => {
    const fastClaimStore = useSelector((state: StoreType) => state.fastClaim);
    const [isSuccess, setIsSuccess] = useState(true);
    const [totalAmount, setTotalAmount] = useState(parseFloat('0.0').toFixed(2));
    const [totalBenefit, setTotalBenefit] = useState(
        parseFloat('0.0').toFixed(2),
    );
    const history = useHistory();

    useEffect(() => {
        console.log({ fastClaimStore });
        if (fastClaimStore?.claimResult?.ClaimId) {
            const haveRejected =
                fastClaimStore.claimResult.ClaimLines.ClaimLine.find(
                    (line) =>
                        line.ClaimLineStatus === 'Reversed/Cancelled' ||
                        line.isRejected,
                );
            if (haveRejected) {
                setIsSuccess(false);
            }
        }
    }, [fastClaimStore]);

    useEffect(() => {
        if (!isSuccess) {
            let tempAmount = 0;
            let tempBenefit = 0;
            fastClaimStore.claimResult.ClaimLines.ClaimLine.forEach((item) => {
                tempAmount = tempAmount + parseFloat(item.Fee);
                tempBenefit = tempBenefit + parseFloat(item.BenefitAmount);
            });
            setTotalAmount(tempAmount.toFixed(2));
            setTotalBenefit(tempBenefit.toFixed(2));
        }
    }, [isSuccess]);

    if (!isSuccess) {
        return (
            <>
                <main
                    className="p-4 thankyou-claim-page error-side"
                    style={{ height: '100%' }}>
                    <div
                        className="d-flex"
                        style={{
                            height: '100%',
                            alignItems: 'center',
                        }}>
                        <div className="w-100 text-center">
                            <div>
                                <Warning />
                            </div>
                            <div
                                className="text-primary-dark-1 font-weight-ultra pt-3"
                                style={{ fontSize: '38px' }}>
                                There was an error with one of your submissions
                            </div>
                            <div className="py-5">
                                <Table className="" responsive striped>
                                    <thead>
                                        <tr>
                                            {Headers.map((head) => (
                                                <th key={head}>{head}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fastClaimStore?.claimResult?.ClaimLines
                                            ?.ClaimLine &&
                                            fastClaimStore?.claimResult?.ClaimLines?.ClaimLine?.map(
                                                (line, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <td>
                                                                {
                                                                    line.Person
                                                                        .GivenName
                                                                }{' '}
                                                                {
                                                                    line.Person
                                                                        .Surname
                                                                }
                                                            </td>
                                                            <td>
                                                                <div>
                                                                    {
                                                                        line.ProviderName
                                                                    }{' '}
                                                                    -{' '}
                                                                    {
                                                                        line.ProviderCode
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {ymdToSlashFormatDate(
                                                                        line.ServiceDate,
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {
                                                                    line.Service
                                                                        .ServiceCode
                                                                }{' '}
                                                                -{' '}
                                                                {
                                                                    line.Service
                                                                        .Description
                                                                }
                                                            </td>
                                                            <td>${line.Fee}</td>
                                                            <td>
                                                                $
                                                                {
                                                                    line.BenefitAmount
                                                                }
                                                            </td>
                                                            <td>
                                                                <LineStatus
                                                                    line={line}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                $
                                                {parseFloat(
                                                    totalAmount,
                                                ).toFixed(2)}
                                            </td>
                                            <td>
                                                $
                                                {parseFloat(
                                                    totalBenefit,
                                                ).toFixed(2)}
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </div>
                            <div className="pt-5">
                                <Button
                                    variant="secondary"
                                    style={{
                                        padding: '13.5px 44px',
                                    }}
                                    className="mr-3"
                                    onClick={() => {
                                        gtagEvent({
                                            screen: 'finishclaim',
                                            action: 'error_startnew',
                                            label: 'User started new claim from error page',
                                        });
                                        history.push('/member/claim/new');
                                    }}>
                                    Start new claim
                                </Button>
                                <Button
                                    variant="outline-primary"
                                    className="text-primary-dark-1 finish-btn"
                                    onClick={() => {
                                        gtagEvent({
                                            screen: 'finishclaim',
                                            action: 'error_finish',
                                            label: 'User finished claim from error page',
                                        });
                                        history.push('/member/claim');
                                    }}>
                                    Finish
                                </Button>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }

    return (
        <>
            <main
                className="p-4 thankyou-claim-page success-side"
                style={{ height: '100%' }}>
                <div
                    className="d-flex"
                    style={{
                        height: '100%',
                        alignItems: 'center',
                    }}>
                    <div className="w-100 text-center">
                        <div>
                            <SuccessCheck />
                        </div>
                        <div
                            className="text-primary-dark-1 font-weight-ultra pt-3"
                            style={{ fontSize: '38px' }}>
                            Thank you!
                        </div>
                        <div
                            className="pt-3"
                            style={{ maxWidth: '345px', margin: '0 auto' }}>
                            Thank you for submitting your claim.
                        </div>
                        <div className="pt-5">
                            <Button
                                variant="secondary"
                                style={{
                                    padding: '13.5px 44px',
                                }}
                                className="mr-3"
                                onClick={() => {
                                    gtagEvent({
                                        screen: 'finishclaim',
                                        action: 'success_startnew',
                                        label: 'User started new claim from success page',
                                    });
                                    history.push('/member/claim/new');
                                }}>
                                Start new claim
                            </Button>
                            <Button
                                variant="outline-primary"
                                className="text-primary-dark-1 finish-btn"
                                onClick={() => {
                                    gtagEvent({
                                        screen: 'finishclaim',
                                        action: 'success_finish',
                                        label: 'User finished claim from success page',
                                    });
                                    history.push('/member/claim');
                                }}>
                                Finish
                            </Button>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};
