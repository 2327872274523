import { dependantHelpers } from 'libs/dependant';
import { ageToYears } from 'libs/helpers';

export const determineIsDependentAge = (currentUser, dependantAge) => {
    const age = ageToYears(currentUser?.Person?.BirthDate);
    const endAge = parseInt(dependantAge);
    
    if (
        dependantHelpers.isTurningAgeSoon(currentUser, 21) ||
        (age >= 21 && age <= endAge)
    ) {
        const isPenStuDep =
            dependantHelpers.isPendingStudentDependant(currentUser);
        const isPenExtDep =
            dependantHelpers.isPendingExtendedDependant(currentUser);
        if (!isPenStuDep && !isPenExtDep) {
            return true;
        }
    } else {
        return false;
    }
};
