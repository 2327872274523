import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

interface FormikContext {
    firstName: string;
    lastName: string;
}

const RegisterName: React.FC = () => {
    const {
        values,
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        getFieldProps,
        isSubmitting,
    } = useFormikContext<FormikContext>();
    const firstNameKey = 'firstName';
    const lastNameKey = 'lastName';

    return (
        <>
            <Form.Group
                className={'col-12 col-lg-6'}
                controlId="member-first-name">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                    {...getFieldProps(firstNameKey)}
                    value={values[firstNameKey]}
                    onChange={(e) => {
                        setFieldTouched(firstNameKey, true);
                        setFieldValue(firstNameKey, e.target.value);
                    }}
                    type="text"
                    placeholder="First Name"
                    autoComplete="new-field"
                />
                {touched[firstNameKey] &&
                    errors[firstNameKey] &&
                    !isSubmitting && (
                        <small className="text-danger form-text">
                            {errors[firstNameKey]}
                        </small>
                    )}
            </Form.Group>
            <Form.Group
                className={'col-12 col-lg-6'}
                controlId="member-last-name">
                <Form.Label>Surname</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Surname"
                    {...getFieldProps(lastNameKey)}
                    value={values[lastNameKey]}
                    onChange={(e) => {
                        setFieldTouched(lastNameKey, true);
                        setFieldValue(lastNameKey, e.target.value);
                    }}
                    autoComplete="new-field"
                />
                {touched[lastNameKey] &&
                    errors[lastNameKey] &&
                    !isSubmitting && (
                        <small className="text-danger form-text">
                            {errors[lastNameKey]}
                        </small>
                    )}
            </Form.Group>
        </>
    );
};

export default RegisterName;
