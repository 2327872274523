import * as Yup from 'yup';

export const ClaimPaymentsModalSchema = Yup.object().shape({
    bank: Yup.string().required('BSB is invalid'),
    bsb: Yup.string()
        .matches(/(?:\d{3})(?:-)?(?:\d{3})/, 'Invalid BSB')
        .min(6, 'Invalid BSB')
        .max(7, 'Invalid BSB')
        .required('BSB is required'),
    accountNumber: Yup.string()
        .required('Account Number is required')
        .min(6, 'Invalid Account Number')
        .max(10, 'Invalid Account Number'),
    accountName: Yup.string().required('Account Holder is required'),
});
