import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import CustomModal from 'components/modals/Modal';
import { Formik } from 'formik';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import gtagEvent from 'libs/gtagEvent';
import { allAdults, mainPerson } from 'libs/helpers';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import api from '@qldtuh/tuh-uh-js-api';
import { ReferAFriendSchema } from './validation/ReferAFriendSchema';
import {
    Preamble,
    MyDetails,
    FriendDetails,
    Terms,
    SubmitForm,
} from './components';

const ReferAFriendModal = ({ show, handleClose, align }) => {
    const membership = useSelector((state) => state.membership);
    const adults = allAdults(membership);
    const [loading, setLoading] = useState(false);

    const [errorSpace, setErrorSpace] = useState(<>&nbsp;</>);
    const [failedAttempts, setFailedAttempts] = useState(0);

    const [successSpace, setSuccessSpace] = useState(<>&nbsp;</>);
    const [successAttempts, setSuccessAttempts] = useState(0);

    const getDefaultValues = () => {
        return {
            person: mainPerson(membership)?.Person?.PersonId || '',
            friendFirst: '',
            friendLast: '',
            friendMobile: '',
            friendEmail: '',
        };
    };

    const [defaultValues, setDefaultValues] = useState(getDefaultValues());

    useEffect(() => {
        if (membership) {
            setDefaultValues(getDefaultValues());
        }
    }, [membership]);

    const onSubmit = async (values, actions, adults, membership) => {
        setLoading(true);
        gtagEvent({
            screen: 'referafriend',
            action: 'submit',
            label: 'Submitted referral',
            type: 'modal',
        });

        const sendingUser = adults.find(
            (person) => person.Person.PersonId === values.person,
        );

        const out = {};
        out.memberid = membership.MembershipId;
        out.name =
            sendingUser.Person.GivenName + ' ' + sendingUser.Person.Surname;
        out.first_name = values.friendFirst;
        out.last_name = values.friendLast;
        out.email = values.friendEmail;
        out.mobile = values.friendMobile;

        console.log('OUT: ', out);

        const access_token = getAccessToken();
        const memberid = getMembershipId();

        try {
            const response = await api
                .website(CONFIG, access_token)
                .refer(memberid, out);

            if (response?.status === 'error') {
                throw new Error(response.message);
            } else {
                console.log(response);
                setSuccessSpace('Thank you for your submission');
                actions.resetForm();
                setSuccessAttempts(1);
                setTimeout(() => {
                    setSuccessSpace(<>&nbsp;</>);
                    setSuccessAttempts(0);
                }, 10000);
                setLoading(false);
            }
        } catch (response) {
            console.log('ERROR');
            console.log(response.message);
            setErrorSpace(response.message);
            setFailedAttempts(1);
            setTimeout(() => {
                setErrorSpace(<>&nbsp;</>);
                setFailedAttempts(0);
            }, 10000);
            setLoading(false);
        }
    };

    return (
        <CustomModal
            handleClose={handleClose}
            align={align}
            show={show}
            title={'Refer a Friend'}>
            <div className="text-primary font-feature row row-cols-1">
                <Preamble />
                <div className="col text-black py-3 ">
                    <Formik
                        enableReinitialize={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        validateOnMount={true}
                        initialValues={defaultValues || null}
                        validationSchema={ReferAFriendSchema}
                        onSubmit={(values, actions) =>
                            onSubmit(values, actions, adults, membership)
                        }>
                        <Form>
                            <MyDetails loading={loading} />
                            <FriendDetails loading={loading} />
                            <Terms />
                            <SubmitForm
                                loading={loading}
                                handleClose={handleClose}
                            />
                        </Form>
                    </Formik>
                    <div style={{ paddingInline: '10px' }}>
                        <Fade collapse bottom when={successAttempts > 0}>
                            <StatusNotificationCard status="success">
                                {successSpace}
                            </StatusNotificationCard>
                        </Fade>
                    </div>
                    <div style={{ paddingInline: '10px' }}>
                        <Fade collapse bottom when={failedAttempts > 0}>
                            <StatusNotificationCard status="error">
                                {errorSpace}
                            </StatusNotificationCard>
                        </Fade>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
};

export default ReferAFriendModal;
