import moment from 'moment';

const isActiveCampaign = (active, start, end) => {
    console.log({time:{ start, end}})
    if (!active) return false;
    if (!start && !end) return true;

    const now = moment().startOf('day');
   
    return active && now.isBetween(start, end);
};

export default isActiveCampaign;
