import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const WhoIsTraveling = () => {
    const { values, setFieldValue } = useFormikContext();

    const changeAnswer = (val) => {
        setFieldValue('everyoneOnMembershipIsTravelling', val);
    };
    return (
        <div className="row frm-brdr">
            <div className="col">
                <p className="form-heading">
                    Will all people on your membership be travelling overseas
                    with you?
                </p>
                <Form.Group controlId={`people-travelling`}>
                    <Form.Check
                        inline
                        type="radio"
                        id={`people-travelling-yes`}
                        label={`Yes`}
                        name={`all-travelling`}
                        className={'text-label-gray font-weight-medium'}
                        checked={
                            values.everyoneOnMembershipIsTravelling === true
                        }
                        onChange={() => changeAnswer(true)}
                    />
                    <Form.Check
                        inline
                        type="radio"
                        id={`people-travelling-no`}
                        label={`No`}
                        name={`all-travelling`}
                        className={'text-label-gray font-weight-medium'}
                        checked={
                            values.everyoneOnMembershipIsTravelling === false
                        }
                        onChange={() => changeAnswer(false)}
                    />
                </Form.Group>
            </div>
        </div>
    );
};

export default WhoIsTraveling;
