import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

export const StudentDependent = () => {
    const { values, getFieldProps, setFieldValue } = useFormikContext();
    const dependantEndAge = useSelector((state) => state.settings.general.dependant_age);

    return (
        <>
            <Form.Group controlId={`school`}>
                <Form.Check
                    inline
                    type="checkbox"
                    id={`schooldeclare-1`}
                    label={`Register as Student Dependant`}
                    name={`schooldeclare`}
                    className={'text-label-gray font-weight-medium'}
                    style={{
                        fontSize: '13px',
                    }}
                    checked={values.schooldeclare === true}
                    onChange={(e) => {
                        if (values.registerAsExtDep && e.target.checked) {
                            setFieldValue('registerAsExtDep', false);
                        }
                        setFieldValue('schooldeclare', e.target.checked);
                    }}
                    {...getFieldProps('schooldeclare')}
                />
            </Form.Group>
            <div className="" style={{ fontSize: '14px' }}>
                <ul>
                    <li>
                        All TUH family and single parent policies cover
                        dependants up to age 21.
                    </li>
                    <li>
                        Dependents who are studying full-time at a recognised
                        education facility can remain on their family’s policy
                        until they reach age {dependantEndAge}.
                    </li>
                </ul>
            </div>
        </>
    );
};

export default StudentDependent;
