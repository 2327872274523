import MembershipActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';

export const setMembership = (membership) => ({
    type: MembershipActionTypes.SET_MEMBERSHIP,
    payload: membership,
});

export const thunkGetMembership = () => (dispatch) => {
    const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    return new Promise((res, rej) => {
        api.user(CONFIG, token)
            .membership.getMembership(memberId)
            .then((response) => {
                if (response?.status === 'error') {
                    console.log(
                        'ERR:: Could not get membership object, this is most likely a HAMBS issue.',
                    );
                    // throw Error(
                    //     'Could not retrieve membership details, please try again lateresponse.',
                    // );
                }
                dispatch(setMembership(response));
                res();
            })
            .catch((e) => rej(e.message));
    });
};

export const thunkGetMembershipCached = () => (dispatch) => {
    const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    return new Promise((res, rej) => {
        api.user(CONFIG, token)
            .membership.getMembershipCached(memberId)
            .then((response) => {
                if (response?.status === 'error') {
                    console.log(
                        'ERR:: Could not get membership object, this is most likely a HAMBS issue.',
                    );
                    // throw Error(
                    //     'Could not retrieve membership details, please try again lateresponse.',
                    // );
                }
                dispatch(setMembership(response));
                res();
            })
            .catch((e) => rej(e.message));
    });
};
