import React from 'react';

export const TabItem = ({ title, active }) => {
    const ACTIVE_STYLE =
        'bg-white text-primary-dark-1 font-weight-medium modal-tab';
    const INACTIVE_STYLE =
        'bg-primary-dark-1 text-light-purple font-weight-medium modal-tab';

    return (
        <div className={active ? ACTIVE_STYLE : INACTIVE_STYLE}>{title}</div>
    );
};

export default TabItem;
