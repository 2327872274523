import React from 'react';
import styled from 'styled-components';

const Division = styled.div`
    background-color: #efeff5;
    width: 100%;
`;


const CardAltDivision = (props) => {
    return (
        <Division>
            {props.children}
        </Division>
    );
};

export default CardAltDivision;
