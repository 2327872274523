import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {MdClose} from 'react-icons/md';
import styled from 'styled-components';

const ModalTitle = styled(Modal.Title)`
    font-size: 24px;
`;

interface CustomModalProps {
    show: boolean;
    handleClose: () => void;
    title?: string;
    footer?: React.ReactNode;
    align?: string;
    className?: string;
}

const CustomModal: React.FC<CustomModalProps> = ({show, handleClose, children, title, footer, align, className}) => {

    let dialogClass = align ? `modal-dialog-align-${align}` : undefined;
    let contentClass = align ? `modal-content-align-${align}` : undefined;

    return (
        <Modal show={show} onHide={handleClose} dialogClassName={dialogClass} className={className}>
            <Modal.Header className="justify-content-between align-items-center bg-primary-dark-1">

                <ModalTitle className="text-white font-feature font-weight-normal text-uppercase">
                    {title}
                </ModalTitle>

                <Button onClick={handleClose} className="bg-primary-dark-1 border-0">
                    <MdClose className="text-white" size="1.75em"/>
                </Button>

            </Modal.Header>
            <Modal.Body className={contentClass}>  {children} </Modal.Body>
            {footer && <Modal.Footer>{footer}</Modal.Footer>}
        </Modal>
    );
};

export default CustomModal;
