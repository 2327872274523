import React from 'react';

const PromoContent = ({ children }) => {
    return (
        <div className="row h-100 mx-3 w-100 justify-content-start referral-promo-shareables">
            {children}
        </div>
    );
};

export default PromoContent;
