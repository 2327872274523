import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const FriendDetails = ({ loading }) => {
    const { touched, errors, getFieldProps } = useFormikContext();
    return (
        <>
            <div className="font-weight-semibold text-black pt-4 col font-14">
                My Friend&apos;s Details
            </div>
            <div className="row pb-3 modal-box-shadow">
                <Form.Group
                    controlId={`friend-first-name`}
                    className="col-6 text-label-gray">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                        type="text"
                        className={
                            errors.friendFirst &&
                            touched.friendFirst &&
                            !loading &&
                            'is-invalid'
                        }
                        {...getFieldProps('friendFirst')}
                    />
                    {touched.friendFirst && errors.friendFirst && !loading && (
                        <Form.Control.Feedback type={'invalid'}>
                            {errors.friendFirst}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group
                    controlId={`friend-last-name`}
                    className="col-6 text-label-gray">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                        type="text"
                        className={
                            errors.friendLast &&
                            touched.friendLast &&
                            !loading &&
                            'is-invalid'
                        }
                        {...getFieldProps('friendLast')}
                    />
                    {touched.friendLast && errors.friendLast && !loading && (
                        <Form.Control.Feedback type={'invalid'}>
                            {errors.friendLast}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
                <Form.Group
                    controlId={`friend-mobile`}
                    className="col-6 text-label-gray">
                    <Form.Label>Mobile</Form.Label>
                    <Form.Control
                        type="text"
                        className={
                            errors.friendMobile &&
                            touched.friendMobile &&
                            !loading &&
                            'is-invalid'
                        }
                        {...getFieldProps('friendMobile')}
                    />
                    {touched.friendMobile &&
                        errors.friendMobile &&
                        !loading && (
                            <Form.Control.Feedback type={'invalid'}>
                                {errors.friendMobile}
                            </Form.Control.Feedback>
                        )}
                </Form.Group>
                <Form.Group
                    controlId={`friend-email`}
                    className="col-6 text-label-gray">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="text"
                        className={
                            errors.friendEmail &&
                            touched.friendEmail &&
                            !loading &&
                            'is-invalid'
                        }
                        {...getFieldProps('friendEmail')}
                    />
                    {touched.friendEmail && errors.friendEmail && !loading && (
                        <Form.Control.Feedback type={'invalid'}>
                            {errors.friendEmail}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
            </div>
        </>
    );
};

export default FriendDetails;
