import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

interface FormikContext {
    memberId: string;
}

const RegisterNumber: React.FC = () => {
    const {
        values,
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        getFieldProps,
        isSubmitting,
    } = useFormikContext<FormikContext>();
    const memKey = 'memberId';
    return (
        <Form.Group className={'mt-3 col-12'} controlId="member-id">
            <Form.Label>Member Number</Form.Label>
            <Form.Control
                {...getFieldProps(memKey)}
                type="text"
                placeholder="Member Number"
                value={values[memKey]}
                onChange={(e) => {
                    setFieldTouched(memKey, true);
                    setFieldValue(memKey, e.target.value);
                }}
                autoComplete="new-field"
            />
            {touched[memKey] && errors[memKey] && !isSubmitting && (
                <small className="text-danger form-text">
                    {errors[memKey]}
                </small>
            )}
        </Form.Group>
    );
};

export default RegisterNumber;
