import {Button} from "react-bootstrap";
import {IoMdAirplane} from "react-icons/all";
import React from "react";

interface Props {
    onClick: () => void;
}

const SuspendCoverWhileOverseas: React.FC<Props> = ({onClick}) => {
    return <Button
        className="ml-0 px-2 flex-shrink-1 text-primary-dark-1"
        variant="outline-gray"
        onClick={onClick}
    >
        <div className="d-flex align-items-center">
            <div className="mr-2" style={{
                transform: "rotate(45deg)"
            }}>
                <IoMdAirplane size={20} style={{verticalAlign:'middle'}}/>
            </div>
            <div style={{fontSize:'13px'}}>
                Suspend Cover while Overseas
            </div>
        </div>
    </Button>;
}

export default SuspendCoverWhileOverseas
