import React from 'react';
import {
    DateOfBirth,
    FirstName,
    GenderSelect,
    Surname,
    Title,
} from '../components';

export const MemberInfo = ({ loading, edit = false }) => {
    return (
        <div className="d-flex pb-2 pt-3 flex-wrap modal-box-shadow">
            <GenderSelect edit={edit} />
            <div className="d-flex flex-grow-1 pl-3 pr-3">
                <Title loading={loading} />
                <FirstName loading={loading} edit={edit} />
                <Surname loading={loading} />
            </div>
            <div className="d-flex flex-grow-1 flex-wrap">
                <DateOfBirth loading={loading} edit={edit} />
            </div>
        </div>
    );
};

export default MemberInfo;
