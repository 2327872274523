import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import Fade from 'react-reveal/Fade';
import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';

export const MedicareEligible = ({ loading }) => {
    const { touched, errors, values, setFieldValue, setFieldTouched } =
        useFormikContext();
    const SHOW_MSG =
        !!touched.eligibleForMedicare &&
        !!errors.eligibleForMedicare &&
        !loading;
    const [showError, setShowError] = useState(SHOW_MSG);

    useEffect(() => {
        setShowError(!!errors.eligibleForMedicare);
        if (loading || !touched.eligibleForMedicare) setShowError(false);
    }, [touched, errors, loading]);

    return (
        <div className="col-12">
            <Form.Group controlId="eligibleForMedicare">
                <Form.Label className="d-block">
                    Is this person eligible for Medicare?*
                </Form.Label>
                <Form.Check
                    inline
                    type="radio"
                    id={`eligibleForMedicare-1`}
                    label={`Yes`}
                    name={`eligibleForMedicare`}
                    className={
                        errors.eligibleForMedicare &&
                        touched.eligibleForMedicare &&
                        !loading
                            ? 'is-invalid text-label-gray font-weight-medium'
                            : 'text-label-gray font-weight-medium'
                    }
                    style={{
                        fontSize: '13px',
                    }}
                    checked={values.eligibleForMedicare === true}
                    onChange={() => {
                        setFieldTouched('eligibleForMedicare', true);
                        setFieldValue('eligibleForMedicare', true);
                    }}
                />
                <Form.Check
                    inline
                    type="radio"
                    id={`eligibleForMedicare-2`}
                    label={`No`}
                    name={`eligibleForMedicare`}
                    className={
                        errors.eligibleForMedicare &&
                        touched.eligibleForMedicare &&
                        !loading
                            ? 'is-invalid text-label-gray font-weight-medium'
                            : 'text-label-gray font-weight-medium'
                    }
                    style={{
                        fontSize: '13px',
                    }}
                    checked={values.eligibleForMedicare === false}
                    onChange={() => {
                        setFieldTouched('eligibleForMedicare', true);
                        setFieldValue('eligibleForMedicare', false);
                    }}
                />
                <Fade bottom collapse when={showError}>
                    {!values.eligibleForMedicare && (
                        <StatusNotificationCard status={'error'}>
                            {errors.eligibleForMedicare}
                        </StatusNotificationCard>
                    )}
                </Fade>
            </Form.Group>
        </div>
    );
};

export default MedicareEligible;
