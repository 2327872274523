import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { usePlacesAutocomplete } from '../../hooks';

export const PostalAddress = ({ isVisible, onSubmitState }) => {
    const { getFieldProps, errors, touched } = useFormikContext();
    const addressRef = usePlacesAutocomplete('post');

    return (
        <div
            style={{
                display: isVisible ? 'block' : 'none',
            }}>
            <div
                className="modal-box-shadow"
                style={{
                    padding: '15px',
                }}>
                <Form.Group
                    controlId={`postal-address-line1`}
                    className="text-label-gray">
                    <Form.Label>Street 1</Form.Label>
                    <Form.Control
                        type="text"
                        ref={addressRef}
                        className={
                            errors.postLine1 &&
                            touched.postLine1 &&
                            !onSubmitState.loading &&
                            'is-invalid'
                        }
                        {...getFieldProps('postLine1')}
                    />
                    {touched.postLine1 &&
                        errors.postLine1 &&
                        !onSubmitState.loading && (
                            <Form.Control.Feedback type={'invalid'}>
                                {errors.postLine1}
                            </Form.Control.Feedback>
                        )}
                </Form.Group>
                <Form.Group
                    controlId={`postal-address-line2`}
                    className="text-label-gray">
                    <Form.Label>Street 2</Form.Label>
                    <Form.Control
                        type="text"
                        className={
                            errors.postLine2 &&
                            touched.postLine2 &&
                            !onSubmitState.loading &&
                            'is-invalid'
                        }
                        {...getFieldProps('postLine2')}
                    />
                    {touched.postLine2 &&
                        errors.postLine2 &&
                        !onSubmitState.loading && (
                            <Form.Control.Feedback type={'invalid'}>
                                {errors.postLine2}
                            </Form.Control.Feedback>
                        )}
                </Form.Group>
                <div className="d-flex align-items-center justify-content-between row">
                    <Form.Group
                        controlId={`postal-address-suburb`}
                        className="col-6 text-label-gray">
                        <Form.Label>City / Suburb</Form.Label>
                        <Form.Control
                            type="text"
                            className={
                                errors.postSuburb &&
                                touched.postSuburb &&
                                !onSubmitState.loading &&
                                'is-invalid'
                            }
                            {...getFieldProps('postSuburb')}
                        />
                        {touched.postSuburb &&
                            errors.postSuburb &&
                            !onSubmitState.loading && (
                                <Form.Control.Feedback
                                    type={'invalid'}
                                    style={{ minHeight: '38px' }}>
                                    {errors.postSuburb}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group
                        controlId={`postal-address-state`}
                        className="col-3 text-label-gray">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                            as="select"
                            className={
                                errors.postState &&
                                touched.postState &&
                                !onSubmitState.loading &&
                                'is-invalid'
                            }
                            {...getFieldProps('postState')}>
                            <option value=""></option>
                            <option value="QLD">QLD</option>
                            <option value="NSW">NSW</option>
                            <option value="VIC">VIC</option>
                            <option value="TAS">TAS</option>
                            <option value="ACT">ACT</option>
                            <option value="SA">SA</option>
                            <option value="WA">WA</option>
                            <option value="NT">NT</option>
                        </Form.Control>
                        {touched.postState &&
                            errors.postState &&
                            !onSubmitState.loading && (
                                <Form.Control.Feedback type={'invalid'}>
                                    {errors.postState}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group
                        controlId={`postal-address-postcode`}
                        className="col-3 text-label-gray">
                        <Form.Label>Postcode</Form.Label>
                        <Form.Control
                            type="text"
                            className={
                                errors.postPostcode &&
                                touched.postPostcode &&
                                !onSubmitState.loading &&
                                'is-invalid'
                            }
                            {...getFieldProps('postPostcode')}
                        />
                        {touched.postPostcode &&
                            errors.postPostcode &&
                            !onSubmitState.loading && (
                                <Form.Control.Feedback type={'invalid'}>
                                    {errors.postPostcode}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                </div>
            </div>
        </div>
    );
};

export default PostalAddress;
