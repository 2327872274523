import React from 'react';

interface Props {
    text: JSX.Element;
}

const FeatureTitle: React.FC<Props> = ({ text }) => {
    return (
        <div
            style={{
                color: 'white',
                fontSize: '2.5rem',
                lineHeight: '3rem',
                padding: '3rem',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '20%',
                textAlign: 'center',
                zIndex: 1,
            }}
            className={'font-feature font-weight-black text'}>
            {text}
        </div>
    );
};

export default FeatureTitle;
