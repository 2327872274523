import React from 'react';
import { Form } from 'react-bootstrap';
import { reversePriceMask } from 'libs/helpers';
import { FastClaimTypeItem } from 'types/fastClaim';

interface FeeProps {
    claimItem?: FastClaimTypeItem;
    updateClaim: (newObject: any) => void;
}

export const Fee: React.FC<FeeProps> = ({ claimItem, updateClaim }) => {
    return (
        <Form.Group controlId={`service-fee`} className="col-4 text-label-gray">
            <Form.Label style={{ fontSize: '11px' }}>Fee</Form.Label>
            <Form.Control
                type="text"
                className=""
                onChange={(event) => {
                    const testRegex = /^[0-9]*\.?[0-9]{0,3}$/g;
                    const valid = testRegex.test(event.target.value);

                    if (valid) {
                        const price = reversePriceMask(
                            event.target.value,
                            '0.00',
                        );
                        updateClaim({
                            ...claimItem,
                            itemFee: price,
                        });
                    }
                }}
                disabled={claimItem?.item?.ServiceCode === undefined}
                value={claimItem?.itemFee ?? ''}
            />
        </Form.Group>
    );
};

export default Fee;
