import React from "react";
import {MdInfo} from "react-icons/all";
import LinkOutlineTextIcon from "./LinkOutlineTextIcon";

interface Props {
    onClick: () => void;
}

const MoreInfo: React.FC<Props> = ({onClick}) => {
    return (
        <LinkOutlineTextIcon
            text="MORE INFO"
            icon={<MdInfo/>}
            iconSide="left"
            className={"text-primary"}
            onClick={onClick}
        />
    );
};

export default MoreInfo;

