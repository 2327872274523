import { getHomeAddressState, getOldestPersonAge } from 'libs/helpers';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';
import { changeCoverConst } from 'store/changeCover/actions';
import { getAccessToken, getMembershipId } from 'libs/auth';
export const changeCoverHelpers = {
    get: {
        products: ({ changeCoverStore, membership }) => {
            return new Promise((resolve, reject) => {
                const state = getHomeAddressState(membership);
                const age = getOldestPersonAge(membership);
                const memberid = getMembershipId();
                const access_token = getAccessToken();
                // Note that regardless of what we choose, we will also call the 'packages'
                // List of product types to search for
                let callableProducts = ['packages'];
                switch (changeCoverStore.coverType) {
                    case changeCoverConst.COVER_TYPE_BOTH:
                        callableProducts.push('hospital_cover');
                        callableProducts.push('extras_cover');
                        break;
                    case changeCoverConst.COVER_TYPE_HOSPITAL:
                        callableProducts.push('hospital_cover');
                        callableProducts.push('extras_cover');
                        break;
                    case changeCoverConst.COVER_TYPE_EXTRAS:
                        callableProducts.push('hospital_cover');
                        callableProducts.push('extras_cover');
                        break;
                }
                const promiseArray = [];
                let allProducts = {};
                // For each type we need, set up an api call
                callableProducts.forEach((productType) => {
                    const data = {
                        cover: productType,
                        filter: changeCoverStore.groupCoverType,
                        state: state,
                        age: age,
                    };
                    promiseArray.push(
                        api
                            .website(CONFIG, access_token)
                            .cover.getProducts(memberid, data),
                    );
                });
                // Make all api calls to fetch products
                Promise.all(promiseArray)
                    .then((values) => {
                        values.forEach((value) => {
                            allProducts = {
                                ...allProducts,
                                ...value,
                            };
                        });
                        resolve(allProducts);
                    })
                    .catch((e) => {
                        // A call failed
                        reject(e);
                    });
            });
        },
        suggestions: ({ changeCoverStore, membership }) => {
            return new Promise((resolve, reject) => {
                const access_token = getAccessToken();
                const age = getOldestPersonAge(membership);
                const scale = changeCoverStore.groupCoverType;
                let looking_for;
                // Translate api keys
                switch (changeCoverStore.coverType) {
                    case changeCoverConst.COVER_TYPE_EXTRAS:
                        looking_for = 'extras';
                        break;
                    case changeCoverConst.COVER_TYPE_HOSPITAL:
                        looking_for = 'hospital';
                        break;
                    case changeCoverConst.COVER_TYPE_BOTH:
                        looking_for = 'bundle';
                        break;
                }
                let essential_hos;
                // mustCover is about hospital
                switch (changeCoverStore.mustCover) {
                    case changeCoverConst.MUST_COVER_PREGNANCY:
                        essential_hos = 'pregnancy';
                        break;
                    case changeCoverConst.MUST_COVER_WEIGHT:
                        essential_hos = 'weight_loss';
                        break;
                    case changeCoverConst.MUST_COVER_JOINTS:
                        essential_hos = 'joint_replacement';
                        break;
                    case changeCoverConst.MUST_COVER_PSYCH:
                        essential_hos = 'psychiatric_care';
                        break;
                    default:
                        essential_hos = 'na';
                        break;
                }
                let essential_extras;
                switch (changeCoverStore.mustCoverExtras) {
                    case changeCoverConst.MUST_COVER_EXTRAS_ACUPUNCTURE:
                        essential_extras = 'acupuncture';
                        break;
                    case changeCoverConst.MUST_COVER_EXTRAS_HEARING_AIDS:
                        essential_extras = 'hearing_aids';
                        break;
                    case changeCoverConst.MUST_COVER_EXTRAS_FOOT_ORTHOTICS:
                        essential_extras = 'foot_orthotics';
                        break;
                    case changeCoverConst.MUST_COVER_EXTRAS_MAJOR_DENTAL:
                        essential_extras = 'major_dental';
                        break;
                    default:
                        essential_extras = 'na';
                        break;
                }
                const data = {
                    scale,
                    looking_for,
                    age,
                    essential_hos,
                    essential_extras,
                };
                api.website(CONFIG, access_token)
                    .cover.getSuggestions(data)
                    .then((response) => {
                        if (response?.status === 'error' || response?.error) {
                            console.log(response);
                            reject();
                        } else {
                            let hospitalCodeArray = [];
                            let extrasCodeArray = [];
                            let extrasCodeSet = new Set();
                            let hospitalCodeSet = new Set();
                            // Now we have an array of arrays.
                            // If it is 'bundle', they will have two values, [hospital, extras]
                            // In this case, bundle actually means 'both hospital and extras'
                            // It should NOT be confused with an actual bundle.
                            // There are no suggestions for bundle products
                            if (looking_for === 'bundle') {
                                // Process these as [hospital, extras] pairs
                                response.forEach((item) => {
                                    if (item[0] !== '') {
                                        hospitalCodeArray.push(item[0]);
                                        extrasCodeArray.push(item[1]);
                                    }
                                });
                                // Casting to set will remove dupes
                                hospitalCodeSet = new Set(hospitalCodeArray);
                                extrasCodeSet = new Set(extrasCodeArray);
                            } else if (looking_for === 'hospital') {
                                // Process as [hospital]
                                response.forEach((item) => {
                                    if (item[0] !== '') {
                                        hospitalCodeArray.push(item[0]);
                                    }
                                });
                                hospitalCodeSet = new Set(hospitalCodeArray);
                            } else if (looking_for === 'extras') {
                                // Process as [extras]
                                response.forEach((item) => {
                                    if (item[0] !== '') {
                                        extrasCodeArray.push(item[0]);
                                    }
                                });
                                extrasCodeSet = new Set(extrasCodeArray);
                            }
                            resolve({
                                hospitalCodes: Array.from(hospitalCodeSet),
                                extrasCodes: Array.from(extrasCodeSet),
                            });
                        }
                    });
            });
        },
        productType: (product) => {
            if (product?.benefit_limits && product?.clinical_categories) {
                return 'bundle';
            } else if (product?.benefit_limits) {
                return 'extras';
            } else if (product?.clinical_categories) {
                return 'hospital';
            }
        },
        productInfo: () => {
            return 'Not implemented';
        },
    },
    filter: {
        allProductsOfType: (allProducts, type) => {
            let filtered = [];
            switch (type) {
                case 'extras':
                    Object.keys(allProducts).forEach((key) => {
                        if (
                            allProducts[key]?.benefit_limits &&
                            !allProducts[key]?.clinical_categories
                        ) {
                            allProducts[key].originalSku = key;
                            allProducts[key].productType = 'extras';
                            filtered.push(allProducts[key]);
                        }
                    });
                    return filtered;
                case 'hospital':
                    Object.keys(allProducts).forEach((key) => {
                        if (
                            allProducts[key]?.clinical_categories &&
                            !allProducts[key]?.benefit_limits
                        ) {
                            // Keep a copy of this key here as we need it for filtering later
                            allProducts[key].originalSku = key;
                            allProducts[key].productType = 'hospital';
                            filtered.push(allProducts[key]);
                        }
                    });
                    return filtered;
                case 'bundle':
                    Object.keys(allProducts).forEach((key) => {
                        if (
                            changeCoverHelpers.get.productType(
                                allProducts[key],
                            ) === 'bundle'
                        ) {
                            allProducts[key].originalSku = key;
                            allProducts[key].productType = 'bundle';
                            filtered.push(allProducts[key]);
                        }
                    });
                    return filtered;
                default:
                    return [];
            }
        },
        suggestion: (productsOfType, suggestions) => {
            if (!suggestions) {
                return productsOfType;
            } else {
                return productsOfType.filter((product) =>
                    suggestions.has(product.originalSku),
                );
            }
        },
    },
};
