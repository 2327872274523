import React from 'react';
import { useSelector } from 'react-redux';
import { FaPhone } from 'react-icons/all';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import CustomModal from 'components/modals/Modal';
import { allPersons, getSelectedUser } from 'libs/helpers';
import { StatusBadge } from './components/StatusBadge';
import { StoreType } from 'store/types';
import { ClaimType } from 'types/claim';

interface ClaimDetailsModalProps {
    show: boolean;
    handleClose: () => void;
    align: string;
    data?: ClaimType;
}

const StyledTable = styled(Table)`
    td:not(:first-child),
    th:not(:first-child) {
        text-align: right;
    }
`;

const ClaimDetailsModal: React.FC<ClaimDetailsModalProps> = ({ show, handleClose, align, data }) => {
    const membership = useSelector((state: StoreType) => state.membership);
    const persons = allPersons(membership);
    const claimLines = data;
    if (!claimLines) {
        return null;
    }

    return (
        <CustomModal
            handleClose={handleClose}
            align={align}
            show={show}
            title={'Claim Details'}>
            <div className={'text-primary row row-cols-1'}>
                <div
                    className={
                        'd-flex justify-content-between align-items-center col pb-3'
                    }>
                    <div>
                        <StatusBadge status={data?.ClaimLineStatus} />
                    </div>
                </div>
                <div
                    className={
                        'd-flex justify-content-between align-items-top flex-wrap'
                    }>
                    <div className="col-4">
                        <div
                            className="font-weight-medium text-light-gray text-uppercase"
                            style={{
                                fontSize: '11px',
                            }}>
                            Provider
                        </div>
                        <div
                            className="text-label-gray"
                            style={{ fontSize: '14px' }}>
                            {data?.Provider?.Name}
                        </div>
                        <div
                            style={{
                                fontSize: '12px',
                                color: '#9998A8',
                            }}>
                            {data?.Provider?.ProviderCode}
                        </div>
                    </div>
                    <div className="col-4">
                        <div
                            className="font-weight-medium text-light-gray text-uppercase"
                            style={{
                                fontSize: '11px',
                            }}>
                            Address
                        </div>
                        <div
                            className="text-label-gray"
                            style={{ fontSize: '14px' }}>
                            {data?.Provider?.Address?.Line1}
                        </div>
                        <div
                            className="text-label-gray"
                            style={{ fontSize: '14px' }}>
                            {data?.Provider?.Address?.Line2}
                        </div>
                        <div
                            className="text-label-gray"
                            style={{ fontSize: '14px' }}>
                            {data?.Provider?.Address?.Suburb},{' '}
                            {data?.Provider?.Address?.PostCode}{' '}
                            {data?.Provider?.Address?.StateCode}
                        </div>
                    </div>
                    <div
                        className="col-4"
                        style={{
                            display: data?.Provider?.PhoneNumbers?.[0]
                                ?.PhoneNumber
                                ? 'block'
                                : 'none',
                        }}>
                        <div
                            className="font-weight-medium text-light-gray text-uppercase"
                            style={{
                                fontSize: '11px',
                            }}>
                            Contact
                        </div>
                        <div
                            className="text-label-gray"
                            style={{
                                fontSize: '14px',
                                display: data?.Provider?.PhoneNumbers
                                    ?.[0]?.PhoneNumber
                                    ? 'block'
                                    : 'none',
                            }}>
                            <FaPhone
                                className="text-primary"
                                style={{
                                    verticalAlign: 'middle',
                                    transform: 'scale(-1, 1)',
                                }}
                            />{' '}
                            {
                                data?.Provider?.PhoneNumbers?.[0]
                                    ?.PhoneNumber
                            }
                        </div>
                    </div>
                </div>
                <div
                    className={
                        'd-flex justify-content-between align-items-center flex-wrap pt-3 pb-3'
                    }>
                    <div className="col-4">
                        <div
                            className="font-weight-medium text-light-gray text-uppercase"
                            style={{
                                fontSize: '11px',
                            }}>
                            Patient
                        </div>
                        <div
                            className="text-label-gray"
                            style={{ fontSize: '14px' }}>
                            {
                                getSelectedUser(persons, data.PersonId)?.Person
                                    ?.GivenName
                            }{' '}
                            {
                                getSelectedUser(persons, data.PersonId)?.Person
                                    ?.Surname
                            }
                        </div>
                    </div>
                </div>
                <div className={'col'}>
                    <StyledTable className="" responsive striped>
                        <thead>
                            <tr>
                                <th>ITEM</th>
                                <th>FEE</th>
                                <th>BENEFIT</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.Service ? (
                                <tr
                                    key={`claim_row_${data.ClaimId}_${data.ClaimLineId}`}
                                    className={'table-link'}>
                                    <td className={''}>
                                        {data?.Service?.ServiceClassCode}-
                                        {data?.Service?.ServiceCode} -{' '}
                                        {data?.Service?.Description}
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                        ${data?.Fee}
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                        ${data?.BenefitAmount}
                                    </td>
                                </tr>
                            ) : (
                                ''
                            )}
                        </tbody>
                    </StyledTable>
                </div>
            </div>
        </CustomModal>
    );
};

export default ClaimDetailsModal;
