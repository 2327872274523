import React from 'react';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { convertToArray } from 'libs/helpers';
import { MdPerson, MdPeople } from 'react-icons/all';

const Headers = ['SERVICE', 'CLAIMED', 'REMAINING', 'ANNUAL LIMIT'];

const StyledTable = styled(Table)`
    thead {
        box-shadow: none !important;
    }
    th {
        border-top: 0;
    }
    td:not(:first-child),
    th:not(:first-child) {
        text-align: right;
    }
    td {
        font-size: 12px !important;
    }
    th,
    td {
        padding: 0.4rem !important;
    }
`;

const ExtrasRow = ({ row, idx, indent = 0 }) => {

    function benefitLevel(level) {
        if (level === 'Person') {
            return (
                <MdPerson
                    size={15}
                    style={{ verticalAlign: 'middle' }}
                    className="text-primary"
                />
            );
        }
        if (level === 'Membership') {
            return (
                <MdPeople
                    size={15}
                    style={{ verticalAlign: 'middle' }}
                    className="text-primary"
                />
            );
        }
    }

    let classes;

    switch (indent) {
        case 0:
            classes = 'top';
            break;
        case 1:
            classes = 'level1';
            break;
        case 2:
            classes = 'level2';
            break;
        case 3:
            classes = 'level3';
            break;
    }

    let inside;
    if (
        typeof row?.RelatedBenefitLimitStatuses !==
        'undefined'
    ) {
        inside = convertToArray(
            row?.RelatedBenefitLimitStatuses,
        );
    }

    if (row?.BenefitLimitType === 'Benefit') {

        return (
            <>
                <tr key={`extra_row_${idx}`} className={classes}>
                    <td>
                        {row?.Description}
                        {indent === 0 &&
                            typeof inside?.map === 'function' &&
                            '*'}
                        {indent === 1 && '**'}
                    </td>
                    <td>${row?.ClaimedAmount}</td>
                    <td>${row?.ClaimableAmount}</td>
                    <td>
                        ${row?.ClaimableTotal}{' '}
                        {benefitLevel(row?.BenefitLimitLevel)}
                    </td>
                </tr>
                {typeof inside?.map === 'function'
                    ? inside.map((insider, idx2) => (
                          <ExtrasRow
                              row={insider}
                              key={idx2}
                              idx={idx2}
                              indent={indent + 1}
                          />
                      ))
                    : null}
            </>
        );
    }
    return null;
};

const ExtrasInclusionTable = ({ className, rows }) => {
    rows = convertToArray(rows);



    return (
        <>
            <StyledTable className={className} responsive hover>
                <thead>
                    <tr>
                        {Headers.map((head) => (
                            <th key={head}>{head}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {typeof rows.map === 'function'
                        ? rows.map((row, idx) => (
                              <ExtrasRow row={row} key={idx} idx={idx} />
                          ))
                        : ''}
                </tbody>
            </StyledTable>
            <div
                className="py-3"
                style={{
                    fontSize: '14px',
                }}>
                <div>
                    <MdPerson
                        style={{ verticalAlign: 'middle' }}
                        className="text-primary"
                        size={15}
                    />
                    : This limit is per person
                </div>
                <div>
                    <MdPeople
                        style={{ verticalAlign: 'middle' }}
                        className="text-primary"
                        size={15}
                    />
                    : This limit is in total for the membership
                </div>
                <div className="mt-2">
                    * Overall limit – sub-limits may apply - Please use benefit
                    calculator to confirm benefit available.
                </div>
                <div className="mt-2">
                    ** Sub-limits are part of group annual/overall limit so can
                    only be claimed to a max of annual/overall limit
                </div>
                <div className="mt-2">
                    Active Health Bonus requires completion of Health-e-profile
                    assessment.
                </div>
            </div>
        </>
    );
};

export default ExtrasInclusionTable;
