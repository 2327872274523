import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

export const HealthFund = () => {
    const { values, getFieldProps } = useFormikContext();
    const allHealthFunds = useSelector((state) => state.healthFunds);

    return (
        <Form.Group
            controlId={`previousfund-fund`}
            className="col-6 text-label-gray"
            style={{
                display: values.previoushealthtransfer ? 'block' : 'none',
            }}>
            <Form.Label>Previous Health Fund</Form.Label>
            <Form.Control as="select" {...getFieldProps('previoushealthfund')}>
                <option value="">- Please select -</option>
                {allHealthFunds?.HealthFund &&
                    allHealthFunds.HealthFund.map((fund, idx) => (
                        <option key={idx} value={fund.HealthFundCode}>
                            {fund.Name}
                        </option>
                    ))}
            </Form.Control>
        </Form.Group>
    );
};

export default HealthFund;
