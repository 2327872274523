import React from "react";
import { useDispatch,useSelector } from 'react-redux';
import { setOnlineClaimServicesVisible } from 'store';
import CustomModal from "components/modals/Modal";

const OnlineClaimServicesModal = ({align}) => {
    const onlineClaimServicesVisible = useSelector((state) => state.ui.onlineClaimServicesVisible);
    const dispatch = useDispatch();

    return (
        <CustomModal 
            handleClose={() => {
                // gtagEvent({
                //     screen: 'ehealthprofile',
                //     action: 'cancel',
                //     label: 'Cancelled or closed modal',
                //     type: 'modal',
                // });
                dispatch(setOnlineClaimServicesVisible(false));
            }}
            align={align}
            show={onlineClaimServicesVisible}
            title={"Online Claim Services"}
        >
            <div className={'font-feature row row-cols-1'}>
                <div className='text-label-gray' style={{fontSize:'14px'}}>
                    <ul>
                        <li>Acupuncture *</li>
                        <li>Audiology</li>
                        <li>Chiropractic</li>
                        <li>Chinese herbalism *</li>
                        <li>Dental (excl. Prosthetics)</li>
                        <li>Dietetics</li>
                        <li>Exercise Physiology</li>
                        <li>Hearing Aids</li>
                        <li>Massage (excl. Bowen Therapy) *</li>
                        <li>Occupational Therapy</li>
                        <li>Optical (excl. Hard Toric/Spherical contact lenses)</li>
                        <li>Osteopathy</li>
                        <li>Pharmacy</li>
                        <li>Physiotherapy</li>
                        <li>Podiatry (excl. Orthotics)</li>
                        <li>Psychology / Counselling</li>
                        <li>Speech Therapy</li>
                    </ul>

                    <div className='col'>
                        <p>* These Service types may display Provider numbers on their invoices that are different from those on record with TUH. A valid Provider number will be required for a claim to be submitted online. Please search for your provider using the drop down list of previously used providers. If they are not listed, please use the Provider search function using their Surname only.</p>
                        <p>If you are still unable to find the correct provider, your claim will need to be submitted using the Easy Claim option on the TUH App.</p>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
}

export default OnlineClaimServicesModal;
