import React from 'react';

interface Props {
    src: string;
}

const FeatureImage: React.FC<Props> = ({ src }) => {
    return (
        <img
            alt={'Test'}
            style={{
                position: 'absolute',
                height: 'auto',
                width: '100%',
                bottom: 0,
                left: 0,
            }}
            src={src}
        />
    );
};

export default FeatureImage;
