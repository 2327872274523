const moment = require('moment')

export const schoolYearHelpers = {

    /**
     * The school year runs from the the 1st of April to
     * the 31st of March the following year
     */
    getCurrent: () => {
        const now = moment()

        const currentYear = now.year()
        const previousYear = now.subtract(1, 'year').year()

        const firstDayOfThisSchoolYear = moment(`currentYear-03-01`, 'YYYY-MM-DD')

        if (now.isAfter(firstDayOfThisSchoolYear)) {
            return currentYear
        } else {
            return previousYear
        }

    }
}
