import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import {
    FaFacebookF,
    FaLinkedinIn,
    FaTwitter,
    IoIosCall,
} from 'react-icons/all';
import LogoMinimalLight from 'img/vector/LogoMinimalLight/comp';
import { currentYear } from 'libs/helpers';
import { StoreType } from 'store/types';

//get version number from package
const { version } = require('../../../../package.json');

interface SocialButtonProps {
    icon: React.ReactNode;
    href: string;
    classes?: string;
}

const SocialButton: React.FC<SocialButtonProps> = ({ icon, href, classes }) => {
    return (
        <Button
            variant={`btn-primary-dark-1 p-0 m-0 bg-primary d-flex align-items-center justify-content-center p-2 text-light ${classes}`}
            href={href}
            target="_blank"
            rel="noopener noreferrer">
            {icon}
        </Button>
    );
};

const FooterContainer = styled.div`
    width: 100%;
`;

const Footer = () => {
    const iconSize = 15;

    const appSettings = useSelector((state: StoreType) => state.settings);

    return (
        <FooterContainer>
            <div className="bg-primary-dark-2 px-3 py-3">
                <footer className="bg-primary-dark-2">
                    <div className="d-flex justify-content-between flex-wrap">
                        <div className="d-flex p-0 m-0 align-items-center flex-wrap">
                            <div className="text-light mr-5 mb-3 ml-2">
                                <LogoMinimalLight />
                            </div>
                            <ul
                                className="d-flex justify-content-start font-weight-light align-items-center text-primary-light p-0 m-0 flex-wrap"
                                style={{
                                    fontSize: '12px',
                                }}>
                                <div className="mr-4 mx-2 flex-wrap-0 font-weight-normal">
                                    &copy; {currentYear()} TUH
                                </div>
                                <li className="mr-4 mx-1">
                                    <a
                                        href={
                                            'https://tuh.com.au/privacy-and-fraud-statement'
                                        }
                                        className={
                                            'link-none text-primary-light'
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Privacy and fraud statement
                                    </a>
                                </li>
                                <li className="mr-4 mx-1">
                                    <a
                                        href={
                                            'https://tuh.com.au/complaints-and-concerns'
                                        }
                                        className={
                                            'link-none text-primary-light'
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Complaints and concerns
                                    </a>
                                </li>
                                <li className="mr-4 mx-1">
                                    <a
                                        href={'https://tuh.com.au/code-conduct'}
                                        className={
                                            'link-none text-primary-light'
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Code of Conduct
                                    </a>
                                </li>
                                <li className="mr-4 mx-1">
                                    <a
                                        href={
                                            'https://tuh.com.au/app-portal-faqs'
                                        }
                                        className={
                                            'link-none text-primary-light'
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        FAQs
                                    </a>
                                </li>
                                <li className="mr-4 mx-1">
                                    <p
                                        className={
                                            'link-none text-primary-light'
                                        }
                                        style={{ display: 'inline' }}>
                                        Version: {version}
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex align-items-center">
                            <Button
                                variant="btn-link btn-lg pr-0 pl-2 mr-3 py-1"
                                href={`tel:${appSettings?.contact?.phone}`}
                                style={{
                                    display: appSettings?.contact?.phone
                                        ? 'inline-block'
                                        : 'none',
                                }}>
                                <div className="text-primary font-weight-bold font-feature mr-3 d-flex justify-content-between align-items-center">
                                    <IoIosCall />
                                    <span className="ml-2 text-light">
                                        {appSettings?.contact?.phone}
                                    </span>
                                </div>
                            </Button>

                            <SocialButton
                                href="https://www.facebook.com/TUHhealthfund/"
                                classes="mr-2"
                                icon={<FaFacebookF size={iconSize} />}
                            />

                            <SocialButton
                                href="https://twitter.com/tuhhealthfund"
                                classes="mr-2"
                                icon={<FaTwitter size={iconSize} />}
                            />

                            <SocialButton
                                href="https://www.linkedin.com/company/tuh"
                                icon={<FaLinkedinIn size={iconSize} />}
                            />
                        </div>
                    </div>
                </footer>
            </div>
        </FooterContainer>
    );
};

export default Footer;
