import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { dependantHelpers } from 'libs/dependant';
import { IoMdInformationCircle } from 'react-icons/io';
import { cleanDate } from '../../helpers';
import { useSelector } from 'react-redux';

export const DateOfBirth = ({ loading, edit = false }) => {
    const { touched, errors, values, setFieldValue, handleBlur } =
        useFormikContext();
    const dependantEndAge = useSelector((state) => state.settings.general.dependant_age);

    const INITIAL_DATE = cleanDate(values?.dob ?? '');

    return (
        <div className="d-flex flex-grow-1">
            <Form.Group controlId={`dob`} className="col-6 text-label-gray">
                <Form.Label>Date of Birth</Form.Label>

                <DatePicker
                    wrapperClassName={
                        touched.dob && errors.dob && !loading && 'is-invalid'
                    }
                    className={
                        touched.dob && errors.dob && !loading
                            ? 'is-invalid form-control'
                            : 'form-control'
                    }
                    dateFormat="dd/MM/yyyy"
                    readOnly={edit}
                    disabled={edit}
                    selected={INITIAL_DATE}
                    maxDate={moment().toDate()}
                    onChange={(e) => {
                        setFieldValue('dob', e);
                        const age = moment().diff(e, 'years');
                        if (
                            ['Ptnr', 'Sps', 'Membr'].includes(
                                values.relationship,
                            )
                        ) {
                            setFieldValue('schoolage', false);
                            setFieldValue('schooldeclare', false);
                            setFieldValue('registerAsExtDep', false);
                        } else {
                            if (
                                dependantHelpers.isTurningAgeSoon(
                                    null,
                                    21,
                                    moment(e).format('YYYY-MM-DD'),
                                ) ||
                                (age >= 21 && age < parseInt(dependantEndAge))
                            ) {
                                setFieldValue('schoolage', true);
                            } else {
                                setFieldValue('schoolage', false);
                                setFieldValue('schooldeclare', false);
                                setFieldValue('registerAsExtDep', false);
                            }
                        }
                    }}
                    onBlur={handleBlur('dob')}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                {touched.dob && errors.dob && !loading && (
                    <Form.Control.Feedback type={'invalid'}>
                        {errors.dob}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            {edit && (
                <div>
                    <div
                        className="d-flex"
                        style={{
                            marginTop: '20px',
                            marginBottom: '5px',
                        }}>
                        <IoMdInformationCircle
                            size={20}
                            className="text-primary"
                        />
                        <div
                            className="text-black"
                            style={{
                                fontSize: '12px',
                                marginLeft: '5px',
                            }}>
                            Please contact us if you need to edit any of these
                            disabled fields
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DateOfBirth;
