import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FaCheck} from "react-icons/all";
import {changeCoverActions} from "store";
import {Button, Spinner} from "react-bootstrap";
import {useHistory} from "react-router-dom";

import {changeCoverHelpers} from "libs/coverChangeHelpers";

import HeartPlus from "img/vector/HeartPlus/comp";
import ToothWithGlasses from "img/vector/ToothWithGlasses/comp";
import HeartWithTooth from "img/vector/HeartWithTooth/comp";

import Single from "img/vector/Single/comp";
import Couple from "img/vector/Couple/comp";
import Family from "img/vector/Family/comp";
import SingleParent from "img/vector/SingleParent/comp";

import CoverWeightLossSurgery from "img/vector/Cover/WeightLossSurgery/comp";
import CoverHospitalPsychiatric from "img/vector/Cover/HospitalPsychiatric/comp";
import CoverJointReplacement from "img/vector/Cover/JointReplacement/comp";
import CoverPregnancy from "img/vector/Cover/Pregnancy/comp";

import CoverAcupuncture from "img/vector/Cover/Acupuncture/comp";
import CoverHearingAids from "img/vector/Cover/HearingAids/comp";
import CoverMajorDental from "img/vector/Cover/MajorDental/comp";
import CoverOrthotics from "img/vector/Cover/Orthotics/comp";

const StartCoverChangePage = () => {

    const changeCoverStore = useSelector(state => state.changeCover);
    const membership = useSelector(state => state.membership);
    const product = useSelector(state => state.product);

    const [coverType, setCoverType] = useState('');
    const [familyType, setFamilyType] = useState('');

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(()=>{
        dispatch(changeCoverActions.reset());
    },[]);

    useEffect(()=>{
        // Product Type
        if (product?.base?.type==="package") {
            setCoverType('Package');
        }
        if (product?.base?.type==="bundle") {
            setCoverType('Hospital + Extras');
        }
        if (product?.base?.type==="hospital") {
            setCoverType('Hospital');
        }
        if (product?.base?.type==="extras") {
            setCoverType('Extras');
        }
        // Family Type
        if (product?.membershipClassCode==="Sgl") {
            setFamilyType('single person');
        }
        if (product?.membershipClassCode==="Cpl") {
            setFamilyType('couple');
        }
        if (product?.membershipClassCode==="Fam"||product?.membershipClassCode==="FamX") {
            setFamilyType('family');
        }
        if (product?.membershipClassCode==="SPFam"||product?.membershipClassCode==="SPFamX") {
            setFamilyType('single parent');
        }
        // Payment frequency
        if (membership?.Contribution?.Frequency==="Fortnightly") {
            dispatch(changeCoverActions.paymentFrequency('fortnightPeriod'));
        }
        if (membership?.Contribution?.Frequency==="Monthly") {
            dispatch(changeCoverActions.paymentFrequency('monthPeriod'));
        }
        if (membership?.Contribution?.Frequency==="Quarterly") {
            dispatch(changeCoverActions.paymentFrequency('quarterPeriod'));
        }
        if (membership?.Contribution?.Frequency==="6 Monthly") {
            dispatch(changeCoverActions.paymentFrequency('halfYearPeriod'));
        }
        if (membership?.Contribution?.Frequency==="Yearly") {
            dispatch(changeCoverActions.paymentFrequency('yearlyPeriod'));
        }
    },[membership,product]);

    const fetchAndNavigate = () => {
        setLoading(true);
        Promise.all([
            // All data required to fetch the right products
            changeCoverHelpers.get.products({
                changeCoverStore,
                membership,
            }),

            // Suggestions based on current selections
            changeCoverHelpers.get.suggestions({
                changeCoverStore,
                membership,
            })

        ]).then(values => {

            const [products, suggestedCodes] = values
            const {hospitalCodes, extrasCodes} = suggestedCodes

            dispatch(changeCoverActions.suggested.hospital(hospitalCodes))
            dispatch(changeCoverActions.suggested.extras(extrasCodes))
            dispatch(changeCoverActions.products(products))

            history.push('/member/coverchange/choose');
        }).catch(e => {
            // dispatch(disableActivityIndicator())
            console.log(e)
        })

    }

    return (
        <>
            <main className="container-fluid p-4 h-100 cover-change-start">
                <div className="row">
                    <div className="col-12 text-center step-numbers">
                        <span
                            className={(changeCoverStore.onboarding.step===1)?`step-number active`:`step-number`}
                            onClick={()=>{
                                if (changeCoverStore.onboarding.step>1) {
                                    dispatch(changeCoverActions.onboarding.setStep(1));
                                }
                            }}
                        >
                            1
                        </span>
                        <span
                            className={(changeCoverStore.onboarding.step===2)?`step-number active`:`step-number`}
                            onClick={()=>{
                                if (changeCoverStore.onboarding.step>2) {
                                    dispatch(changeCoverActions.onboarding.setStep(2));
                                }
                            }}
                        >
                            2
                        </span>
                        <span
                            className={(changeCoverStore.onboarding.step===3)?`step-number active`:`step-number`}
                            onClick={()=>{
                                if (changeCoverStore.onboarding.step>3) {
                                    dispatch(changeCoverActions.onboarding.setStep(3));
                                }
                            }}
                        >
                            3
                        </span>
                        <span
                            className={(changeCoverStore.onboarding.step===4)?`step-number active`:`step-number`}
                            onClick={()=>{
                                if (changeCoverStore.onboarding.step>4) {
                                    dispatch(changeCoverActions.onboarding.setStep(4));
                                }
                            }}
                            style={{
                               display:(changeCoverStore.coverType==="both")?'inline-block':'none'
                           }}
                        >
                            4
                        </span>
                    </div>

                    <div className="questions-holder col-12">
                        <div className="question step-1" style={{
                            display:(changeCoverStore.onboarding.step===1)?'block':'none'
                        }}>
                            <div className="col-12 text-center text-light-turquoise pt-3 pb-0" style={{
                                fontSize:'20px'
                            }}>
                                You currently have {coverType} cover
                            </div>
                            <div className="col-12 text-center text-white pt-1 pb-3 font-weight-ultra" style={{
                                fontSize:'36px'
                            }}>
                                Change what you&apos;re covered for?
                            </div>
                            <div className="col-12 text-center answers-section">
                                <div
                                    className={(changeCoverStore.coverType==="hospital")?`answer active`:`answer`}
                                    onClick={()=>{
                                        dispatch(changeCoverActions.coverType.hospital());
                                        dispatch(changeCoverActions.mustCoverExtras.none());
                                    }}
                                >
                                    <span className="answer-circle">
                                        <HeartPlus/>
                                        <span className="check-circle"><FaCheck style={{verticalAlign:'top'}}/></span>
                                    </span>
                                    <span className="title">Hospital cover</span>
                                    <span className="subtitle subtitle-blue">
                                        Offering choice and security for hospital visits
                                    </span>
                                </div>
                                <div
                                    className={(changeCoverStore.coverType==="extras")?`answer active`:`answer`}
                                    onClick={()=>{
                                        dispatch(changeCoverActions.coverType.extras());
                                        dispatch(changeCoverActions.mustCover.none());
                                    }}
                                >
                                    <span className="answer-circle">
                                        <ToothWithGlasses/>
                                        <span className="check-circle"><FaCheck style={{verticalAlign:'top'}}/></span>
                                    </span>
                                    <span className="title">Extras cover</span>
                                    <span className="subtitle subtitle-blue">
                                        Allied health and wellbeing cover
                                    </span>
                                </div>
                                <div
                                    className={(changeCoverStore.coverType==="both")?`answer active`:`answer`}
                                    onClick={()=>{
                                        dispatch(changeCoverActions.coverType.both());
                                    }}
                                >
                                    <span className="answer-circle">
                                        <HeartWithTooth/>
                                        <span className="check-circle"><FaCheck style={{verticalAlign:'top'}}/></span>
                                    </span>
                                    <span className="title">Both please!</span>
                                    <span className="subtitle subtitle-blue">
                                        a combination of hospital and extras
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 text-center button-holder pt-3">
                                <Button
                                    variant="secondary"
                                    onClick={()=>{
                                        dispatch(changeCoverActions.onboarding.setStep(2));
                                    }}
                                    disabled={
                                        changeCoverStore.coverType===null
                                    }
                                >
                                    Continue
                                </Button>
                            </div>
                        </div>

                        <div className="question step-2" style={{
                            display:(changeCoverStore.onboarding.step===2)?'block':'none'
                        }}>
                            <div className="col-12 text-center text-light-turquoise pt-3 pb-0" style={{
                                fontSize:'20px'
                            }}>
                                You&apos;re currently covered as a {familyType}
                            </div>
                            <div className="col-12 text-center text-white pt-1 pb-3 font-weight-ultra" style={{
                                fontSize:'36px'
                            }}>
                                Change who&apos;s covered?
                            </div>
                            <div className="col-12 text-center answers-section">
                                <div
                                    className={(changeCoverStore.groupCoverType==="S")?`answer active`:`answer`}
                                    onClick={()=>{
                                        dispatch(changeCoverActions.groupCoverType.single());
                                    }}
                                >
                                    <span className="answer-circle">
                                        <Single/>
                                        <span className="check-circle"><FaCheck style={{verticalAlign:'top'}}/></span>
                                    </span>
                                    <span className="title">Single</span>
                                    <span className="subtitle subtitle-white"></span>
                                </div>
                                <div
                                    className={(changeCoverStore.groupCoverType==="FAM")?`answer active`:`answer`}
                                    onClick={()=>{
                                        dispatch(changeCoverActions.groupCoverType.family());
                                    }}
                                >
                                    <span className="answer-circle">
                                        <Family/>
                                        <span className="check-circle"><FaCheck style={{verticalAlign:'top'}}/></span>
                                    </span>
                                    <span className="title">Family</span>
                                    <span className="subtitle subtitle-white"></span>
                                </div>
                                <div
                                    className={(changeCoverStore.groupCoverType==="C")?`answer active`:`answer`}
                                    onClick={()=>{
                                        dispatch(changeCoverActions.groupCoverType.couple());
                                    }}
                                >
                                    <span className="answer-circle">
                                        <Couple/>
                                        <span className="check-circle"><FaCheck style={{verticalAlign:'top'}}/></span>
                                    </span>
                                    <span className="title">Couple</span>
                                    <span className="subtitle subtitle-white">you and your partner</span>
                                </div>
                                <div
                                    className={(changeCoverStore.groupCoverType==="SP")?`answer active`:`answer`}
                                    onClick={()=>{
                                        dispatch(changeCoverActions.groupCoverType.singleParent());
                                    }}
                                >
                                    <span className="answer-circle">
                                        <SingleParent/>
                                        <span className="check-circle"><FaCheck style={{verticalAlign:'top'}}/></span>
                                    </span>
                                    <span className="title">Single Parent</span>
                                    <span className="subtitle subtitle-white"></span>
                                </div>
                            </div>
                            <div className="col-12 text-center button-holder pt-3">
                                <Button
                                    variant="secondary"
                                    onClick={()=>{
                                        dispatch(changeCoverActions.onboarding.setStep(3));
                                    }}
                                    disabled={
                                        changeCoverStore.groupCoverType===null
                                    }
                                >
                                    Continue
                                </Button>
                            </div>
                        </div>

                        <div className="question step-3" style={{
                            display:(changeCoverStore.onboarding.step===3&&changeCoverStore.coverType==="hospital"||changeCoverStore.onboarding.step===3&&changeCoverStore.coverType==="both")?'block':'none'
                        }}>
                            <div className="col-12 text-center text-light-turquoise pt-3 pb-0" style={{
                                fontSize:'20px'
                            }}>

                            </div>
                            <div className="col-12 text-center text-white pt-1 pb-3 font-weight-ultra" style={{
                                fontSize:'36px'
                            }}>
                                Do you need to be covered for any of these?
                            </div>
                            <div className="col-12 text-center answers-section">
                                <div
                                    className={(changeCoverStore.mustCover==="pregnancy")?`answer active`:`answer`}
                                    onClick={()=>{
                                        if (changeCoverStore.mustCover==="pregnancy") {
                                            dispatch(changeCoverActions.mustCover.none());
                                        } else {
                                            dispatch(changeCoverActions.mustCover.pregnancy());
                                        }
                                    }}
                                >
                                    <span className="answer-circle">
                                        <CoverPregnancy/>
                                        <span className="check-circle"><FaCheck style={{verticalAlign:'top'}}/></span>
                                    </span>
                                    <span className="title">Pregnancy</span>
                                    <span className="subtitle subtitle-white"></span>
                                </div>
                                <div
                                    className={(changeCoverStore.mustCover==="joints")?`answer active`:`answer`}
                                    onClick={()=>{
                                        if (changeCoverStore.mustCover==="joints") {
                                            dispatch(changeCoverActions.mustCover.none());
                                        } else {
                                            dispatch(changeCoverActions.mustCover.joints());
                                        }
                                    }}
                                >
                                    <span className="answer-circle">
                                        <CoverJointReplacement/>
                                        <span className="check-circle"><FaCheck style={{verticalAlign:'top'}}/></span>
                                    </span>
                                    <span className="title">Joint Replacement</span>
                                    <span className="subtitle subtitle-white"></span>
                                </div>
                                <div
                                    className={(changeCoverStore.mustCover==="weight")?`answer active`:`answer`}
                                    onClick={()=>{
                                        if (changeCoverStore.mustCover==="weight") {
                                            dispatch(changeCoverActions.mustCover.none());
                                        } else {
                                            dispatch(changeCoverActions.mustCover.weight());
                                        }
                                    }}
                                >
                                    <span className="answer-circle">
                                        <CoverWeightLossSurgery/>
                                        <span className="check-circle"><FaCheck style={{verticalAlign:'top'}}/></span>
                                    </span>
                                    <span className="title">Weight loss surgery</span>
                                    <span className="subtitle subtitle-white"></span>
                                </div>
                                <div
                                    className={(changeCoverStore.mustCover==="psych")?`answer active`:`answer`}
                                    onClick={()=>{
                                        if (changeCoverStore.mustCover==="psych") {
                                            dispatch(changeCoverActions.mustCover.none());
                                        } else {
                                            dispatch(changeCoverActions.mustCover.psych());
                                        }
                                    }}
                                >
                                    <span className="answer-circle">
                                        <CoverHospitalPsychiatric/>
                                        <span className="check-circle"><FaCheck style={{verticalAlign:'top'}}/></span>
                                    </span>
                                    <span className="title">Hospital Psychiatric Care</span>
                                    <span className="subtitle subtitle-white"></span>
                                </div>
                            </div>
                            <div className="col-12 text-center button-holder pt-3">
                                <Button
                                    variant="secondary"
                                    onClick={()=>{
                                        if (changeCoverStore.coverType==="both") {
                                            dispatch(changeCoverActions.onboarding.setStep(4));
                                        } else {
                                            // go to next page
                                            fetchAndNavigate();
                                            // history.push('/member/coverchange/choose');
                                        }
                                    }}
                                    disabled={
                                        loading
                                    }
                                >
                                    {loading ? <><Spinner animation="border" role="status" as="span" size="sm"/>Continue</> : 'Continue'}
                                </Button>
                            </div>
                        </div>

                        <div className="question step-4" style={{
                            display:(changeCoverStore.onboarding.step===3&&changeCoverStore.coverType==="extras"||changeCoverStore.onboarding.step===4&&changeCoverStore.coverType==="both")?'block':'none'
                        }}>
                            <div className="col-12 text-center text-light-turquoise pt-3 pb-0" style={{
                                fontSize:'20px'
                            }}>

                            </div>
                            <div className="col-12 text-center text-white pt-1 pb-3 font-weight-ultra" style={{
                                fontSize:'36px'
                            }}>
                                Do you need to be covered for any of these?
                            </div>
                            <div className="col-12 text-center answers-section">
                                <div
                                    className={(changeCoverStore.mustCoverExtras==="majorDental")?`answer active`:`answer`}
                                    onClick={()=>{
                                        if (changeCoverStore.mustCoverExtras==="majorDental") {
                                            dispatch(changeCoverActions.mustCoverExtras.none());
                                        } else {
                                            dispatch(changeCoverActions.mustCoverExtras.majorDental());
                                        }
                                    }}
                                >
                                    <span className="answer-circle">
                                        <CoverMajorDental/>
                                        <span className="check-circle"><FaCheck style={{verticalAlign:'top'}}/></span>
                                    </span>
                                    <span className="title">Major Dental</span>
                                    <span className="subtitle subtitle-white"></span>
                                </div>
                                <div
                                    className={(changeCoverStore.mustCoverExtras==="footOrthotics")?`answer active`:`answer`}
                                    onClick={()=>{
                                        if (changeCoverStore.mustCoverExtras==="footOrthotics") {
                                            dispatch(changeCoverActions.mustCoverExtras.none());
                                        } else {
                                            dispatch(changeCoverActions.mustCoverExtras.footOrthotics());
                                        }
                                    }}
                                >
                                    <span className="answer-circle">
                                        <CoverOrthotics/>
                                        <span className="check-circle"><FaCheck style={{verticalAlign:'top'}}/></span>
                                    </span>
                                    <span className="title">Orthotics</span>
                                    <span className="subtitle subtitle-white"></span>
                                </div>
                                <div
                                    className={(changeCoverStore.mustCoverExtras==="hearingAids")?`answer active`:`answer`}
                                    onClick={()=>{
                                        if (changeCoverStore.mustCoverExtras==="hearingAids") {
                                            dispatch(changeCoverActions.mustCoverExtras.none());
                                        } else {
                                            dispatch(changeCoverActions.mustCoverExtras.hearingAids());
                                        }
                                    }}
                                >
                                    <span className="answer-circle">
                                        <CoverHearingAids/>
                                        <span className="check-circle"><FaCheck style={{verticalAlign:'top'}}/></span>
                                    </span>
                                    <span className="title">Hearing aids or health aids</span>
                                    <span className="subtitle subtitle-white"></span>
                                </div>
                                <div
                                    className={(changeCoverStore.mustCoverExtras==="acupuncture")?`answer active`:`answer`}
                                    onClick={()=>{
                                        if (changeCoverStore.mustCoverExtras==="acupuncture") {
                                            dispatch(changeCoverActions.mustCoverExtras.none());
                                        } else {
                                            dispatch(changeCoverActions.mustCoverExtras.acupuncture());
                                        }
                                    }}
                                >
                                    <span className="answer-circle">
                                        <CoverAcupuncture/>
                                        <span className="check-circle"><FaCheck style={{verticalAlign:'top'}}/></span>
                                    </span>
                                    <span className="title">Acupuncture</span>
                                    <span className="subtitle subtitle-white"></span>
                                </div>
                            </div>
                            <div className="col-12 text-center button-holder pt-3">
                                <Button
                                    variant="secondary"
                                    onClick={()=>{
                                        // dispatch(changeCoverActions.onboarding.setStep(4));
                                        // go to next page
                                        // history.push('/member/coverchange/choose');
                                        fetchAndNavigate();
                                    }}
                                    disabled={
                                        loading
                                    }
                                >
                                    {loading ? <><Spinner animation="border" role="status" as="span" size="sm"/>Continue</> : 'Continue'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default StartCoverChangePage;
