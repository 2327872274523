import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRebateFormModalVisible } from 'store';
import { Button, Spinner } from 'react-bootstrap';
import { SuccessCheck } from 'img/vector/SuccessCheck/comp';
import { FaFileDownload } from 'react-icons/all';
import { useAsyncFn } from 'react-use';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { Base64 } from 'js-base64';
import buildMedicareData from '../functions/buildMedicareData';

const FinishRebate = () => {
    const dispatch = useDispatch();
    const rebateForm = useSelector((store) => store.rebateForm);
    const membership = useSelector((store) => store.membership);

    const [getPdfState, getPdf] = useAsyncFn(async (rebateForm, membership) => {
        const access_token = getAccessToken();
        const memberid = getMembershipId();

        const iframeData = buildMedicareData(membership, rebateForm);

        const data = {
            data: iframeData,
        };
        // console.log('DATA:',data);

        try {
            const response = await api
                .medicare(CONFIG, access_token)
                .generatePDF(memberid, data);

            if (response?.status === 'error') {
                console.log(response);
                throw new Error(response.message);
            } else {
                console.log('Got a pdf');
                // console.log(response.message)
                if (response?.document) {
                    var downloadLink = document.createElement('a');
                    downloadLink.target = '_blank';
                    downloadLink.download =
                        'medicare_government_rebate_application.pdf';

                    // convert downloaded data to a Blob
                    let unEncodedDoc = Base64.toUint8Array(response.document);
                    var blob = new Blob([unEncodedDoc], {
                        type: 'application/pdf',
                    });

                    // create an object URL from the Blob
                    var URL = window.URL || window.webkitURL;
                    var downloadUrl = URL.createObjectURL(blob);

                    // set object URL as the anchor's href
                    downloadLink.href = downloadUrl;

                    // append the anchor to document body
                    document.body.appendChild(downloadLink);

                    // fire a click event on the anchor
                    downloadLink.click();

                    // cleanup: remove element and revoke object URL
                    document.body.removeChild(downloadLink);
                    URL.revokeObjectURL(downloadUrl);
                }
            }
        } catch (err) {
            console.log('ERROR');
            console.log(err.message);
        }
    });

    return (
        <div className="rebate-form-step-5">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center">
                        <SuccessCheck />
                    </div>
                    <div className="col-12 text-center">
                        <div>Thank you!</div>
                    </div>
                    <div className="col-12 text-center pb-5">
                        <div>
                            We will review and get back to you as soon as
                            possible.
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    background: '#E6E6EE',
                    height: '2px',
                    margin: '0px -30px',
                }}></div>
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-12 text-center">
                        <Button
                            variant="secondary"
                            className="mr-3"
                            onClick={() => {
                                // dispatch(setRebateFormStep(3))
                              dispatch(setRebateFormModalVisible(false));
                              sessionStorage.setItem('rebatePopupShown', 'true');
                            }}>
                            Finish
                        </Button>
                        <Button
                            variant="link-outline-gray"
                            onClick={() => {
                                getPdf(rebateForm, membership);
                            }}
                            disabled={getPdfState.loading}>
                            {getPdfState.loading ? (
                                <>
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        as="span"
                                        size="sm"
                                    />
                                    <FaFileDownload
                                        style={{ verticalAlign: 'middle' }}
                                    />{' '}
                                    Download PDF
                                </>
                            ) : (
                                <>
                                    <FaFileDownload
                                        style={{ verticalAlign: 'middle' }}
                                    />{' '}
                                    Download PDF
                                </>
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FinishRebate;
