import React from 'react';
import { Form } from 'react-bootstrap';
import { TOOTH_ID_INFO_MSG } from '../common/constants';
import { FastClaimTypeItem } from 'types/fastClaim';

interface Props {
    bodyPartText: string;
    claimItem?: FastClaimTypeItem;
    updateClaim: (newObject: any) => void;
}

export const BodyPartRequired: React.FC<Props> = ({ bodyPartText, claimItem, updateClaim }) => {
    return (
        <Form.Group
            controlId={`body-part`}
            className="col-8 text-label-gray"
            style={{
                display: claimItem?.item?.IsBodyPartRequired ? 'block' : 'none',
            }}>
            <Form.Label style={{ fontSize: '11px' }}>{bodyPartText}</Form.Label>
            <Form.Control
                type="text"
                className=""
                onChange={(e) => {
                    updateClaim({
                        ...claimItem,
                        itemBodyPart: e.target.value,
                    });
                }}
                disabled={typeof claimItem?.item?.ServiceCode === 'undefined'}
                value={claimItem?.itemBodyPart ?? ''}
            />
            {bodyPartText === 'Tooth number' && (
                <div className="pt-2" style={{ fontSize: '11px' }}>
                    {TOOTH_ID_INFO_MSG}
                </div>
            )}
        </Form.Group>
    );
};

export default BodyPartRequired;
