import SelectedUserActionTypes from './actions-types';

const INITIAL_STATE = null;

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case SelectedUserActionTypes.SET_SELECTED_USER:
            if (process.env.REACT_APP_ENVIRONMENT==="development") {
                console.log(`SET_SELECTED_USER with payload ${action.payload}`);
            }
            return action.payload;
        default:
            return state;
    }
};
