import React from 'react';
import styled from 'styled-components';

const Label = styled.div`
    font-family: Gotham;
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #89889b;
    text-transform: uppercase;
`;

const LabelledTitle = ({label, title}) => {
    return (
        <div>
            <Label>{label}</Label>
            <h1 className={"text-primary"}>{title}</h1>
        </div>
    );
};

export default LabelledTitle;
