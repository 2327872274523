import { default as ChangeButton } from 'components/elements/buttons/Change';
import CardHorizontalRule from 'components/elements/divisions/CardHorizontalRule';
import LabelledContent from 'components/elements/labels/LabelledContent';
import gtagEvent from 'libs/gtagEvent';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setClaimPaymentsModalVisible } from 'store';
import { StoreType } from 'store/types';

export function BenefitPayments() {
    const dispatch = useDispatch();
    const membership = useSelector((state: StoreType) => state.membership);

    return (
        <>
            <Card className="py-4 mb-4">
                <h1 className="px-4 text-uppercase text-primary">
                    Benefit Payments
                </h1>

                <div className="my-3">
                    <CardHorizontalRule />
                </div>

                {/* Benefit Bank Account */}
                <div className="d-flex justify-content-between mx-4">
                    <LabelledContent label="Bank Details">
                        <div className="font-feature">
                            {/*WestPact Acc ****97*/}
                            {membership?.CreditAccount?.BankAccount?.AccountNumber.replace(
                                /\d(?=\d{4})/g,
                                '*',
                            )}
                        </div>
                    </LabelledContent>

                    <ChangeButton
                        onClick={() => {
                            gtagEvent({
                                screen: 'membership',
                                action: 'claim_payments',
                                label: 'Clicked on edit benefit button',
                                type: 'model_open',
                            });
                            dispatch(setClaimPaymentsModalVisible(true));
                        }}
                    />
                </div>
            </Card>
        </>
    );
}
