import React from "react";
import {Button} from "react-bootstrap";

interface Props {
    text: string;
    variant?: string;
    onClick: () => void;
    className?: string;
    style?: React.CSSProperties;
}

const LinkOutlineText: React.FC<Props> = ({text, variant = undefined, onClick, className, style}) => {
    return (
        // Pass through classnames from parent
        <Button
            variant={variant ? variant : "link-primary-dark-1 p-0 m-0"}
            onClick={onClick}
            style={style}
        >
            <div className={className}>
                <div className="d-flex align-items-center">
                <span>
                    {text}
                </span>
                </div>
            </div>
        </Button>

    );
};

export default LinkOutlineText;

