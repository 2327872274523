import actionTypes from './actions-types';

const INITIAL_STATE = {
    currentStep: '0',
    currentItemId: null,
    items: [],
    documents: [],
    sendConfirmation: false,
    email: '',
    claimAhb: false,
    eligibleForAhb: false,
    agreeDisclaimers: false,
    amountTotal: '',
    amountOutOfPocket: '',
    amountBenefit: '',
    useOneTimeBankAccount: false,
    oneTimeBankAccountDetails: {
        acc: '',
        bsb: '',
        name: '',
    },
    highRisk: false,
    // claimResult: test_claim
    benefitQuote: null,
    claimResult: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.SET_CLAIM_RESULTS:
            return {
                ...state,
                claimResult: action.payload,
            };

        case actionTypes.SET_CURRENT_ITEM:
            return {
                ...state,
                currentItemId: action.payload,
            };

        case actionTypes.SET_STEP:
            return {
                ...state,
                currentStep: action.payload,
            };

        case actionTypes.RESET:
            return INITIAL_STATE;

        // Item
        case actionTypes.ADD_ITEM:
            // Create a new item object with epoch id
            return {
                ...state,
                items: [
                    ...state.items,
                    {
                        id: action.payload,
                    },
                ],
            };

        case actionTypes.REMOVE_ITEM:
            // Find the item with matching ID
            return {
                ...state,
                items: state.items.filter((item) => item.id !== action.payload),
            };

        case actionTypes.UPDATE_ITEM: {
            // Find the correct item
            const targetItem = state.items.find(
                (item) => item.id === action.payload.itemId,
            );

            // Create new item with changes
            const updatedItem = {
                ...targetItem,
                ...action.payload.itemPartial,
            };

            return {
                ...state,
                // If this is the target item, replace with updated item.
                // If not, just return it.
                items: state.items.map((item) =>
                    item.id === action.payload.itemId ? updatedItem : item,
                ),
            };
        }

        // Document
        case actionTypes.ADD_DOCUMENT:
            return {
                ...state,
                documents: [...state.documents, action.payload],
            };

        case actionTypes.REMOVE_DOCUMENT:
            // Find the item with matching ID
            return {
                ...state,
                documents: state.documents.filter(
                    (document) => document.id !== action.payload,
                ),
            };

        // Review
        case actionTypes.SET_SEND_CONFIRMATION:
            return {
                ...state,
                sendConfirmation: action.payload,
            };

        case actionTypes.SET_ELIGIBLE_FOR_AHB:
            return {
                ...state,
                eligibleForAhb: action.payload,
            };

        case actionTypes.SET_CLAIM_ABH:
            return {
                ...state,
                claimAhb: action.payload,
            };

        case actionTypes.SET_AGREE_DISCLAIMERS:
            return {
                ...state,
                agreeDisclaimers: action.payload,
            };

        case actionTypes.SET_EMAIL:
            return {
                ...state,
                email: action.payload,
            };

        // Amount
        case actionTypes.SET_BENEFIT_QUOTE:
            return {
                ...state,
                benefitQuote: action.payload,
            };

        case actionTypes.SET_AMOUNT_OUT_OF_POCKET:
            return {
                ...state,
                amountOutOfPocket: action.payload,
            };

        case actionTypes.SET_AMOUNT_BENEFIT:
            return {
                ...state,
                amountBenefit: action.payload,
            };

        case actionTypes.SET_AMOUNT_TOTAL:
            return {
                ...state,
                amountTotal: action.payload,
            };

        // Bank Account
        case actionTypes.SET_USE_ONE_TIME_BANK_ACCOUNT:
            return {
                ...state,
                useOneTimeBankAccount: action.payload,
            };

        case actionTypes.SET_ONE_TIME_BANK_ACCOUNT_DETAILS:
            return {
                ...state,
                oneTimeBankAccountDetails: action.payload,
            };

        case actionTypes.SET_HIGH_RISK:
            return {
                ...state,
                highRisk: action.payload,
            };

        default:
            return state;
    }
};
