import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import CustomModal from 'components/modals/Modal';
import { Formik } from 'formik';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import gtagEvent from 'libs/gtagEvent';
import moment from 'moment';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { setMembership } from 'store';
import api from '@qldtuh/tuh-uh-js-api';
import { MustTakeCover } from '../Shared/components';
import { constructSubmittable } from '../Shared/helpers/index';
import {
    DependentsInfo,
    JoiningInfo,
    MedicareInfo,
    MemberInfo,
    PreviousFund,
    SubmitForm,
} from '../Shared/sections';
import {
    AddPersonModalSchema,
    INITIAL_VALUES,
} from '../Shared/validation/schema';
import { useSelector } from 'react-redux';

const AddPersonModal = ({ show, handleClose, align }) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [outcome, setOutcome] = useState(null);

    const [defaultValues, setDefaultValues] = useState(INITIAL_VALUES);

    const dependantEndAge = useSelector((state) => state?.settings?.general?.dependant_age);

    const doSuccess = (response, actions) => {
        if (response?.status === 'error') {
            throw new Error(response.message);
        } else {
            console.log(response);
            dispatch(setMembership(response));
            setLoading(false);
            actions.resetForm();
            setDefaultValues(INITIAL_VALUES);
            setMessage('Added person to membership');
            setOutcome('success');
            setTimeout(() => {
                setOutcome(null);
                setMessage('');
            }, 10000);
        }
    };

    const doError = (err) => {
        console.log('ERROR');
        setOutcome('fail');
        setMessage(err.message || 'Could not connect to server');
        setTimeout(() => {
            setOutcome(null);
            setMessage('');
        }, 10000);
        setLoading(false);
    };

    const onSubmit = async (values, actions) => {
        setLoading(true);
        gtagEvent({
            screen: 'addperson',
            action: 'submit',
            label: 'Requested to add person to cover',
            type: 'modal',
        });

        let outValues = constructSubmittable.new(values);
        outValues.dob = moment(outValues.dob).format('YYYY-MM-DD');
        outValues.date = moment(outValues.date).format('YYYY-MM-DD');

        const access_token = getAccessToken();
        const memberid = getMembershipId();

        api.user(CONFIG, access_token)
            .person.addPerson(memberid, outValues)
            .then((response) => doSuccess(response, actions))
            .catch(doError);
    };

    return (
        <CustomModal
            handleClose={handleClose}
            align={align}
            show={show}
            title={'Add Person'}>
            <div className={'font-feature row row-cols-1'}>
                <Formik
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={true}
                    validateOnMount={true}
                    validationSchema={AddPersonModalSchema(dependantEndAge)}
                    initialValues={defaultValues || null}
                    onSubmit={(values, actions) => onSubmit(values, actions)}>
                    <Form>
                        <JoiningInfo loading={loading} />
                        <MemberInfo loading={loading} />
                        <PreviousFund />
                        <DependentsInfo loading={loading} />
                        <MedicareInfo loading={loading} />
                        <MustTakeCover />
                        <SubmitForm loading={loading} />
                    </Form>
                </Formik>
                <Fade bottom when={outcome !== null}>
                    <StatusNotificationCard
                        status={outcome === 'fail' ? 'error' : 'success'}>
                        {message}
                    </StatusNotificationCard>
                </Fade>
            </div>
        </CustomModal>
    );
};

export default AddPersonModal;
