import React from 'react';
import styled from 'styled-components';
import InterpolateColours from 'color-interpolate';

const ProgressBarOuterContainer = styled.div`
    position: relative;
    height: 12px;
    border-radius: 7px;
    background-color: #e7e6ee;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    box-shadow: inset 0 2px 0 0 #d4d2e3;
`;

const ProgressBarInner = styled.div`
    height: 12px;
    position: relative;
    border-radius: 7px;
    overflow: hidden;
    width: ${(props) => props.width}%;
    background-color: ${(props) => props.backgroundColor};
    box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
`;

const ColorPalette = [
    'rgb(232, 66, 86)',
    'rgb(248, 207, 5)',
    'rgb(130, 209, 43)',
];

const ProgressBar = ({ maxValue, value, className }) => {
    value = parseFloat(value);
    maxValue = parseFloat(maxValue);

    const takeOff = Math.floor((value / maxValue) * 100);
    const width = 100 - takeOff;
    if (isNaN(width)) {
        return null;
    }
    const backgroundColor = InterpolateColours(ColorPalette)(width / 100);

    return (
        <ProgressBarOuterContainer className={className}>
            <ProgressBarInner width={width} backgroundColor={backgroundColor} />
        </ProgressBarOuterContainer>
    );
};

export default ProgressBar;
