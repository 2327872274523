import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const AgreeDisclaimers = () => {
    const { values, setFieldValue } = useFormikContext();

    /**
     * Based on the current form state,
     * generate the correct disclaimers.
     */
    const generateDisclaimers = (values) => {
        const standardDisclaimers = [
            'I have read, understand and agree to be bound by the conditions that relate to a suspension of membership as included on this form and the current TUH Fund Rules.',
            'I understand and accept that my personal information provided in this form, will be used in accordance with the TUH Privacy Policy.',
        ];

        // Is your partner or dependent who is covered on your membership NOT travelling overseas with you?
        if (values.partnerOrDepNotTravelling) {
            return [
                ...standardDisclaimers,
                'I authorise my TUH membership, including any associated payment authorities to be transferred into the name of my partner/dependant for the period of my trip. I understand payment of premiums during this period is my responsibility.',
            ];
        } else {
            return standardDisclaimers;
        }
    };

    return (
        <div className="row frm-brdr disclaimer-row">
            <div className="col-12">
                {generateDisclaimers(values).map((disclaimer, i) => {
                    return <p key={`disclaimer-${i}`}>{disclaimer}</p>;
                })}
            </div>

            <div className="col-12">
                <Form.Group
                    controlId={`agree-disclaimers`}
                    className="checkbox-group">
                    <Form.Control
                        type={'checkbox'}
                        className="checkbox-group"
                        name={'conditions'}
                        onChange={(e) => {
                            setFieldValue('agreeDisclaimers', e.target.checked);
                        }}
                        checked={values.agreeDisclaimers}
                    />
                    <Form.Check.Label className="text-label-gray font-weight-medium pb-3 font-13">
                        I agree to the disclaimers above
                    </Form.Check.Label>
                </Form.Group>
            </div>
        </div>
    );
};

export default AgreeDisclaimers;
