import React from 'react';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import HeadShake from 'react-reveal/HeadShake';
import { Spinner, Button } from 'react-bootstrap';
import gtagEvent from 'libs/gtagEvent';

interface Props {
    outcome: string | null;
    loading: boolean;
}


const SubmitRegistration: React.FC<Props> = ({ outcome, loading }) => {
    const { handleSubmit, isValid } = useFormikContext();
    return (
        <>
            <HeadShake spy={outcome}>
                <div className="col-12 text-center">
                    <div className="text-center pt-2">
                        <Button
                            variant="secondary"
                            type="submit"
                            className={'p-2'}
                            disabled={loading || !isValid}
                            onClick={(e: React.MouseEvent<HTMLFormElement, MouseEvent>) => handleSubmit(e)}>
                            <span className={'m-5'}>
                                {loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        as="span"
                                        size="sm"
                                    />
                                ) : (
                                    'Register'
                                )}
                            </span>
                        </Button>
                    </div>
                    <div
                        className="text-primary-dark-1 mt-3 font-weight-medium"
                        style={{ fontSize: '12px' }}>
                        Already have an account?{' '}
                        <Link
                            to="/"
                            style={{
                                color: '#CD0084',
                                textDecoration: 'none',
                            }}
                            onClick={() => {
                                gtagEvent({
                                    screen: 'register',
                                    action: 'login',
                                    label: 'Clicked login instead link',
                                });
                            }}>
                            Login
                        </Link>{' '}
                        instead
                    </div>
                </div>
            </HeadShake>
        </>
    );
};

export default SubmitRegistration;
