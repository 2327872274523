import ClaimSpecialityActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';

export const setClaimSpeciality = (speciality) => ({
    type: ClaimSpecialityActionTypes.SET_CLAIM_SPECIALITY,
    payload: speciality,
});

export const thunkGetClaimsSpeciality = () => (dispatch, _getState) => {
    const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    api.provider(CONFIG, token)
        .getSpeciality(memberId)
        .then((response) => {
            if (!response?.message) {
                dispatch(setClaimSpeciality(response));
            }
            // console.log(response)
        });
};
