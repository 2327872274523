import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

const RegisterConfirmPassword: React.FC = () => {
    const {
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        getFieldProps,
        isSubmitting,
    } = useFormikContext();
    const passwordKey = 'passwordConfirmation';
    return (
        <Form.Group className={'col-12'} controlId="member-confirm-password">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
                {...getFieldProps(passwordKey)}
                type="password"
                placeholder="Confirm password"
                onChange={(e) => {
                    setFieldTouched(passwordKey, true);
                    setFieldValue(passwordKey, e.target.value);
                }}
                isInvalid={touched[passwordKey] && errors[passwordKey]}
                autoComplete="new-field"
            />
            {touched[passwordKey] && errors[passwordKey] && !isSubmitting && (
                <small className="text-danger form-text">
                    {errors[passwordKey]}
                </small>
            )}
        </Form.Group>
    );
};

export default RegisterConfirmPassword;
