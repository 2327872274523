import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import Edit from 'components/elements/buttons/Edit';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CardPicture } from 'components/Common/components/exports';
import gtagEvent from 'libs/gtagEvent';
import api from '@qldtuh/tuh-uh-js-api';

const CreditCard = ({
    getFieldProps,
    isVisible,
    errors,
    touched,
    values,
    membership,
    loading,
    showIframe,
    setShowIframe,
    setFieldValue,
}) => {
    const idPrefix = 'payment-method';
    const membershipId = membership?.MembershipId;

    const url = api.publicHelpers.publicGetFullApiUrl(
        'tuh',
        process.env.REACT_APP_API_ENVIRONMENT
    );

    const debitAccounts = useSelector((state) => state.paymentMethods?.debitAccounts);
    const account = debitAccounts?.[0];

    let cardNum;
    if (account?.chargeCard?.cardNumber) {
        cardNum =
            '**' +
            account?.chargeCard?.cardNumber.substring(
                account?.chargeCard?.cardNumber
                    .length - 3,
            );
    }

    let cardType;
    if (account?.accountTypeCode === 'Mcard') {
        cardType = 'Mastercard';
    }
    if (account?.accountTypeCode === 'Visa') {
        cardType = 'Visa';
    }
    const chargeCard = account?.chargeCard;
    const cardExpiration = `${chargeCard?.expiryMonth}/${chargeCard?.expiryYear}`;
    //TODO: remove this if-else and iframeAction
    let iframeAction;
    if (process.env.REACT_APP_API_ENVIRONMENT !== 'prod') {
        if (membership?.DebitAccount?.ChargeCard) {
            iframeAction = 'edit';
        } else {
            iframeAction = 'add';
        }
    } else {
        iframeAction = '1';
    }

    return (
        <div
            style={{
                display: isVisible ? 'block' : 'none',
            }}>
            <div
                className="d-flex align-items-center justify-content-between pt-2 pb-3"
                style={{
                    boxShadow: 'inset 0px -2px 0px 0px #E6E6EE',
                }}>
                <div className="row px-2">
                    <Form.Group
                        controlId={`${idPrefix}-credit-holder`}
                        className="col-6 pr-1 text-label-gray">
                        <Form.Label style={{ fontSize: '11px' }}>
                            Name on Card
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className={
                                errors.cardholder &&
                                touched.cardholder &&
                                !loading
                                    ? 'is-invalid'
                                    : ''
                            }
                            {...getFieldProps('cardholder')}
                        />
                        {touched.cardholder &&
                            errors.cardholder &&
                            !loading && (
                                <Form.Control.Feedback type={'invalid'}>
                                    {errors.cardholder}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>

                    <Form.Group
                        controlId={`${idPrefix}-credit-freq`}
                        className="col-6 text-label-gray">
                        <Form.Label style={{ fontSize: '11px' }}>
                            Payment Frequency
                        </Form.Label>
                        <Form.Control
                            as="select"
                            className={
                                errors.cardPaymentFreq &&
                                touched.cardPaymentFreq &&
                                !loading
                                    ? 'is-invalid'
                                    : ''
                            }
                            {...getFieldProps('cardPaymentFreq')}>
                            <option value="Monthly">Monthly</option>
                            <option value="Quarterly">Quarterly</option>
                            <option value="6 Monthly">6 Monthly</option>
                            <option value="Yearly">Yearly</option>
                        </Form.Control>
                        {touched.cardPaymentFreq &&
                            errors.cardPaymentFreq &&
                            !loading && (
                                <Form.Control.Feedback type={'invalid'}>
                                    {errors.cardPaymentFreq}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group
                        controlId={`${idPrefix}-credit-date`}
                        className="col-6 text-label-gray">
                        <Form.Label style={{ fontSize: '11px' }}>
                            Debit on date
                        </Form.Label>
                        <Form.Control
                            type="date"
                            className={
                                errors.cardPaymentDate &&
                                touched.cardPaymentDate &&
                                !loading
                                    ? 'is-invalid'
                                    : ''
                            }
                            disabled={true}
                            readOnly
                            {...getFieldProps('cardPaymentDate')}
                        />
                        {touched.cardPaymentDate &&
                            errors.cardPaymentDate &&
                            !loading && (
                                <Form.Control.Feedback type={'invalid'}>
                                    {errors.cardPaymentDate}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <div className="col-6 text-label-gray">
                        <Form.Check
                            type="checkbox"
                            id={`checkbox-card-change-date`}
                            label={`Change Debit Date`}
                            className={
                                errors.ccDateChange &&
                                touched.ccDateChange &&
                                !loading
                                    ? 'is-invalid text-label-gray font-weight-medium'
                                    : 'text-label-gray font-weight-medium'
                            }
                            style={{
                                fontSize: '13px',
                            }}
                            name={`ccDateChange`}
                            checked={values.ccDateChange}
                            onChange={(val) => {
                                setFieldValue(
                                    'ccDateChange',
                                    val.target.checked,
                                );
                            }}
                        />
                        {touched.ccDateChange &&
                            errors.ccDateChange &&
                            !loading && (
                                <Form.Control.Feedback type={'invalid'}>
                                    {errors.ccDateChange}
                                </Form.Control.Feedback>
                            )}
                    </div>
                    <Form.Group
                        controlId={`${idPrefix}-credit-date-new`}
                        className="col-6 text-label-gray"
                        style={{
                            display: values.ccDateChange ? 'block' : 'none',
                        }}>
                        <Form.Label style={{ fontSize: '11px' }}>
                            New Debit on date
                        </Form.Label>
                        {/*<Form.Control
                            type="date"
                            className={errors.ccNewDate && touched.ccNewDate && !loading ? 'is-invalid' : ''}
                            {...getFieldProps('ccNewDate')}
                        />*/}
                        <DatePicker
                            wrapperClassName={
                                errors.ccNewDate && !loading ? 'is-invalid' : ''
                            }
                            className={
                                errors.ccNewDate && !loading
                                    ? 'is-invalid form-control'
                                    : 'form-control'
                            }
                            dateFormat="dd/MM/yyyy"
                            selected={values?.ccNewDate ?? ''}
                            minDate={moment().add(2, 'days').toDate()}
                            // maxDate={moment().add(8,'months').toDate()}
                            onChange={(e) => {
                                setFieldValue('ccNewDate', e);
                            }}
                            showMonthYearDropdown
                        />
                        {errors.ccNewDate && !loading ? (
                            <Form.Control.Feedback type={'invalid'}>
                                {errors.ccNewDate}
                            </Form.Control.Feedback>
                        ) : null}
                    </Form.Group>
                    <div
                        className="col-12"
                        style={{
                            display:
                                showIframe === true ??
                                !membership?.DebitAccount
                                    ?.ChargeCard
                                    ? 'none'
                                    : 'block',
                        }}>
                        <div
                            className="text-black font-weight-medium d-flex justify-content-around align-items-center"
                            style={{
                                backgroundColor: '#EFEFF5',
                                borderRadius: '5px',
                                fontSize: '14px',
                                padding: '15px',
                            }}>
                            <div>
                                <CardPicture
                                    type={cardType}
                                    style={{ height: '20px' }}
                                />
                            </div>
                            <div>
                                {cardType} ending in {cardNum}{' '}
                                <small>
                                    EXP:{' '} {cardExpiration}
                                </small>
                            </div>
                            <Edit
                                className={'text-primary text-purple'}
                                onClick={() => {
                                    gtagEvent({
                                        screen: 'paymentmethod',
                                        action: 'update_card',
                                        label: 'User chose to edit card details',
                                        type: 'modal',
                                    });
                                    setShowIframe(true);
                                }}
                            />
                        </div>
                    </div>
                    <iframe
                        //TODO: remove iframeAction
                        src={`${url}/nab/store/${membershipId}?type=${iframeAction}`}
                        id="iframe_hambs_pay"
                        style={{
                            border: '0',
                            display: showIframe === false ? 'none' : 'block',
                        }}
                        border="0"
                        width="260px"
                        height="300px"
                        className="col-12 px-2"></iframe>
                </div>
            </div>
        </div>
    );
};

export default CreditCard;
