import PaymentActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from 'libs/constants';

export const setDebitAccount = (debit) => ({
    type: PaymentActionTypes.SET_DEBIT,
    payload: debit,
});

export const thunkGetDebitAccount = () => (dispatch) => {
    const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    return new Promise((res, rej) => {
        api.user(CONFIG, token)
            .membership.getDebitAccounts(memberId)
            .then((response) => {
                if (response?.status === 'error') {
                    console.log(
                        'ERR:: Could not get debit info, this is most likely a HAMBS issue'
                    );
                }
                dispatch(setDebitAccount(response));
                res();
            })
            .catch((e) => rej(e.message));
    });

}