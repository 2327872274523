export const generateDefaults = (membershipObj) => {
    return {
        sameBankAccount: false,
        bank:
            membershipObj?.CreditAccount?.BankAccount?.Bank ||
            '',
        bsb:
            membershipObj?.CreditAccount?.BankAccount
                ?.BsbNumber || '',
        accountNumber:
            membershipObj?.CreditAccount?.BankAccount
                ?.AccountNumber || '',
        accountName:
            membershipObj?.CreditAccount?.AccountName || '',
    };
};
