import {guaranteeArray} from "libs/helpers";

export const benefitLimitHelpers = benefitLimits => {
    const limits = benefitLimits

    return {
        getSpecialityByClass: (currentUserBenefitLimits, className) => {
            if (currentUserBenefitLimits?.length < 1) {
                return null;
            }
            if (currentUserBenefitLimits) {
                return currentUserBenefitLimits?.find(limits => limits.SpecialityClassCode.trim() === className)
            }
        },
        getCurrentUserBenefits: selectedUserId => {
            let currentUserBenefits;
            // Find correct benefits
            if (limits?.length) {
                const benefits = limits.find(limits => limits.PersonId === selectedUserId);
                if (benefits?.SpecialityClasses?.SpecialityClass) {
                    currentUserBenefits = benefits.SpecialityClasses.SpecialityClass;
                } else {
                    currentUserBenefits = false;
                }
            } else {
                if (limits?.SpecialityClasses?.SpecialityClass) {
                    currentUserBenefits = limits?.SpecialityClasses?.SpecialityClass;
                } else {
                    currentUserBenefits = false;
                }
            }
      
            return currentUserBenefits
        },
        getMovableGridCurrentUserBenefits: (selectedUserId, userPreferences) => {
            // Can't do anything until all these calls have finished
            if (!selectedUserId || userPreferences === null) return;
            // Get the benefits for this person
            // const benefits = benefitLimitHelpers(benefitLimits)?.getCurrentUserBenefits(selectedUserId)
            // Cannot progress without limits
            const benefits = benefitLimits;

            console.log({benefits});

            if (!benefits) return
            // Check for 'Den' and 'Opt' codes
            // We omit these from our results
            const featuredBenefit = benefit => ['Den', 'Opt'].includes(benefit.SpecialityClassCode)

            // If the user has an order preference:
            // 1. Look through benefits to find each code, and add to the new array
            // 2. If there exist any unknown codes in the old array, append them to the new array
            const benefitCodeOrder = userPreferences?.extrasGridOrder;
            console.log({userPreferences:userPreferences});
            let outputBenefits = []
            // If the user has a preference on order
            if (benefitCodeOrder) {
                // Iterate on user preference orders
                benefitCodeOrder.forEach(code => {
                    // Search for this code in the benefits
                    const targetBenefit = guaranteeArray(benefits).find(benefit => benefit.SpecialityClassCode === code)
                    // If found, add to ordered array
                    // Exclude featured categories
                    if (targetBenefit && !featuredBenefit(targetBenefit)) {
                        // Key is required by the movable grid package
                        targetBenefit.key = targetBenefit.SpecialityClassCode
                        outputBenefits.push(targetBenefit)
                    }
                })
                // Now we must check for items that are not included in the preference list, which
                // could happen when changing cover.
                // If there are some codes missing from the ordered array
                if (outputBenefits.length !== benefits.length) {
                    guaranteeArray(benefits).forEach(benefit => {
                        // If our preference list does not include this speciality code
                        // If this is not one of the 'featured' categories
                        if (
                            (! benefitCodeOrder.includes(benefit.SpecialityClassCode))
                            && !featuredBenefit(benefit)
                        ) {
                            // Key is required by the movable grid package
                            benefit.key = benefit.SpecialityClassCode
                            outputBenefits.push(benefit)
                        }
                    })
                }
            // If the user has no preference on order
            } else {
                benefits.forEach(benefit => {
                    benefit.key = benefit.SpecialityClassCode
                    if (!featuredBenefit(benefit)) {
                        outputBenefits.push(benefit)
                    }
                })
            }
            console.log({outputBenefits});
            return outputBenefits
        },
        /**
         * Top Extras are feature on the Extras Cover card
         * and at the top of the Extras Cover details scren
         */
        getTopExtras: selectedUserId => {
            let currentUserBenefits = benefitLimitHelpers(benefitLimits).getCurrentUserBenefits(selectedUserId)
            const top = {};
            const dental = benefitLimitHelpers(benefitLimits).getSpecialityByClass(currentUserBenefits,'Den');
            if (dental?.HasGeneralDental) {
                top.generalDental = dental?.BenefitLimitStatuses?.find(limits => limits.BenefitLimitCodeShort==="086"||limits.BenefitLimitCodeShort==="400");
                top.generalDental.BenefitLimitStatuses = {};
                top.generalDental.BenefitLimitStatuses = dental?.BenefitLimitStatuses.filter(limits => limits.BenefitLimitCodeShort==="086"||limits.BenefitLimitCodeShort==="400");
            }
            if (dental?.HasMajorDental) {
                top.majorDental = dental?.BenefitLimitStatuses.find(limits => limits.BenefitLimitCodeShort==="087");
                top.majorDental.BenefitLimitStatuses = {};
                top.majorDental.BenefitLimitStatuses = dental?.BenefitLimitStatuses.filter(limits => limits.BenefitLimitCodeShort!=="086"&&limits.BenefitLimitCodeShort!=="400");
            }
            if (dental?.HasOverallDental) {
                top.dental = dental;
                top.dental.overall = dental?.BenefitLimitStatuses.find(limits => limits.BenefitLimitCodeShort==="500");
                if (top.dental.overall) {33
                    top.dental.ClaimedAmount = top.dental.overall.ClaimedAmount;
                    top.dental.ClaimableAmount = top.dental.overall.ClaimableAmount;
                    top.dental.ClaimableTotal = top.dental.overall.ClaimableTotal;
                }
            }
            if(!dental?.HasGeneralDental && !dental?.HasMajorDental && !dental?.HasOverallDental) {
                top.dental = dental;
            }
            top.optical = benefitLimitHelpers(benefitLimits).getSpecialityByClass(currentUserBenefits, 'Opt');
            return top
        }
    }
}
