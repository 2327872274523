import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { setRebateFormStep } from 'store';
import buildMedicareData from '../functions/buildMedicareData';
import api from '@qldtuh/tuh-uh-js-api';

const RebateDeclaration = () => {
    const dispatch = useDispatch();

    const membership = useSelector((state) => state.membership);
    const rebateForm = useSelector((store) => store.rebateForm);

    const [iframeIsValid, setIframeIsValid] = useState(false);
    const [iframeData, setIframeData] = useState('');

    const url = api.publicHelpers.publicGetFullApiUrl(
        'tuh',
        process.env.REACT_APP_API_ENVIRONMENT
    );

    const onMessageReceivedFromIframe = (e) => {
        if (e.origin !== `${url}`) {
            return;
        }
        var json = JSON.parse(e.data);
        console.log({ json });
        if (json?.valid === false) {
            setIframeIsValid(false);
        } else if (json?.valid === true) {
            setIframeIsValid(true);
        }
    };

    useEffect(() => {
        const data = buildMedicareData(membership, rebateForm);
        if (data) {
            setIframeData(data);
        }
    }, [membership, rebateForm]);

    useEffect(() => {
        if (rebateForm.step === 3) {
            window.removeEventListener('message', onMessageReceivedFromIframe);
            window.addEventListener(
                'message',
                onMessageReceivedFromIframe,
                false,
            );
            return () => {
                window.removeEventListener(
                    'message',
                    onMessageReceivedFromIframe,
                );
            };
        }
    }, [rebateForm]);

    useEffect(() => {
        if (iframeData) {
            document.getElementById('iframe-form-medicare-interactive').submit();
        }
    }, [iframeData]);

    return (
        <div className="rebate-form-step-3">
            <div className="container-fluid">
                <div className="row flex-wrap">
                    <div className="col-12 text-center steps">
                        <span
                            onClick={() => {
                                dispatch(setRebateFormStep(2));
                            }}>
                            1
                        </span>
                        <span className="active">2</span>
                        <span>3</span>
                    </div>
                    <div
                        className="col-12 text-primary font-21 pb-3 font-weight-bold text-center"
                        style={{ fontSize: '21px' }}>
                        Register for Government Rebate
                    </div>
                    <div
                        className="col-12 col-md-8 offset-md-2 text-center pb-3"
                        style={{ fontSize: '14px' }}>
                        Please fill out the form below:
                    </div>
                </div>
            </div>
            <div
                style={{
                    background: '#E6E6EE',
                    height: '2px',
                    margin: '0px -30px',
                }}></div>
            <div className="py-3">
                {iframeData ? (
                    <>
                        <form
                            id="iframe-form-medicare-interactive"
                            action={`${url}/medicare/interactivepost`}
                            method="post"
                            target="iframe_medicare_interactive">
                            <input type="hidden" name="data" value={iframeData} />
                            <input type="submit" value="Submit" style={{ display: 'none' }} />
                        </form>
                        <iframe
                            name="iframe_medicare_interactive"
                            id="iframe_medicare_interactive"
                            style={{
                                border: '0',
                            }}
                            border="0"
                            width="260px"
                            height="560px"
                            className="col-12 px-2"
                        />
                    </>
                ) : null}
            </div>
            <div
                style={{
                    background: '#E6E6EE',
                    height: '2px',
                    margin: '0px -30px',
                }}></div>
            <div className="container-fluid pt-3">
                <div className="row">
                    <div className="col-12 text-center">
                        <Button
                            variant="link-outline-gray"
                            className="mr-3"
                            onClick={() => {
                                dispatch(setRebateFormStep(2));
                            }}>
                            Back
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                dispatch(setRebateFormStep(4));
                            }}
                            disabled={!iframeIsValid}>
                            Continue
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RebateDeclaration;
