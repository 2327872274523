import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeCoverActions } from 'store';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import {
    FaChevronRight,
    IoMdInformationCircle,
    BiChevronDown,
    BiCheck,
    IoMdClose,
    BiChevronUp,
    FaCheck,
    IoMdInformationCircleOutline,
} from 'react-icons/all';
import Shadow from 'img/raster/Shadow/comp';

import HeartPlus from 'img/vector/HeartPlus/comp';
import ToothWithGlasses from 'img/vector/ToothWithGlasses/comp';
import HeartWithTooth from 'img/vector/HeartWithTooth/comp';

import Single from 'img/vector/Single/comp';
import Couple from 'img/vector/Couple/comp';
import Family from 'img/vector/Family/comp';
import SingleParent from 'img/vector/SingleParent/comp';

import {
    getHomeAddressState,
    ageToYears,
    mainCouple,
    getPaymentMethodDebit,
} from 'libs/helpers';

import LimitsModal from 'components/modals/LimitsModal/LimitsModal';

const CardContext = React.createContext(null);

const FindExcess = ({ currentSku, setCurrentSku }) => {
    const cardContext = useContext(CardContext);
    if (cardContext?.product?.excess?.length === 1) {
        return <>${cardContext?.product?.excess?.[0].excess}</>;
    }
    return (
        <>
            <span className="excess-select">
                <select
                    value={currentSku}
                    onChange={(e) => {
                        setCurrentSku(e.target.value);
                    }}>
                    {cardContext?.product?.excess?.map((prod, idx) => {
                        return (
                            <option key={idx} value={prod?.sku}>
                                ${prod?.excess}
                            </option>
                        );
                    })}
                </select>
                <span className="dropArrow">
                    <BiChevronDown style={{ verticalAlign: 'middle' }} />
                </span>
            </span>
        </>
    );
};

const FindExcessTop = ({ currentSku, setCurrentSku, product }) => {
    if (product?.excess?.length === 1) {
        return <>${product?.excess?.[0].excess}</>;
    }
    return (
        <>
            <span className="excess-select">
                <select
                    value={currentSku}
                    onChange={(e) => {
                        setCurrentSku(e.target.value);
                    }}>
                    {product?.excess?.map((prod, idx) => {
                        return (
                            <option key={idx} value={prod?.sku}>
                                ${prod?.excess}
                            </option>
                        );
                    })}
                </select>
                <span className="dropArrow">
                    <BiChevronDown style={{ verticalAlign: 'middle' }} />
                </span>
            </span>
        </>
    );
};

const IconChooser = (value) => {
    switch (value) {
        case 'included':
            return (
                <i className="icon-check">
                    <BiCheck style={{ verticalAlign: 'middle' }} />
                </i>
            );
        case 'excluded':
            return (
                <i className="icon-small-x">
                    <IoMdClose style={{ verticalAlign: 'middle' }} />
                </i>
            );
        default:
            return <i className=""></i>;
    }
};

const ModalIcon = ({ value }) => {
    // console.log({value});
    const cardContext = useContext(CardContext);
    const dispatch = useDispatch();
    const hasModal = value?.hasModal;
    const modalOptions = value?.modalOptions;
    switch (hasModal) {
        case true:
            return (
                <i
                    className="icon-info"
                    onClick={() => {
                        dispatch(
                            changeCoverActions.ui.limitsModalOptions({
                                sku: cardContext.product.originalSku,
                                options: modalOptions,
                            }),
                        );
                        dispatch(changeCoverActions.ui.showLimitsModal(true));
                    }}>
                    <IoMdInformationCircle
                        style={{ verticalAlign: 'middle' }}
                    />
                </i>
            );
        default:
            return null;
    }
};

const CSSClasses = (value) => {
    let outClass = '';
    if (value?.group) {
        outClass = outClass + ' group';
    }
    if (value?.lastOfGroup) {
        outClass = outClass + ' last-group';
    }
    if (value?.status) {
        outClass = outClass + ' ' + value?.status;
    }
    return outClass;
};

const FamilyType = ({ value }) => {
    switch (value) {
        case 'S':
            return (
                <>
                    <div className="icon-holder">
                        <Single />
                    </div>
                    <div className="text-holder">Showing cover for a</div>
                    <div className="family-type-select-holder">Single</div>
                </>
            );
        case 'C':
            return (
                <>
                    <div className="icon-holder">
                        <Couple />
                    </div>
                    <div className="text-holder">Showing cover for a</div>
                    <div className="family-type-select-holder">Couple</div>
                </>
            );
        case 'FAM':
            return (
                <>
                    <div className="icon-holder">
                        <Family />
                    </div>
                    <div className="text-holder">Showing cover for a</div>
                    <div className="family-type-select-holder">Family</div>
                </>
            );
        case 'SP':
            return (
                <>
                    <div className="icon-holder">
                        <SingleParent />
                    </div>
                    <div className="text-holder">Showing cover for a</div>
                    <div className="family-type-select-holder">
                        Single Parent
                    </div>
                </>
            );
        default:
            return null;
    }
};

const QuotePage = () => {
    const changeCoverStore = useSelector((state) => state.changeCover);
    const membership = useSelector((state) => state.membership);
    const lhc = useSelector((state) => state.lhc);

    const mainPeople = mainCouple(membership);
    const paymentMethod = getPaymentMethodDebit(membership);

    const product = useSelector((state) => state.product);
    const currentHomeState = getHomeAddressState(membership);
    const [age, setAge] = useState(0);
    const [agePartner, setAgePartner] = useState(0);
    const [membershipLHC, setMembershipLHC] = useState(0);
    const [membershipPaymentMethod, setMembershipPaymentMethod] =
        useState('cc');

    console.log(
        'why are these vars not being used?\n',
        'FILE: QuotePage.js\n',
        {
            product,
            currentHomeState,
            age,
            agePartner,
            membershipLHC,
            membershipPaymentMethod,
        },
    );

    const [totalPrice, setTotalPrice] = useState(0);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (mainPeople?.length) {
            mainPeople.forEach((person, i) => {
                if (i === 0) {
                    setAge(ageToYears(person?.Person?.BirthDate));
                } else {
                    setAgePartner(ageToYears(person?.Person?.BirthDate));
                }
            });
        }
    }, [mainPeople]);
    useEffect(() => {
        if (typeof lhc !== 'undefined' && lhc !== null) {
            if (
                Object?.keys(lhc)?.length !== 0 &&
                lhc?.constructor === Object
            ) {
                // console.log('LHC: ',lhc);
                let totalLHC = 0;
                Object.entries(lhc).forEach((person) => {
                    // console.log('person lhc: ',person[1]);
                    totalLHC = totalLHC + person[1];
                });
                setMembershipLHC(totalLHC);
            }
        }
    }, [lhc]);
    useEffect(() => {
        switch (paymentMethod) {
            case 'billing':
            case 'payroll':
            case 'credit card':
                setMembershipPaymentMethod('cc');
                break;
            case 'direct debit':
                setMembershipPaymentMethod('dd');
                break;
            default:
                setMembershipPaymentMethod('cc');
        }
    }, [paymentMethod]);

    useEffect(() => {
        if (
            changeCoverStore?.selected?.hospital === null &&
            changeCoverStore?.selected?.extras === null &&
            changeCoverStore?.selected?.bundle === null
        ) {
            history.push('/member/coverchange/start');
        }

        if (
            changeCoverStore?.pricing?.hospital !== null ||
            changeCoverStore?.pricing?.extras !== null ||
            changeCoverStore?.pricing?.bundle !== null
        ) {
            let totalingPrice = parseFloat(0);
            if (changeCoverStore?.selected?.bundle !== null) {
                console.log(changeCoverStore.pricing);
                switch (changeCoverStore.paymentFrequency) {
                    case 'fortnightPeriod':
                        totalingPrice =
                            totalingPrice +
                            parseFloat(
                                changeCoverStore.pricing.bundle[
                                    changeCoverStore.selected.bundle
                                ]['f'],
                            );
                        break;
                    case 'monthPeriod':
                        totalingPrice =
                            totalingPrice +
                            parseFloat(
                                changeCoverStore.pricing.bundle[
                                    changeCoverStore.selected.bundle
                                ]['m'],
                            );
                        break;
                    case 'quarterPeriod':
                        totalingPrice =
                            totalingPrice +
                            parseFloat(
                                changeCoverStore.pricing.bundle[
                                    changeCoverStore.selected.bundle
                                ]['q'],
                            );
                        break;
                    case 'halfYearPeriod':
                        totalingPrice =
                            totalingPrice +
                            parseFloat(
                                changeCoverStore.pricing.bundle[
                                    changeCoverStore.selected.bundle
                                ]['h'],
                            );
                        break;
                }
            } else {
                if (changeCoverStore?.selected?.hospital !== null) {
                    switch (changeCoverStore.paymentFrequency) {
                        case 'fortnightPeriod':
                            totalingPrice =
                                totalingPrice +
                                parseFloat(
                                    changeCoverStore.pricing.hospital[
                                        changeCoverStore.selected.hospital
                                    ]['f'],
                                );
                            break;
                        case 'monthPeriod':
                            totalingPrice =
                                totalingPrice +
                                parseFloat(
                                    changeCoverStore.pricing.hospital[
                                        changeCoverStore.selected.hospital
                                    ]['m'],
                                );
                            break;
                        case 'quarterPeriod':
                            totalingPrice =
                                totalingPrice +
                                parseFloat(
                                    changeCoverStore.pricing.hospital[
                                        changeCoverStore.selected.hospital
                                    ]['q'],
                                );
                            break;
                        case 'halfYearPeriod':
                            totalingPrice =
                                totalingPrice +
                                parseFloat(
                                    changeCoverStore.pricing.hospital[
                                        changeCoverStore.selected.hospital
                                    ]['h'],
                                );
                            break;
                    }
                }
                if (changeCoverStore?.selected?.extras !== null) {
                    switch (changeCoverStore.paymentFrequency) {
                        case 'fortnightPeriod':
                            totalingPrice =
                                totalingPrice +
                                parseFloat(
                                    changeCoverStore.pricing.extras[
                                        changeCoverStore.selected.extras
                                    ]['f'],
                                );
                            break;
                        case 'monthPeriod':
                            totalingPrice =
                                totalingPrice +
                                parseFloat(
                                    changeCoverStore.pricing.extras[
                                        changeCoverStore.selected.extras
                                    ]['m'],
                                );
                            break;
                        case 'quarterPeriod':
                            totalingPrice =
                                totalingPrice +
                                parseFloat(
                                    changeCoverStore.pricing.extras[
                                        changeCoverStore.selected.extras
                                    ]['q'],
                                );
                            break;
                        case 'halfYearPeriod':
                            totalingPrice =
                                totalingPrice +
                                parseFloat(
                                    changeCoverStore.pricing.extras[
                                        changeCoverStore.selected.extras
                                    ]['h'],
                                );
                            break;
                    }
                }
            }

            setTotalPrice(totalingPrice);
        }
        console.log(changeCoverStore.selectedProducts);
    }, [changeCoverStore]);

    return (
        <>
            <main className="container-fluid p-4 cover-change-quote">
                <div className="row">
                    <div className="col-12 text-center text-primary-dark-1 page-title">
                        Here&apos;s your new quote:
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-6 left-box">
                            <div className="inner">
                                {changeCoverStore?.selectedProducts?.bundle ? (
                                    <>
                                        <div className="bundle-side">
                                            <div className="inner-flex">
                                                <div className="icon">
                                                    <HeartWithTooth />
                                                </div>
                                                <div className="product-type">
                                                    Hospital + Extras Cover
                                                </div>
                                                <div className="product-title">
                                                    {
                                                        changeCoverStore
                                                            ?.selectedProducts
                                                            ?.bundle?.name
                                                    }
                                                </div>
                                                <div className="product-excess">
                                                    <span className="excess-text">
                                                        Excess:
                                                    </span>{' '}
                                                    <span className="excess-amount">
                                                        $500
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {changeCoverStore?.selectedProducts
                                            ?.hospital ? (
                                            <div className="hospital-side">
                                                <div className="inner-flex">
                                                    <div className="icon">
                                                        <HeartPlus />
                                                    </div>
                                                    <div className="product-type">
                                                        Hospital Cover
                                                    </div>
                                                    <div className="product-title">
                                                        {
                                                            changeCoverStore
                                                                ?.selectedProducts
                                                                ?.hospital?.name
                                                        }
                                                    </div>
                                                    <div className="product-excess">
                                                        <span className="excess-text">
                                                            Excess:
                                                        </span>{' '}
                                                        <span className="excess-amount">
                                                            <FindExcessTop
                                                                product={
                                                                    changeCoverStore
                                                                        ?.selectedProducts
                                                                        ?.hospital
                                                                }
                                                                currentSku={
                                                                    changeCoverStore
                                                                        ?.selected
                                                                        ?.hospital
                                                                }
                                                                setCurrentSku={() => {}}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="hospital-side no">
                                                <div className="inner-flex">
                                                    <div className="icon">
                                                        <HeartPlus />
                                                    </div>
                                                    <div className="product-type">
                                                        Hospital Cover
                                                    </div>
                                                    <div className="product-title">
                                                        No Hospital
                                                    </div>
                                                    <div className="product-excess"></div>
                                                </div>
                                            </div>
                                        )}
                                        {changeCoverStore?.selectedProducts
                                            ?.extras ? (
                                            <div className="extras-side">
                                                <div className="inner-flex">
                                                    <div className="icon">
                                                        <ToothWithGlasses />
                                                    </div>
                                                    <div className="product-type">
                                                        Extras Cover
                                                    </div>
                                                    <div className="product-title">
                                                        {
                                                            changeCoverStore
                                                                ?.selectedProducts
                                                                ?.extras?.name
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="extras-side no">
                                                <div className="inner-flex">
                                                    <div className="icon">
                                                        <ToothWithGlasses />
                                                    </div>
                                                    <div className="product-type">
                                                        Extras Cover
                                                    </div>
                                                    <div className="product-title">
                                                        No Extras
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-6 right-box">
                            <div className="inner">
                                <div className="family-side">
                                    <div className="inner-flex">
                                        {
                                            <FamilyType
                                                value={
                                                    changeCoverStore.groupCoverType
                                                }
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="quote-side">
                                    <div className="inner-flex">
                                        <div className="top-holder">
                                            Your Quote:
                                        </div>
                                        <div className="price-holder">
                                            ${parseFloat(totalPrice).toFixed(2)}
                                            *
                                        </div>
                                        <div className="payment-select-holder">
                                            <span>
                                                <select
                                                    value={
                                                        changeCoverStore.paymentFrequency
                                                    }
                                                    onChange={(e) => {
                                                        dispatch(
                                                            changeCoverActions.paymentFrequency(
                                                                e.target.value,
                                                            ),
                                                        );
                                                    }}>
                                                    <option value="fortnightPeriod">
                                                        Per Fortnight
                                                    </option>
                                                    <option value="monthPeriod">
                                                        Per Month
                                                    </option>
                                                    <option value="quarterPeriod">
                                                        Per Quarter
                                                    </option>
                                                    <option value="halfYearPeriod">
                                                        Per Half Year
                                                    </option>
                                                </select>
                                                <span className="dropArrow">
                                                    <BiChevronDown
                                                        style={{
                                                            verticalAlign:
                                                                'middle',
                                                        }}
                                                    />
                                                </span>
                                            </span>
                                        </div>
                                        <div className="button-holder">
                                            <Button variant="secondary">
                                                Submit Change{' '}
                                                <FaChevronRight
                                                    style={{
                                                        verticalAlign: 'middle',
                                                    }}
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-12 text-center py-4"
                            style={{
                                fontSize: '11px',
                                color: '#6C6F7D',
                            }}>
                            (*) Prices include direct debit discount. (R)
                            Restrictions apply. Sub limits may apply on extras
                            products, click on{' '}
                            <IoMdInformationCircle
                                style={{ verticalAlign: 'middle' }}
                            />{' '}
                            for more details. Set extras benefits apply per
                            service and vary depending on cover. Waiting periods
                            may apply. Your rates may differ based on your{' '}
                            <a
                                href="https://tuh.com.au/information/government-initiatives/lifetime-health-cover-loading-lhc"
                                target="_blank"
                                rel="noopener noreferrer">
                                Lifetime Health Cover loading (LHC)
                            </a>
                            .
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-12 text-center text-primary-dark-1 font-weight-bold"
                            style={{
                                fontSize: '32px',
                            }}>
                            Your selected hospital cover includes:
                        </div>
                    </div>
                </div>
                <div className="selected-products-holder container">
                    {changeCoverStore?.selected?.hospital !== null &&
                    changeCoverStore?.selected?.bundle === null ? (
                        <CardContext.Provider
                            value={{
                                product:
                                    changeCoverStore.selectedProducts.hospital,
                                section: 'hospital',
                                pricing: changeCoverStore.pricing.hospital,
                                bundlePricing: [],
                            }}>
                            <OfferProduct />
                        </CardContext.Provider>
                    ) : null}
                    {changeCoverStore?.selected?.extras !== null &&
                    changeCoverStore?.selected?.bundle === null ? (
                        <CardContext.Provider
                            value={{
                                product:
                                    changeCoverStore.selectedProducts.extras,
                                section: 'extras',
                                pricing: changeCoverStore.pricing.extras,
                                bundlePricing: [],
                            }}>
                            <OfferProduct />
                        </CardContext.Provider>
                    ) : null}
                </div>
                <LimitsModal
                    handleClose={() => {
                        dispatch(changeCoverActions.ui.showLimitsModal(false));
                    }}
                    show={changeCoverStore.showLimitsModal}
                    align={'center'}
                />
            </main>
        </>
    );
};

const OfferProduct = () => {
    const cardContext = useContext(CardContext);
    const changeCoverStore = useSelector((state) => state.changeCover);
    const dispatch = useDispatch();

    const [currentSku, setCurrentSku] = useState('');
    const [currentPrices, setCurrentPrices] = useState([]);
    console.log('Unused Var: ', { currentPrices });
    const [mainPrice, setMainPrice] = useState(0);
    const [selectedTab, setSelectedTab] = useState(
        cardContext.section === 'hospital' ? 0 : 1,
    );

    useEffect(() => {
        if (currentSku === '') {
            setCurrentSku(cardContext.product.originalSku);
        }
    }, [currentSku]);

    let prodIsSuggested = false;
    let foundProdInSuggested = false;
    if (cardContext.section === 'hospital') {
        foundProdInSuggested = changeCoverStore.suggested.hospital.find(
            (prod) => prod === cardContext.product.originalSku,
        );
        if (
            foundProdInSuggested &&
            changeCoverStore.suggested.hospital.indexOf(
                cardContext.product.originalSku,
            ) === 0
        ) {
            prodIsSuggested = true;
        }
    }
    if (cardContext.section === 'extras') {
        foundProdInSuggested = changeCoverStore.suggested.extras.find(
            (prod) => prod === cardContext.product.originalSku,
        );
        if (
            foundProdInSuggested &&
            changeCoverStore.suggested.extras.indexOf(
                cardContext.product.originalSku,
            ) === 0
        ) {
            prodIsSuggested = true;
        }
    }

    // let currentPrices = [];
    // let mainPrice = 0;
    useEffect(() => {
        if (cardContext?.pricing) {
            console.log('PRICING: ', cardContext.pricing);
            if (
                typeof cardContext.pricing[currentSku] !== 'undefined' &&
                cardContext.product.productType !== 'bundle'
            ) {
                let currentPrice = cardContext.pricing[currentSku];
                switch (changeCoverStore.paymentFrequency) {
                    case 'fortnightPeriod':
                        setMainPrice(currentPrice['f']);
                        break;
                    case 'monthPeriod':
                        setMainPrice(currentPrice['m']);
                        break;
                    case 'quarterPeriod':
                        setMainPrice(currentPrice['q']);
                        break;
                    case 'halfYearPeriod':
                        setMainPrice(currentPrice['h']);
                        break;
                }
                setCurrentPrices(currentPrice);
            } else if (
                typeof cardContext.bundlePricing[currentSku] !== 'undefined' &&
                cardContext.product.productType === 'bundle'
            ) {
                let currentPrice = cardContext.bundlePricing[currentSku];
                switch (changeCoverStore.paymentFrequency) {
                    case 'fortnightPeriod':
                        setMainPrice(currentPrice['f']);
                        break;
                    case 'monthPeriod':
                        setMainPrice(currentPrice['m']);
                        break;
                    case 'quarterPeriod':
                        setMainPrice(currentPrice['q']);
                        break;
                    case 'halfYearPeriod':
                        setMainPrice(currentPrice['h']);
                        break;
                }
                setCurrentPrices(currentPrice);
            }
        }
    }, [cardContext, currentSku]);

    // console.log(cardContext);
    switch (cardContext.product.productType) {
        case 'hospital':
            return (
                <div
                    className={`offer hospital-cover ${
                        prodIsSuggested ? 'recommended' : ''
                    }`}>
                    <span
                        className="recommended-tab"
                        style={{
                            display: prodIsSuggested ? 'block' : 'none',
                        }}>
                        RECOMMENDED
                    </span>
                    <span className="selected-tab d-none">
                        <i className="icon icon-check"></i> SELECTED
                    </span>
                    <div className="inner">
                        <div className="innerpadding pleft20 pright20">
                            <div className="matchingHeight">
                                <div className="discount">
                                    <div className="agediscount text-center font-brandon font-white font-bold font-11 border-radius-bottom d-none">
                                        <span>AGE BASED DISCOUNT APPLIED</span>
                                    </div>
                                    {cardContext.product.only_with_extras ? (
                                        <div className="forceextras text-center font-brandon font-white font-bold font-11 border-radius-bottom">
                                            <span>
                                                ONLY AVAILABLE WITH EXTRAS COVER
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="row m-0">
                                    <div className="col-xs-6 col-md-12 mobile-left">
                                        <div className="text-center font-brandon font-grey font-medium text-uppercase type">
                                            Hospital cover
                                        </div>
                                        <div className="text-center font-brandon font-cyan font-uppercase font-bold title">
                                            {cardContext?.product?.name}
                                        </div>
                                        <div className="text-center excess-holder">
                                            <span className="font-grey font-brandon font-medium font-uppercase">
                                                EXCESS:{' '}
                                            </span>
                                            <span className="font-brandon font-bold font-uppercase excess">
                                                <FindExcess
                                                    currentSku={currentSku}
                                                    setCurrentSku={
                                                        setCurrentSku
                                                    }
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-xs-6 col-md-12 mobile-right">
                                        <div className="calc_package-price font-bold font-brandon font-34 font-darkblue text-center mtop20">
                                            ${mainPrice}*
                                        </div>
                                        <div className="calc_package-payment-frequency text-center">
                                            <span>
                                                <select
                                                    value={
                                                        changeCoverStore.paymentFrequency
                                                    }
                                                    onChange={(e) => {
                                                        dispatch(
                                                            changeCoverActions.paymentFrequency(
                                                                e.target.value,
                                                            ),
                                                        );
                                                    }}>
                                                    <option value="fortnightPeriod">
                                                        Per Fortnight
                                                    </option>
                                                    <option value="monthPeriod">
                                                        Per Month
                                                    </option>
                                                    <option value="quarterPeriod">
                                                        Per Quarter
                                                    </option>
                                                    <option value="halfYearPeriod">
                                                        Per Half Year
                                                    </option>
                                                </select>
                                                <span className="dropArrow">
                                                    <BiChevronDown
                                                        style={{
                                                            verticalAlign:
                                                                'middle',
                                                        }}
                                                    />
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rounded-shadow mtop10">
                                <Shadow />
                            </div>
                            <div className="buttons">
                                <div className="row m-0">
                                    <div className="col-xs-6 col-md-6 pl-0 pr-1 text-center">
                                        <a
                                            href={cardContext.product.link}
                                            className="btn btn--light-grey"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <IoMdInformationCircleOutline
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                            />{' '}
                                            See Details
                                        </a>
                                    </div>
                                    <div className="col-xs-6 col-md-6 pl-1 pr-0 text-center">
                                        <a
                                            href="#"
                                            className="btn btn-select"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (
                                                    cardContext?.product
                                                        ?.only_with_extras
                                                ) {
                                                    dispatch(
                                                        changeCoverActions.hospitalMustChooseExtras(
                                                            true,
                                                        ),
                                                    );
                                                }
                                                dispatch(
                                                    changeCoverActions.selected.hospital(
                                                        currentSku,
                                                    ),
                                                );
                                                dispatch(
                                                    changeCoverActions.ui.showDetails(
                                                        false,
                                                    ),
                                                );
                                                dispatch(
                                                    changeCoverActions.selectedProducts.hospital(
                                                        cardContext.product,
                                                    ),
                                                );
                                            }}>
                                            <span>Select</span>{' '}
                                            <FaCheck
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bgcolor-darkblue pleft30 pright30 ptop10 pbottom10">
                            <div className="font-white font-bold font-16 d-inline-block pull-left">
                                Hospital cover
                            </div>
                        </div>
                        <div className="ptop10 pbottom40 cover-items">
                            {cardContext.product.featured.clinical_categories.map(
                                (item, idx) => {
                                    if (item.status === 'restricted') {
                                        return (
                                            <div className="item" key={idx}>
                                                <i className="icon-restricted">
                                                    R
                                                </i>{' '}
                                                {item.name}
                                            </div>
                                        );
                                    }
                                    if (item.status === 'excluded') {
                                        return (
                                            <div
                                                className="item excluded"
                                                key={idx}>
                                                <i className="icon-excluded">
                                                    <IoMdClose
                                                        style={{
                                                            verticalAlign:
                                                                'middle',
                                                        }}
                                                    />
                                                </i>{' '}
                                                {item.name}
                                            </div>
                                        );
                                    }
                                    if (item.status === 'included') {
                                        return (
                                            <div className="item" key={idx}>
                                                <i className="icon-check">
                                                    <BiCheck
                                                        style={{
                                                            verticalAlign:
                                                                'middle',
                                                        }}
                                                    />
                                                </i>{' '}
                                                {item.name}
                                            </div>
                                        );
                                    }
                                },
                            )}
                            <div
                                className=""
                                style={{
                                    display: changeCoverStore.showDetails
                                        ? 'block'
                                        : 'none',
                                }}>
                                {cardContext.product.clinical_categories.map(
                                    (item, idx) => {
                                        if (item.status === 'restricted') {
                                            return (
                                                <div className="item" key={idx}>
                                                    <i className="icon-restricted">
                                                        R
                                                    </i>{' '}
                                                    {item.name}
                                                </div>
                                            );
                                        }
                                        if (item.status === 'excluded') {
                                            return (
                                                <div
                                                    className="item excluded"
                                                    key={idx}>
                                                    <i className="icon-excluded">
                                                        <IoMdClose
                                                            style={{
                                                                verticalAlign:
                                                                    'middle',
                                                            }}
                                                        />
                                                    </i>{' '}
                                                    {item.name}
                                                </div>
                                            );
                                        }
                                        if (item.status === 'included') {
                                            return (
                                                <div className="item" key={idx}>
                                                    <i className="icon-check">
                                                        <BiCheck
                                                            style={{
                                                                verticalAlign:
                                                                    'middle',
                                                            }}
                                                        />
                                                    </i>{' '}
                                                    {item.name}
                                                </div>
                                            );
                                        }
                                    },
                                )}
                            </div>
                            <div className="collapse-btns text-center">
                                <button
                                    className="btn btn-details"
                                    type="button"
                                    onClick={() => {
                                        dispatch(
                                            changeCoverActions.ui.showDetails(
                                                !changeCoverStore.showDetails,
                                            ),
                                        );
                                    }}>
                                    {!changeCoverStore.showDetails ? (
                                        <>
                                            Expand Details{' '}
                                            <BiChevronDown
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            Hide Details{' '}
                                            <BiChevronUp
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                            />
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 'extras':
            return (
                <div
                    className={`offer extras-cover ${
                        prodIsSuggested ? 'recommended' : ''
                    }`}>
                    <span
                        className="recommended-tab"
                        style={{
                            display: prodIsSuggested ? 'block' : 'none',
                        }}>
                        RECOMMENDED
                    </span>
                    {/*<span className="selected-tab"><i className="icon icon-check"></i> SELECTED</span>*/}
                    <div className="inner">
                        <div className="innerpadding pleft20 pright20">
                            <div className="matchingHeight">
                                <div className="discount"></div>
                                <div className="row m-0">
                                    <div className="col-xs-6 col-md-12 mobile-left">
                                        <div className="text-center font-brandon font-grey font-medium font-uppercase type">
                                            Extras cover
                                        </div>
                                        <div className="text-center font-brandon font-cyan font-uppercase font-bold title">
                                            {cardContext?.product?.name}
                                        </div>
                                    </div>
                                    <div className="col-xs-6 col-md-12 mobile-right">
                                        <div className="calc_package-price font-bold font-brandon font-34 font-darkblue text-center mtop20">
                                            ${mainPrice}*
                                        </div>
                                        <div className="calc_package-payment-frequency text-center">
                                            <span>
                                                <select
                                                    value={
                                                        changeCoverStore.paymentFrequency
                                                    }
                                                    onChange={(e) => {
                                                        dispatch(
                                                            changeCoverActions.paymentFrequency(
                                                                e.target.value,
                                                            ),
                                                        );
                                                    }}>
                                                    <option value="fortnightPeriod">
                                                        Per Fortnight
                                                    </option>
                                                    <option value="monthPeriod">
                                                        Per Month
                                                    </option>
                                                    <option value="quarterPeriod">
                                                        Per Quarter
                                                    </option>
                                                    <option value="halfYearPeriod">
                                                        Per Half Year
                                                    </option>
                                                </select>
                                                <span className="dropArrow">
                                                    <BiChevronDown
                                                        style={{
                                                            verticalAlign:
                                                                'middle',
                                                        }}
                                                    />
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rounded-shadow mtop10">
                                <Shadow />
                            </div>
                            <div className="buttons">
                                <div className="row m-0">
                                    <div className="col-xs-6 col-md-6 pl-0 pr-1 text-center">
                                        <a
                                            href={cardContext.product.link}
                                            className="btn btn--light-grey"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <IoMdInformationCircleOutline
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                            />{' '}
                                            See Details
                                        </a>
                                    </div>
                                    <div className="col-xs-6 col-md-6 pl-1 pr-0 text-center">
                                        <a
                                            href="#"
                                            className="btn btn-select"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                dispatch(
                                                    changeCoverActions.selected.extras(
                                                        currentSku,
                                                    ),
                                                );
                                                dispatch(
                                                    changeCoverActions.ui.showDetails(
                                                        false,
                                                    ),
                                                );
                                                dispatch(
                                                    changeCoverActions.selectedProducts.extras(
                                                        cardContext.product,
                                                    ),
                                                );
                                            }}>
                                            <span>Select</span>{' '}
                                            <FaCheck
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bgcolor-darkblue d-flex justify-content-between">
                            <div className="font-white font-bold font-16 d-inline-block pull-left">
                                Extras cover
                            </div>
                            <div className="font-cyan font-lato font-16 d-inline-block pull-right">
                                annual limit
                            </div>
                        </div>
                        <div className="cover-items">
                            <div
                                className="product-highlights"
                                style={{
                                    display: !changeCoverStore.showDetails
                                        ? 'block'
                                        : 'none',
                                }}>
                                {cardContext.product.featured.benefit_limits.map(
                                    (item, idx) => {
                                        if (item.status === 'restricted') {
                                            return (
                                                <div
                                                    className="item extras_cover"
                                                    key={idx}>
                                                    <span className="title">
                                                        <i className="icon-restricted">
                                                            R
                                                        </i>{' '}
                                                        {item.name}
                                                    </span>
                                                    <span className="value">
                                                        {item.value}
                                                    </span>
                                                </div>
                                            );
                                        }
                                        if (item.status === 'excluded') {
                                            return (
                                                <div
                                                    className="item excluded extras_cover"
                                                    key={idx}>
                                                    <span className="title">
                                                        <i className="icon-excluded">
                                                            <IoMdClose
                                                                style={{
                                                                    verticalAlign:
                                                                        'middle',
                                                                }}
                                                            />
                                                        </i>{' '}
                                                        {item.name}
                                                    </span>
                                                    <span className="value">
                                                        {item.value}
                                                    </span>
                                                </div>
                                            );
                                        }
                                        if (item.status === 'included') {
                                            return (
                                                <div
                                                    className="item extras_cover"
                                                    key={idx}>
                                                    <span className="title">
                                                        <i className="icon-check">
                                                            <BiCheck
                                                                style={{
                                                                    verticalAlign:
                                                                        'middle',
                                                                }}
                                                            />
                                                        </i>{' '}
                                                        {item.name}
                                                    </span>
                                                    <span className="value">
                                                        {item.value}
                                                    </span>
                                                </div>
                                            );
                                        }
                                    },
                                )}
                            </div>
                            <div
                                className="collapse"
                                style={{
                                    display: changeCoverStore.showDetails
                                        ? 'block'
                                        : 'none',
                                }}>
                                <div className="item parent extras_cover">
                                    <span>
                                        <i className=""></i> Dental
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.limit
                                        }
                                    </span>
                                </div>
                                <div
                                    className="item secondlevel extras_cover"
                                    data-top="D"
                                    data-limit="G">
                                    <span>
                                        <i className=""></i> General dental
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['General dental']
                                                ?.limit
                                        }
                                    </span>
                                </div>
                                <div
                                    className={`item thirdlevel extras_cover  ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Dental?.['General dental']?.[
                                            'Preventive dental'
                                        ],
                                    )}`}
                                    data-top="D"
                                    data-limit="G">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['General dental']?.[
                                                'Preventive dental'
                                            ]?.status,
                                        )}{' '}
                                        Preventive dental
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['General dental']?.[
                                                'Preventive dental'
                                            ]?.limit
                                        }
                                    </span>
                                </div>
                                <div
                                    className={`item thirdlevel extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Dental?.['General dental']?.[
                                            'General dental'
                                        ],
                                    )}`}
                                    data-top="D"
                                    data-limit="G">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['General dental']?.[
                                                'General dental'
                                            ]?.status,
                                        )}{' '}
                                        General dental
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['General dental']?.[
                                                'General dental'
                                            ]?.value
                                        }
                                    </span>
                                </div>
                                <div
                                    className={`item secondlevel last-group extras_cover`}
                                    data-top="D"
                                    data-limit="M">
                                    <span>
                                        <i className=""></i> Major dental
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['Major dental']
                                                ?.limit
                                        }
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Dental?.['Major dental']?.[
                                            'Crowns and bridges'
                                        ],
                                    )}`}
                                    data-top="D"
                                    data-limit="M">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['Major dental']?.[
                                                'Crowns and bridges'
                                            ]?.status,
                                        )}{' '}
                                        Crowns and bridges
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['Major dental']?.[
                                                'Crowns and bridges'
                                            ]?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits?.Dental?.[
                                                    'Major dental'
                                                ]?.['Crowns and bridges']
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Dental?.['Major dental']?.[
                                            'Dental implants'
                                        ],
                                    )}`}
                                    data-top="D"
                                    data-limit="M">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['Major dental']?.[
                                                'Dental implants'
                                            ]?.status,
                                        )}{' '}
                                        Dental implants
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['Major dental']?.[
                                                'Dental implants'
                                            ]?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits?.Dental?.[
                                                    'Major dental'
                                                ]?.['Dental implants']
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Dental?.['Major dental']?.[
                                            'Dentures'
                                        ],
                                    )}`}
                                    data-top="D"
                                    data-limit="M">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['Major dental']?.[
                                                'Dentures'
                                            ]?.status,
                                        )}{' '}
                                        Dentures
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['Major dental']?.[
                                                'Dentures'
                                            ]?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits?.Dental?.[
                                                    'Major dental'
                                                ]?.['Dentures']
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Dental?.['Major dental']?.[
                                            'Endodontia'
                                        ],
                                    )}`}
                                    data-top="D"
                                    data-limit="M">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['Major dental']?.[
                                                'Endodontia'
                                            ]?.status,
                                        )}{' '}
                                        Endodontia
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['Major dental']?.[
                                                'Endodontia'
                                            ]?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits?.Dental?.[
                                                    'Major dental'
                                                ]?.['Endodontia']
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Dental?.['Major dental']?.[
                                            'Periodontia'
                                        ],
                                    )}`}
                                    data-top="D"
                                    data-limit="M">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['Major dental']?.[
                                                'Periodontia'
                                            ]?.status,
                                        )}{' '}
                                        Periodontia
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['Major dental']?.[
                                                'Periodontia'
                                            ]?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits?.Dental?.[
                                                    'Major dental'
                                                ]?.['Periodontia']
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Dental?.['Major dental']?.[
                                            'Inlays, onlays, facings'
                                        ],
                                    )}`}
                                    data-top="D"
                                    data-limit="M">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['Major dental']?.[
                                                'Inlays, onlays, facings'
                                            ]?.status,
                                        )}{' '}
                                        Inlays, onlays, facings
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['Major dental']?.[
                                                'Inlays, onlays, facings'
                                            ]?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits?.Dental?.[
                                                    'Major dental'
                                                ]?.['Inlays, onlays, facings']
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Dental?.['Major dental']?.[
                                            'Orthodontia'
                                        ],
                                    )}`}
                                    data-top="D"
                                    data-limit="M">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['Major dental']?.[
                                                'Orthodontia'
                                            ]?.status,
                                        )}{' '}
                                        Orthodontia
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Dental?.['Major dental']?.[
                                                'Orthodontia'
                                            ]?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits?.Dental?.[
                                                    'Major dental'
                                                ]?.['Orthodontia']
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item parent extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Optical,
                                    )}`}
                                    data-top="O"
                                    data-limit="">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Optical?.status,
                                        )}{' '}
                                        Optical
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Optical?.limit
                                        }
                                    </span>
                                </div>
                                <div
                                    className={`item parent extras_cover`}
                                    data-top="T"
                                    data-limit="">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.status,
                                        )}{' '}
                                        Therapies
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Therapies?.['Physiotherapy'],
                                    )}`}
                                    data-top="T"
                                    data-limit="physiotherapy">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.['Physiotherapy']
                                                ?.status,
                                        )}{' '}
                                        Physiotherapy
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.['Physiotherapy']
                                                ?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits
                                                    ?.Therapies?.[
                                                    'Physiotherapy'
                                                ]
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Therapies?.['Acupuncture'],
                                    )}`}
                                    data-top="T"
                                    data-limit="acupuncture">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.['Acupuncture']
                                                ?.status,
                                        )}{' '}
                                        Acupuncture
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.['Acupuncture']
                                                ?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits
                                                    ?.Therapies?.['Acupuncture']
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Therapies?.[
                                            'Massage and myotherapy'
                                        ],
                                    )}`}
                                    data-top="T"
                                    data-limit="natural_therapies">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.[
                                                'Massage and myotherapy'
                                            ]?.status,
                                        )}{' '}
                                        Massage and myotherapy
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.[
                                                'Massage and myotherapy'
                                            ]?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits
                                                    ?.Therapies?.[
                                                    'Massage and myotherapy'
                                                ]
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Therapies?.['Osteopathy'],
                                    )}`}
                                    data-top="T"
                                    data-limit="osteopathy">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.['Osteopathy']
                                                ?.status,
                                        )}{' '}
                                        Osteopathy
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.['Osteopathy']
                                                ?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits
                                                    ?.Therapies?.['Osteopathy']
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Therapies?.['Chiropractic'],
                                    )}`}
                                    data-top="T"
                                    data-limit="chiropractic">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.['Chiropractic']
                                                ?.status,
                                        )}{' '}
                                        Chiropractic
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.['Chiropractic']
                                                ?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits
                                                    ?.Therapies?.[
                                                    'Chiropractic'
                                                ]
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Therapies?.[
                                            'Psychology / hypnotherapy'
                                        ],
                                    )}`}
                                    data-top="T"
                                    data-limit="psychology">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.[
                                                'Psychology / hypnotherapy'
                                            ]?.status,
                                        )}{' '}
                                        Psychology / hypnotherapy
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.[
                                                'Psychology / hypnotherapy'
                                            ]?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits
                                                    ?.Therapies?.[
                                                    'Psychology / hypnotherapy'
                                                ]
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Therapies?.['Podiatry'],
                                    )}`}
                                    data-top="T"
                                    data-limit="podiatry">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.['Podiatry']
                                                ?.status,
                                        )}{' '}
                                        Podiatry
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.['Podiatry']?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits
                                                    ?.Therapies?.['Podiatry']
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Therapies?.['Orthotics'],
                                    )}`}
                                    data-top="T"
                                    data-limit="orthotics">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.['Orthotics']
                                                ?.status,
                                        )}{' '}
                                        Orthotics
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.['Orthotics']
                                                ?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits
                                                    ?.Therapies?.['Orthotics']
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Therapies?.['Dietetics'],
                                    )}`}
                                    data-top="T"
                                    data-limit="dietetics">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.['Dietetics']
                                                ?.status,
                                        )}{' '}
                                        Dietetics
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.['Dietetics']
                                                ?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits
                                                    ?.Therapies?.['Dietetics']
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Therapies?.['Speech therapy'],
                                    )}`}
                                    data-top="T"
                                    data-limit="speech_therapy">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.['Speech therapy']
                                                ?.status,
                                        )}{' '}
                                        Speech therapy
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.['Speech therapy']
                                                ?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits
                                                    ?.Therapies?.[
                                                    'Speech therapy'
                                                ]
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Therapies?.[
                                            'Occupational therapy'
                                        ],
                                    )}`}
                                    data-top="T"
                                    data-limit="occupational_therapy">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.[
                                                'Occupational therapy'
                                            ]?.status,
                                        )}{' '}
                                        Occupational therapy
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Therapies?.[
                                                'Occupational therapy'
                                            ]?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits
                                                    ?.Therapies?.[
                                                    'Occupational therapy'
                                                ]
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item parent extras_cover`}
                                    data-top="H"
                                    data-limit="">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Other?.status,
                                        )}{' '}
                                        Other
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Other?.['Health management'],
                                    )}`}
                                    data-top="H"
                                    data-limit="health_wellness">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Other?.['Health management']
                                                ?.status,
                                        )}{' '}
                                        Health management
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Other?.['Health management']
                                                ?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits?.Other?.[
                                                    'Health management'
                                                ]
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Other?.['Pharmaceuticals'],
                                    )}`}
                                    data-top="H"
                                    data-limit="pharmacy">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Other?.['Pharmaceuticals']
                                                ?.status,
                                        )}{' '}
                                        Pharmaceuticals
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Other?.['Pharmaceuticals']
                                                ?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits?.Other?.[
                                                    'Pharmaceuticals'
                                                ]
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Other?.[
                                            'Mechanical / health appliances'
                                        ],
                                    )}`}
                                    data-top="H"
                                    data-limit="mechanical_health_appliances">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Other?.[
                                                'Mechanical / health appliances'
                                            ]?.status,
                                        )}{' '}
                                        Mechanical / health appliances
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Other?.[
                                                'Mechanical / health appliances'
                                            ]?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits?.Other?.[
                                                    'Mechanical / health appliances'
                                                ]
                                            }
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`item extras_cover ${CSSClasses(
                                        cardContext?.product?.benefit_limits
                                            ?.Other?.['Hearing aids'],
                                    )}`}
                                    data-top="H"
                                    data-limit="hearing_aids">
                                    <span>
                                        {IconChooser(
                                            cardContext?.product?.benefit_limits
                                                ?.Other?.['Hearing aids']
                                                ?.status,
                                        )}{' '}
                                        Hearing aids
                                    </span>
                                    <span className="right-side">
                                        {
                                            cardContext?.product?.benefit_limits
                                                ?.Other?.['Hearing aids']?.value
                                        }{' '}
                                        <ModalIcon
                                            value={
                                                cardContext?.product
                                                    ?.benefit_limits?.Other?.[
                                                    'Hearing aids'
                                                ]
                                            }
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="collapse-btns text-center">
                                <button
                                    className="btn btn-details"
                                    type="button"
                                    onClick={() => {
                                        dispatch(
                                            changeCoverActions.ui.showDetails(
                                                !changeCoverStore.showDetails,
                                            ),
                                        );
                                    }}>
                                    {!changeCoverStore.showDetails ? (
                                        <>
                                            Expand Details{' '}
                                            <BiChevronDown
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            Hide Details{' '}
                                            <BiChevronUp
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                            />
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 'bundle':
            return (
                <div
                    className={`offer packages_cover ${
                        prodIsSuggested ? 'recommended' : ''
                    }`}>
                    <span
                        className="recommended-tab"
                        style={{
                            display: prodIsSuggested ? 'block' : 'none',
                        }}>
                        RECOMMENDED
                    </span>
                    <span className="selected-tab d-none">
                        <i className="icon icon-check"></i> SELECTED
                    </span>
                    <div className="inner">
                        <div className="innerpadding pleft20 pright20">
                            <div className="matchingHeight">
                                <div className="discount">
                                    <div className="agediscount text-center font-brandon font-white font-bold font-11 border-radius-bottom d-none">
                                        <span>AGE BASED DISCOUNT APPLIED</span>
                                    </div>
                                    {cardContext.product.only_with_extras ? (
                                        <div className="forceextras text-center font-brandon font-white font-bold font-11 border-radius-bottom">
                                            <span>
                                                ONLY AVAILABLE WITH EXTRAS COVER
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="row m-0">
                                    <div className="col-xs-6 col-md-12 mobile-left">
                                        <div className="text-center font-brandon font-grey font-medium text-uppercase type">
                                            Hospital + extras cover
                                        </div>
                                        <div className="text-center font-brandon font-cyan font-uppercase font-bold title">
                                            {cardContext?.product?.name}
                                        </div>
                                        <div className="text-center excess-holder">
                                            <span className="font-grey font-brandon font-medium font-uppercase">
                                                EXCESS:{' '}
                                            </span>
                                            <span className="font-brandon font-bold font-uppercase excess">
                                                <FindExcess
                                                    currentSku={currentSku}
                                                    setCurrentSku={
                                                        setCurrentSku
                                                    }
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-xs-6 col-md-12 mobile-right">
                                        <div className="calc_package-price font-bold font-brandon font-34 font-darkblue text-center mtop20">
                                            ${mainPrice}*
                                        </div>
                                        <div className="calc_package-payment-frequency text-center">
                                            <span>
                                                <select
                                                    value={
                                                        changeCoverStore.paymentFrequency
                                                    }
                                                    onChange={(e) => {
                                                        dispatch(
                                                            changeCoverActions.paymentFrequency(
                                                                e.target.value,
                                                            ),
                                                        );
                                                    }}>
                                                    <option value="fortnightPeriod">
                                                        Per Fortnight
                                                    </option>
                                                    <option value="monthPeriod">
                                                        Per Month
                                                    </option>
                                                    <option value="quarterPeriod">
                                                        Per Quarter
                                                    </option>
                                                    <option value="halfYearPeriod">
                                                        Per Half Year
                                                    </option>
                                                </select>
                                                <span className="dropArrow">
                                                    <BiChevronDown
                                                        style={{
                                                            verticalAlign:
                                                                'middle',
                                                        }}
                                                    />
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rounded-shadow mtop10">
                                <Shadow />
                            </div>
                            <div className="buttons">
                                <div className="row m-0">
                                    <div className="col-xs-6 col-md-6 p0 pright10 text-center">
                                        <a
                                            href={cardContext.product.link}
                                            className="btn btn--light-grey"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <IoMdInformationCircleOutline
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                            />{' '}
                                            See Details
                                        </a>
                                    </div>
                                    <div className="col-xs-6 col-md-6 p0 pleft10 text-center">
                                        <a
                                            href="#"
                                            className="btn btn-select"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                dispatch(
                                                    changeCoverActions.selected.hospital(
                                                        currentSku,
                                                    ),
                                                );
                                                dispatch(
                                                    changeCoverActions.selected.extras(
                                                        currentSku,
                                                    ),
                                                );
                                                dispatch(
                                                    changeCoverActions.selected.bundle(
                                                        currentSku,
                                                    ),
                                                );
                                                dispatch(
                                                    changeCoverActions.ui.showDetails(
                                                        false,
                                                    ),
                                                );
                                                dispatch(
                                                    changeCoverActions.selectedProducts.bundle(
                                                        cardContext.product,
                                                    ),
                                                );
                                            }}>
                                            <span>Select</span>{' '}
                                            <FaCheck
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tabs">
                            <ul className="nav nav-tabs" role="tablist">
                                <li
                                    role="presentation"
                                    className={`${
                                        selectedTab === 0 ? 'active' : ''
                                    }`}>
                                    <a
                                        href="#"
                                        aria-controls="hospital"
                                        className="font-gotham font-14 font-bold"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedTab(0);
                                        }}>
                                        <span></span> Hospital cover
                                    </a>
                                </li>
                                <li
                                    role="presentation"
                                    className={`${
                                        selectedTab === 1 ? 'active' : ''
                                    }`}>
                                    <a
                                        href="#extras"
                                        aria-controls="extras"
                                        className="font-gotham font-14 font-bold"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setSelectedTab(1);
                                        }}>
                                        <span></span> Extras cover
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div
                                    className={`tab-pane ${
                                        selectedTab === 0 ? 'active' : ''
                                    }`}
                                    role="tabpanel">
                                    <div className="cover-items">
                                        {cardContext.product.featured.clinical_categories.map(
                                            (item, idx) => {
                                                if (
                                                    item.status === 'restricted'
                                                ) {
                                                    return (
                                                        <div
                                                            className="item"
                                                            key={idx}>
                                                            <i className="icon-restricted">
                                                                R
                                                            </i>{' '}
                                                            {item.name}
                                                        </div>
                                                    );
                                                }
                                                if (
                                                    item.status === 'excluded'
                                                ) {
                                                    return (
                                                        <div
                                                            className="item excluded"
                                                            key={idx}>
                                                            <i className="icon-excluded">
                                                                <IoMdClose
                                                                    style={{
                                                                        verticalAlign:
                                                                            'middle',
                                                                    }}
                                                                />
                                                            </i>{' '}
                                                            {item.name}
                                                        </div>
                                                    );
                                                }
                                                if (
                                                    item.status === 'included'
                                                ) {
                                                    return (
                                                        <div
                                                            className="item"
                                                            key={idx}>
                                                            <i className="icon-check">
                                                                <BiCheck
                                                                    style={{
                                                                        verticalAlign:
                                                                            'middle',
                                                                    }}
                                                                />
                                                            </i>{' '}
                                                            {item.name}
                                                        </div>
                                                    );
                                                }
                                            },
                                        )}
                                        <div
                                            className=""
                                            style={{
                                                display:
                                                    changeCoverStore.showDetails
                                                        ? 'block'
                                                        : 'none',
                                            }}>
                                            {cardContext.product.clinical_categories.map(
                                                (item, idx) => {
                                                    if (
                                                        item.status ===
                                                        'restricted'
                                                    ) {
                                                        return (
                                                            <div
                                                                className="item"
                                                                key={idx}>
                                                                <i className="icon-restricted">
                                                                    R
                                                                </i>{' '}
                                                                {item.name}
                                                            </div>
                                                        );
                                                    }
                                                    if (
                                                        item.status ===
                                                        'excluded'
                                                    ) {
                                                        return (
                                                            <div
                                                                className="item excluded"
                                                                key={idx}>
                                                                <i className="icon-excluded">
                                                                    <IoMdClose
                                                                        style={{
                                                                            verticalAlign:
                                                                                'middle',
                                                                        }}
                                                                    />
                                                                </i>{' '}
                                                                {item.name}
                                                            </div>
                                                        );
                                                    }
                                                    if (
                                                        item.status ===
                                                        'included'
                                                    ) {
                                                        return (
                                                            <div
                                                                className="item"
                                                                key={idx}>
                                                                <i className="icon-check">
                                                                    <BiCheck
                                                                        style={{
                                                                            verticalAlign:
                                                                                'middle',
                                                                        }}
                                                                    />
                                                                </i>{' '}
                                                                {item.name}
                                                            </div>
                                                        );
                                                    }
                                                },
                                            )}
                                        </div>
                                        <div className="collapse-btns text-center">
                                            <button
                                                className="btn btn-details"
                                                type="button"
                                                onClick={() => {
                                                    dispatch(
                                                        changeCoverActions.ui.showDetails(
                                                            !changeCoverStore.showDetails,
                                                        ),
                                                    );
                                                }}>
                                                {!changeCoverStore.showDetails ? (
                                                    <>
                                                        Expand Details{' '}
                                                        <BiChevronDown
                                                            style={{
                                                                verticalAlign:
                                                                    'middle',
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        Hide Details{' '}
                                                        <BiChevronUp
                                                            style={{
                                                                verticalAlign:
                                                                    'middle',
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`tab-pane ${
                                        selectedTab === 1 ? 'active' : ''
                                    }`}
                                    role="tabpanel">
                                    <div className="cover-items">
                                        <div
                                            className="product-highlights"
                                            style={{
                                                display:
                                                    !changeCoverStore.showDetails
                                                        ? 'block'
                                                        : 'none',
                                            }}>
                                            {cardContext.product.featured.benefit_limits.map(
                                                (item, idx) => {
                                                    if (
                                                        item.status ===
                                                        'restricted'
                                                    ) {
                                                        return (
                                                            <div
                                                                className="item extras_cover"
                                                                key={idx}>
                                                                <span className="title">
                                                                    <i className="icon-restricted">
                                                                        R
                                                                    </i>{' '}
                                                                    {item.name}
                                                                </span>
                                                                <span className="value">
                                                                    {item.value}
                                                                </span>
                                                            </div>
                                                        );
                                                    }
                                                    if (
                                                        item.status ===
                                                        'excluded'
                                                    ) {
                                                        return (
                                                            <div
                                                                className="item excluded extras_cover"
                                                                key={idx}>
                                                                <span className="title">
                                                                    <i className="icon-excluded">
                                                                        <IoMdClose
                                                                            style={{
                                                                                verticalAlign:
                                                                                    'middle',
                                                                            }}
                                                                        />
                                                                    </i>{' '}
                                                                    {item.name}
                                                                </span>
                                                                <span className="value">
                                                                    {item.value}
                                                                </span>
                                                            </div>
                                                        );
                                                    }
                                                    if (
                                                        item.status ===
                                                        'included'
                                                    ) {
                                                        return (
                                                            <div
                                                                className="item extras_cover"
                                                                key={idx}>
                                                                <span className="title">
                                                                    <i className="icon-check">
                                                                        <BiCheck
                                                                            style={{
                                                                                verticalAlign:
                                                                                    'middle',
                                                                            }}
                                                                        />
                                                                    </i>{' '}
                                                                    {item.name}
                                                                </span>
                                                                <span className="value">
                                                                    {item.value}
                                                                </span>
                                                            </div>
                                                        );
                                                    }
                                                },
                                            )}
                                        </div>
                                        <div
                                            className="collapse"
                                            style={{
                                                display:
                                                    changeCoverStore.showDetails
                                                        ? 'block'
                                                        : 'none',
                                            }}>
                                            <div className="item parent extras_cover">
                                                <span>
                                                    <i className=""></i> Dental
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.limit
                                                    }
                                                </span>
                                            </div>
                                            <div
                                                className="item secondlevel extras_cover"
                                                data-top="D"
                                                data-limit="G">
                                                <span>
                                                    <i className=""></i> General
                                                    dental
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'General dental'
                                                        ]?.limit
                                                    }
                                                </span>
                                            </div>
                                            <div
                                                className={`item thirdlevel extras_cover  ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Dental?.[
                                                        'General dental'
                                                    ]?.['Preventive dental'],
                                                )}`}
                                                data-top="D"
                                                data-limit="G">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'General dental'
                                                        ]?.['Preventive dental']
                                                            ?.status,
                                                    )}{' '}
                                                    Preventive dental
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'General dental'
                                                        ]?.['Preventive dental']
                                                            ?.limit
                                                    }
                                                </span>
                                            </div>
                                            <div
                                                className={`item thirdlevel extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Dental?.[
                                                        'General dental'
                                                    ]?.['General dental'],
                                                )}`}
                                                data-top="D"
                                                data-limit="G">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'General dental'
                                                        ]?.['General dental']
                                                            ?.status,
                                                    )}{' '}
                                                    General dental
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'General dental'
                                                        ]?.['General dental']
                                                            ?.value
                                                    }
                                                </span>
                                            </div>
                                            <div
                                                className={`item secondlevel last-group extras_cover`}
                                                data-top="D"
                                                data-limit="M">
                                                <span>
                                                    <i className=""></i> Major
                                                    dental
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'Major dental'
                                                        ]?.limit
                                                    }
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Dental?.[
                                                        'Major dental'
                                                    ]?.['Crowns and bridges'],
                                                )}`}
                                                data-top="D"
                                                data-limit="M">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'Major dental'
                                                        ]?.[
                                                            'Crowns and bridges'
                                                        ]?.status,
                                                    )}{' '}
                                                    Crowns and bridges
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'Major dental'
                                                        ]?.[
                                                            'Crowns and bridges'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Dental?.[
                                                                'Major dental'
                                                            ]?.[
                                                                'Crowns and bridges'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Dental?.[
                                                        'Major dental'
                                                    ]?.['Dental implants'],
                                                )}`}
                                                data-top="D"
                                                data-limit="M">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'Major dental'
                                                        ]?.['Dental implants']
                                                            ?.status,
                                                    )}{' '}
                                                    Dental implants
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'Major dental'
                                                        ]?.['Dental implants']
                                                            ?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Dental?.[
                                                                'Major dental'
                                                            ]?.[
                                                                'Dental implants'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Dental?.[
                                                        'Major dental'
                                                    ]?.['Dentures'],
                                                )}`}
                                                data-top="D"
                                                data-limit="M">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'Major dental'
                                                        ]?.['Dentures']?.status,
                                                    )}{' '}
                                                    Dentures
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'Major dental'
                                                        ]?.['Dentures']?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Dental?.[
                                                                'Major dental'
                                                            ]?.['Dentures']
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Dental?.[
                                                        'Major dental'
                                                    ]?.['Endodontia'],
                                                )}`}
                                                data-top="D"
                                                data-limit="M">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'Major dental'
                                                        ]?.['Endodontia']
                                                            ?.status,
                                                    )}{' '}
                                                    Endodontia
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'Major dental'
                                                        ]?.['Endodontia']?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Dental?.[
                                                                'Major dental'
                                                            ]?.['Endodontia']
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Dental?.[
                                                        'Major dental'
                                                    ]?.['Periodontia'],
                                                )}`}
                                                data-top="D"
                                                data-limit="M">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'Major dental'
                                                        ]?.['Periodontia']
                                                            ?.status,
                                                    )}{' '}
                                                    Periodontia
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'Major dental'
                                                        ]?.['Periodontia']
                                                            ?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Dental?.[
                                                                'Major dental'
                                                            ]?.['Periodontia']
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Dental?.[
                                                        'Major dental'
                                                    ]?.[
                                                        'Inlays, onlays, facings'
                                                    ],
                                                )}`}
                                                data-top="D"
                                                data-limit="M">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'Major dental'
                                                        ]?.[
                                                            'Inlays, onlays, facings'
                                                        ]?.status,
                                                    )}{' '}
                                                    Inlays, onlays, facings
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'Major dental'
                                                        ]?.[
                                                            'Inlays, onlays, facings'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Dental?.[
                                                                'Major dental'
                                                            ]?.[
                                                                'Inlays, onlays, facings'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Dental?.[
                                                        'Major dental'
                                                    ]?.['Orthodontia'],
                                                )}`}
                                                data-top="D"
                                                data-limit="M">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'Major dental'
                                                        ]?.['Orthodontia']
                                                            ?.status,
                                                    )}{' '}
                                                    Orthodontia
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Dental?.[
                                                            'Major dental'
                                                        ]?.['Orthodontia']
                                                            ?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Dental?.[
                                                                'Major dental'
                                                            ]?.['Orthodontia']
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item parent extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Optical,
                                                )}`}
                                                data-top="O"
                                                data-limit="">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Optical?.status,
                                                    )}{' '}
                                                    Optical
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Optical?.limit
                                                    }
                                                </span>
                                            </div>
                                            <div
                                                className={`item parent extras_cover`}
                                                data-top="T"
                                                data-limit="">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.status,
                                                    )}{' '}
                                                    Therapies
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Therapies?.[
                                                        'Physiotherapy'
                                                    ],
                                                )}`}
                                                data-top="T"
                                                data-limit="physiotherapy">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Physiotherapy'
                                                        ]?.status,
                                                    )}{' '}
                                                    Physiotherapy
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Physiotherapy'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Therapies?.[
                                                                'Physiotherapy'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Therapies?.[
                                                        'Acupuncture'
                                                    ],
                                                )}`}
                                                data-top="T"
                                                data-limit="acupuncture">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Acupuncture'
                                                        ]?.status,
                                                    )}{' '}
                                                    Acupuncture
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Acupuncture'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Therapies?.[
                                                                'Acupuncture'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Therapies?.[
                                                        'Massage and myotherapy'
                                                    ],
                                                )}`}
                                                data-top="T"
                                                data-limit="natural_therapies">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Massage and myotherapy'
                                                        ]?.status,
                                                    )}{' '}
                                                    Massage and myotherapy
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Massage and myotherapy'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Therapies?.[
                                                                'Massage and myotherapy'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Therapies?.[
                                                        'Osteopathy'
                                                    ],
                                                )}`}
                                                data-top="T"
                                                data-limit="osteopathy">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Osteopathy'
                                                        ]?.status,
                                                    )}{' '}
                                                    Osteopathy
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Osteopathy'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Therapies?.[
                                                                'Osteopathy'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Therapies?.[
                                                        'Chiropractic'
                                                    ],
                                                )}`}
                                                data-top="T"
                                                data-limit="chiropractic">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Chiropractic'
                                                        ]?.status,
                                                    )}{' '}
                                                    Chiropractic
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Chiropractic'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Therapies?.[
                                                                'Chiropractic'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Therapies?.[
                                                        'Psychology / hypnotherapy'
                                                    ],
                                                )}`}
                                                data-top="T"
                                                data-limit="psychology">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Psychology / hypnotherapy'
                                                        ]?.status,
                                                    )}{' '}
                                                    Psychology / hypnotherapy
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Psychology / hypnotherapy'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Therapies?.[
                                                                'Psychology / hypnotherapy'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Therapies?.[
                                                        'Podiatry'
                                                    ],
                                                )}`}
                                                data-top="T"
                                                data-limit="podiatry">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Podiatry'
                                                        ]?.status,
                                                    )}{' '}
                                                    Podiatry
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Podiatry'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Therapies?.[
                                                                'Podiatry'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Therapies?.[
                                                        'Orthotics'
                                                    ],
                                                )}`}
                                                data-top="T"
                                                data-limit="orthotics">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Orthotics'
                                                        ]?.status,
                                                    )}{' '}
                                                    Orthotics
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Orthotics'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Therapies?.[
                                                                'Orthotics'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Therapies?.[
                                                        'Dietetics'
                                                    ],
                                                )}`}
                                                data-top="T"
                                                data-limit="dietetics">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Dietetics'
                                                        ]?.status,
                                                    )}{' '}
                                                    Dietetics
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Dietetics'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Therapies?.[
                                                                'Dietetics'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Therapies?.[
                                                        'Speech therapy'
                                                    ],
                                                )}`}
                                                data-top="T"
                                                data-limit="speech_therapy">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Speech therapy'
                                                        ]?.status,
                                                    )}{' '}
                                                    Speech therapy
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Speech therapy'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Therapies?.[
                                                                'Speech therapy'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Therapies?.[
                                                        'Occupational therapy'
                                                    ],
                                                )}`}
                                                data-top="T"
                                                data-limit="occupational_therapy">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Occupational therapy'
                                                        ]?.status,
                                                    )}{' '}
                                                    Occupational therapy
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Therapies?.[
                                                            'Occupational therapy'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Therapies?.[
                                                                'Occupational therapy'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item parent extras_cover`}
                                                data-top="H"
                                                data-limit="">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Other?.status,
                                                    )}{' '}
                                                    Other
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Other?.[
                                                        'Health management'
                                                    ],
                                                )}`}
                                                data-top="H"
                                                data-limit="health_wellness">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Other?.[
                                                            'Health management'
                                                        ]?.status,
                                                    )}{' '}
                                                    Health management
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Other?.[
                                                            'Health management'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Other?.[
                                                                'Health management'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Other?.[
                                                        'Pharmaceuticals'
                                                    ],
                                                )}`}
                                                data-top="H"
                                                data-limit="pharmacy">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Other?.[
                                                            'Pharmaceuticals'
                                                        ]?.status,
                                                    )}{' '}
                                                    Pharmaceuticals
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Other?.[
                                                            'Pharmaceuticals'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Other?.[
                                                                'Pharmaceuticals'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Other?.[
                                                        'Mechanical / health appliances'
                                                    ],
                                                )}`}
                                                data-top="H"
                                                data-limit="mechanical_health_appliances">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Other?.[
                                                            'Mechanical / health appliances'
                                                        ]?.status,
                                                    )}{' '}
                                                    Mechanical / health
                                                    appliances
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Other?.[
                                                            'Mechanical / health appliances'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Other?.[
                                                                'Mechanical / health appliances'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                            <div
                                                className={`item extras_cover ${CSSClasses(
                                                    cardContext?.product
                                                        ?.benefit_limits
                                                        ?.Other?.[
                                                        'Hearing aids'
                                                    ],
                                                )}`}
                                                data-top="H"
                                                data-limit="hearing_aids">
                                                <span>
                                                    {IconChooser(
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Other?.[
                                                            'Hearing aids'
                                                        ]?.status,
                                                    )}{' '}
                                                    Hearing aids
                                                </span>
                                                <span className="right-side">
                                                    {
                                                        cardContext?.product
                                                            ?.benefit_limits
                                                            ?.Other?.[
                                                            'Hearing aids'
                                                        ]?.value
                                                    }{' '}
                                                    <ModalIcon
                                                        value={
                                                            cardContext?.product
                                                                ?.benefit_limits
                                                                ?.Other?.[
                                                                'Hearing aids'
                                                            ]
                                                        }
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="collapse-btns text-center">
                                            <button
                                                className="btn btn-details"
                                                type="button"
                                                onClick={() => {
                                                    dispatch(
                                                        changeCoverActions.ui.showDetails(
                                                            !changeCoverStore.showDetails,
                                                        ),
                                                    );
                                                }}>
                                                {!changeCoverStore.showDetails ? (
                                                    <>
                                                        Expand Details{' '}
                                                        <BiChevronDown
                                                            style={{
                                                                verticalAlign:
                                                                    'middle',
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        Hide Details{' '}
                                                        <BiChevronUp
                                                            style={{
                                                                verticalAlign:
                                                                    'middle',
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        default:
            return null;
    }
};

export default QuotePage;
