import React from 'react';
import {
    EndDate,
    FromAnotherFund,
    HealthFund,
    MemberNumber,
    PreviousJoinDate,
    StillAMember,
} from '../components';

export const PreviousFund = ({ edit = false }) => {
    return (
        <div className="d-flex pb-2 pt-3 flex-wrap modal-box-shadow">
            <div className="col-12 text-label-gray">
                <FromAnotherFund />
            </div>
            <HealthFund />
            <MemberNumber />
            <PreviousJoinDate />
            {!edit && <StillAMember />}
            <EndDate />
        </div>
    );
};

export default PreviousFund;
