import ClaimsActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';

export const setClaimsHistory = (claimHistory) => ({
    type: ClaimsActionTypes.SET_CLAIMS_HISTORY,
    payload: claimHistory,
});

export const thunkGetClaimsHistory = () => (dispatch, _getState) => {
    const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    api.user(CONFIG, token)
        .membership.claim.getClaimHistory(memberId)
        .then((response) => {
            if (!response?.message) {
                dispatch(setClaimsHistory(response));
            }
            // console.log(response)
        });
};
