import React from "react";

// const USERS = [
//     {
//         name: {
//             first: 'Peter',
//             last: 'Doe'
//         },
//         id: '94815608'
//     },
//     {
//         name: {
//             first: 'Jane',
//             last: 'Doe'
//         },
//         id: '3892472',
//     },
//     {
//         name: {
//             first: 'Sally',
//             last: 'Doe'
//         },
//         id: '9382472',
//     },
// ]

/**
 * Icon containing initials of a user.
 * @param user
 */
const InitialIcon = ({user, selected, size = 1}) => {

    if (!user?.Person) {
        return (
            <>
            </>
        )
    }

    const initials = `${user.Person.GivenName[0]}${user.Person.Surname[0]}`;
    const backgroundColorClass = selected ? 'bg-primary' : 'bg-primary-dark-1';

    return (
        <div style={{
            borderRadius: '100%',
            backgroundColor: 'orange',
            width: `${40*size}px`,
            height: `${40*size}px`,
            display: 'flex',
            fontSize: `${12.8*size}px`,
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '1rem',
            color: 'white',
        }} className={`font-feature font-weight-black ${backgroundColorClass}`}>
            {initials}
        </div>
    )
}

export default InitialIcon;

