import React, {useEffect} from 'react';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DirectDebit = ({
    getFieldProps,
    isVisible,
    errors,
    touched,
    values,
    setFieldValue,
    checkBSB,
    form,
    loading,
}) => {


    useEffect(() => {
        // This callback will be called whenever 'bsb' changes
        async function handleBsbChange() {
            try {
                checkBSB(getFieldProps('bsb').value, setFieldValue, form);
            } catch (error) {
                // Handle errors here, e.g., show an error message to the user
                console.error('Error handling BSB change:', error);
            }
        }
    
        // Call the API function when 'bsb' changes
        handleBsbChange();
    }, [form, setFieldValue]);
    

    const idPrefix = 'payment-method';
    return (
        <div
            style={{
                display: isVisible ? 'block' : 'none',
            }}>
            <div
                className="d-flex align-items-center justify-content-between pt-2 pb-3"
                style={{
                    boxShadow: 'inset 0px -2px 0px 0px #E6E6EE',
                }}>
                <div className="row pl-2 pr-2">
                    <Form.Group
                        controlId={`${idPrefix}-debit-bank`}
                        className="col-6 text-label-gray pr-1">
                        <Form.Label style={{ fontSize: '11px' }}>
                            Bank
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className={
                                errors.bank && touched.bank && !loading
                                    ? 'is-invalid'
                                    : ''
                            }
                            readOnly
                            {...getFieldProps('bank')}
                        />
                        {touched.bank && errors.bank && !loading ? (
                            <Form.Control.Feedback type={'invalid'}>
                                {errors.bank}
                            </Form.Control.Feedback>
                        ) : null}
                    </Form.Group>
                    <Form.Group
                        controlId={`${idPrefix}-debit-bsb`}
                        className="col-3 text-label-gray pl-1 pr-0">
                        <Form.Label style={{ fontSize: '11px' }}>
                            BSB
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className={
                                errors.bsb && touched.bsb && !loading
                                    ? 'is-invalid'
                                    : ''
                            }
                            {...getFieldProps('bsb')}
                            onChange={(val) => {
                                setFieldValue('bsb', val.target.value);
                                checkBSB(val.target.value, setFieldValue, form);
                            }}
                        />
                        {touched.bsb && errors.bsb && !loading.loading ? (
                            <Form.Control.Feedback type={'invalid'}>
                                {errors.bsb}
                            </Form.Control.Feedback>
                        ) : null}
                    </Form.Group>
                    <Form.Group
                        controlId={`${idPrefix}-debit-account`}
                        className="col-3 text-label-gray pl-0">
                        <Form.Label style={{ fontSize: '11px' }}>
                            Account Number
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className={
                                errors.accountNumber &&
                                touched.accountNumber &&
                                !loading
                                    ? 'is-invalid'
                                    : ''
                            }
                            {...getFieldProps('accountNumber')}
                        />
                        {touched.accountNumber &&
                        errors.accountNumber &&
                        !loading ? (
                            <Form.Control.Feedback type={'invalid'}>
                                {errors.accountNumber}
                            </Form.Control.Feedback>
                        ) : null}
                    </Form.Group>
                    <Form.Group
                        controlId={`${idPrefix}-debit-holder`}
                        className="col-12 text-label-gray">
                        <Form.Label style={{ fontSize: '11px' }}>
                            Account Holder
                        </Form.Label>
                        <Form.Control
                            type="text"
                            className={
                                errors.accountName &&
                                touched.accountName &&
                                !loading
                                    ? 'is-invalid'
                                    : ''
                            }
                            {...getFieldProps('accountName')}
                        />
                        {touched.accountName &&
                        errors.accountName &&
                        !loading ? (
                            <Form.Control.Feedback type={'invalid'}>
                                {errors.accountName}
                            </Form.Control.Feedback>
                        ) : null}
                    </Form.Group>
                    <Form.Group
                        controlId={`${idPrefix}-debit-freq`}
                        className="col-6 text-label-gray">
                        <Form.Label style={{ fontSize: '11px' }}>
                            Payment Frequency
                        </Form.Label>
                        <Form.Control
                            as="select"
                            className={
                                errors.bankPaymentFreq &&
                                touched.bankPaymentFreq &&
                                !loading
                                    ? 'is-invalid'
                                    : ''
                            }
                            {...getFieldProps('bankPaymentFreq')}>
                            <option value="Fortnightly">Fortnightly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Quarterly">Quarterly</option>
                            <option value="6 Monthly">6 Monthly</option>
                            <option value="Yearly">Yearly</option>
                        </Form.Control>
                        {touched.bankPaymentFreq &&
                        errors.bankPaymentFreq &&
                        !loading ? (
                            <Form.Control.Feedback type={'invalid'}>
                                {errors.bankPaymentFreq}
                            </Form.Control.Feedback>
                        ) : null}
                    </Form.Group>
                    <Form.Group
                        controlId={`${idPrefix}-debit-date`}
                        className="col-6 text-label-gray">
                        <Form.Label style={{ fontSize: '11px' }}>
                            Current Debit on date
                        </Form.Label>
                        <Form.Control
                            type="date"
                            className={
                                errors.bankPaymentDate &&
                                touched.bankPaymentDate &&
                                !loading
                                    ? 'is-invalid'
                                    : ''
                            }
                            disabled={true}
                            readOnly
                            {...getFieldProps('bankPaymentDate')}
                        />
                        {touched.bankPaymentDate &&
                        errors.bankPaymentDate &&
                        !loading ? (
                            <Form.Control.Feedback type={'invalid'}>
                                {errors.bankPaymentDate}
                            </Form.Control.Feedback>
                        ) : null}
                    </Form.Group>
                    <div className="col-6 text-label-gray">
                        <Form.Check
                            type="checkbox"
                            id={`checkbox-bank-change-date`}
                            label={`Change Debit Date`}
                            className={
                                errors.bankDateChange &&
                                touched.bankDateChange &&
                                !loading
                                    ? 'is-invalid text-label-gray font-weight-medium'
                                    : 'text-label-gray font-weight-medium'
                            }
                            style={{
                                fontSize: '13px',
                            }}
                            name={`bankDateChange`}
                            checked={values.bankDateChange}
                            onChange={(val) => {
                                setFieldValue(
                                    'bankDateChange',
                                    val.target.checked,
                                );
                            }}
                        />
                        {touched.bankDateChange &&
                        errors.bankDateChange &&
                        !loading ? (
                            <Form.Control.Feedback type={'invalid'}>
                                {errors.bankDateChange}
                            </Form.Control.Feedback>
                        ) : null}
                    </div>
                    <Form.Group
                        controlId={`${idPrefix}-debit-date-new`}
                        className="col-6 text-label-gray"
                        style={{
                            display: values.bankDateChange ? 'block' : 'none',
                        }}>
                        <Form.Label style={{ fontSize: '11px' }}>
                            New Debit on date
                        </Form.Label>
                        {/*<Form.Control
                            type="date"
                            className={errors.bankNewDate && touched.bankNewDate && !loading ? 'is-invalid' : ''}
                            {...getFieldProps('bankNewDate')}
                        />*/}
                        <DatePicker
                            wrapperClassName={
                                errors.bankNewDate && !loading
                                    ? 'is-invalid'
                                    : ''
                            }
                            className={
                                errors.bankNewDate && !loading
                                    ? 'is-invalid form-control'
                                    : 'form-control'
                            }
                            dateFormat="dd/MM/yyyy"
                            selected={values?.bankNewDate ?? ''}
                            minDate={moment().add(2, 'days').toDate()}
                            // maxDate={moment().add(8,'months').toDate()}
                            onChange={(e) => {
                                setFieldValue('bankNewDate', e);
                            }}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                        {errors.bankNewDate && !loading ? (
                            <Form.Control.Feedback type={'invalid'}>
                                {errors.bankNewDate}
                            </Form.Control.Feedback>
                        ) : null}
                    </Form.Group>

                    {/* Benefit Account Details check box */}
                    <div className="col-6 text-label-gray">
                        <Form.Check
                            type="checkbox"
                            id={`checkbox-update-benefit-account-details`}
                            label={`Update my benefit account details as well`}
                            className={
                                errors.sameBankAccount &&
                                touched.sameBankAccount &&
                                !loading
                                    ? 'is-invalid text-label-gray font-weight-medium'
                                    : 'text-label-gray font-weight-medium'
                            }
                            style={{
                                fontSize: '13px',
                            }}
                            name={`sameBankAccount`}
                            checked={values.sameBankAccount}
                            onChange={(val) => {
                                setFieldValue(
                                    'sameBankAccount',
                                    val.target.checked,
                                );
                            }}
                        />
                    </div>                
                </div>
            </div>
        </div>
    );
};

export default DirectDebit;
