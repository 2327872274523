export const getSpecialityClassCodeDescriptions = specialityClassCodes => {
    if (!specialityClassCodes) return;
    const codes = []

    specialityClassCodes.forEach(item => {
        codes.push(item.Description)
    })

    return codes;
}

export const getSpecialityClassCodeDescriptionFromCode = (allCodes, targetCode) => {
    if (!allCodes || !targetCode) return null;
    return allCodes.find(item => item.SpecialityClassCode === targetCode)?.Description
}

export const getSpecialityClassCodeFromDescription = (allCodes, desc) => {
    if (!allCodes) return null
    return allCodes.find(item => item.Description === desc)?.SpecialityClassCode
}

export const getSpecialityClassCodeObjectFromDescription = (allCodes, desc) => {
    if (!allCodes) return null
    return allCodes.find(item => item.Description === desc)
}

export const getClaimableSpecialityClassCodes = (allCodes) => {
    return allCodes.filter(code => code.Claimable === true)
}

export const getDefaultProvider = (allCodes, targetDescription) => {
    return allCodes.find(code => code.Description === targetDescription)?.DefaultProvider
}

export const getDisclaimerResult = (allCodes, targetCode) => {
    return allCodes.find(code => code.SpecialityClassCode === targetCode)?.DisclaimerResult || ''
}
