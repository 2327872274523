import actionTypes from './actions-types';

export const fastClaimActions = {

    general: {
        setStep: int => ({
            type: actionTypes.SET_STEP,
            payload: int
        }),
        reset: () => ({
            type: actionTypes.RESET,
        }),
        setHighRisk: boolean => ({
            type: actionTypes.SET_HIGH_RISK,
            payload: boolean
        }),
        claimResult: payload => ({
            type: actionTypes.SET_CLAIM_RESULTS,
            payload: payload
        }),
    },

    item: {
        /**
         * Add an empty item
         * ID is created automatically
         */
        add: itemId => ({
            type: actionTypes.ADD_ITEM,
            payload: itemId
        }),

        update: (itemId, itemPartial) => ({
            type: actionTypes.UPDATE_ITEM,
            payload: {itemId, itemPartial}
        }),

        remove: itemId => ({
            type: actionTypes.REMOVE_ITEM,
            payload: itemId
        }),

        set: {
            currentItem: id => ({
                type: actionTypes.SET_CURRENT_ITEM,
                payload: id
            })
        },

    },

    document: {
        add: document => ({
            type: actionTypes.ADD_DOCUMENT,
            payload: document
        }),

        remove: documentId => ({
            type: actionTypes.REMOVE_DOCUMENT,
            payload: documentId
        })
    },

    review: {
        set: {
            sendConfirmation: boolean => ({
                type: actionTypes.SET_SEND_CONFIRMATION,
                payload: boolean
            }),

            ahb: {
                eligible: boolean => ({
                    type: actionTypes.SET_ELIGIBLE_FOR_AHB,
                    payload: boolean
                }),

                claim: boolean => ({
                    type: actionTypes.SET_CLAIM_ABH,
                    payload: boolean
                })
            },

            agreeDisclaimers: boolean => ({
                type: actionTypes.SET_AGREE_DISCLAIMERS,
                payload: boolean
            }),

            email: email => ({
                type: actionTypes.SET_EMAIL,
                payload: email
            })

        }
    },

    amount: {
        set: {
            outOfPocket: boolean => ({
                type: actionTypes.SET_AMOUNT_OUT_OF_POCKET,
                payload: boolean
            }),

            benefit: boolean => ({
                type: actionTypes.SET_AMOUNT_BENEFIT,
                payload: boolean
            }),

            benefitQuote: quote => ({
                type: actionTypes.SET_BENEFIT_QUOTE,
                payload: quote
            }),

            total: boolean => ({
                type: actionTypes.SET_AMOUNT_TOTAL,
                payload: boolean
            })

        }
    },

    oneTimeBankAccount: {
        set: {
            use: boolean => ({
                type: actionTypes.SET_USE_ONE_TIME_BANK_ACCOUNT,
                payload: boolean
            }),

            details: boolean => ({
                type: actionTypes.SET_ONE_TIME_BANK_ACCOUNT_DETAILS,
                payload: boolean
            })

        }
    }
}
