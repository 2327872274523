import ProductActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';

export const setProduct = (product) => ({
    type: ProductActionTypes.SET_PRODUCT,
    payload: product,
});

export const thunkGetProduct = () => (dispatch, _getState) => {
    const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    api.user(CONFIG, token)
        .membership.getCurrentProduct(memberId)
        .then((response) => {
            dispatch(setProduct(response));
            // console.log(response)
        });
};
