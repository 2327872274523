import React from 'react';
import { ReactComponent as SrcSvg } from './src.svg';

interface Props {
    style?: React.CSSProperties;
}

const IconSuccess: React.FC<Props> = ({ style }) => {
    // alt={'Success'} not used?
    return <SrcSvg style={style} />;
};

export default IconSuccess;
