import React from 'react';
import { MedicareEligible } from '../components';

export const MedicareInfo = ({ loading }) => {
    return (
        <div className="d-flex pb-2 pt-3 flex-wrap modal-box-shadow">
            <MedicareEligible loading={loading} />
        </div>
    );
};

export default MedicareInfo;
