import React from 'react';
import { Header, HeroImage, HeroTitle } from '../../PromoBlock/exports';
import ShareCode from 'img/raster/ShareCode/comp';

const ReferFriendHead = () => {
    return (
        <Header>
            <HeroTitle>
                <h1>Share the love!</h1>
                <h3>
                    Refer a friend to your health fund and if they join,
                    you&apos;ll receive a $100 eGift Card*
                </h3>
            </HeroTitle>
            <HeroImage
                image={<ShareCode classes="hero-image align-middle" />}
            />
        </Header>
    );
};

export default ReferFriendHead;
