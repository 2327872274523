import RebateRatesActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';

export const setRebateRates = (rebateRates) => ({
    type: RebateRatesActionTypes.SET_REBATE_RATES,
    payload: rebateRates,
});

export const thunkGetRebateRates = () => (dispatch, _getState) => {
    const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    api.user(CONFIG, token)
        .membership.getRebates(memberId)
        .then((response) => {
            dispatch(setRebateRates(response));
            // console.log(response)
        });
};
