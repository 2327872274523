import React, { Fragment } from 'react';
import { Form } from 'react-bootstrap';
import { injectService } from '../common/functions';
import { FastClaimTypeItem } from 'types/fastClaim';
import { ClaimSpecialityType } from '@qldtuh/tuh-uh-js-api';

interface Props {
    specialties: ClaimSpecialityType[];
    claimItem?: FastClaimTypeItem;
    updateClaim: (newObject: any) => void;
    handleChange: (benefitService?: ClaimSpecialityType) => void;
}

export const Service: React.FC<Props> = ({
    specialties,
    claimItem,
    updateClaim,
    handleChange,
}) => {
    return (
        <Form.Group controlId={`service`} className="col-12 text-label-gray">
            <Form.Label>Service</Form.Label>
            <Form.Control
                as="select"
                onChange={(e) => {
                    const benefitService = specialties?.find(
                        (spec) => spec.Description === e.target.value,
                    );

                    const newObject = injectService(claimItem, benefitService);
                    updateClaim(newObject);
                    handleChange(benefitService);
                }}
                disabled={
                    claimItem?.personId === undefined ||
                    claimItem.personId === ''
                }
                value={claimItem?.Description ?? ''}>
                <option value="">- Please select -</option>
                {typeof specialties?.map === 'function' &&
                    specialties.map((row, idx) => (
                        <Fragment key={idx}>
                            {row.Claimable && (
                                <option value={row.Description}>
                                    {row.Description}
                                </option>
                            )}
                        </Fragment>
                    ))}
            </Form.Control>
        </Form.Group>
    );
};

export default Service;
