import { User } from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';
import {
    setAccessToken,
    getAccessToken,
    setRefreshToken,
    setAccessTokenExpires,
    setMembershipId,
    getMembershipId,
} from '../../libs/auth';
import {
    setMembership,
    setSelectedUser,
    setRebateRates,
    setLHC,
    setHra,
    setPremium,
    setProduct,
    setClaimsHistory,
    setPaymentMethodModalVisible,
    setEditPersonModalVisible,
    setHraModalVisible,
    setReferAFriendModalVisible,
    setClaimPaymentsModalVisible,
    setRebateFormModalVisible,
    setRebateFormReset,
} from 'store';

export const setUser = (payload) => {
    return {
        type: User.SET_USER,
        payload: payload,
    };
};

export const clearUser = () => {
    return {
        type: User.CLEAR_USER,
    };
};

export const setReferCode = (payload) => {
    return {
        type: User.SET_REFER_CODE,
        payload: payload,
    };
};

export const thunkLogin = (response) => (dispatch, _getState) => {
    dispatch(setUser(response));

    setAccessToken(response.access_token);
    setAccessTokenExpires(response.expires);
    setRefreshToken(response.refresh_token);
    if (response?.membershipid) {
        setMembershipId(response.membershipid);
    }
};

export const thunkRefreshLogin = () => (dispatch, _getState) => {
    const refresh_token = localStorage.getItem(STRINGS.REFRESH_TOKEN);

    return new Promise(function (resolve, reject) {
        api.user(CONFIG)
            .loginRefresh(refresh_token)
            .then((response) => {
                if (response?.access_token) {
                    dispatch(thunkLogin(response));
                    resolve();
                } else {
                    console.log('ERROR WITH REFRESH');
                    reject();
                }
            })
            .catch(() => {
                console.log('ERROR WITH REFRESH');
                reject();
            });
    });
};

export const thunkGetReferCode = () => (dispatch, _getState) => {
    const membershipId = getMembershipId();
    const auth = getAccessToken();

    return new Promise(function (resolve, reject) {
        api.user(CONFIG, auth)
            .membership.getReferCode(membershipId)
            .then((res) => {
                if (res?.refer_code) {
                    dispatch(setReferCode(res.refer_code));
                    resolve();
                } else {
                    console.log('ERROR WITH REFER CODE RETRIEVAL');
                    reject();
                }
            })
            .catch(() => {
                console.log('ERROR WITH FETCHING REFER CODE');
                reject();
            });
    });
};

export const thunkLogout = () => (dispatch, _getState) => {
    dispatch(clearUser());
    dispatch(setSelectedUser(null));
    dispatch(setMembership(null));
    dispatch(setRebateRates(null));
    dispatch(setLHC(null));
    dispatch(setHra(null));
    dispatch(setPremium(null));
    dispatch(setProduct(null));
    dispatch(setClaimsHistory(null));
    dispatch(setPaymentMethodModalVisible(false));
    dispatch(setEditPersonModalVisible(false));
    dispatch(setHraModalVisible(false));
    dispatch(setReferAFriendModalVisible(false));
    dispatch(setClaimPaymentsModalVisible(false));
    dispatch(setRebateFormModalVisible(false));
    // Rebate Form reset
    dispatch(setRebateFormReset());
    localStorage.clear();
    sessionStorage.clear();
};
