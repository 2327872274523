import actionTypes from './actions-types';
export const changeCoverConst = {
    COVER_TYPE_HOSPITAL: 'hospital',
    COVER_TYPE_EXTRAS: 'extras',
    COVER_TYPE_BOTH: 'both',
    GROUP_COVER_TYPE_SINGLE: 'S',
    GROUP_COVER_TYPE_FAMILY: 'FAM',
    GROUP_COVER_TYPE_COUPLE: 'C',
    GROUP_COVER_TYPE_SINGLE_PARENT: 'SP',
    MUST_COVER_WEIGHT: 'weight',
    MUST_COVER_PREGNANCY: 'pregnancy',
    MUST_COVER_JOINTS: 'joints',
    MUST_COVER_PSYCH: 'psych',
    MUST_COVER_EXTRAS_MAJOR_DENTAL: 'majorDental',
    MUST_COVER_EXTRAS_FOOT_ORTHOTICS: 'footOrthotics',
    MUST_COVER_EXTRAS_HEARING_AIDS: 'hearingAids',
    MUST_COVER_EXTRAS_ACUPUNCTURE: 'acupuncture',
    PAYMENT_FREQ_FORTNIGHT: 'fortnightPeriod',
    PAYMENT_FREQ_MONTH: 'monthPeriod',
    PAYMENT_FREQ_QUARTER: 'quarterPeriod',
    PAYMENT_FREQ_HALF_YEAR: 'halfYearPeriod',
    PAYMENT_FREQ_YEARLY: 'yearlyPeriod',
}
export const changeCoverActions = {
    reset: () => ({
        type: actionTypes.CHANGE_COVER_RESET,
    }),
    ui: {
        showDetails: boolean => ({
            type: actionTypes.SET_CHANGE_COVER_UI_SHOW_DETAILS,
            payload: boolean
        }),
        showLimitsModal: boolean => ({
            type: actionTypes.SET_CHANGE_COVER_UI_SHOW_LIMITS_MODAL,
            payload: boolean
        }),
        limitsModalOptions: string => ({
            type: actionTypes.SET_CHANGE_COVER_UI_LIMITS_MODAL_OPTIONS,
            payload: string
        })
    },
    onboarding: {
        setStep: integer => ({
            type: actionTypes.SET_CHANGE_COVER_ONBOARDING_STEP,
            payload: integer
        })
    },
    coverType: {
        hospital: () => ({
            type: actionTypes.SET_CHANGE_COVER_TYPE,
            payload: changeCoverConst.COVER_TYPE_HOSPITAL
        }),
        extras: () => ({
            type: actionTypes.SET_CHANGE_COVER_TYPE,
            payload: changeCoverConst.COVER_TYPE_EXTRAS
        }),
        both: () => ({
            type: actionTypes.SET_CHANGE_COVER_TYPE,
            payload: changeCoverConst.COVER_TYPE_BOTH
        }),
    },
    groupCoverType: {
        single: () => ({
            type: actionTypes.SET_CHANGE_COVER_GROUP_TYPE,
            payload: changeCoverConst.GROUP_COVER_TYPE_SINGLE
        }),
        family: () => ({
            type: actionTypes.SET_CHANGE_COVER_GROUP_TYPE,
            payload: changeCoverConst.GROUP_COVER_TYPE_FAMILY
        }),
        couple: () => ({
            type: actionTypes.SET_CHANGE_COVER_GROUP_TYPE,
            payload: changeCoverConst.GROUP_COVER_TYPE_COUPLE
        }),
        singleParent: () => ({
            type: actionTypes.SET_CHANGE_COVER_GROUP_TYPE,
            payload: changeCoverConst.GROUP_COVER_TYPE_SINGLE_PARENT
        }),
    },
    mustCover: {
        none: () => ({
            type: actionTypes.SET_CHANGE_COVER_MUST_COVER,
            payload: null
        }),
        pregnancy: () => ({
            type: actionTypes.SET_CHANGE_COVER_MUST_COVER,
            payload: changeCoverConst.MUST_COVER_PREGNANCY
        }),
        joints: () => ({
            type: actionTypes.SET_CHANGE_COVER_MUST_COVER,
            payload: changeCoverConst.MUST_COVER_JOINTS
        }),
        weight: () => ({
            type: actionTypes.SET_CHANGE_COVER_MUST_COVER,
            payload: changeCoverConst.MUST_COVER_WEIGHT
        }),
        psych: () => ({
            type: actionTypes.SET_CHANGE_COVER_MUST_COVER,
            payload: changeCoverConst.MUST_COVER_PSYCH
        })
    },
    mustCoverExtras: {
        none: () => ({
            type: actionTypes.SET_CHANGE_COVER_MUST_COVER_EXTRAS,
            payload: null
        }),
        majorDental: () => ({
            type: actionTypes.SET_CHANGE_COVER_MUST_COVER_EXTRAS,
            payload: changeCoverConst.MUST_COVER_EXTRAS_MAJOR_DENTAL
        }),
        footOrthotics: () => ({
            type: actionTypes.SET_CHANGE_COVER_MUST_COVER_EXTRAS,
            payload: changeCoverConst.MUST_COVER_EXTRAS_FOOT_ORTHOTICS
        }),
        hearingAids: () => ({
            type: actionTypes.SET_CHANGE_COVER_MUST_COVER_EXTRAS,
            payload: changeCoverConst.MUST_COVER_EXTRAS_HEARING_AIDS
        }),
        acupuncture: () => ({
            type: actionTypes.SET_CHANGE_COVER_MUST_COVER_EXTRAS,
            payload: changeCoverConst.MUST_COVER_EXTRAS_ACUPUNCTURE
        })
    },
    hospitalMustChooseExtras: boolean => ({
        type: actionTypes.SET_CHANGE_COVER_HOSPITAL_MUST_CHOOSE_EXTRAS,
        payload: boolean
    }),
    products: products => ({
        type: actionTypes.SET_CHANGE_COVER_PRODUCTS,
        payload: products
    }),
    suggested: {
        hospital: set => ({
            type: actionTypes.SET_CHANGE_COVER_SUGGESTED_HOSPITAL,
            payload: set
        }),
        extras: set => ({
            type: actionTypes.SET_CHANGE_COVER_SUGGESTED_EXTRAS,
            payload: set
        }),
    },
    selected: {
        hospital: set => ({
            type: actionTypes.SET_CHANGE_COVER_SELECTED_HOSPITAL_PRODUCT,
            payload: set
        }),
        extras: set => ({
            type: actionTypes.SET_CHANGE_COVER_SELECTED_EXTRAS_PRODUCT,
            payload: set
        }),
        bundle: set => ({
            type: actionTypes.SET_CHANGE_COVER_SELECTED_BUNDLE_PRODUCT,
            payload: set
        }),
    },
    selectedProducts: {
        hospital: set => ({
            type: actionTypes.SET_CHANGE_COVER_SELECTED_PRODUCT_HOSPITAL_PRODUCT,
            payload: set
        }),
        extras: set => ({
            type: actionTypes.SET_CHANGE_COVER_SELECTED_PRODUCT_EXTRAS_PRODUCT,
            payload: set
        }),
        bundle: set => ({
            type: actionTypes.SET_CHANGE_COVER_SELECTED_PRODUCT_BUNDLE_PRODUCT,
            payload: set
        }),
    },
    pricing: {
        hospital: set => ({
            type: actionTypes.SET_CHANGE_COVER_PRICING_HOSPITAL,
            payload: set
        }),
        extras: set => ({
            type: actionTypes.SET_CHANGE_COVER_PRICING_EXTRAS,
            payload: set
        }),
        bundle: set => ({
            type: actionTypes.SET_CHANGE_COVER_PRICING_BUNDLE,
            payload: set
        }),
    },
    paymentFrequency: frequencyConst => ({
        type: actionTypes.SET_CHANGE_COVER_PAYMENT_FREQ,
        payload: frequencyConst
    })
}
