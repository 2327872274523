import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const StillAMember = () => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <Form.Group
            controlId={`previousfund-stillmember`}
            className="col-6 text-label-gray align-self-end"
            style={{
                display: values.previoushealthtransfer ? 'block' : 'none',
            }}>
            <Form.Check
                inline
                type="checkbox"
                id={`previousfund-stillmember`}
                label={`Still a member of previous fund?`}
                name={`previousHealthFundStillAMember`}
                className={'text-label-gray font-weight-medium'}
                style={{
                    fontSize: '13px',
                }}
                checked={values.previousHealthFundStillAMember === true}
                onChange={(e) => {
                    setFieldValue(
                        'previousHealthFundStillAMember',
                        e.target.checked,
                    );
                    if (e.target.checked) {
                        setFieldValue('previoushealthfundcease', '');
                    }
                }}
            />
        </Form.Group>
    );
};

export default StillAMember;
