import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import { Formik } from 'formik';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import gtagEvent from 'libs/gtagEvent';
import { allAdults, mainPerson } from 'libs/helpers';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import api from '@qldtuh/tuh-uh-js-api';
import { ReferAFriendSchema } from './validation/ReferAFriendSchema';
import {
    // Preamble,
    MyDetails,
    FriendDetails,
    // Terms,
    SubmitForm,
} from './components';
import { PromoSubtitle, PromoText } from '../../PromoBlock/exports';

const ReferFriendForm = () => {
    const membership = useSelector((state) => state.membership);
    const adults = allAdults(membership);
    const [loading, setLoading] = useState(false);

    const [errorSpace, setErrorSpace] = useState(<>&nbsp;</>);
    const [failedAttempts, setFailedAttempts] = useState(0);

    const [successSpace, setSuccessSpace] = useState(<>&nbsp;</>);
    const [successAttempts, setSuccessAttempts] = useState(0);

    const getDefaultValues = () => {
        return {
            person: mainPerson(membership)?.Person?.PersonId || '',
            friendFirst: '',
            friendLast: '',
            friendMobile: '',
            friendEmail: '',
        };
    };

    const [defaultValues, setDefaultValues] = useState(getDefaultValues());

    useEffect(() => {
        if (membership) {
            setDefaultValues(getDefaultValues());
        }
    }, [membership]);

    const onSubmit = async (values, actions, adults, membership) => {
        setLoading(true);
        gtagEvent({
            screen: 'referafriend',
            action: 'submit',
            label: 'Submitted referral',
            type: 'modal',
        });

        const sendingUser = adults.find(
            (person) => person.Person.PersonId === values.person,
        );

        const out = {};
        out.memberid = membership.MembershipId;
        out.name =
            sendingUser.Person.GivenName + ' ' + sendingUser.Person.Surname;
        out.first_name = values.friendFirst;
        out.last_name = values.friendLast;
        out.email = values.friendEmail;
        out.mobile = values.friendMobile;

        console.log('OUT: ', out);

        const access_token = getAccessToken();
        const memberid = getMembershipId();

        try {
            const response = await api
                .website(CONFIG, access_token)
                .refer(memberid, out);

            if (response?.status === 'error') {
                throw new Error(response.message);
            } else {
                console.log(response);
                setSuccessSpace('Thank you for your submission');
                actions.resetForm();
                setSuccessAttempts(1);
                setTimeout(() => {
                    setSuccessSpace(<>&nbsp;</>);
                    setSuccessAttempts(0);
                }, 10000);
                setLoading(false);
            }
        } catch (response) {
            console.log('ERROR');
            console.log(response.message);
            setErrorSpace(response.message);
            setFailedAttempts(1);
            setTimeout(() => {
                setErrorSpace(<>&nbsp;</>);
                setFailedAttempts(0);
            }, 10000);
            setLoading(false);
        }
    };

    return (
        <div className="text-primary font-feature row px-3">
            {/*<Preamble />*/}
            <div className="col-12 col-md-7 text-black py-3 px-5">
                <Formik
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={true}
                    validateOnMount={true}
                    initialValues={defaultValues || null}
                    validationSchema={ReferAFriendSchema}
                    onSubmit={(values, actions) =>
                        onSubmit(values, actions, adults, membership)
                    }>
                    <Form>
                        <MyDetails loading={loading} />
                        <FriendDetails loading={loading} />
                        {/*<Terms />*/}
                        <SubmitForm
                            loading={loading}
                        />
                    </Form>
                </Formik>
                <div style={{ paddingInline: '10px' }}>
                    <Fade collapse bottom when={successAttempts > 0}>
                        <StatusNotificationCard status="success">
                            {successSpace}
                        </StatusNotificationCard>
                    </Fade>
                </div>
                <div style={{ paddingInline: '10px' }}>
                    <Fade collapse bottom when={failedAttempts > 0}>
                        <StatusNotificationCard status="error">
                            {errorSpace}
                        </StatusNotificationCard>
                    </Fade>
                </div>
            </div>
            <div className="col-12">
                <PromoSubtitle>Disclaimer</PromoSubtitle>
                <PromoText>
                    <p>
                        The offer is available to members whose premium payments are
                        up-to-date at the date of referral. The referred family
                        member/friend cannot currently be a Union Health or TUH
                        member or dependant of a Union Health or TUH member coming
                        off a parent&apos;s policy. The $100 eGift Card will be sent
                        within 30 days of receiving the first premium of the new
                        member. This offer is not available in conjunction with any
                        other offer.
                    </p>
                    <p>*<a
                        href="https://tuh.com.au/egift-card-terms-conditions"
                        target="_blank"
                        rel="noopener noreferrer">
                        Terms and conditions
                    </a> apply.</p>
                </PromoText>
            </div>
        </div>
    );
}

export default ReferFriendForm;