import React from 'react';

const PromoText = ({ children }) => {
    return (
        <div className="px-3 w-100 refer-promo-body">
            <>{children}</>
        </div>
    );
};

export default PromoText;
