import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import { setRebateFormStep } from 'store';
import { useAsyncFn } from 'react-use';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { setMembership } from 'store/membership/actions';
import buildMedicareData from '../functions/buildMedicareData';

const AcceptRebate = () => {
  const dispatch = useDispatch();

  const membership = useSelector((state) => state.membership);
  const rebateForm = useSelector((store) => store.rebateForm);

  const [iframeData, setIframeData] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(true);

  const url = api.publicHelpers.publicGetFullApiUrl(
    'tuh',
    process.env.REACT_APP_API_ENVIRONMENT,
  );

  // const [checkBox, _setCheckBox] = useState(true);
  const checkBox = true; // Sorry, I can't remember why this is here.

  useEffect(() => {
    const data = buildMedicareData(membership, rebateForm);
    if (data) {
      setIframeData(data);
    }
  }, [membership, rebateForm]);

  useEffect(() => {
    if (checkBox) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [checkBox]);

  const [onSubmitState, onSubmit] = useAsyncFn(async (...props) => {
    const access_token = getAccessToken();
    const memberid = getMembershipId();

    const [iframeData] = props;
    const data = iframeData;
    const output = {};
    output.data = data;
    output.portal = '1';

    try {
      const response = await api
        .medicare(CONFIG, access_token)
        .sendMedicare(memberid, output);
      if (response?.status === 'error') {
        throw new Error(response.message);
      } else {
        dispatch(setMembership(response));
        dispatch(setRebateFormStep(5));
      }
    } catch (err) {
      console.log(err.message);
    }
  });

  useEffect(() => {
    if (iframeData) {
      document.getElementById('iframe-form-medicare-generate').submit();
    }
  }, [iframeData]);

  return (
    <div className="rebate-form-step-4">
      <div className="container-fluid">
        <div className="row flex-wrap">
          <div className="col-12 text-center steps">
            <span
              onClick={() => {
                dispatch(setRebateFormStep(2));
              }}>
              1
            </span>
            <span
              onClick={() => {
                dispatch(setRebateFormStep(3));
              }}>
              2
            </span>
            <span className="active">3</span>
          </div>
          <div
            className="col-12 text-primary font-21 pb-3 font-weight-bold text-center"
            style={{ fontSize: '21px' }}>
            Confirm and submit
          </div>
          <div
            className="col-12 col-md-8 offset-md-2 text-center pb-3"
            style={{ fontSize: '14px' }}>
            Please review your completed Australian Government Rebate form prior
            to submission
          </div>
        </div>
      </div>
      <div
        style={{
          background: '#E6E6EE',
          height: '2px',
          margin: '0px -30px',
        }}></div>
      <div className="pt-5 pb-3 px-5 text-center">
        {iframeData ? (
          <>
            <form
              id="iframe-form-medicare-generate"
              action={`${url}/medicare/generatepost`}
              method="post"
              target="iframe_medicare_generate">
              <input type="hidden" name="data" value={iframeData} />
              <input type="submit" value="Submit" style={{ display: 'none' }} />
            </form>
            <iframe
              name="iframe_medicare_generate"
              id="iframe_medicare_generate"
              style={{
                border: '1px solid #D4D2E3',
                boxShadow: 'inset 0px 2px 5px 0px rgba(0,0,0,0.18)',
                borderRadius: '12px',
                margin: '0 -30px',
              }}
              border="0"
              width="360px"
              height="560px"
              className="col-12 py-2 px-3"></iframe>
          </>
        ) : null}
      </div>
      {/* <div className="px-5 pb-3">
                <Form>
                    <Form.Check
                        type="checkbox"
                        id={`checkbox-consent`}
                        label={`You are required to review your completed Australian Government Rebate Application form prior to continuing. Please click 'Submit' to continue.`}
                        className={
                            !checkBoxValid && !onSubmitState.loading
                                ? 'is-invalid text-label-gray font-weight-medium'
                                : 'text-label-gray font-weight-medium'
                        }
                        style={{
                            fontSize: '13px',
                        }}
                        name={`consent`}
                        checked={checkBox}
                        onChange={(val) => {
                            setCheckBox(val.target.checked);
                            if (val.target.checked) {
                                setCheckBoxError('');
                                setCheckBoxValid(true);
                            } else {
                                setCheckBoxError('You must agree');
                                setCheckBoxValid(false);
                            }
                        }}
                    />
                    {checkBoxError !== '' && !onSubmitState.loading ? (
                        <Form.Control.Feedback type={'invalid'}>
                            {checkBoxError}
                        </Form.Control.Feedback>
                    ) : null}
                </Form>
            </div> */}
      <div
        style={{
          background: '#E6E6EE',
          height: '2px',
          margin: '0px -30px',
        }}></div>
      <div className="container-fluid pt-3">
        <div className="row">
          <div className="col-12 text-center">
            <Button
              variant="link-outline-gray"
              className="mr-3"
              onClick={() => {
                dispatch(setRebateFormStep(3));
              }}>
              Back
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                onSubmit(iframeData);
              }}
              disabled={disableSubmit || onSubmitState.loading}>
              {onSubmitState.loading ? (
                <>
                  <Spinner
                    animation="border"
                    role="status"
                    as="span"
                    size="sm"
                  />
                  Accept
                </>
              ) : (
                'Accept'
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptRebate;
