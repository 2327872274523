import React from 'react';
import { ReactComponent as SrcSvg } from './src.svg';

const HeartWithTooth = () => {
    return (
        <SrcSvg
            alt={
                'Red heart icon bearing a medical cross and Tooth wearing glasses.'
            }
        />
    );
};

export default HeartWithTooth;
