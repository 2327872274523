import React, { useState, useEffect } from 'react';
import { Content, PromoLabel, PromoContent } from '../../PromoBlock/exports';
import SocialCircle from './content/SocialCircle';
import CodePill from './content/CodePill';
import { FaFacebookF, FaEnvelope, FaTwitter } from 'react-icons/all';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedUser, allPersons } from 'libs/helpers';
import { setReferAFriendModalVisible } from 'store';

const ReferFriendContent = ({ promoURL }) => {
    const selectedUserId = useSelector((state) => state.selectedUser);
    const membership = useSelector((state) => state.membership);
    const referCode = useSelector((state) => state.user.refer_code);
    const dispatch = useDispatch();

    const iconSize = 22;

    /**
     * The Mail button will not work if a default mail client is not set
     * It is on the end user to have this default mailer set in browser
     */

    const mailToMsg = `Hey,\n\nI love TUH Health Fund and I think you will too! \n\nCheck it out; I’m sure you’ll find the health cover that’s just right for you  : -).\n\n${promoURL}/${referCode}\n\nCheers!`;

    const currentUser = getSelectedUser(allPersons(membership), selectedUserId);
    const [mailText, setMailText] = useState(mailToMsg);
    useEffect(() => {
        if (currentUser?.Person?.GivenName) {
            const newMsg = `Hey,\n\nI love TUH Health Fund and I think you will too! \n\nCheck it out; I’m sure you’ll find the health cover that’s just right for you  : -).\n\n${promoURL}/${referCode}\n\nCheers!\n\n${currentUser.Person.GivenName}.`;
            setMailText(newMsg);
        }
    }, [currentUser]);

    const mailBody = encodeURIComponent(mailText);
    const mailSubject = encodeURIComponent('TUH | Refer a friend');
    const mailToHref = `mailto:?subject=${mailSubject}&body=${mailBody}`;

    const twitterHref = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        mailText,
    )}`;
    const facebookHref = `https://www.facebook.com/sharer/sharer.php?u=${promoURL}/${referCode}`;

    return (
        <Content>
            <PromoLabel>{'Share your code:'}</PromoLabel>
            <PromoContent>
                <SocialCircle
                    icon={<FaFacebookF size={iconSize} />}
                    color="#3B5998"
                    href={facebookHref}
                    disabled={!referCode}
                    gtag={{
                        screen: 'referafriend',
                        action: 'share_facebook',
                        label: 'Clicked on share via Facebook',
                    }}
                />
                <SocialCircle
                    icon={<FaTwitter size={iconSize} />}
                    color="#76c3ed"
                    href={twitterHref}
                    disabled={!referCode}
                    gtag={{
                        screen: 'referafriend',
                        action: 'share_twitter',
                        label: 'Clicked on share via Twitter',
                    }}
                />
                <SocialCircle
                    icon={<FaEnvelope size={iconSize} />}
                    color="#67C8C6"
                    href={mailToHref}
                    disabled={!referCode}
                    gtag={{
                        screen: 'referafriend',
                        action: 'share_email',
                        label: 'Clicked on share via email',
                    }}
                />
                <CodePill shareCode={referCode} shareURL={promoURL} />
            </PromoContent>
            <div className={'modal-form-link'}>
                Prefer to pop their details instead? Fill in{' '}
                <span
                    className={'modal-reveal'}
                    onClick={() => dispatch(setReferAFriendModalVisible(true))}>
                    this
                </span>{' '}
                form and we’ll give them a call!
            </div>
        </Content>
    );
};

export default ReferFriendContent;
