import ProductActionTypes from './actions-types';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ProductActionTypes.SET_PRODUCT:
            if (process.env.REACT_APP_ENVIRONMENT==="development") {
                console.log(`SET_PRODUCT with payload ${action.payload}`);
            }
            return action.payload;
        default:
            return state;
    }
};
