import React from "react";
import {Button} from "react-bootstrap";
import {IoMdEye} from "react-icons/all";

const ShowAll = () => {
    return (
        <Button
            variant="link-primary-dark-1 p-0 m-0 text-muted"
        >
            <div className="d-flex align-items-center">

                <span className="flex-shrink-0 d-flex align-items-center justify-content-center mr-1">
                    <IoMdEye/>
                </span>

                <span>
                    SHOW ALL
                </span>

            </div>
        </Button>

    );
};

export default ShowAll;

