const autocorrectCurrencyString = (string) => {
    if (/^\d*\.\d$/.test(string)) {
        string += '0';
    }
    if (/^\d+\.$/.test(string)) {
        string += '00';
    }
    if (/^\d+$/.test(string)) {
        string += '.00';
    }
    if (/^$/.test(string)) {
        string += '0.00';
    }
    return string;
};

export default autocorrectCurrencyString;
