import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Spinner } from 'react-bootstrap';
import CustomModal from 'components/modals/Modal';
import Cancel from 'components/elements/buttons/Cancel';
import gtagEvent from 'libs/gtagEvent';
import { useAsyncFn } from 'react-use';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { setMembership } from 'store/membership/actions';
import moment from 'moment';

const GovernmentRebateModal = ({ show, handleClose, align }) => {
  const [initRebate, setInitRebate] = useState(null);
  const [rebateTier, setRebateTier] = useState('');
  const [rebatePercentage, setRebatePercentage] = useState(null);
  const [termsCheck, setTermsCheck] = useState(false);
  const [income, setIncome] = useState([]);

  const membership = useSelector((state) => state.membership);
  const rebateRates = useSelector((state) => state.rebateRates);
  const product = useSelector((state) => state.product);

  const dispatch = useDispatch();

  const SINGLE_INCOME_ARRAY = [
    '$97,000 or less',
    '$97,001 - $113,000',
    '$113,001 - $151,000',
    '$151,001 or more',
  ];

  const DUAL_INCOME_ARRAY = [
    '$194,000 or less',
    '$194,001 - $226,000',
    '$226,001 - $302,000',
    '$302,001 or more',
  ];

  useEffect(() => {
    const tier = membership?.Contribution?.RebateTier || 0;

    setRebateTier(tier.toString());
    setInitRebate(tier.toString());
    setRebatePercentage(membership?.Contribution?.RebatePercentage);

    const membershipType = product?.membershipClassCode;
    const incomeArray =
      membershipType === 'Sgl' ? SINGLE_INCOME_ARRAY : DUAL_INCOME_ARRAY;
    setIncome(incomeArray);
  }, [membership, product]);

  const [updateRebateState, updateRebate] = useAsyncFn(async (rebateTier) => {
    const access_token = getAccessToken();
    const memberid = getMembershipId();

    gtagEvent({
      screen: 'govrebate_tier',
      action: 'submit',
      label: 'changed gov rebate teir',
      type: 'modal',
    });

    const data = {
      rebate: {
        date: moment().format('YYYY-MM-DD'),
        tier: rebateTier.toString(),
      },
    };

    try {
      const response = await api
        .user(CONFIG, access_token)
        .membership.updateMembership(memberid, data);

      if (response?.status === 'error') {
        throw new Error(response.message);
      } else {
        dispatch(setMembership(response));
      }
    } catch (err) {
      console.log('ERROR');
      console.log(err.message);
    }
  });

  const handleRebateChange = (event) => {
    setRebateTier(event.target.value);
    setRebatePercentage(rebateRates[event.target.value].RebatePercentage);
  };

  return (
    <CustomModal
      handleClose={() => {
        gtagEvent({
          screen: 'govrebate_tier',
          action: 'cancel',
          label: 'Cancelled or closed modal',
          type: 'modal',
        });
        handleClose();
        setTermsCheck(false);
      }}
      align={align}
      show={show}
      title={'Government Rebate'}>
      <div className={'text-primary font-feature row row-cols-1'}>
        <Form>
          <div className="col text-black pb-2 font-14 modal-box-shadow">
            <p>
              To make private health insurance more affordable, the Australian
              Government provides a rebate on premiums. Your rebate entitlement
              depends on your income and age. For more information on the rebate
              visit the Australian Taxation Office or{' '}
              <a
                href="https://privatehealth.gov.au"
                target="_blank"
                rel="noopener noreferrer">
                privatehealth.gov.au
              </a>
              .
            </p>
          </div>
          <div className="col pt-2 font-14 modal-box-shadow">
            <p className="font-weight-medium pt-2">
              Your combined household income on June 30
            </p>
            <div className="pb-2">
              <Form.Group>
                <Form.Check type={'radio'}>
                  <Form.Check.Input
                    type={'radio'}
                    name={'rebatetier'}
                    id={'rebatetier-0'}
                    value="0"
                    checked={rebateTier === '0'}
                    onChange={handleRebateChange}
                  />
                  <Form.Check.Label
                    htmlFor="rebatetier-0"
                    className="text-label-gray font-weight-medium font-13">
                    {`Base Tier: ${income[0]}`}
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check type={'radio'}>
                  <Form.Check.Input
                    type={'radio'}
                    name={'rebatetier'}
                    id={'rebatetier-1'}
                    value="1"
                    checked={rebateTier === '1'}
                    onChange={handleRebateChange}
                  />
                  <Form.Check.Label
                    htmlFor="rebatetier-1"
                    className="text-label-gray font-weight-medium font-13">
                    {`Tier 1: ${income[1]}`}
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check type={'radio'}>
                  <Form.Check.Input
                    type={'radio'}
                    name={'rebatetier'}
                    id={'rebatetier-2'}
                    value="2"
                    checked={rebateTier === '2'}
                    onChange={handleRebateChange}
                  />
                  <Form.Check.Label
                    htmlFor="rebatetier-2"
                    className="text-label-gray font-weight-medium font-13">
                    {`Tier 2: ${income[2]}`}
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check type={'radio'}>
                  <Form.Check.Input
                    type={'radio'}
                    name={'rebatetier'}
                    id={'rebatetier-3'}
                    value="3"
                    checked={rebateTier === '3'}
                    onChange={handleRebateChange}
                  />
                  <Form.Check.Label
                    htmlFor="rebatetier-3"
                    className="text-label-gray font-weight-medium font-13">
                    {`Tier 3: ${income[3]}`}
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
            </div>
          </div>
          <div className="col pt-3 pb-3 modal-box-shadow">
            <div className="d-flex align-items-center justify-content-between">
              <div className="text-uppercase font-weight-medium font-13 font-faded">
                Your eligible Government rebate:
              </div>
              <div className="text-black font-weight-bold font-18">
                {rebatePercentage}%
              </div>
            </div>
          </div>
          <div className="col pt-3">
            <Form.Check
              type="checkbox"
              id={`checkbox-terms`}
              label={`I declare that the above information is true and correct`}
              className="text-label-gray font-weight-medium font-13"
              onChange={() => {
                setTermsCheck(!termsCheck);
              }}
            />
            <div className="pt-3">
              <Button
                variant="secondary"
                disabled={
                  initRebate === rebateTier ||
                  !termsCheck ||
                  updateRebateState.loading
                }
                onClick={() => {
                  updateRebate(rebateTier);
                }}>
                <>
                  {updateRebateState.loading && (
                    <Spinner
                      animation="border"
                      role="status"
                      as="span"
                      size="sm"
                    />
                  )}
                  Save Changes
                </>
              </Button>
              <Cancel
                className="font-faded pl-3"
                onClick={() => {
                  handleClose();
                  setTermsCheck(false);
                }}
              />
            </div>
          </div>
        </Form>
      </div>
    </CustomModal>
  );
};

export default GovernmentRebateModal;
