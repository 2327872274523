import React from 'react';
import srcPng from './src.png';

const ShareCode = ({ classes }) => {
    return (
        <img
            src={srcPng}
            alt={'Share Referral Code Image'}
            className={classes}
        />
    );
};

export default ShareCode;
