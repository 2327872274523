import React from "react";

const SpecialOfferBannerTemplate = ({left, right}) => {
    return (
        <div style={{
            borderRadius: '5px',
            backgroundColor: 'white',
            margin: '.5rem',
            display: 'flex',
            padding: '.5rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
            <div style={{
                marginRight: '1rem'
            }}>
                {left}
            </div>
            <div>
                {right}
            </div>
        </div>
    )
}

export default SpecialOfferBannerTemplate;

