import React from 'react';

const TabItem = ({ title, active }) => {
    return (
        <div
            className={`${
                active
                    ? 'bg-white text-primary-dark-1'
                    : 'bg-primary-dark-1 text-light-purple'
            } font-weight-medium`}
            style={{
                padding: '15px',
                cursor: 'pointer',
                color: '#464B4E',
                fontSize: '13px',
            }}>
            <span
                className={`${
                    active ? 'd-inline-block' : 'd-none'
                } border-primary`}
                style={{
                    backgroundColor: '#FFFFFF',
                    border: '5px solid',
                    borderRadius: '40px',
                    width: '16px',
                    height: '16px',
                    marginRight: '5px',
                    position: 'relative',
                    top: '2px',
                }}></span>
            <span
                className={`${active ? 'd-none' : 'd-inline-block'}`}
                style={{
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #B7B7C0',
                    borderRadius: '40px',
                    width: '16px',
                    height: '16px',
                    marginRight: '5px',
                    position: 'relative',
                    top: '2px',
                }}></span>
            {title}
        </div>
    );
};

export default TabItem;
