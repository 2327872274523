import React from 'react';
import { Form } from 'react-bootstrap';
import { autocorrectCurrencyString } from '../functions/exports';
import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import GlobalConfig from '../../../../../src/config.json'

const PaymentAmount = ({
    isVisible,
    getFieldProps,
    setFieldValue,
    checkPremium,
    premiumAmount,
    errors: { contributionamount: errors },
}) => {
    const show = isVisible ? 'block' : 'none';

    //having the EIGHT_MONTHS_ERROR here saves over complicating the Yup Schema with fetch requests
    const EIGHT_MONTHS_ERROR =
        'Members are not allowed to pay more than eight months in advance.';
    if (premiumAmount === 'More than '+GlobalConfig.limit+' '+GlobalConfig.type+'...') errors = EIGHT_MONTHS_ERROR;

    return (
        <div className="payment-amount" style={{ display: show }}>
            <div className="tab-content">
                <Form.Group controlId="payment-amount-contribution-amount">
                    <Form.Label>Contribution amount:</Form.Label>
                    <div className="row">
                        <p className="dollar-sign">$</p>
                        <Form.Control
                            className={`payment-input ${
                                errors ? 'is-invalid' : ''
                            }`}
                            type="text"
                            {...getFieldProps('contributionamount')}
                            placeholder={'0.00'}
                            onChange={(val) => {
                                errors = undefined;
                                /**
                                 * the check premium endpoint of the
                                 * tuh-uh-api-js errors out if sent a
                                 * value greater than $144802.13
                                 * so we cap the input at $80000, which
                                 * should be more than enough.
                                 */
                                val.target.value < 80000
                                    ? setFieldValue(
                                          'contributionamount',
                                          val.target.value,
                                      )
                                    : setFieldValue(
                                          'contributionamount',
                                          80000,
                                      );

                                if (!errors) {
                                    checkPremium(
                                        val.target.value,
                                        'contributionamount',
                                    );
                                }
                            }}
                            onBlur={(val) => {
                                errors = undefined;
                                const correctedValue =
                                    autocorrectCurrencyString(val.target.value);
                                setFieldValue(
                                    'contributionamount',
                                    correctedValue,
                                );
                                if (!errors) {
                                    checkPremium(
                                        correctedValue,
                                        'contributionamount',
                                    );
                                }
                            }}
                        />
                        {errors && (
                            <StatusNotificationCard status="error">
                                {errors}
                            </StatusNotificationCard>
                        )}
                    </div>
                </Form.Group>

                <div className="font-feature text-black font-weight-medium text-uppercase covered-until">
                    You&apos;ll now be covered until:&nbsp;
                    <span
                        id="contributionamount"
                        className="font-weight-bold text-primary-dark-1">
                        {premiumAmount}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PaymentAmount;
