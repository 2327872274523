import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Spinner } from 'react-bootstrap';
// import {IoMdInformationCircle,MdCreditCard} from "react-icons/all";
import CustomModal from 'components/modals/Modal';
import Cancel from 'components/elements/buttons/Cancel';
import { Formik } from 'formik';
import { useAsyncFn } from 'react-use';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { setMembership, setPaymentMethodModalVisible } from 'store';
import { DirectDebit, CreditCard, TabItem } from './components/exports';
import { getDefaultValues } from './functions/exports';
import { BankSchema, CardSchema } from './validation/exports';
import Fade from 'react-reveal/Fade';
import moment from 'moment';
import gtagEvent from 'libs/gtagEvent';
import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import { thunkGetDebitAccount } from 'store';

const PaymentMethodModal = ({ align }) => {
    const dispatch = useDispatch();
    const membership = useSelector((state) => state.membership);

    const [successSpace, setSuccessSpace] = useState(<>&nbsp;</>);
    const [successAttempts, setSuccessAttempts] = useState(0);
    const [errorSpace, setErrorSpace] = useState(<>&nbsp;</>);
    const [failedAttempts, setFailedAttempts] = useState(0);

    const [loading, setLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState(1);
    const [iframeIsDirty, setIframeIsDirty] = useState(false);
    const [iframeIsValid, setIframeIsValid] = useState(false);
    const [showIframe, setShowIframe] = useState(false);
    const [currentPaymentMethod, setCurrentPaymentMethod] = useState('');

    const paymentMethodModalVisible = useSelector(
        (state) => state.ui.paymentMethodModalVisible,
    );

    const [defaultValues, setDefaultValues] = useState(
        getDefaultValues(membership, selectedTab),
    );

    const [creditCardExtraDataPay, setCreditCardExtraDataPay] = useState({});

    const logCreditCardUpdate = () => {
        const access_token = getAccessToken();
        const memberid = getMembershipId();

        api.user(CONFIG, access_token).log.updateCreditCard(memberid);

        return null;
    };

    function submitCC() {
        console.log('Submit CC IFRAME');
        logCreditCardUpdate();
        const iframeHambs =
            document.getElementById('iframe_hambs_pay').contentWindow;
        iframeHambs.postMessage('submitcc', '*');
    }

    const url = api.publicHelpers.publicGetFullApiUrl(
        'tuh',
        process.env.REACT_APP_API_ENVIRONMENT
    );

    function resetIframe() {
        const membershipId = membership?.MembershipId;
        const iframeHambs =
            document.getElementById('iframe_hambs_pay').contentWindow;
        //TODO: remove this if-else
        if (process.env.REACT_APP_API_ENVIRONMENT !== 'prod') {
            let iframeAction;
            if (membership?.DebitAccount?.ChargeCard) {
                iframeAction = 'edit';
            } else {
                iframeAction = 'add';
            }
            iframeHambs.location.href = `${url}/nab/store/${membershipId}?type=${iframeAction}`;
        } else {
            iframeHambs.location.href = `${url}/nab/store/${membershipId}`;
        }
    }

    const onMessageReceivedFromIframePay = (e) => {
        // console.log('DATA: ',e);
        if (e.origin !== `${url}`) {
            return;
        }
        var json = JSON.parse(e.data);
        if (json.summarycode === '1') {
            setFailedAttempts(0);
            console.log('SUCCESSFUL: ', json);
            const access_token = getAccessToken();
            const memberid = getMembershipId();

            let extraData = creditCardExtraDataPay;

            let outValues = {
                accounts: {
                    debit: {
                       type: 'cc',
                       cardno: json.pan,
                       cardtype: json.CCType,
                       accountname: extraData.cardholder,
                       expirymonth: json.expirydate.substring(0, 2),
                       expiryyear: json.expirydate.substring(2),
                       token: json.token,
                    }
                },
                payfrequency: extraData.payfrequency,
            };
            if (extraData?.paymentdate) {
                outValues.paymentdate = extraData.paymentdate;
            }

            api.user(CONFIG, access_token)
                .membership.updateMembership(memberid, outValues)
                .then((response) => {
                    if (response?.status === 'error') {
                        throw new Error(response.message);
                    } else {
                        console.log(response);
                        dispatch(setMembership(response));
                        setLoading(false);
                        response?.message
                            ? setSuccessSpace(response.message)
                            : setSuccessSpace(
                                'Payment method updated successfully.',
                            );
                        setSuccessAttempts(1);
                        setTimeout(() => {
                            setSuccessSpace(<>&nbsp;</>);
                            setSuccessAttempts(0);
                        }, 10000);
                        // Refetch the latest debitAccount details
                        dispatch(thunkGetDebitAccount())
                    }
                })
                .catch((e) => {
                    console.log('ERROR');
                    setFailedAttempts(failedAttempts + 1);
                    setErrorSpace(e.message || 'Could not connect to server');
                    setLoading(false);
                    resetIframe();
                });
        } else if (typeof json.valid !== 'undefined') {
            console.log('Valid: ', json.valid);
            if (json.valid === true) {
                setIframeIsValid(true);
            } else {
                console.log({ number: json.number });
                if (json.number > 0) {
                    setFailedAttempts(failedAttempts + 1);
                    setErrorSpace(false);
                    setLoading(false);
                    setIframeIsValid(false);
                } else {
                    setLoading(false);
                    setIframeIsValid(false);
                }
            }
        } else if (typeof json.dirty !== 'undefined') {
            console.log('DIRTY');
            setIframeIsDirty(true);
        } else {
            console.log('DATA: ', e);
            setFailedAttempts(failedAttempts + 1);
            setErrorSpace(json.message || 'Form is not complete');
            setLoading(false);
            resetIframe();
        }
    };

    useEffect(() => {
        if (paymentMethodModalVisible) {
            window.removeEventListener(
                'message',
                onMessageReceivedFromIframePay,
            );
            window.addEventListener(
                'message',
                onMessageReceivedFromIframePay,
                false,
            );
            return () => {
                window.removeEventListener(
                    'message',
                    onMessageReceivedFromIframePay,
                );
            };
        }
    }, [creditCardExtraDataPay, paymentMethodModalVisible]);

    useEffect(() => {
        if (membership?.DebitAccount?.ChargeCard) {
            setSelectedTab(0);
            setShowIframe(false);
            setCurrentPaymentMethod('cc');
        } else {
            // setSelectedTab(1);
            setShowIframe(true);
            setCurrentPaymentMethod('dd');
        }
        setDefaultValues(getDefaultValues(membership, selectedTab));
    }, [membership]);

    useEffect(() => {
        setDefaultValues(getDefaultValues(membership, selectedTab));
    }, [selectedTab]);

    const onSubmit = async (values) => {
        // actions.setSubmitting(true);
        setLoading(true);
        console.log(values.selectedTab2);

        if (values.selectedTab2 === 1) {
            let outValues = {
                accounts: {
                    debit: {
                        type: 'bank',
                        accountname: values.accountName,
                        bsb: values.bsb,
                        accountno: values.accountNumber,
                    },
                },
                payfrequency: values.bankPaymentFreq,
            };
            if (values.bankDateChange) {
                outValues.paymentdate = moment(values.bankNewDate).format(
                    'YYYY-MM-DD',
                );
            }
            // Implement logic update Claim Payments data if the checkbox is set
            if (values.sameBankAccount) {
                outValues.accounts.benefit = {
                    accountname: values.accountName,
                    bsb: values.bsb,
                    accountno: values.accountNumber,
                };
            }
            const access_token = getAccessToken();
            const memberid = getMembershipId();

            try {
                const response = await api
                    .user(CONFIG, access_token)
                    .membership.updateMembership(memberid, outValues);

                if (response?.status === 'error') {
                    throw new Error(response.message);
                } else {
                    console.log(response);
                    dispatch(setMembership(response));
                    // setDefaultValues(getDefaultValues())
                    // resetForm()
                    setLoading(false);
                    setFailedAttempts(0);
                    setErrorSpace('');
                    response?.message
                        ? setSuccessSpace(response.message)
                        : setSuccessSpace(
                            'Payment method updated successfully.',
                        );
                    setSuccessAttempts(1);
                    setTimeout(() => {
                        setSuccessSpace(<>&nbsp;</>);
                        setSuccessAttempts(0);
                    }, 10000);
                }
            } catch (response) {
                console.log('ERROR');
                setFailedAttempts(failedAttempts + 1);
                setErrorSpace(
                    response.message || 'Could not connect to server',
                );
                setLoading(false);
            }
        } else if (values.selectedTab2 === 0) {
            console.log('DIRT:', iframeIsDirty);
            if (!iframeIsDirty) {
                // We are updating data other than the CC
                let outValues = {};
                outValues.payfrequency = values.cardPaymentFreq;
                if (values.ccDateChange) {
                    outValues.paymentdate = moment(values.ccNewDate).format(
                        'YYYY-MM-DD',
                    );
                }
                const access_token = getAccessToken();
                const memberid = getMembershipId();

                try {
                    console.log('BUGHUNT', { outValues });

                    const response = await api
                        .user(CONFIG, access_token)
                        .membership.updateMembership(memberid, outValues);

                    if (response?.status === 'error') {
                        throw new Error(response.message);
                    } else {
                        console.log(response);
                        dispatch(setMembership(response));
                        response?.message
                            ? setSuccessSpace(response.message)
                            : setSuccessSpace(
                                'Payment method updated successfully.',
                            );
                        setSuccessAttempts(1);
                        setTimeout(() => {
                            setSuccessSpace(<>&nbsp;</>);
                            setSuccessAttempts(0);
                        }, 10000);
                        setLoading(false);
                        // Refetch the latest debitAccount details
                        dispatch(thunkGetDebitAccount())
                    }
                } catch (response) {
                    console.log('ERROR');
                    setFailedAttempts(failedAttempts + 1);
                    setErrorSpace(
                        response.message || 'Could not connect to server',
                    );
                    setLoading(false);
                }
            } else {
                let extraData = {};
                extraData.cardholder = values.cardholder;
                //BUGHUNT: HERE?
                extraData.payfrequency = values.cardPaymentFreq;
                if (values.ccDateChange) {
                    extraData.paymentdate = moment(values.ccNewDate).format(
                        'YYYY-MM-DD',
                    );
                }
                // console.log('BUGHUNT', { extraData });
                setCreditCardExtraDataPay(extraData);
                submitCC();
            }
        }
        // actions.setSubmitting(false);
        // console.log(outValues);
    };

    const [checkBSBState, checkBSB] = useAsyncFn(
        async (value, setFieldValue, form) => {
            if (value.match(/(?:\d{3})(?:-)?(?:\d{3})/)) {
                const access_token = getAccessToken();
                try {
                    const response = await api
                        .app(CONFIG, access_token)
                        .bsb(value);

                    if (response?.status === 'error') {
                        throw new Error(response);
                    } else {
                        console.log(response);
                        setFieldValue('bank', response.Bank);
                    }
                } catch (response) {
                    console.log({ checkBSBState });
                    console.log('ERROR');
                    console.log(response);
                    setFieldValue('bank', '');
                    form.validateForm();
                }
            }
        },
    );

    return (
        <CustomModal
            handleClose={() => {
                gtagEvent({
                    screen: 'paymentmethod',
                    action: 'cancel',
                    label: 'Cancelled or closed modal',
                    type: 'modal',
                });
                dispatch(setPaymentMethodModalVisible(false));
                if (showIframe && currentPaymentMethod === 'cc') {
                    setShowIframe(false);
                }
            }}
            align={align}
            show={paymentMethodModalVisible}
            title={'Payment Method'}>

            <div className={'text-primary row row-cols-1'}>
                <div
                    className={'d-flex align-items-center bg-primary-dark-1'}
                    style={{
                        paddingTop: '2px',
                        marginTop: '-17px',
                    }}>
                    <div
                        onClick={() => {
                            gtagEvent({
                                screen: 'paymentmethod',
                                action: 'directdebit_tab',
                                label: 'Switched to direct debit tab',
                                type: 'modal',
                            });
                            setSelectedTab(1);
                        }}>
                        <TabItem
                            title="Direct Debit"
                            active={selectedTab === 1}
                        />
                    </div>
                    <div
                        onClick={() => {
                            gtagEvent({
                                screen: 'paymentmethod',
                                action: 'creditcard_tab',
                                label: 'switched to credit card tab',
                                type: 'modal',
                            });
                            setSelectedTab(0);
                        }}>
                        <TabItem
                            title="Credit Card"
                            active={selectedTab === 0}
                        />
                    </div>
                </div>
                <Formik
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={true}
                    validateOnMount={true}
                    initialValues={defaultValues || null}
                    validationSchema={
                        selectedTab === 1 ? BankSchema : CardSchema
                    }
                    onSubmit={(values, actions) => {
                        gtagEvent({
                            screen: 'paymentmethod',
                            action: 'submit',
                            label: 'Submitted payment method change.',
                            type: 'modal',
                        });
                        onSubmit(
                            values,
                            actions,
                            setCreditCardExtraDataPay,
                            creditCardExtraDataPay,
                        );
                    }}>
                    {(props) => {
                        const {
                            form,
                            values,
                            touched,
                            errors,
                            dirty,
                            handleSubmit,
                            isValid,
                            getFieldProps,
                            setFieldValue,
                        } = props;
                        return (
                            <Form>
                                <DirectDebit
                                    isVisible={selectedTab === 1}
                                    getFieldProps={getFieldProps}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    checkBSB={checkBSB}
                                    form={form}
                                    loading={loading}
                                />
                                <CreditCard
                                    isVisible={selectedTab === 0}
                                    getFieldProps={getFieldProps}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    membership={membership}
                                    loading={loading}
                                    showIframe={showIframe}
                                    setShowIframe={setShowIframe}
                                />
                                <Form.Control
                                    type="hidden"
                                    {...getFieldProps('selectedTab2')}
                                />
                                <div className="col pt-3">
                                    <Button
                                        variant="secondary"
                                        style={{
                                            padding: '9px',
                                            marginRight: '10px',
                                        }}
                                        onClick={handleSubmit}
                                        disabled={
                                            !isValid ||
                                            loading ||
                                            (showIframe &&
                                                !iframeIsDirty &&
                                                selectedTab === 0) ||
                                            (!showIframe &&
                                                selectedTab === 0 &&
                                                !dirty) ||
                                            (selectedTab === 1 && !dirty) ||
                                            (selectedTab === 0 &&
                                                showIframe &&
                                                iframeIsDirty &&
                                                !iframeIsValid)
                                        }>
                                        {loading ? (
                                            <>
                                                <Spinner
                                                    animation="border"
                                                    role="status"
                                                    as="span"
                                                    size="sm"
                                                />
                                                Save Changes
                                            </>
                                        ) : (
                                            'Save Changes'
                                        )}
                                    </Button>

                                    <Cancel
                                        style={{
                                            color: '#9998A8',
                                        }}
                                        onClick={() => {
                                            dispatch(
                                                setPaymentMethodModalVisible(
                                                    false,
                                                ),
                                            );
                                            if (
                                                !iframeIsDirty &&
                                                showIframe &&
                                                currentPaymentMethod === 'cc'
                                            ) {
                                                setShowIframe(false);
                                            }
                                        }}
                                    />
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
                <div style={{ paddingInline: '10px' }}>
                    <Fade collapse bottom when={failedAttempts > 0}>
                        {errorSpace && (
                            <StatusNotificationCard status="error">
                                {errorSpace}
                            </StatusNotificationCard>
                        )}

                    </Fade>
                </div>
                <div style={{ paddingInline: '10px' }}>
                    <Fade collapse bottom when={successAttempts > 0}>
                        <StatusNotificationCard status="success">
                            {successSpace}
                        </StatusNotificationCard>
                    </Fade>
                </div>
            </div>
        </CustomModal>
    );
};

export default PaymentMethodModal;
