import React from 'react';
import Fade from 'react-reveal/Fade';
import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import { DUPLICATE_CLAIM_ITEM_ERROR } from '../common/constants';

interface StatusNotificationsProps {
    errorSpace: string;
    medicareMessage?: string;
    failedAttempts: number;
    isDuplicate: boolean;
}

export const StatusNotifications: React.FC<StatusNotificationsProps> = ({
    errorSpace,
    medicareMessage,
    failedAttempts,
    isDuplicate,
}) => {
    return (
        <>
            <div
                style={{
                    paddingInline: '10px',
                    width: '100%',
                    position: 'absolute',
                    top: '0px',
                }}>
                <Fade bottom when={!!medicareMessage}>
                    <StatusNotificationCard status="info">
                        {medicareMessage}
                    </StatusNotificationCard>
                </Fade>
            </div>
            <div
                style={{
                    paddingInline: '10px',
                    width: '100%',
                    position: 'absolute',
                    top: '0px',
                }}>
                <Fade collapse bottom when={failedAttempts > 0}>
                    <StatusNotificationCard status="error">
                        {errorSpace}
                    </StatusNotificationCard>
                </Fade>
            </div>
            <div
                style={{
                    paddingInline: '10px',
                    width: '100%',
                    position: 'absolute',
                    top: '0px',
                }}>
                <Fade bottom when={isDuplicate}>
                    <StatusNotificationCard status="error">
                        {DUPLICATE_CLAIM_ITEM_ERROR}
                    </StatusNotificationCard>
                </Fade>
            </div>
        </>
    );
};

export default StatusNotifications;
