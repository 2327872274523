import * as Yup from 'yup';

export const HomeOnlySchema = Yup.object().shape({
    email: Yup.string().email('Invalid email'),
    // .required('Email is required')
    mobile: Yup.string().matches(
        /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[4])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
        'Not a valid mobile number',
    ),
    mobile2: Yup.string().matches(
        /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[4])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
        'Not a valid mobile number',
    ),
    homePhone: Yup.string().matches(
        /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])?\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
        'Not a valid phone number. Must contain state code',
    ),
    workPhone: Yup.string().matches(
        /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])?\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
        'Not a valid phone number. Must contain state code',
    ),
    homeLine1: Yup.string().required('Street 1 is required'),
    homeSuburb: Yup.string().required('Suburb is required'),
    homePostcode: Yup.string()
        .length(4, 'Not a valid Postcode')
        .matches(/\d{4}/, 'Not a valid Postcode')
        .required('Postcode is required'),
});
