import gtagEvent from 'libs/gtagEvent';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, CloseButton } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { fastClaimActions } from 'store';

export interface DocumentsDataType {
  file: File;
  fileName: string;
}

interface UploadReceiptProps {
  documentsData: DocumentsDataType[];
  setDocumentsData: Dispatch<SetStateAction<DocumentsDataType[]>>;
}

const UploadReceipt = ({
  documentsData,
  setDocumentsData,
}: UploadReceiptProps) => {
  const dispatch = useDispatch();
  const [fileArray, setFileArray] = useState<File[]>([]);
  const [error, setError] = useState('');

  function changeSection() {
    dispatch(fastClaimActions.general.setStep('3'));
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      Array.from(selectedFiles).map((file, _index) => {
        setFileArray((prevFiles) => [...prevFiles, file]);
        setDocumentsData((prevDocuments) => [
          ...prevDocuments,
          { file: file, fileName: file.name },
        ]);
        return;
      });
    }
  }

  const removeDocument = (index: number) => {
    const newFileArray = fileArray.filter((_, i) => i !== index);
    setFileArray(newFileArray);
    setDocumentsData((prevDocuments) =>
      prevDocuments.filter((_, i) => i !== index),
    );
  };

  useEffect(() => {
    setError('');
    documentsData.forEach((document) => {
      const { file } = document;
      if (file.size > 20 * 1024 * 1024) {
        setError('File size should not exceed 20MB');
        return;
      }
    });
  }, [documentsData]);

  return (
    <div className="p-4">
      <div>
        <input
          type="file"
          multiple
          accept=".pdf,image/*"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="upload-receipts"
        />
        <label htmlFor="upload-receipts">
          <Button variant="outline-primary" as="span">
            Upload Receipts
          </Button>
        </label>
        {fileArray && fileArray.length > 0 && (
          <div>
            <p>Files:</p>
            <ul>
              {Array.from(fileArray).map((file, index) => (
                <li key={index} style={{ width: '500px' }}>
                  {file.name}{' '}
                  <CloseButton onClick={() => removeDocument(index)} />
                </li>
              ))}
            </ul>
          </div>
        )}
        {error && <p className="text-danger">{error}</p>}
      </div>
      <div className="d-flex flex-row-reverse">
        <Button
          variant="secondary"
          className=""
          onClick={() => {
            gtagEvent({
              screen: 'fastclaim',
              action: 'three_next',
              label: 'Clicked next button in step three of fast claim',
            });
            changeSection();
          }}
          disabled={!fileArray.length || !!error.length}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default UploadReceipt;
