import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { usePlacesAutocomplete } from '../../hooks';

export const HomeAddress = ({
    isVisible,
    onSubmitState,
    updateSchema,
    setSelectedTab,
}) => {
    const { getFieldProps, errors, touched, values, setFieldValue } =
        useFormikContext();
        console.log({values})
    const addressRef = usePlacesAutocomplete('home');
    const { usePostalSchema, setUsePostalSchema } = updateSchema;

    return (
        <div
            style={{
                display: isVisible ? 'block' : 'none',
            }}>
            <div
                className="modal-box-shadow"
                style={{
                    padding: '15px',
                }}>
                <Form.Group
                    controlId={`home-address-line1`}
                    className="text-label-gray">
                    <Form.Label>Street 1</Form.Label>
                    <Form.Control
                        type="text"
                        as="input"
                        ref={addressRef}
                        className={
                            errors.homeLine1 &&
                            touched.homeLine1 &&
                            !onSubmitState.loading &&
                            'is-invalid'
                        }
                        onChange={(e) => {
                            setFieldValue('homeLine1', `${e.target.value}`);
                        }}
                        value={values.homeLine1}
                        {...getFieldProps('homeLine1')}
                    />
                    {touched.homeLine1 &&
                        errors.homeLine1 &&
                        !onSubmitState.loading && (
                            <Form.Control.Feedback type={'invalid'}>
                                {errors.homeLine1}
                            </Form.Control.Feedback>
                        )}
                </Form.Group>
                <Form.Group
                    controlId={`home-address-line2`}
                    className="text-label-gray">
                    <Form.Label>Street 2</Form.Label>
                    <Form.Control
                        type="text"
                        className={
                            errors.homeLine2 &&
                            touched.homeLine2 &&
                            !onSubmitState.loading &&
                            'is-invalid'
                        }
                        {...getFieldProps('homeLine2')}
                    />
                    {touched.homeLine2 &&
                        errors.homeLine2 &&
                        !onSubmitState.loading && (
                            <Form.Control.Feedback type={'invalid'}>
                                {errors.homeLine2}
                            </Form.Control.Feedback>
                        )}
                </Form.Group>
                <div className="d-flex align-items-center justify-content-between row">
                    <Form.Group
                        controlId={`home-address-suburb`}
                        className="col-6 text-label-gray">
                        <Form.Label>City / Suburb</Form.Label>
                        <Form.Control
                            type="text"
                            className={
                                errors.homeSuburb &&
                                touched.homeSuburb &&
                                !onSubmitState.loading &&
                                'is-invalid'
                            }
                            {...getFieldProps('homeSuburb')}
                        />
                        {touched.homeSuburb &&
                            errors.homeSuburb &&
                            !onSubmitState.loading && (
                                <Form.Control.Feedback
                                    type={'invalid'}
                                    style={{ minHeight: '38px' }}>
                                    {errors.homeSuburb}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group
                        controlId={`home-address-state`}
                        className="col-3 text-label-gray">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                            as="select"
                            className={
                                errors.homeState &&
                                touched.homeState &&
                                !onSubmitState.loading &&
                                'is-invalid'
                            }
                            {...getFieldProps('homeState')}>
                            <option value="QLD">QLD</option>
                            <option value="NSW">NSW</option>
                            <option value="VIC">VIC</option>
                            <option value="TAS">TAS</option>
                            <option value="ACT">ACT</option>
                            <option value="SA">SA</option>
                            <option value="WA">WA</option>
                            <option value="NT">NT</option>
                        </Form.Control>
                        {touched.homeState &&
                            errors.homeState &&
                            !onSubmitState.loading && (
                                <Form.Control.Feedback type={'invalid'}>
                                    {errors.homeState}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                    <Form.Group
                        controlId={`home-address-postcode`}
                        className="col-3 text-label-gray">
                        <Form.Label>Postcode</Form.Label>
                        <Form.Control
                            type="text"
                            className={
                                errors.homePostcode &&
                                touched.homePostcode &&
                                !onSubmitState.loading &&
                                'is-invalid'
                            }
                
                            {...getFieldProps('homePostcode')}
                        />
                        {touched.homePostcode &&
                            errors.homePostcode &&
                            !onSubmitState.loading && (
                                <Form.Control.Feedback type={'invalid'}>
                                    {errors.homePostcode}
                                </Form.Control.Feedback>
                            )}
                    </Form.Group>
                </div>
                <div>
                    {console.log({values})};
                    <Form.Check
                        type="checkbox"
                        id={`checkbox-terms`}
                        label={`Same postal address`}
                        className={
                            errors.homeSame &&
                            touched.homeSame &&
                            !onSubmitState.loading
                                ? 'is-invalid text-label-gray font-weight-medium font-13'
                                : 'text-label-gray font-weight-medium font-13'
                        }
                        name={'homeSame'}
                        checked={!usePostalSchema}
                        onChange={(val) => {
                            setUsePostalSchema(!val.target.checked);
                            setFieldValue('homeSame', val.target.checked);
                            
                            if (!val.target.checked) {
                                setFieldValue('postSuburb', '');
                                setFieldValue('postState', '');
                                //setFieldValue('postPostcode', '');
                                setFieldValue('postLine1', '');
                                setFieldValue('postLine2', '');
                                setSelectedTab(0); //switch to postal address tab
                            } else {
                                setFieldValue('postSuburb', values.homeSuburb);
                                setFieldValue('postState', values.homeState);
                                // setFieldValue(
                                //     'postPostcode',
                                //     values.homePostcode,
                                // );
                                setFieldValue('postLine1', values.homeLine1);
                                setFieldValue('postLine2', values.homeLine2);
                            }
                        }}
                    />
                    {touched.homeSame &&
                        errors.homeSame &&
                        !onSubmitState.loading && (
                            <Form.Control.Feedback type={'invalid'}>
                                {errors.homeSame}
                            </Form.Control.Feedback>
                        )}
                </div>
            </div>
        </div>
    );
};

export default HomeAddress;
