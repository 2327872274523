import React from 'react';

const HeroTitle = ({ children }) => {
    return (
        <div className="col-12 col-md-7 order-1 order-md-2 d-flex align-self-center justify-content-center">
            <div className="col-12 justify-content-start hero-title">
                {children}
            </div>
        </div>
    );
};

export default HeroTitle;
