import BenefitCalculator from 'components/elements/benefitCalculator/benefitCalculator';
import ProgressBar from 'components/elements/etc/ProgressBar';
import CustomModal from 'components/modals/Modal';
import ExtrasInclusionTable from 'components/sections/overview/ExtrasInclusionTable';
import moment from 'moment';
import React, { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { IoMdCalculator } from 'react-icons/all';
import gtagEvent from 'libs/gtagEvent';

const ExtrasLimitsModal = ({
    show,
    handleClose,
    title,
    currentExtraBenefit,
}) => {
    const [pane, setPane] = useState('sublimits');
    const tabStyle = (tabName, activeTab) => {
        return tabName === activeTab
            ? 'modal-pane-active'
            : 'modal-pane-inactive';
    };
    console.log({BenefitLimitStatuses:currentExtraBenefit});
    const showPowerBar = ['Den', 'Opt'].includes(
        currentExtraBenefit?.SpecialityClassCode,
    );

    return (
        <CustomModal
            title={title}
            show={show}
            handleClose={handleClose}
            align="right">
            {showPowerBar && (
                <div className="row justify-content-between py-3">
                    <div className="col-5 font-weight-medium">
                        MEMBERSHIP USAGE
                    </div>
                    <div className="col-7">
                        <div className="d-flex justify-content-between">
                            <div>
                                <span className="font-weight-bold font-18">
                                    ${currentExtraBenefit.ClaimableAmount}
                                </span>
                                <span className="font-weight-medium pl-1 font-14 font-faded">
                                    remaining
                                </span>
                            </div>
                            <div className="font-weight-medium font-14 font-faded">
                                ${currentExtraBenefit?.ClaimableTotal}
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <ProgressBar
                            value={currentExtraBenefit.ClaimedAmount}
                            maxValue={currentExtraBenefit?.ClaimableTotal}
                        />
                    </div>
                    <div className="col-12 text-right pt-2 pb-1 font-12 font-weight-normal">
                        Usage period resets
                        <span className="font-weight-medium">
                            &nbsp;1 Jan {moment().add(1, 'year').format('YYYY')}
                        </span>
                    </div>
                </div>
            )}
            {!showPowerBar && (
                <div className="py-1 font-12 font-weight-normal d-flex">
                    Usage period resets
                    <span className="font-weight-medium">
                        &nbsp;1 Jan {moment().add(1, 'year').format('YYYY')}
                    </span>
                </div>
            )}
            <Tab.Container
                id="limits-tabs2"
                activeKey={pane}
                onSelect={(tab) => setPane(tab)}>
                <div className="row">
                    <Nav className="flex-grow-1 modal-pane-background">
                        <Nav.Item>
                            <Nav.Link
                                onClick={() => {
                                    gtagEvent({
                                        screen: currentExtraBenefit.eventName,
                                        action: 'sublimits',
                                        label: 'Clicked on extras modal sublimits tab',
                                        type: 'modal',
                                    });
                                }}
                                eventKey="sublimits"
                                className={tabStyle('sublimits', pane)}>
                                SUB-LIMITS
                            </Nav.Link>
                        </Nav.Item>
                        {currentExtraBenefit?.SpecialityClassCode !==
                            'SPDen' && (
                            <Nav.Item>
                                <Nav.Link
                                    onClick={() => {
                                        gtagEvent({
                                            screen: currentExtraBenefit.eventName,
                                            action: 'benefitcalc',
                                            label: 'Clicked on extras modal benefits calculator tab',
                                            type: 'modal',
                                        });
                                    }}
                                    eventKey="benefitcalculator"
                                    className={tabStyle(
                                        'benefitcalculator',
                                        pane,
                                    )}>
                                    <IoMdCalculator
                                        style={{
                                            verticalAlign: 'middle',
                                            marginRight: '3px',
                                        }}
                                    />
                                    Benefit Calculator
                                </Nav.Link>
                            </Nav.Item>
                        )}
                    </Nav>
                </div>
                <Tab.Content>
                    <Tab.Pane eventKey="sublimits">
                        <ExtrasInclusionTable
                            className="mt-2 benefit-limits-table"
                            rows={
                                currentExtraBenefit?.BenefitLimitStatuses
                            }
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="benefitcalculator">
                        <div className="pt-3">
                            <BenefitCalculator
                                personHide={true}
                                speciality={
                                    currentExtraBenefit?.SpecialityClassCode
                                }
                                handleClose={handleClose}
                            />
                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </CustomModal>
    );
};

export default ExtrasLimitsModal;
