import RebateRatesActionTypes from './actions-types';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case RebateRatesActionTypes.SET_REBATE_RATES:
            if (process.env.REACT_APP_ENVIRONMENT==="development") {
                console.log(`SET_REBATE_RATES with payload ${action.payload}`)
            }
            return action.payload;
        default:
            return state;
    }
};
