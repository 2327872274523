import { getAccessToken, getMembershipId } from 'libs/auth';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';
import moment from 'moment';
import GlobalConfig from '../../../../../src/config.json'

const calculateCoverDateAsync = async (value, abortSignal) => {
    const memberid = getMembershipId();
    const access_token = getAccessToken();

    /** @type {{ paymentAmount: String, failedAttempts: String, premiumAmount:String,}} */
    const CoverConfig = {};

    if (value === '') {
        CoverConfig.paymentAmount = null;
        CoverConfig.failedAttempts = 0;
        CoverConfig.premiumAmount = null;
        return CoverConfig;
    }

    try {
        const response = await api
            .user(CONFIG, access_token)
            .membership.premium.premiumEstimate(memberid, value, abortSignal);
        if (response?.status === 'error') throw new Error(response);

        const lessThanLimit = moment(response.PaidToDate).isBefore(
            moment().add(GlobalConfig.Cover.limit, GlobalConfig.Cover.type),
        );
        CoverConfig.paymentAmount = value.amount;
        CoverConfig.premiumAmount = response.PaidToDate;
        if (!lessThanLimit) {
            CoverConfig.paymentAmount = 0;
            CoverConfig.premiumAmount = 'More than $period ...';
            CoverConfig.premiumAmount = CoverConfig.premiumAmount.replace('$period',GlobalConfig.Cover.limit + ' ' + GlobalConfig.Cover.type);
        }
        return CoverConfig;
    } catch (response) {
        // Continuation logic has already been skipped, so return normally
        if (response.name === 'AbortError') return;

        console.log('ERROR');
        console.log({ response });
        CoverConfig.premiumAmount = '--';
        return CoverConfig;
    }
};

export default calculateCoverDateAsync;
