import * as Yup from 'yup';

export const ReferAFriendSchema = Yup.object().shape({
    friendFirst: Yup.string().required('First name is required'),
    friendLast: Yup.string().required('Last name is required'),
    friendMobile: Yup.string()
        .matches(
            /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[4])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
            'Not a valid mobile number',
        )
        .required('Mobile number is required'),
    friendEmail: Yup.string()
        .email('Not a valid email')
        .required('Email is required'),
});
