import React, { useEffect } from 'react';
import {
    Redirect,
    Route,
    Switch,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import api from '@qldtuh/tuh-uh-js-api';
import { STRINGS, CONFIG } from '../libs/constants';
import {
    setHeartbeat,
    getHeartbeat,
    isLoggedIn,
    getAccessToken,
} from '../libs/auth';
import { useHistory } from 'react-router-dom';

import { useTracking } from 'libs/useTracking';
import gtagEvent from 'libs/gtagEvent';
import Header from 'components/elements/header/Header';
import AuthenticationPage from 'pages/public/authentication/AuthenticationPage';
import OverviewPage from 'pages/members/OverviewPage';
import MembershipPage from 'pages/members/MembershipPage';
import CoverPage from 'pages/members/CoverPage';
import ClaimPage from 'pages/members/ClaimsPage';
import ContactPage from 'pages/members/ContactPage';
import ProviderPage from 'pages/members/ProviderPage';
import SupportPage from 'pages/members/SupportPage';
import Footer from '../components/elements/footer/Footer';
import ForgotPasswordPage from 'pages/public/forgotPassword/ForgotPasswordPage';
import RegisterPage from '../pages/public/register/RegisterPage';
import StartCoverChangePage from '../pages/members/cover/StartCoverChangePage';
import ChooseCoverPage from '../pages/members/cover/ChooseCoverPage';
import QuotePage from '../pages/members/cover/QuotePage';
import WellbeingPage from '../pages/members/WellbeingPage';
import ReferAFriendPage from '../pages/members/ReferAFriendPage';
import {
    thunkGetMembership,
    thunkGetMembershipCached,
    thunkGetPremium,
    thunkGetRebateRates,
    thunkGetProduct,
    thunkGetLHC,
    thunkGetClaimsHistory,
    thunkRefreshLogin,
    setSelectedUser,
    thunkGetHra,
    thunkGetAHB,
    thunkLogout,
    thunkGetTitles,
    thunkGetRelationships,
    thunkGetClinicalCategories,
    thunkGetHealthFunds,
    thunkGetBenefitLimits,
    thunkGetClaimsSpeciality,
    thunkGetLastProviders,
    thunkGetUserSettings,
    thunkGetRebateFormShow,
    thunkGetDebitAccount,
    setPaymentMethodModalVisible,
    setEditPersonModalVisible,
    setHraModalVisible,
    setLogoutMessage,
    setTerminatedModalVisible,
    setReferAFriendModalVisible,
    setClaimPaymentsModalVisible,
    setBenefitCalculatorModalVisible,
    setRebateFormModalVisible,
    setClaimDisclaimerModalVisible,
    setOnlineClaimServicesVisible,
    thunkGetSettings,
    thunkGetReferCode,
    setLogoutMessageColour,
} from 'store';
import { ResetPasswordPage } from '../pages/ResetPassword';
import ChangePasswordForgotPage from 'pages/public/changePassword/ChangePasswordForgotPage';
import { StartNewClaimPage } from '../pages/members/claim/StartNewClaimPage';
import { FasterClaimPage } from '../pages/members/claim/FasterClaimPage';
import { ThankYouClaimPage } from '../pages/members/claim/ThankYouClaimPage';
import { allPersons } from '../libs/helpers';
import TerminatedModal from 'components/modals/TerminatedModal/TerminatedModal';
import ReferAFriendModal from 'components/modals/ReferAFriendModal/ReferAFriendModal';
import ClaimPaymentsModal from 'components/modals/ClaimPaymentsModal/ClaimPaymentsModal';
import BenefitCalculatorModal from 'components/modals/BenefitCalculatorModal/BenefitCalculatorModal';
import PaymentMethodModal from 'components/modals/PaymentMethodModal/PaymentMethodModal';
import RebateFormModal from 'components/modals/RebateForm/RebateFormModal';
import ClaimDisclaimerModal from 'components/modals/ClaimDisclaimerModal/ClaimDisclaimerModal';
import OnlineClaimServicesModal from 'components/modals/OnlineClaimServices/OnlineClaimServices';

const MemberRoutes = () => {
    let match = useRouteMatch();

    const dispatch = useDispatch();
    const history = useHistory();
    const memberId = useSelector((store) => store.user.memberId);
    const membership = useSelector((store) => store.membership);
    const selectedUser = useSelector((store) => store.selectedUser);
    const rebateForm = useSelector((store) => store.rebateForm);

    const terminatedModalVisible = useSelector(
        (state) => state.ui.terminatedModalVisible,
    );
    const referAFriendModalVisible = useSelector(
        (state) => state.ui.referAFriendModalVisible,
    );
    const claimPaymentsModalVisible = useSelector(
        (state) => state.ui.claimPaymentsModalVisible,
    );
    const benefitCalculatorModalVisible = useSelector(
        (state) => state.ui.benefitCalculatorModalVisible,
    );
    const paymentMethodModalVisible = useSelector(
        (state) => state.ui.benefitCalculatorModalVisible,
    );
    const claimDisclaimerModalVisible = useSelector(
        (state) => state.ui.claimDisclaimerModalVisible,
    );
    const onlineClaimServicesVisible = useSelector(
        (state) => state.ui.onlineClaimServicesVisible,
    );

    const rebateFormModalVisible = useSelector(
        (state) => state.ui.rebateFormModalVisible,
    );
    const rebateFormStep = useSelector((state) => state.rebateForm.step);

    const location = useLocation();

    const logoutWithMessage = (message) => {
        dispatch(setLogoutMessage(message));
        dispatch(thunkLogout());
        history.replace('/');
    };

    useEffect(() => {
        dispatch(setLogoutMessageColour('#67C8C6'));
        dispatch(thunkGetSettings());
        dispatch(thunkRefreshLogin())
            .then(() => {
                dispatch(thunkGetMembershipCached())
                .then(() => {
                    console.log('ENV: ', process.env.REACT_APP_ENVIRONMENT);
                    dispatch(thunkGetMembership());
                    dispatch(thunkGetRebateRates());
                    dispatch(thunkGetProduct());
                    dispatch(thunkGetClinicalCategories());
                    dispatch(thunkGetBenefitLimits());
                    dispatch(thunkGetLHC());
                    dispatch(thunkGetHra());
                    dispatch(thunkGetAHB());
                    dispatch(thunkGetClaimsHistory());
                    dispatch(thunkGetLastProviders());
                    dispatch(thunkGetUserSettings());
                    dispatch(thunkGetRebateFormShow());
                    dispatch(thunkGetReferCode());
                    dispatch(thunkGetDebitAccount());

                    // Not super important calls
                    dispatch(thunkGetTitles());
                    dispatch(thunkGetRelationships());
                    dispatch(thunkGetHealthFunds());
                    dispatch(thunkGetClaimsSpeciality());
                });
            })
            .catch(() => {
                logoutWithMessage(
                    'Could not refresh login, please login again',
                );
            });
    }, []);

    useEffect(() => {
        if (membership?.status === 'error') {
            logoutWithMessage(
                'Could not retrieve membership details, please try again later.',
            );
        }
    }, [membership]);

    useEffect(() => {
        const rebatePopupShown = sessionStorage.getItem('rebatePopupShown');

        if (membership?.Contribution) {
            if (!membership?.Contribution?.RebateFormFilled) {
                if (rebateForm?.show && !rebatePopupShown) {
                    dispatch(setRebateFormModalVisible(true));
                }
            }
        }
    }, [rebateForm, membership]);

    useEffect(() => {
      if (membership !== null) {
        dispatch(thunkGetPremium());
        if (membership?.HealthCover?.CoverStatus === 'Terminated') {
          dispatch(setTerminatedModalVisible(true));
        }
      }
      if (!selectedUser && membership?.MembershipPersons) {
        const memberPerson = allPersons(membership).find((person) => person.RelationshipCode === 'Membr') || allPersons(membership)[0].Person.PersonId;
        dispatch(setSelectedUser(memberPerson?.Person?.PersonId));
      }
    }, [membership]);

    useEffect(() => {
        let timer = setInterval(() => {
            const access_token_expires = localStorage.getItem(
                STRINGS.ACCESS_TOKEN_EXPIRES,
            );
            // console.log('ACCESS_TOKEN_EXPIRES: ',access_token_expires);
            const currentTime = Math.round(new Date().getTime() / 1000);
            // console.log('CURRENTTIME: ',currentTime);
            if (currentTime >= access_token_expires) {
                dispatch(thunkRefreshLogin()).catch(() => {
                    dispatch(
                        setLogoutMessage(
                            'Could not refresh login, please login again',
                        ),
                    );
                    dispatch(thunkLogout());
                    history.replace('/');
                });
            }
        }, 10000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const lastHeartbeat = getHeartbeat();
        let startHeartbeatTimer = false;
        if (lastHeartbeat) {
            // console.log(lastHeartbeat);
            const heartbeatDiff = new Date().getTime() - lastHeartbeat;
            const timeoutTime = 1000 * 60 * 30;
            if (heartbeatDiff > timeoutTime) {
                // console.log('We should log the user out as they had been away for a while');
                api.user(CONFIG, getAccessToken())
                    .logout(memberId)
                    .then(() => {
                        dispatch(
                            setLogoutMessage(
                                'You have been logged out due to inactivity for 30 minutes.',
                            ),
                        );
                        dispatch(thunkLogout());
                        history.replace('/');
                    })
                    .catch(() => {
                        // TODO!
                        alert('Error');
                    });
            } else {
                // console.log('We are good to stay logged in');
                startHeartbeatTimer = true;
            }
        } else {
            setHeartbeat(new Date().getTime());
            startHeartbeatTimer = true;
        }

        let timer;
        if (startHeartbeatTimer) {
            timer = setInterval(() => {
                setHeartbeat(new Date().getTime());
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        // Set modals that are in redux back to false
        dispatch(setPaymentMethodModalVisible(false));
        dispatch(setEditPersonModalVisible(false));
        dispatch(setHraModalVisible(false));
        dispatch(setClaimPaymentsModalVisible(false));
        dispatch(setBenefitCalculatorModalVisible(false));
        dispatch(setRebateFormModalVisible(false));
        dispatch(setClaimDisclaimerModalVisible(false));
        dispatch(setOnlineClaimServicesVisible(false));
    }, [location]);

    const handleOnIdle = () => {
        // console.log('user is idle', event)
        // console.log('last active', getLastActiveTime())
        api.user(CONFIG, getAccessToken())
            .logout(memberId)
            .then(() => {
                dispatch(
                    setLogoutMessage(
                        'You have been logged out due to inactivity for 30 minutes.',
                    ),
                );
                dispatch(thunkLogout());
                history.replace('/');
            })
            .catch(() => {
                // TODO!
                alert('Error');
            });
    };

    const handleOnActive = () => {
        // console.log('user is active', event)
        // console.log('time remaining', getRemainingTime())
    };

    const handleOnAction = () => {
        // console.log('user did something', event)
    };

    const { _getRemainingTime, _getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 30,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500,
    });

    console.log('Unused Vars: ', { _getRemainingTime, _getLastActiveTime });

    return (
        <div className="d-flex flex-column h-100">
            <Header />
            <div className="h-100 d-flex flex-column justify-content-between">
                <Switch>
                    <Route path={`${match.path}/membership`}>
                        <MembershipPage />
                    </Route>
                    <Route path={`${match.path}/cover`}>
                        <CoverPage />
                    </Route>
                    <Route path={`${match.path}/claim/start`}>
                        <StartNewClaimPage />
                    </Route>
                    <Route path={`${match.path}/claim/new`}>
                        <FasterClaimPage />
                    </Route>
                    <Route path={`${match.path}/claim/thankyou`}>
                        <ThankYouClaimPage />
                    </Route>
                    <Route path={`${match.path}/claim`}>
                        <ClaimPage />
                    </Route>
                    <Route path={`${match.path}/provider`}>
                        <ProviderPage />
                    </Route>
                    <Route path={`${match.path}/support`}>
                        <SupportPage />
                    </Route>
                    <Route path={`${match.path}/wellbeing`}>
                        <WellbeingPage />
                    </Route>
                    <Route path={`${match.path}/contact`}>
                        <ContactPage />
                    </Route>
                    <Route path={`${match.path}/coverchange/start`}>
                        <StartCoverChangePage />
                    </Route>
                    <Route path={`${match.path}/coverchange/choose`}>
                        <ChooseCoverPage />
                    </Route>
                    <Route path={`${match.path}/coverchange/quote`}>
                        <QuotePage />
                    </Route>
                    <Route path={`${match.path}/refer`}>
                        <ReferAFriendPage />
                    </Route>
                    <Route>
                        <OverviewPage path={match.path} />
                    </Route>
                </Switch>
                <Footer />
            </div>
            <TerminatedModal
                handleClose={() => {
                    dispatch(setTerminatedModalVisible(false));
                    dispatch(
                        setLogoutMessage(
                            'You have been successfully logged out.',
                        ),
                    );
                    dispatch(thunkLogout());
                    history.replace('/');
                }}
                show={terminatedModalVisible}
                align={'center'}
            />
            <ReferAFriendModal
                handleClose={() => {
                    console.log('closed modal - uwu ######');
                    gtagEvent({
                        screen: 'referafriend',
                        action: 'cancel',
                        label: 'Cancelled or closed modal',
                        type: 'modal',
                    });
                    dispatch(setReferAFriendModalVisible(false));
                }}
                show={referAFriendModalVisible}
                align={'right'}
            />
            <ClaimPaymentsModal
                handleClose={() => {
                    gtagEvent({
                        screen: 'claimpayments',
                        action: 'cancel',
                        label: 'Cancelled or closed modal',
                        type: 'modal',
                    });
                    dispatch(setClaimPaymentsModalVisible(false));
                }}
                show={claimPaymentsModalVisible}
                align={'right'}
            />
            <BenefitCalculatorModal
                handleClose={() => {
                    gtagEvent({
                        screen: 'benefitcalc',
                        action: 'cancel',
                        label: 'Cancelled or closed modal',
                        type: 'modal',
                    });
                    dispatch(setBenefitCalculatorModalVisible(false));
                }}
                show={benefitCalculatorModalVisible}
                align={'right'}
            />
            <PaymentMethodModal
                handleClose={() => {
                    gtagEvent({
                        screen: 'paymentmethod',
                        action: 'cancel',
                        label: 'Cancelled or closed modal',
                        type: 'modal',
                    });
                    dispatch(setPaymentMethodModalVisible(false));
                }}
                show={paymentMethodModalVisible}
                align={'right'}
            />
            <RebateFormModal
                handleClose={() => {
                    if (rebateFormStep === 5) {
                        gtagEvent({
                            screen: 'govrebate',
                            action: 'cancel',
                            label: 'Cancelled or closed modal',
                            type: 'modal',
                        });
                        dispatch(setRebateFormModalVisible(false));
                    }
                }}
                show={rebateFormModalVisible}
                align={'center'}
            />
            <ClaimDisclaimerModal
                handleClose={() => {
                    dispatch(setClaimDisclaimerModalVisible(false));
                }}
                show={claimDisclaimerModalVisible}
                align={'center'}
            />
            <OnlineClaimServicesModal
                handleClose={() => {
                    dispatch(setOnlineClaimServicesVisible(false));
                }}
                show={onlineClaimServicesVisible}
                align={'right'}
            />
        </div>
    );
};

const Router = () => {
    const location = useLocation();
    useTracking(process.env.REACT_APP_GOOGLE_TRACKING_ID);

    if (isLoggedIn() && location.pathname === '/') {
        return <Redirect to="/member" />;
    }

    return (
        <Switch>
            <PrivateRoute path="/member">
                <MemberRoutes />
            </PrivateRoute>

            <Route path="/forgot">
                <ForgotPasswordPage />
            </Route>

            <Route path="/register">
                <RegisterPage />
            </Route>

            {/* From email deeplink */}
            <Route path="/resetpassword">
                <ResetPasswordPage />
            </Route>

            {/* Interface for setting new password */}
            <Route path="/changepassword">
                <ChangePasswordForgotPage />
            </Route>

            <Route path="/">
                <AuthenticationPage />
            </Route>
        </Switch>
    );
};

export default Router;
