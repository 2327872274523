import * as Yup from 'yup';
import moment from 'moment';

const registrationSchema = Yup.object().shape({
    memberId: Yup.string()
        .matches(/^\d+$/, 'Member Number can only contain numbers')
        .max(8, 'Member Number must be no greater than 8 characters')
        .required('Member Number is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    dob: Yup.string()
        .required('Date of Birth is required')
        .test({
            name: 'not-in-the-future',
            message: 'DOB cannot be in the future',
            test: (value) => {
                const selectedDate = moment(value);
                const now = moment();
                return selectedDate.isBefore(now);
            },
        })
        .max(moment().toDate(), 'DOB cannot be in the future'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .oneOf([Yup.ref('passwordConfirmation'), null], 'Passwords must match')
        .max(20, 'Password cannot be longer than 20 characters')
        .matches(
            /[A-Z]/,
            'Password must contain at least one uppercase character.',
        )
        .matches(
            /[a-z]/,
            'Password must contain at least one lowercase character.',
        )
        .matches(/[0-9]/, 'Password must contain at least one number.')
        .test({
            name: 'no-illegal',
            message: 'Password cannot contain <, >, &, # or spaces.',
            test: (value) => {
                if (!value) return true;
                return !(
                    value.includes('#') ||
                    value.includes('<') ||
                    value.includes('<') ||
                    value.includes('>') ||
                    value.includes(' ')
                );
            },
        }),
    passwordConfirmation: Yup.string()
        //.oneOf([Yup.ref('password'), null], 'Passwords must match')
        .min(8, 'Password must be at least 8 characters')
        .max(20, 'Password cannot be longer than 20 characters')
        .matches(
            /[A-Z]/,
            'Password must contain at least one uppercase character.',
        )
        .matches(
            /[a-z]/,
            'Password must contain at least one lowercase character.',
        )
        .matches(/[0-9]/, 'Password must contain at least one number.')
        .test({
            name: 'no-illegal',
            message: 'Password cannot contain <, >, &, # or spaces.',
            test: (value) => {
                if (!value) return true;
                return !(
                    value.includes('#') ||
                    value.includes('<') ||
                    value.includes('<') ||
                    value.includes('>') ||
                    value.includes(' ')
                );
            },
        }),
});

export default registrationSchema;
