import React from 'react';
import { useDispatch } from 'react-redux';
import { setRebateFormModalVisible } from 'store';
import { Button } from 'react-bootstrap';
import { setRebateFormStep } from 'store';
import gtagEvent from 'libs/gtagEvent';
import { useAsyncFn } from 'react-use';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { setMembership } from 'store/membership/actions';

const DeclineConfirmation = () => {
    const dispatch = useDispatch();

    const [onSubmitState, onSubmit] = useAsyncFn(async () => {
        const access_token = getAccessToken();
        const memberid = getMembershipId();

        try {
            const response = await api
                .medicare(CONFIG, access_token)
                .sendMedicare(memberid, { portal: '1' });
            if (response?.status === 'error') {
                throw new Error(response.message);
            } else {
                dispatch(setMembership(response));
            }
        } catch (error) {
            console.log({ onSubmitState });
            console.log(error.message);
        }
    });

    return (
        <div className="rebate-form-step-6">
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="col-12 text-primary font-21 pb-3 font-weight-bold text-center"
                        style={{ fontSize: '21px' }}>
                        Are you sure you want to decline the Rebate?
                    </div>
                    <div
                        className="col-12 col-md-8 offset-md-2 text-center"
                        style={{ fontSize: '14px' }}>
                        <p>
                            Are you sure you wish to decline claiming the
                            Australian Government Rebate on your Private Health
                            Insurance premiums? When joining TUH you indicated
                            you would like to claim the Australian Government
                            Rebate as a reduced premium and your premium has
                            been calculated based on this. If you decline this
                            Rebate form you will pay more for your premiums.
                        </p>
                    </div>
                    <div className="col-12 text-center">
                        <Button
                            variant="link"
                            style={{
                                textTransform: 'uppercase',
                                padding: 20,
                                color: '#AAA',
                            }}
                            onClick={() => {
                                gtagEvent({
                                    screen: 'govrebate',
                                    action: 'decline_rebate',
                                    label: 'Declined gov rebate after are you sure prompt',
                                    type: 'modal',
                                });
                                dispatch(setRebateFormModalVisible(false));
                                sessionStorage.setItem('rebatePopupShown', 'true');
                                onSubmit();
                            }}>
                            Decline Rebate
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'govrebate',
                                    action: 'reconsider',
                                    label: 'went back to gov rebate offer after initial decline',
                                    type: 'modal',
                                });
                                dispatch(setRebateFormStep(1));
                            }}>
                            Go Back
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeclineConfirmation;
