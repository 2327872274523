import PaymentActionTypes from './actions-types';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {

        case PaymentActionTypes.SET_DEBIT:
            if (process.env.REACT_APP_ENVIRONMENT === 'development') {
                console.log(`SET_DEBIT with payload ${action.payload}`);
            }
            return action.payload;
        default:
            return state;
    }
}