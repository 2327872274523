import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { genderDisabled } from '../../helpers';

export const GenderSelect = ({ edit = false }) => {
    const { values, setFieldValue } = useFormikContext();
    return (
        <div className="col-12">
            <Form.Check
                inline
                type="radio"
                id={`gender-1`}
                label={`Male`}
                name={`gender`}
                className={'text-label-gray font-weight-medium'}
                style={{
                    fontSize: '13px',
                }}
                checked={values.gender === 'Male'}
                onChange={() => {
                    setFieldValue('gender', 'Male');
                }}
                disabled={genderDisabled(values) || edit}
                readOnly={edit}
            />
            <Form.Check
                inline
                type="radio"
                id={`gender-2`}
                label={`Female`}
                name={`gender`}
                className={'text-label-gray font-weight-medium'}
                style={{
                    fontSize: '13px',
                }}
                checked={values.gender === 'Female'}
                onChange={() => {
                    setFieldValue('gender', 'Female');
                }}
                disabled={genderDisabled(values) || edit}
                readOnly={edit}
            />
        </div>
    );
};

export default GenderSelect;
