import HraActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';

export const setHra = (hra) => ({
    type: HraActionTypes.SET_HRA,
    payload: hra,
});

export const thunkGetHra = () => (dispatch, _getState) => {
    const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    api.hra(CONFIG, token)
        .getTokens(memberId)
        .then((response) => {
            dispatch(setHra(response));
        });
};
