import React from 'react';

interface Props {
    image: string;
    link: string;
    alt: string;
    gtag: () => void;
}

const DownloadButton: React.FC<Props> = ({ image, link, alt, gtag }) => {
    return (
        <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ zIndex: 1, margin: '.5rem' }}
            onClick={() => gtag()}>
            <img alt={alt} style={{ height: '40px' }} src={image} />
        </a>
    );
};

export default DownloadButton;
