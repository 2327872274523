import React from 'react';
import CustomModal from 'components/modals/Modal';

const SuccessModal = ({ show, handleClose, align }) => {
    return (
        <CustomModal
            handleClose={handleClose}
            align={align}
            show={show}
            title={'Terminated Membership'}>
            This membership has been terminated. Please contact TUH to
            reactivate this account. Closing this modal will log you out.
        </CustomModal>
    );
};

export default SuccessModal;
