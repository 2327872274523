import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const SubmitButton = ({ loading, edit = false }) => {
    const { values, isValid, handleSubmit } = useFormikContext();
    const ACTION = edit ? 'Update' : 'Add';
    return (
        <div>
            <Button
                variant="secondary"
                style={{
                    padding: '9px',
                    marginRight: '10px',
                }}
                onClick={handleSubmit}
                disabled={!isValid || loading || values.disablesubmit}>
                {loading ? (
                    <>
                        <Spinner
                            animation="border"
                            role="status"
                            as="span"
                            size="sm"
                        />
                        {ACTION} Person
                    </>
                ) : (
                    `${ACTION} Person`
                )}
            </Button>
        </div>
    );
};

export default SubmitButton;
