import React from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import CardHorizontalRule from 'components/elements/divisions/CardHorizontalRule';
import {
    MdLocalPhone,
    FaFax,
    MdEmail,
    IoIosClock,
    FaMapMarkerAlt,
} from 'react-icons/all';
import { StoreType } from 'store/types';

const ContactUsCard = () => {
    const settings = useSelector((state: StoreType) => state.settings);

    return (
        <>
            <Card className="pt-4 pb-2 h-100">
                <h1 className="px-4 text-primary text-uppercase mx-1">
                    Contact Us
                </h1>
                <CardHorizontalRule />

                <div className="row px-4 mt-3">
                    <div className="col-1 flex-shrink-1 pr-0 text-right">
                        <MdLocalPhone
                            size={40}
                            style={{}}
                            className="pt-2 text-primary"
                        />
                    </div>
                    <div className="col flex-grow-1 pl-2">
                        <div
                            className="text-primary-dark-3 font-weight-bold"
                            style={{ fontSize: '32px' }}>
                            {settings?.contact?.phone}
                        </div>
                        <div style={{ fontSize: '14px' }}>
                            Calling from overseas{' '}
                            <span className="text-primary font-weight-bold">
                                {settings?.contact?.phone_overseas}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row px-4 mt-4 mb-4">
                    <div className="col-12 col-lg-6">
                        <div className="d-flex">
                            <div className="col-2 flex-shrink-1 pr-2 text-right">
                                <FaMapMarkerAlt
                                    size={20}
                                    style={{}}
                                    className="text-primary"
                                />
                            </div>
                            <div className="col flex-grow-1 pl-0">
                                <div
                                    className="text-weight-medium text-uppercase text-light-gray"
                                    style={{ fontSize: '11px' }}>
                                    Postal Address
                                </div>
                                <div
                                    className="font-weight-normal"
                                    style={{ whiteSpace: 'pre-wrap' }}>
                                    {settings?.contact?.postal}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="d-flex">
                            <div className="col-2 flex-shrink-1 pr-2 text-right">
                                <IoIosClock
                                    size={20}
                                    style={{}}
                                    className="text-primary"
                                />
                            </div>
                            <div className="col flex-grow-1 pl-0">
                                <div
                                    className="text-weight-medium text-uppercase text-light-gray"
                                    style={{ fontSize: '11px' }}>
                                    Contact Centre Hours
                                </div>
                                <div
                                    className="font-weight-normal"
                                    style={{ whiteSpace: 'pre-wrap' }}>
                                    {settings?.contact?.hours}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 pt-lg-5">
                        <div className="d-flex">
                            <div className="col-2 flex-shrink-1 pr-2 text-right">
                                <MdEmail
                                    size={20}
                                    style={{}}
                                    className="text-primary"
                                />
                            </div>
                            <div className="col flex-grow-1 pl-0">
                                <div
                                    className="text-weight-medium text-uppercase text-light-gray"
                                    style={{ fontSize: '11px' }}>
                                    Email
                                </div>
                                <div className="font-weight-normal">
                                    {settings?.contact?.email}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 pt-lg-5">
                        <div className="d-flex">
                            <div className="col-2 flex-shrink-1 pr-2 text-right">
                                <FaFax
                                    size={20}
                                    style={{}}
                                    className="text-primary"
                                />
                            </div>
                            <div className="col flex-grow-1 pl-0">
                                <div
                                    className="text-weight-medium text-uppercase text-light-gray"
                                    style={{ fontSize: '11px' }}>
                                    Fax
                                </div>
                                <div className="font-weight-normal">
                                    {settings?.contact?.phone_fax}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
};

export default ContactUsCard;
