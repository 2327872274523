import PromoBlock from './PromoBlock';
export default PromoBlock;
export { default as Header } from './components/Header/Header';
export { default as HeroTitle } from './components/Header/HeroTitle';
export { default as HeroImage } from './components/Header/HeroImage';
export { default as Body } from './components/Body/Body';
export { default as PromoSubtitle } from './components/Body/PromoSubtitle';
export { default as PromoText } from './components/Body/PromoText';
export { default as Content } from './components/Content/Content';
export { default as PromoLabel } from './components/Content/PromoLabel';
export { default as PromoContent } from './components/Content/PromoContent';
