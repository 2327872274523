import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

interface FormikContext {
    email: string;
}

const RegisterEmail: React.FC = () => {
    const {
        values,
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        getFieldProps,
        isSubmitting,
    } = useFormikContext<FormikContext>();
    const emailKey = 'email';

    return (
        <Form.Group
            className={'col-12 col-lg-6'}
            controlId="member-email-address">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
                {...getFieldProps(emailKey)}
                type="text"
                placeholder="Email Address"
                value={values[emailKey]}
                onChange={(e) => {
                    setFieldTouched(emailKey, true);
                    setFieldValue(emailKey, e.target.value);
                }}
                required
                autoComplete="new-field"
            />
            {touched[emailKey] && errors[emailKey] && !isSubmitting && (
                <small className="text-danger form-text">
                    {errors[emailKey]}
                </small>
            )}
        </Form.Group>
    );
};

export default RegisterEmail;
