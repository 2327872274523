import React from 'react';
import styled from 'styled-components';

const Label = styled.div`
    font-family: Gotham;
    font-size: 11px;
    font-weight: 500;
    color: #89889b;
    text-transform: uppercase;
`;

const LabelledContent = (props) => {
    return (
        <div className={props.className}>
            <Label>{props.label}</Label>
            <div>
                {props.children}
            </div>
        </div>
    );
};

export default LabelledContent;
