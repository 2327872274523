import { Tab } from 'react-bootstrap';
import React from 'react';
import { CommunicationPreferences } from '../cards/CommunicationPreferences';
import { ChangePassword } from '../cards/changePassword/ChangePassword';

export const Settings = () => {
    return (
        <Tab.Pane eventKey="settings">
            <div className="row">
                <div className="col-8">
                    <CommunicationPreferences />
                </div>
                <div className="col-4">
                    <ChangePassword />
                </div>
            </div>
        </Tab.Pane>
    );
};
