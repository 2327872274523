import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import {Card} from 'react-bootstrap';

export const PlaceHolderCard = ({className,rows,color}) => {
    return (
        <>
        <Card className={className}>
            <ReactPlaceholder showLoadingAnimation rows={rows} color={color||'#e5e4ed'}/>
        </Card>
        </>
    )
}
