import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import moment from 'moment';
// import DatePicker from 'react-datepicker'; // Why is this not being used???
// import 'react-datepicker/dist/react-datepicker.css';

export const EndDate = () => {
    const { values, getFieldProps } = useFormikContext();

    return (
        <Form.Group
            controlId={`previousfund-cease`}
            className="col-6 text-label-gray"
            style={{
                display:
                    values.previoushealthtransfer &&
                    !values.previousHealthFundStillAMember
                        ? 'block'
                        : 'none',
            }}>
            <Form.Label>Cease Date</Form.Label>
            <Form.Control
                type="date"
                min={moment(values.previoushealthfundjoin).format('YYYY-MM-DD')}
                max={moment().format('YYYY-MM-DD')}
                {...getFieldProps('previoushealthfundcease')}
            />
        </Form.Group>
    );
};

export default EndDate;
