import React, { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { Spinner, Button, Form } from 'react-bootstrap';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';
import { useHistory } from 'react-router-dom';
import { Lock } from 'img/vector/Lock/comp';
import HeadShake from 'react-reveal/HeadShake';
import Fade from 'react-reveal/Fade';
import { SuccessCheck } from 'img/vector/SuccessCheck/comp';
import ReactMarkdown from 'react-markdown';
import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import gtagEvent from 'libs/gtagEvent';
import {
    DownloadButton,
    FeatureImage,
    FeatureTitle,
    Terms,
} from '../common/components/exports';

const ForgotPasswordScreen = () => {
    const [username, setUsername] = useState('');
    const [featureImage, setFeatureImage] = useState<JSX.Element>();
    const [featureTitle, setFeatureTitle] = useState<JSX.Element>();
    const [androidButton, setAndroidButton] = useState<JSX.Element>();
    const [iosButton, setIosButton] = useState<JSX.Element>();
    const [message, setMessage] = useState('');
    const [outcome, setOutcome] = useState<string | null>(null);

    const history = useHistory();

    const [loadFeatureState, loadFeature] = useAsyncFn(async () => {
        const {
            portal: { forgot, login },
        } = await api.app(CONFIG).settings();

        setFeatureTitle(
            <FeatureTitle
                text={<ReactMarkdown>{forgot.text}</ReactMarkdown>}
            />,
        );

        setFeatureImage(<FeatureImage src={forgot.bg_img} />);

        setIosButton(
            <DownloadButton
                image={login.app_store_img}
                link={login.app_store_url}
                alt={'iOS App Store download button'}
                gtag={() => {
                    gtagEvent({
                        screen: 'forgot',
                        action: 'download_ios',
                        label: 'Download from AppStore',
                    });
                }}
            />,
        );

        setAndroidButton(
            <DownloadButton
                image={login.play_store_img}
                link={login.play_store_url}
                alt={'Google Play Store download button'}
                gtag={() => {
                    gtagEvent({
                        screen: 'forgot',
                        action: 'download_android',
                        label: 'Download from Google Play Store',
                    });
                }}
            />,
        );
    });

    useEffect(() => {
        console.log('useAsyncFn is probably being used incorrectly: ', {
            loadFeatureState,
        });
        loadFeature();
    }, []);

    const resetCallback = async (username) => { 
        return await api.user(CONFIG).forgotPassword(username);
    }

    const [resetState, reset] = useAsyncFn<any,any>(resetCallback);

    const onSubmit = (e) => {
        e.preventDefault();
        gtagEvent({
            screen: 'forgot',
            action: 'sendreset',
            label: 'Requested reset password link',
        });
        reset(username).then((response) => {
            if (response.status === 'success') {
                setOutcome('success');
            } else {
                setOutcome('fail');
                setMessage(
                    response?.message ||
                        'Sorry, your request could not be completed. Please try again.',
                );
                setTimeout(() => {
                    setOutcome(null);
                    setMessage('');
                }, 30000);
            }
        });
    };

    return (
        <main className="h-100 d-flex forgot-password-page">
            <div
                className="d-none d-lg-flex flex-column h-100 col-xl-7"
                style={{
                    backgroundColor: '#AFE8E6',
                    maxWidth: '1000px',
                }}>
                {featureImage}
                {featureTitle}
                <div
                    className={
                        'd-flex align-items-center justify-content-center'
                    }>
                    {iosButton}
                    {androidButton}
                </div>
            </div>
            <div className="bg-white col-lg-5 d-flex col-xl align-items-center justify-content-center">
                <Form
                    className="w-100 w-sm-50"
                    style={{ maxWidth: '20rem' }}
                    onSubmit={onSubmit}>
                    <div className={'pb-3'}>
                        <div
                            className={
                                'w-100 d-flex align-items-center justify-content-center mb-3'
                            }>
                            <div
                                style={{
                                    position: 'absolute',
                                }}>
                                <Fade left when={outcome !== 'success'}>
                                    <Lock />
                                </Fade>
                            </div>

                            <Fade right when={outcome === 'success'}>
                                <SuccessCheck />
                            </Fade>
                        </div>
                    </div>

                    <div
                        className={
                            'text-primary-dark-1 font-weight-bold font-feature mb-2'
                        }
                        style={{
                            fontSize: 22,
                            position: 'relative',
                        }}>
                        <div
                            style={{
                                position: 'absolute',
                            }}>
                            <Fade when={outcome !== 'success'}>
                                Reset your password
                            </Fade>
                        </div>

                        <Fade when={outcome === 'success'}>Thank you</Fade>
                    </div>

                    <p
                        style={{
                            fontSize: 14,
                            position: 'relative',
                        }}>
                        <div
                            style={{
                                position: 'absolute',
                                marginBottom: '2rem',
                            }}>
                            <Fade when={outcome !== 'success'}>
                                Enter your email or member number and we’ll send
                                you a link to reset your password.
                            </Fade>
                        </div>

                        <Fade collapse when={outcome === 'success'}>
                            <span className={'mb-2'}>
                                If your details exist in our system, you will
                                receive an email with a link to reset your
                                password.
                            </span>
                        </Fade>
                    </p>

                    <Fade collapse when={outcome === 'success'}>
                        <Button
                            variant="secondary"
                            block
                            className={'p-2'}
                            onClick={() => {
                                history.replace('/');
                            }}>
                            Ok
                        </Button>
                    </Fade>

                    <Fade when={outcome !== 'success'}>
                        <Form.Group
                            className={'mt-5'}
                            controlId="auth-username">
                            <Form.Label>Login</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Email or Member Number"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Form.Group>

                        <HeadShake spy={outcome}>
                            <Button
                                variant="secondary"
                                type="submit"
                                block
                                className={'p-2'}
                                disabled={resetState.loading}>
                                <span className={'m-5'}>
                                    {resetState.loading ? (
                                        <Spinner
                                            animation="border"
                                            role="status"
                                            as="span"
                                            size="sm"
                                        />
                                    ) : (
                                        'Send Reset Link'
                                    )}
                                </span>
                            </Button>
                        </HeadShake>
                        <div style={{ margin: '0px -100px' }}>
                            <Fade bottom when={outcome !== null}>
                                <StatusNotificationCard
                                    status={
                                        outcome === 'fail' ? 'error' : 'success'
                                    }>
                                    {message}
                                </StatusNotificationCard>
                            </Fade>
                        </div>
                    </Fade>
                </Form>
            </div>

            <div
                style={{
                    fontSize: 12,
                    position: 'absolute',
                    bottom: 20,
                    right: 20,
                }}
                className={'text-light-gray font-feature'}>
                <Terms />
            </div>
        </main>
    );
};

export default ForgotPasswordScreen;
