import React from 'react';
import CustomModal from 'components/modals/Modal';
import SuspendCoverForm from './SuspendCoverForm';

const SuspendCoverModal = ({ show, handleClose, align }) => {
    return (
        <CustomModal
            className={`suspend-modal-cover`}
            handleClose={handleClose}
            align={align}
            show={show}
            title={'Suspend Cover'}>
            <div className={`row heading-text`}>
                <div className={`col`}>
                    <p>
                        If you are travelling overseas and have been a financial
                        member of TUH for at least twelve months, you may
                        suspend your membership for a minimum period of two
                        calendar months to a maximum period of three years.
                    </p>
                </div>
            </div>
            <div className={'text-primary font-feature row row-cols-1'}>
                <SuspendCoverForm handleClose={handleClose} />
            </div>
        </CustomModal>
    );
};

export default SuspendCoverModal;
