import React from 'react';
import { PhoneField } from './components/PhoneField';

export const PhoneNumbers = ({ loading }) => {
    return (
        <div className="pt-2 pb-2 px-3 modal-box-shadow">
            <div className="row">
                <PhoneField
                    controlId={'home-phone'}
                    label={'Home Phone'}
                    field={'homePhone'}
                    loading={loading}
                />
                <PhoneField
                    controlId={'mobile-phone'}
                    label={'Mobile Phone'}
                    field={'mobile'}
                    loading={loading}
                />
                <PhoneField
                    controlId={'mobile2-phone'}
                    label={'Mobile Phone 2'}
                    field={'mobile2'}
                    loading={loading}
                />
                <PhoneField
                    controlId={'work-phone'}
                    label={'Work Phone'}
                    field={'workPhone'}
                    loading={loading}
                />
            </div>
        </div>
    );
};

export default PhoneNumbers;
