import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import React from 'react';

export const Email = ({ loading }) => {
    const { values, touched, errors, getFieldProps, setFieldValue } =
        useFormikContext();
    return (
        <div className="col">
            <Form.Group controlId={`email`}>
                <Form.Label className="text-label-gray">Email</Form.Label>
                <Form.Control
                    type="email"
                    className={
                        errors.email &&
                        touched.email &&
                        !loading &&
                        'is-invalid'
                    }
                    {...getFieldProps('email')}
                />
                {touched.email && errors.email && !loading && (
                    <Form.Control.Feedback type={'invalid'}>
                        {errors.email}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Check
                type="checkbox"
                id={`checkbox-consent`}
                label={`I consent to TUH sending me communications via email`}
                className={
                    errors.consentEmail && touched.consentEmail && !loading
                        ? 'is-invalid text-label-gray font-weight-medium font-13'
                        : 'text-label-gray font-weight-medium font-13'
                }
                name={`consentEmail`}
                checked={values.consentEmail === '1'}
                onChange={(val) => {
                    setFieldValue(
                        'consentEmail',
                        val.target.checked ? '1' : '0',
                    );
                }}
            />
            {touched.consentEmail && errors.consentEmail && !loading && (
                <Form.Control.Feedback type={'invalid'}>
                    {errors.consentEmail}
                </Form.Control.Feedback>
            )}
        </div>
    );
};

export default Email;
