import * as Yup from 'yup';

const ContributionSchema = () => {
    return Yup.object().shape({
        cardholder: Yup.string()
            // .test('consoleLog', 'debugger', (value) => {
            //     console.log(value);
            //     return true;
            // })
            .min(3, 'Name on Card is required')
            .required('Name on Card is required'),
        contributionamount: Yup.string()
            .test(
                'no-alphas-in-currency',
                'Amount cannot contain letters.',
                (value) => {
                    if (!value) return true;
                    return value.match(/[0-9.]/);
                },
            )
            .test(
                'not-zero-currency-amount',
                'Amount cannot be zero.',
                (value) => {
                    if (!value) return true;
                    return !value.match(/^0+\.?0*$/);
                },
            )
            .test(
                'is-currency-decimal',
                'Invalid currency amount.',
                (value) => {
                    if (!value) return true;
                    return value.match(/^\d*\.?\d*$/);
                },
            ),
        understand: Yup.boolean().required().oneOf([true], 'Required'),
        declare: Yup.boolean().required().oneOf([true], 'Required'),
    });
};

export default ContributionSchema;
