import ClaimSpecialityActionTypes from './actions-types';

const INITIAL_STATE = null;

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ClaimSpecialityActionTypes.SET_CLAIM_SPECIALITY:
            if (process.env.REACT_APP_ENVIRONMENT==="development") {
                console.log(`SET_CLAIM_SPECIALITY with payload ${action.payload}`);
            }
            return action.payload;
        default:
            return state;
    }
};
