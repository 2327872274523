import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { createYearArray } from '../../helpers';

export const MedicareCardInfo = ({ loading }) => {
    const { touched, errors, getFieldProps } = useFormikContext();
    return (
        <div className="pb-2 pt-3 d-flex flex-wrap modal-box-shadow">
            <Form.Group
                controlId={`medicare-number`}
                className="col-6 text-label-gray">
                <Form.Label>Medicare number</Form.Label>
                <Form.Control
                    type="text"
                    className={
                        errors.medicareno &&
                        touched.medicareno &&
                        !loading &&
                        'is-invalid'
                    }
                    {...getFieldProps('medicareno')}
                />
                {touched.medicareno && errors.medicareno && !loading && (
                    <Form.Control.Feedback type={'invalid'}>
                        {errors.medicareno}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group
                controlId={`medicare-ref`}
                className="col-6 text-label-gray">
                <Form.Label>Reference</Form.Label>
                <Form.Control
                    type="text"
                    className={
                        errors.medicareref &&
                        touched.medicareref &&
                        !loading &&
                        'is-invalid'
                    }
                    {...getFieldProps('medicareref')}
                />
                {touched.medicareref && errors.medicareref && !loading && (
                    <Form.Control.Feedback type={'invalid'}>
                        {errors.medicareref}
                    </Form.Control.Feedback>
                )}
            </Form.Group>
            <Form.Group
                controlId={`medicare-month`}
                className="col-6 text-label-gray">
                <Form.Label>Medicare Expiry Month</Form.Label>
                <Form.Control
                    as="select"
                    className={
                        errors.medicareexpirymonth &&
                        touched.medicareexpirymonth &&
                        !loading &&
                        'is-invalid'
                    }
                    {...getFieldProps('medicareexpirymonth')}>
                    <option value="">- Please select -</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                </Form.Control>
                {touched.medicareexpirymonth &&
                    errors.medicareexpirymonth &&
                    !loading && (
                        <Form.Control.Feedback type={'invalid'}>
                            {errors.medicareexpirymonth}
                        </Form.Control.Feedback>
                    )}
            </Form.Group>
            <Form.Group
                controlId={`medicare-year`}
                className="col-6 text-label-gray">
                <Form.Label>Medicare Expiry Year</Form.Label>
                <Form.Control
                    as="select"
                    className={
                        errors.medicareexpiryyear &&
                        touched.medicareexpiryyear &&
                        !loading &&
                        'is-invalid'
                    }
                    {...getFieldProps('medicareexpiryyear')}>
                    <option value="">- Please select -</option>
                    {createYearArray().map((year, idx) => {
                        return (
                            <option key={idx} value={year}>
                                {year}
                            </option>
                        );
                    })}
                </Form.Control>
                {touched.medicareexpiryyear &&
                    errors.medicareexpiryyear &&
                    !loading && (
                        <Form.Control.Feedback type={'invalid'}>
                            {errors.medicareexpiryyear}
                        </Form.Control.Feedback>
                    )}
            </Form.Group>
            <Form.Group
                controlId={`medicare-name-first`}
                className="col-6 text-label-gray">
                <Form.Label>First Name as on card</Form.Label>
                <Form.Control
                    type="text"
                    className={
                        errors.medicarefirstname &&
                        touched.medicarefirstname &&
                        !loading &&
                        'is-invalid'
                    }
                    {...getFieldProps('medicarefirstname')}
                />
                {touched.medicarefirstname &&
                    errors.medicarefirstname &&
                    !loading && (
                        <Form.Control.Feedback type={'invalid'}>
                            {errors.medicarefirstname}
                        </Form.Control.Feedback>
                    )}
            </Form.Group>
            <Form.Group
                controlId={`medicare-name-last`}
                className="col-6 text-label-gray">
                <Form.Label>Surname as on card</Form.Label>
                <Form.Control
                    type="text"
                    className={
                        errors.medicarelastname &&
                        touched.medicarelastname &&
                        !loading &&
                        'is-invalid'
                    }
                    {...getFieldProps('medicarelastname')}
                />
                {touched.medicarelastname &&
                    errors.medicarelastname &&
                    !loading && (
                        <Form.Control.Feedback type={'invalid'}>
                            {errors.medicarelastname}
                        </Form.Control.Feedback>
                    )}
            </Form.Group>
        </div>
    );
};

export default MedicareCardInfo;
