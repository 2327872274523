import { default as BenefitCalculatorButton } from 'components/elements/buttons/BenefitCalculator';
import LinkOutlineTextIcon from 'components/elements/buttons/LinkOutlineTextIcon';
import { default as LostMemberCardButton } from 'components/elements/buttons/LostMemberCard';
import { default as SuspendCoverWhileOverseasButton } from 'components/elements/buttons/SuspendCoverWhileOverseas';
import Label from 'components/elements/labels/Label';
import SuspendCoverModal from 'components/modals/SuspendCoverModal/SuspendCoverModal';
import HeartPlus from 'img/vector/HeartPlus/comp';
import ToothWithGlasses from 'img/vector/ToothWithGlasses/comp';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { IoMdDocument, MdEdit, MdInfo } from 'react-icons/all';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setBenefitCalculatorModalVisible } from 'store';
import gtagEvent from 'libs/gtagEvent';
import { hasCover } from 'libs/booleanHelpers';

const Cover = ({ icon, label, title, titleClass, body, style, button }) => {
    if (!titleClass) {
        titleClass = 'text-primary';
    }
    console.log('COVER_PANEL: ', label.toLowerCase().split(' ')[0]);

    return (
        <div
            className="d-flex flex-column justify-content-between h-100 pt-4"
            style={style}>
            {/* Icon + Description */}
            <div className="d-flex align-items-center flex-column justify-content-between">
                <div className="text-primary mb-2 icon">{icon}</div>

                <Label text={label} />

                <h1 className={titleClass}>{title}</h1>

                <div>{body}</div>
            </div>

            {/* Button Row */}
            <div className="row my-4 mx-1 align-self-center">
                {button?.details && (
                    <div className="col-lg-4 col-md-12 text-center">
                        <a
                            href={button?.details}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'cover',
                                    action: `${
                                        label.toLowerCase().split(' ')[0]
                                    }_details`,
                                    label: `Requested cover ${
                                        label.toLowerCase().split(' ')[0]
                                    } details`,
                                });
                            }}>
                            <LinkOutlineTextIcon
                                className={'text-secondary'}
                                text="DETAILS"
                                icon={<MdInfo />}
                                iconSide="left"
                            />
                        </a>
                    </div>
                )}
                {button?.brochure && (
                    <div className="col-lg-4 col-md-12 text-center">
                        <a
                            href={button?.brochure}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'cover',
                                    action: `${
                                        label.toLowerCase().split(' ')[0]
                                    }_brochure`,
                                    label: `Requested cover ${
                                        label.toLowerCase().split(' ')[0]
                                    } brochure`,
                                });
                            }}>
                            <LinkOutlineTextIcon
                                className={'text-secondary'}
                                text="PRODUCT&nbsp;GUIDE"
                                icon={<IoMdDocument />}
                                iconSide="left"
                            />
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

const ExtrasCover = ({ product, show }) => {
    if (!product?.base?.type) {
        return (
            <div className="d-flex flex-column justify-content-between h-100 pt-4">
                <ReactPlaceholder
                    showLoadingAnimation
                    rows={6}
                    color="#e5e4ed"
                />
            </div>
        );
    }

    return (
        <>
            {show ? (
                <Cover
                    icon={<ToothWithGlasses />}
                    label="Extras Cover"
                    title={product?.extrasName}
                    button={{
                        details:
                            product?.extrasDetailsURL ??
                            product?.bundleDetailsURL,
                        brochure: product?.base?.webpage,
                    }}
                    style={{
                        borderBottom: '2px solid #efeff5',
                    }}
                />
            ) : (
                <Cover
                    icon={<ToothWithGlasses />}
                    label="Extras Cover"
                    title="No Extras"
                    titleClass="text-label-gray-no"
                    button={{
                        details: null,
                        brochure: null,
                    }}
                    style={{
                        borderBottom: '2px solid #efeff5',
                    }}
                />
            )}
        </>
    );
};

const HospitalCover = ({ product, show }) => {
    if (!product?.base?.type) {
        return (
            <div className="d-flex flex-column justify-content-between h-100 pt-4">
                <ReactPlaceholder
                    showLoadingAnimation
                    rows={6}
                    color="#e5e4ed"
                />
            </div>
        );
    }

    return (
        <>
            {show ? (
                <Cover
                    icon={<HeartPlus />}
                    label="Hospital Cover"
                    title={product?.hospitalName}
                    body={
                        <div>
                            <div className="text-center">
                                EXCESS:{' '}
                                <strong>{product?.excess || '---'}</strong>
                            </div>
                            <small
                                style={{
                                    display:
                                        product?.excess === 'No Excess'
                                            ? 'none'
                                            : 'block',
                                }}>
                                (Once per calendar year, per adult)
                            </small>
                        </div>
                    }
                    button={{
                        changeCover: null,
                        details:
                            product?.hospitalDetailsURL ??
                            product?.bundleDetailsURL,
                        brochure: product?.base?.webpage,
                    }}
                    style={{
                        borderRight: '2px solid #efeff5',
                        borderBottom: '2px solid #efeff5',
                    }}
                />
            ) : (
                <Cover
                    icon={<HeartPlus />}
                    label="Hospital Cover"
                    title="No Hospital"
                    titleClass="text-label-gray-no"
                    body=""
                    button={{
                        changeCover: null,
                        details: null,
                        brochure: null,
                    }}
                    style={{
                        borderRight: '2px solid #efeff5',
                        borderBottom: '2px solid #efeff5',
                    }}
                />
            )}
        </>
    );
};

const HospitalExtrasCoverCombo = () => {
    const [suspendCoverModalVisible, setSuspendCoverModalVisible] =
        useState(false);
    // const [benefitCalculatorModalVisible, setBenefitCalculatorModalVisible] = useState(false);
    const appSettings = useSelector((state) => state.settings);
    const product = useSelector((state) => state.product);
    const dispatch = useDispatch();

    const [changeCoverSection, setChangeCoverSection] = useState('');
    // const [_grandfatheredProduct, setGrandfatheredProduct] = useState('');

    useEffect(() => {
        if (appSettings?.general?.closed_products && product?.base?.type) {
            const closed_products = appSettings.general.closed_products;
            let grandfathered = false;
            if (product?.base?.hospitalSetCode) {
                if (
                    closed_products.indexOf(product?.base?.hospitalSetCode) !==
                    -1
                ) {
                    setChangeCoverSection('grandfather');
                    grandfathered = true;
                    // setGrandfatheredProduct('hospital');
                }
            }
            if (product?.base?.ancillarySetCode) {
                if (
                    closed_products.indexOf(product?.base?.ancillarySetCode) !==
                    -1
                ) {
                    setChangeCoverSection('grandfather');
                    grandfathered = true;
                    // setGrandfatheredProduct('extras');
                }
            }
            if (product?.base?.productCode) {
                if (
                    closed_products.indexOf(product?.base?.productCode) !== -1
                ) {
                    setChangeCoverSection('grandfather');
                    grandfathered = true;
                    // setGrandfatheredProduct('bundle');
                }
            }

            if (!grandfathered) {
                setChangeCoverSection('change');
            }

            // console.log(appSettings.general);
        }
    }, [appSettings, product]);

    const showExtras = hasCover(product, 'extras');
    const showHospital = hasCover(product, 'hospital');

    return (
        <>
            <Card className="pb-3 mb-4 hospital-extras-cover-combo">
                <div
                    style={{ borderBottom: '2px solid #efeff5' }}
                    className="row py-3 mx-0 justify-content-between align-items-center">
                    <div
                        className="col-6 text-primary font-weight-medium text-uppercase"
                        style={{
                            fontSize: '21px',
                        }}>
                        Your cover package
                    </div>
                    <div className="col-6 text-right">
                        {changeCoverSection === '' && (
                            <ReactPlaceholder
                                showLoadingAnimation
                                rows={1}
                                color="#e5e4ed"
                            />
                        )}
                        {/* TODO - is the change cover still being used? */}
                        {changeCoverSection === 'change' &&
                            process.env.REACT_APP_ENVIRONMENT !==
                                'production' && (
                                <>
                                    <Link
                                        to="/member/coverchange/start"
                                        onClick={() => {
                                            gtagEvent({
                                                screen: 'cover',
                                                action: 'changecover',
                                                label: 'Clicked on change cover button',
                                            });
                                        }}>
                                        <Button variant="secondary">
                                            Change cover{' '}
                                            <MdEdit
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                            />
                                        </Button>
                                    </Link>
                                    {/* disabled the following message as per TUHPORTAL-63 */}
                                    {changeCoverSection === 'grandfather' &&
                                        false && (
                                            <div style={{ fontSize: '14px' }}>
                                                {/*Your current {grandfatheredProduct} cover is a closed product.<br/> Please call <span className="text-secondary font-weight-bold">1300 360 701</span> if you wish to change it.*/}
                                                This product is closed to new
                                                joins
                                            </div>
                                        )}
                                </>
                            )}
                    </div>
                </div>
                <div className="row mx-0">
                    <div className="col-md-12 col-lg-6 p-0">
                        <HospitalCover product={product} show={showHospital} />
                    </div>

                    <div className="col-md-12 col-lg-6 p-0">
                        <ExtrasCover product={product} show={showExtras} />
                    </div>
                </div>

                <div className="d-flex ml-3 flex-wrap">
                    <div className="mr-3 mt-3">
                        <LostMemberCardButton
                            gtag={() => {
                                gtagEvent({
                                    screen: 'cover',
                                    action: 'newcard_request',
                                    label: 'Clicked on New Member card button',
                                    type: 'modal_open',
                                });
                            }}
                        />
                    </div>
                    <div className="mr-3 mt-3">
                        <BenefitCalculatorButton
                            onClick={() => {
                                gtagEvent({
                                    screen: 'cover',
                                    action: 'benefit_calculator',
                                    label: 'Clicked on benefit calculator button',
                                    type: 'modal_open',
                                });
                                dispatch(
                                    setBenefitCalculatorModalVisible(true),
                                );
                            }}
                        />
                    </div>
                    <div className="mr-3 mt-3">
                        <SuspendCoverWhileOverseasButton
                            onClick={() => {
                                gtagEvent({
                                    screen: 'cover',
                                    action: 'suspend_cover',
                                    label: 'Clicked on suspend cover button',
                                    type: 'modal_open',
                                });
                                setSuspendCoverModalVisible(true);
                            }}
                        />
                    </div>
                </div>
            </Card>

            <SuspendCoverModal
                handleClose={() => {
                    gtagEvent({
                        screen: 'suspendcover',
                        action: 'cancel',
                        label: 'Closed or cancelled modal',
                        type: 'modal',
                    });
                    setSuspendCoverModalVisible(false);
                }}
                show={suspendCoverModalVisible}
                align={'right'}
            />
        </>
    );
};

export default HospitalExtrasCoverCombo;
