import styled from "styled-components";
import React from "react";

const Container = styled.div`
    box-shadow: inset 0 1px 0 0 #dad9e3, inset 0 -1px 0 0 #e5e4ed;
    width: 99%;
    margin-left: auto;
    margin-right: auto;
`;

/**
 * A container with a thin top and bottom border,
 * used to separate portions of a divisions. Children
 * are rendered within the container.
 */
const CardVerticalBorderContainer = (props) => {
    return (
        <Container>
            {props.children}
        </Container>
    );
};

export default CardVerticalBorderContainer;
