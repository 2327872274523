const INITIAL_STATE = {
    patients: [
        {id: 1, name: 'Rudy', membership_number: 'UH999873234'},
        {id: 2, name: 'Cintia', membership_number: 'UH999873234'},
    ],
    activePatient: 1,
};

export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case 'patient-switched':
            return {
                ...state,
                activePatient: action.id,
            };
        default:
            return state;
    }
};
