import Details from 'components/sections/membership/tabs/Details';
import { Settings } from 'components/sections/membership/tabs/Settings';
import { Statements } from 'components/sections/membership/tabs/Statements';
import gtagEvent from 'libs/gtagEvent';
import React from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useLocation } from 'react-router';

const AccountPage = () => {
    const location = useLocation();
    const defaultTab = location?.hash?.split('#')[1] || 'details';

    return (
        <>
            <main className="container-fluid p-4">
                <Tab.Container
                    transition={false}
                    id="left-tabs-example"
                    defaultActiveKey={defaultTab}>
                    <Nav className={'font-feature'} variant="tabs">
                        <Nav.Item>
                            <Nav.Link
                                eventKey="details"
                                onClick={() => {
                                    gtagEvent({
                                        screen: 'membership',
                                        action: 'details_tab',
                                        label: 'Clicked Membership details tab',
                                    });
                                }}>
                                Details
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="statements"
                                onClick={() => {
                                    gtagEvent({
                                        screen: 'membership',
                                        action: 'statements_tab',
                                        label: 'Clicked Membership statements tab',
                                    });
                                }}>
                                Statements
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="settings"
                                onClick={() => {
                                    gtagEvent({
                                        screen: 'membership',
                                        action: 'settings_tab',
                                        label: 'Clicked Membership settings tab',
                                    });
                                }}>
                                Settings
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className="mt-3">
                        <Details />
                        <Statements />
                        <Settings />
                    </Tab.Content>
                </Tab.Container>
            </main>
        </>
    );
};

export default AccountPage;
