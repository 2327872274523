import React from 'react';
import Fade from 'react-reveal/Fade';
import { useFormikContext } from 'formik';
import {
    CourseEndDate,
    CourseStartDate,
    DependentDisclaimers,
    ExtendedDependent,
    StudentDependent,
    MustRegisterDependent,
} from '../components';

export const DependentsInfo = ({ loading, edit = false }) => {
    const { values } = useFormikContext();
    console.log({ values });
    return (
        <>
            <MustRegisterDependent />
            <Fade collapse bottom when={values.schoolage}>
                <div className="pb-2 pt-3 flex-wrap modal-box-shadow">
                    <div className="col-12 text-label-gray">
                        <StudentDependent />
                        {!edit && (
                            <div className="row">
                                <CourseStartDate />
                                <CourseEndDate />
                            </div>
                        )}
                    </div>
                    <ExtendedDependent />
                </div>
            </Fade>
            <Fade
                collapse
                bottom
                when={values.schooldeclare || values.registerAsExtDep}>
                <DependentDisclaimers loading={loading} />
            </Fade>
        </>
    );
};

export default DependentsInfo;
