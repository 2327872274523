import React from 'react';
import { useDispatch } from 'react-redux';
import CustomModal from 'components/modals/Modal';
import { setBenefitCalculatorModalVisible } from 'store';
import BenefitCalculator from 'components/elements/benefitCalculator/benefitCalculator';

const BenefitCalculatorModal = ({ show, handleClose, align }) => {
    const dispatch = useDispatch();
    return (
        <CustomModal
            handleClose={() => {
                handleClose();
                dispatch(setBenefitCalculatorModalVisible(false));
            }}
            align={align}
            show={show}
            title={'Benefit Calculator'}>
            <div className={'font-feature'}>
                <BenefitCalculator handleClose={handleClose} />
            </div>
        </CustomModal>
    );
};

export default BenefitCalculatorModal;
