import React from 'react'

const Content = ({ children }) => {
  return (
    <>
      {children}
    </>
  )
}

export default Content
