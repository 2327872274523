export default {

    // General --
    SET_STEP: 'set_step',
    RESET: 'reset',
    SET_HIGH_RISK: 'set_high_risk',
    // ----------

    // Item --
    ADD_ITEM: 'item_add',
    UPDATE_ITEM: 'item_update',
    REMOVE_ITEM: 'item_remove',
    SET_CURRENT_ITEM: 'set_current_item',
    // -------

    // Document --
    ADD_DOCUMENT: 'document_add',
    REMOVE_DOCUMENT: 'document_remove',
    // -----------

    // Review --
    SET_SEND_CONFIRMATION: 'set_send_confirmation',
    SET_ELIGIBLE_FOR_AHB: 'set_eligible_for_ahb',
    SET_CLAIM_ABH: 'set_claim_ahb',
    SET_AGREE_DISCLAIMERS: 'set_agree_disclaimers',
    SET_EMAIL: 'set_email',
    // --------

    // Amount --
    SET_BENEFIT_QUOTE: 'fast_claim_set_benefit_quote',
    SET_AMOUNT_OUT_OF_POCKET: 'set_amount_out_of_pocket',
    SET_AMOUNT_BENEFIT: 'set_amount_benefit',
    SET_AMOUNT_TOTAL: 'set_amount_total',
    // ---------

    // Bank Account --
    SET_USE_ONE_TIME_BANK_ACCOUNT: 'set_use_one_time_bank_account',
    SET_ONE_TIME_BANK_ACCOUNT_DETAILS: 'set_one_time_bank_account_details',
    // ---------------

    SET_CLAIM_RESULTS: 'set_claim_results',
};
