export default {
    SET_CHANGE_COVER_TYPE: 'set_change_cover_type',
    SET_CHANGE_COVER_GROUP_TYPE: 'set_change_cover_group_type',
    SET_CHANGE_COVER_MUST_COVER: 'set_change_cover_must_cover',
    SET_CHANGE_COVER_ONBOARDING_STEP: 'set_change_cover_onboarding_step',
    SET_CHANGE_COVER_UI_SHOW_DETAILS: 'set_change_cover_ui_show_details',
    SET_CHANGE_COVER_UI_SHOW_LIMITS_MODAL: 'set_change_cover_ui_show_limits_modal',
    SET_CHANGE_COVER_UI_LIMITS_MODAL_OPTIONS: 'set_change_cover_ui_limits_modal_options',
    SET_CHANGE_COVER_MUST_COVER_EXTRAS: 'set_change_cover_must_cover_extras',
    SET_CHANGE_COVER_PRODUCTS: 'set_change_cover_products',
    SET_CHANGE_COVER_SUGGESTED_EXTRAS: 'set_change_cover_suggested_extras',
    SET_CHANGE_COVER_SUGGESTED_HOSPITAL: 'set_change_cover_suggested_hospital',
    SET_CHANGE_COVER_PAYMENT_FREQ: 'set_change_cover_payment_freq',
    SET_CHANGE_COVER_SELECTED_HOSPITAL_PRODUCT: 'set_change_cover_selected_hospital_product',
    SET_CHANGE_COVER_SELECTED_EXTRAS_PRODUCT: 'set_change_cover_selected_extras_product',
    SET_CHANGE_COVER_SELECTED_BUNDLE_PRODUCT: 'set_change_cover_selected_bundle_product',
    SET_CHANGE_COVER_SELECTED_PRODUCT_HOSPITAL_PRODUCT: 'set_change_cover_selected_product_hospital_product',
    SET_CHANGE_COVER_SELECTED_PRODUCT_EXTRAS_PRODUCT: 'set_change_cover_selected_product_extras_product',
    SET_CHANGE_COVER_SELECTED_PRODUCT_BUNDLE_PRODUCT: 'set_change_cover_selected_product_bundle_product',
    SET_CHANGE_COVER_HOSPITAL_MUST_CHOOSE_EXTRAS: 'set_change_cover_hospital_must_choose_extras',
    SET_CHANGE_COVER_PRICING_HOSPITAL: 'set_change_cover_pricing_hospital',
    SET_CHANGE_COVER_PRICING_EXTRAS: 'set_change_cover_pricing_extras',
    SET_CHANGE_COVER_PRICING_BUNDLE: 'set_change_cover_pricing_bundle',
    CHANGE_COVER_RESET: 'change_cover_reset'
};
