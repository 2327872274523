import {
  IoMdCheckmarkCircle,
  IoMdInformationCircle,
  MdCreditCard,
} from 'react-icons/all';
import React from 'react';

const Appearance = (paymentType) => {
  let appearance = {};
  const size = 14;

  switch (paymentType) {
    case 'billing':
      appearance.icon = <IoMdInformationCircle size={size} />;
      appearance.paymentType = 'Invoice';
      appearance.textClass = 'text-warning';
      break;

    case 'accounts':
      appearance.icon = <IoMdInformationCircle size={size} />;
      appearance.paymentType = 'Accounts';
      appearance.textClass = 'text-warning';
      break;

    case 'payroll':
      appearance.icon = <IoMdCheckmarkCircle size={size} />;
      appearance.paymentType = 'Payroll';
      appearance.textClass = 'text-success';
      break;

    case 'direct debit':
      appearance.icon = <IoMdCheckmarkCircle size={size} />;
      appearance.paymentType = 'Direct Debit';
      appearance.textClass = 'text-success';
      break;

    case 'credit card':
      appearance.icon = <MdCreditCard size={size} />;
      appearance.paymentType = 'Credit Card';
      appearance.textClass = 'text-primary-dark-1';
      break;

    case 'bank account':
      appearance.icon = <IoMdInformationCircle size={size} />;
      appearance.paymentType = 'Accounts';
      appearance.textClass = 'text-warning';
      break;
  }

  return appearance;
};

const PaymentMethodBadge = ({ method }) => {
  let appearance = Appearance(method);

  return (
    <div className={`d-flex align-items-center ${appearance.textClass}`}>
      {appearance.icon}
      <small className="font-weight-semibold ml-1 text-uppercase">
        {appearance.paymentType}
      </small>
    </div>
  );
};

export default PaymentMethodBadge;
