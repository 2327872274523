import React from 'react';
import { Form } from 'react-bootstrap';
import { injectServiceItem, disableItemSelect } from '../common/functions';
import { FastClaimTypeItem } from 'types/fastClaim';

export interface ServiceItem {
    ServiceCode: string;
    Description: string;
}

interface ItemProps {
    serviceItems: ServiceItem[];
    claimItem?: FastClaimTypeItem;
    updateClaim: (newObject: any) => void;
    handleChange: (item?: ServiceItem) => void;
}

export const Item: React.FC<ItemProps> = ({
    serviceItems,
    claimItem,
    updateClaim,
    handleChange,
}) => {
    return (
        <Form.Group controlId={`item`} className="col-12 text-label-gray">
            <Form.Label>Item</Form.Label>
            <Form.Control
                as="select"
                onChange={(event) => {
                    const serviceItem = serviceItems.find(
                        (item) => item.ServiceCode === event.target.value,
                    );
                    const newObject = injectServiceItem(claimItem, serviceItem);
                    updateClaim(newObject);
                    handleChange(serviceItem);
                }}
                disabled={disableItemSelect(claimItem, serviceItems)}
                value={claimItem?.item?.ServiceCode ?? ''}>
                <option value="">- Please select -</option>
                {typeof serviceItems?.map === 'function' &&
                    serviceItems.map((row, idx) => (
                        <option key={idx} value={row.ServiceCode}>
                            {row.ServiceCode} - {row.Description}
                        </option>
                    ))}
            </Form.Control>
        </Form.Group>
    );
};

export default Item;
