import React from "react";
import {Button} from "react-bootstrap";

interface Props {
    icon: React.ReactNode;
    text: string;
    iconSide: 'left' | 'right';
    variant?: string;
    onClick: () => void;
    className?: string;
}

const LinkOutlineTextIcon: React.FC<Props> = ({icon, text, iconSide, variant = undefined, onClick, className}) => {
    return (
        // Pass through classnames from parent
        <Button
            variant={variant ? variant : "link-primary-dark-1 p-0 m-0"}
            onClick={onClick}
        >
            <div className={className}>
                <div className="d-flex align-items-center">
                <span className="flex-shrink-0 d-flex align-items-center justify-content-center">
                    {iconSide === 'left' ? icon : ''}
                </span>

                    <span className={iconSide === 'left' ? 'ml-1' : 'mr-1'}>
                    {text}
                </span>

                    {iconSide === 'right' ? icon : ''}
                </div>
            </div>
        </Button>

    );
};

export default LinkOutlineTextIcon;

