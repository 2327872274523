import * as Yup from 'yup';
import moment from 'moment';

const CardSchema = () => {
    return Yup.object().shape({
        cardholder: Yup.string().required('Name on card is required'),
        ccNewDate: Yup.date().when(['ccDateChange', 'ccPaymentFreq'], {
            is: (ccDateChange, ccPaymentFreq) =>
                ccDateChange && ccPaymentFreq !== 'Fortnightly',
            then: Yup.date()
                .min(
                    moment().add(2, 'days').format('YYYY-MM-DD'),
                    'Date must be 2 days from now or greater',
                )
                .max(
                    moment().add(30, 'days').format('YYYY-MM-DD'),
                    'Date must be within a month from today',
                )
                .required('Date required'),
        }),
    });
};

export default CardSchema;
