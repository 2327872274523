import React from 'react';
import { MdClose } from 'react-icons/all';

const CrossIcon = () => {
    return (
        <span className="text-muted">
            <MdClose />
        </span>
    );
};

export default CrossIcon;
