import Change from 'components/elements/buttons/Change';
import MoreInfo from 'components/elements/buttons/MoreInfo';
import CardHorizontalRule from 'components/elements/divisions/CardHorizontalRule';
import LabelRebate from 'components/elements/labels/LabelRebate';
import AgeBasedDiscountModal from 'components/modals/AgeBasedDiscountModal/AgedBaseDiscountModal';
import GovernmentRebateModal from 'components/modals/GovernmentRebateModal/GovernmentRebateModal';
import LHCModal from 'components/modals/LHCModal/LHCModal';
import gtagEvent from 'libs/gtagEvent';
import {
    ageAtCertainYear,
    ageBasedDiscount,
    ageToYears,
    findPartner,
    getPaymentMethodDebit,
    mainCouple,
    mainPerson,
} from 'libs/helpers';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';
import { useDispatch, useSelector } from 'react-redux';
import { setPaymentMethodModalVisible } from 'store';
import { CrossIcon, TickIcon, Discount } from './components/exports';
import { hasCover } from 'libs/booleanHelpers';
import { StoreType } from 'store/types';
import { MembershipPersonType } from '@qldtuh/tuh-uh-js-api';

export function DiscountsAndRebates() {
    const dispatch = useDispatch();
    const membership = useSelector((state: StoreType) => state.membership);
    const lhc = useSelector((state: StoreType) => state.lhc);
    const product = useSelector((state: StoreType) => state.product);

    const [governmentRebateModelVisible, setGovernmentRebateModelVisible] =
        useState(false);
    const [ageBasedDiscountModalVisible, setAgeBasedDiscountModalVisible] =
        useState(false);
    const [lhcModalVisible, setLhcModalVisible] = useState(false);
    const [ageAtJoining, setAgeAtJoining] = useState('');
    const [currentAge, setCurrentAge] = useState('');
    const [mainMembershipPersons, setMainMembershipPersons] = useState<MembershipPersonType[]>([]);
    const [partnerAgeAtJoining, setPartnerAgeAtJoining] = useState('');
    const [partnerCurrentAge, setPartnerCurrentAge] = useState('');
    const [mainPersonABD, setMainPersonABD] = useState(0);
    const [partnerABD, setPartnerABD] = useState(0);
    const [membershipABD, setMembershipABD] = useState(0);
    const [membershipLHC, setMembershipLHC] = useState(0);

    useEffect(() => {
        setAgeAtJoining(
            ageAtCertainYear(
                mainPerson(membership)?.Person?.BirthDate,
                mainPerson(membership)?.JoinDate,
            )?.toString() ?? '',
        );
        setCurrentAge(ageToYears(mainPerson(membership)?.Person?.BirthDate)?.toString() ?? '');

        setMainMembershipPersons(mainCouple(membership) ?? []);

        // Work out the ABD
        setMainPersonABD(ageBasedDiscount(ageAtJoining, currentAge));
        const familyType = product?.membershipClassCode;
        if (
            familyType === 'Fam' ||
            familyType === 'FamX' ||
            familyType === 'Cpl'
        ) {
            const partner = findPartner(membership);
            // console.log('PARTNER',partner);
            if (typeof partner !== 'undefined') {
                if (partner.length === 1) {
                    // discount = (mainPersonABD+partnerABD)/2;
                    setPartnerAgeAtJoining(
                        ageAtCertainYear(
                            partner[0]?.Person?.BirthDate,
                            partner[0]?.JoinDate,
                        )?.toString() ?? '',
                    );
                    setPartnerCurrentAge(
                        ageToYears(partner[0]?.Person?.BirthDate)?.toString() ?? '',
                    );

                    setPartnerABD(
                        ageBasedDiscount(
                            partnerAgeAtJoining,
                            partnerCurrentAge,
                        ),
                    );

                    setMembershipABD((mainPersonABD + partnerABD) / 2);
                }
            }
        } else {
            setMembershipABD(mainPersonABD);
        }

        // Work out the LHC
        if (typeof lhc !== 'undefined' && lhc !== null) {
            if (
                Object?.keys(lhc)?.length !== 0 &&
                lhc?.constructor === Object
            ) {
                // console.log('LHC: ',lhc);
                let totalLHC = 0;
                Object.entries(lhc).forEach((person) => {
                    // console.log('person lhc: ',person[1]);
                    totalLHC = totalLHC + person[1];
                });
                if (hasCover(product, 'hospital')) {
                    setMembershipLHC(totalLHC);
                }
            }
        }
    }, [membership, product, lhc]);

    return (
        <>
            <Card className="py-4 mb-4">
                {/* Title */}
                <div>
                    <h1 className="px-4 text-primary text-uppercase">
                        Discounts, Rebates & Loadings
                    </h1>

                    <div className="mt-3 mb-2">
                        <CardHorizontalRule />
                    </div>
                </div>

                {/* Government Rebate Tier */}
                <div>
                    <Discount
                        label="Government Rebate Tier"
                        contents={<LabelRebate membership={membership} />}
                        button={
                            membership?.Contribution?.RebateStatus !==
                                'Unregistered' && (
                                <Change
                                    onClick={() => {
                                        gtagEvent({
                                            screen: 'membership',
                                            action: 'government_rebate',
                                            label: 'Clicked on edit government rebate button',
                                            type: 'modal_open',
                                        });
                                        setGovernmentRebateModelVisible(true);
                                    }}
                                />
                            )
                        }
                        icon={
                            membership?.Contribution?.RebateStatus !==
                            'Unregistered' ? (
                                <TickIcon />
                            ) : (
                                <CrossIcon />
                            )
                        }
                    />

                    <div className="my-2">
                        <CardHorizontalRule />
                    </div>
                </div>

                {/* Age Based Discount */}
                <div
                    style={{
                        display: membershipABD > 0 ? 'block' : 'none',
                    }}>
                    <Discount
                        label="Age Based Discount"
                        contents={
                            <div>
                                <ReactPlaceholder
                                    showLoadingAnimation
                                    rows={1}
                                    color="#e5e4ed"
                                    ready={membershipABD > 0}
                                    type='text'
                                >
                                    <strong>{membershipABD}%</strong>
                                </ReactPlaceholder>
                            </div>
                        }
                        button={
                            <MoreInfo
                                onClick={() => {
                                    gtagEvent({
                                        screen: 'membership',
                                        action: 'aged_based_discount',
                                        label: 'Clicked on more info for Age Based Discount',
                                        type: 'modal_open',
                                    });
                                    setAgeBasedDiscountModalVisible(true);
                                }}
                            />
                        }
                        icon={<TickIcon />}
                    />

                    <div className="my-2">
                        <CardHorizontalRule />
                    </div>
                </div>

                {/* Direct Debit Discount */}
                <div>
                    <Discount
                        label="Direct Debit Discount"
                        contents={
                            getPaymentMethodDebit(membership) ===
                                'direct debit' ||
                            getPaymentMethodDebit(membership) === 'payroll' ? (
                                <div>
                                    {getPaymentMethodDebit(membership) ===
                                    'direct debit'
                                        ? 'Direct Debit'
                                        : 'Payroll'}{' '}
                                    -{' '}
                                    <strong>
                                        {getPaymentMethodDebit(membership) ===
                                        'direct debit'
                                            ? '1.5'
                                            : '2.5'}
                                        %
                                    </strong>
                                </div>
                            ) : (
                                <div>
                                    Get a <strong>1.5%</strong> discount with
                                    Direct Debit
                                </div>
                            )
                        }
                        button={
                            <Change
                                onClick={() => {
                                    gtagEvent({
                                        screen: 'membership',
                                        action: 'payment_method',
                                        label: 'Clicked on change direct debit discount button',
                                        type: 'modal_open',
                                    });
                                    dispatch(
                                        setPaymentMethodModalVisible(true),
                                    );
                                }}
                            />
                        }
                        icon={
                            getPaymentMethodDebit(membership) ===
                                'direct debit' ||
                            getPaymentMethodDebit(membership) === 'payroll' ? (
                                <TickIcon />
                            ) : (
                                <CrossIcon />
                            )
                        }
                    />

                    <div className="my-2">
                        <CardHorizontalRule />
                    </div>
                </div>

                {/* LHC Loading */}
                <div
                    style={{
                        display: membershipLHC > 0 ? 'block' : 'none',
                    }}>
                    <Discount
                        label="Lifetime Health Cover (LHC) Loading"
                        contents={
                            <div>
                                <ReactPlaceholder
                                    showLoadingAnimation
                                    rows={1}
                                    color="#e5e4ed"
                                    ready={mainMembershipPersons.length > 0}
                                    type='text'
                                >
                                    {
                                        mainMembershipPersons.map((person, idx) => {
                                            if (person?.Person?.GivenName) {
                                                return (
                                                    <div key={idx}>
                                                        {person?.Person?.GivenName}{' '}
                                                        -{' '}
                                                        <strong>
                                                            {lhc?.[person?.Person?.PersonId] || '0'}%
                                                        </strong>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })
                                    }
                                </ReactPlaceholder>
                            </div>
                        }
                        button={
                            <MoreInfo
                                onClick={() => {
                                    gtagEvent({
                                        screen: 'membership',
                                        action: 'lhc_moreinfo',
                                        label: 'Clicked on more info for LHC',
                                        type: 'modal_open',
                                    });
                                    setLhcModalVisible(true);
                                }}
                            />
                        }
                        icon={<TickIcon />}
                    />
                </div>
            </Card>

            {/* Modals */}
            <GovernmentRebateModal
                handleClose={() => {
                    gtagEvent({
                        screen: 'govrebate_tier',
                        action: 'cancel',
                        label: 'Cancelled or closed modal',
                        type: 'modal',
                    });
                    setGovernmentRebateModelVisible(false);
                }}
                show={governmentRebateModelVisible}
                align={'right'}
            />

            <AgeBasedDiscountModal
                handleClose={() => {
                    gtagEvent({
                        screen: 'agebased_disc',
                        action: 'cancel',
                        label: 'Cancelled or closed modal',
                        type: 'modal',
                    });
                    setAgeBasedDiscountModalVisible(false);
                }}
                show={ageBasedDiscountModalVisible}
                align={'right'}
            />

            <LHCModal
                handleClose={() => {
                    gtagEvent({
                        screen: 'lhc',
                        action: 'cancel',
                        label: 'Cancelled or closed modal',
                        type: 'modal',
                    });
                    setLhcModalVisible(false);
                }}
                show={lhcModalVisible}
                align={'right'}
            />
        </>
    );
}
