import {
    getAddressByType,
    getPhoneByName,
    addressToString,
} from 'libs/helpers';

export const getDefaultValues = (membership, useSameAddress) => {
    const consentToEmail = membership?.EmailAddresses?.EmailAddress?.[0]?.IsConsentToEmail ? '1' : '0';
    const homeAddress = getAddressByType(membership, 'Home');
    const postAddress = doPostalAddress(
        getAddressByType(membership, 'Postal'),
        homeAddress,
    );
        console.log({homeAddress});
    const defaults = {
        email: membership?.EmailAddresses?.EmailAddress?.[0]?.EmailAddress || '',
        consentEmail: consentToEmail || '0',
        mobile: getPhoneByName(membership, 'Mobile1') || '',
        mobile2: getPhoneByName(membership, 'Mobile2') || '',
        homePhone: getPhoneByName(membership, 'Home') || '',
        workPhone: getPhoneByName(membership, 'Work1') || '',
        homeLine1: homeAddress?.Line1 || '',
        homeLine2: homeAddress?.Line2 || '',
        homeSuburb: homeAddress?.Suburb || '',
        homeState: homeAddress?.StateCode || '',
        homePostcode: homeAddress?.PostCode || '',
        homeSame: useSameAddress,
        postLine1: postAddress?.Line1 || '',
        postLine2: postAddress?.Line2 || '',
        postSuburb: postAddress?.Suburb || '',
        postState: postAddress?.StateCode || '',
        postPostcode: postAddress?.PostCode || '',
        partnerAuth: getPartnerAuthority(membership) || '0',
    };

    const home = addressToString(homeAddress);
    const postal = addressToString(postAddress);

    return [defaults, home, postal];
};

function doPostalAddress(postAddress, homeAddress) {
    if (!postAddress && !homeAddress) return null;
    if (!postAddress && homeAddress) return homeAddress;
    if (!postAddress?.IsActive) return homeAddress;

    return postAddress;
}

const getPartnerAuthority = (membership) => {
    if (!membership?.CustomProperties?.CustomProperty) return;

    const customProperties = membership?.CustomProperties?.CustomProperty;

    if (typeof customProperties?.find === 'function') {
        const authority = customProperties.find(
            (property) => property.Key === 'Partner Access Authorised',
        );

        if (typeof authority !== 'undefined') {
            switch (authority.Value) {
                case 'Yes':
                    return '1';
                case 'No':
                    return '0';
                default:
                    break;
            }
        }
    }
    return '0';
};
