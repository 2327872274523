import HealthFundsActionTypes from './actions-types';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG, STRINGS } from '../../libs/constants';

export const setHealthFunds = (healthfunds) => ({
    type: HealthFundsActionTypes.SET_HEALTHFUNDS,
    payload: healthfunds,
});

export const thunkGetHealthFunds = () => (dispatch, _getState) => {
    // const memberId = localStorage.getItem(STRINGS.MEMBERSHIP_ID);
    const token = localStorage.getItem(STRINGS.ACCESS_TOKEN);

    api.app(CONFIG, token)
        .healthfunds()
        .then((response) => {
            dispatch(setHealthFunds(response));
            // console.log(response)
        });
};
