import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { findPartner } from 'libs/helpers';

export const Relationship = ({ loading, disabled = false }) => {
    const allRelationships = useSelector((state) => state.relationships);
    const membership = useSelector((state) => state.membership);
    const partnerObj = findPartner(membership);
    const { touched, errors, getFieldProps, setFieldValue } =
        useFormikContext();
    return (
        <Form.Group
            controlId={`relationship`}
            className="col-6 text-label-gray">
            <Form.Label>Relationship</Form.Label>
            <Form.Control
                {...getFieldProps('relationship')}
                as="select"
                disabled={disabled}
                className={
                    errors.relationship &&
                    touched.relationship &&
                    !loading &&
                    'is-invalid'
                }
                onChange={(e) => {
                    const selectedRelationship =
                        allRelationships?.Relationship?.find(
                            (row) => row.RelationshipCode === e.target.value,
                        );

                    if (
                        selectedRelationship?.IsMale &&
                        !selectedRelationship?.IsFemale
                    ) {
                        setFieldValue('gender', 'Male');
                    }
                    if (
                        !selectedRelationship?.IsMale &&
                        selectedRelationship?.IsFemale
                    ) {
                        setFieldValue('gender', 'Female');
                    }

                    setFieldValue('relationship', e.target.value);
                    setFieldValue('title', '');
                }}>
                <option>- Select -</option>
                {allRelationships?.Relationship
                    ? allRelationships.Relationship.map((row, idx) => {
                          if (
                              [
                                  'Membr',
                                  'Dec',
                                  'Baby',
                                  'FDtr',
                                  'FSon',
                                  'Adult',
                                  'Other',
                              ].includes(row?.RelationshipCode)
                          ) {
                              return null;
                          }
                          if (partnerObj?.length > 0) {
                              if (
                                  ['Sps', 'Ptnr'].includes(
                                      row?.RelationshipCode,
                                  )
                              ) {
                                  return null;
                              }
                          }
                          return (
                              <option key={idx} value={row?.RelationshipCode}>
                                  {row?.Description}
                              </option>
                          );
                      })
                    : ''}
            </Form.Control>
        </Form.Group>
    );
};

export default Relationship;
