import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const OverseasContact = () => {
    const { values, setFieldValue, touched, errors, setFieldTouched } =
        useFormikContext();

    return (
        <>
            <div className="row frm-brdr">
                <div className="col">
                    <p className="form-heading">Overseas contact details</p>
                    <p>
                        If you’re travelling for more than 6 months, we’ll
                        notify you before your membership resumes.
                    </p>
                </div>
            </div>
            <div className="row mb-1">
                <div className="col-sm-6">
                    <Form.Group controlId={`overseas-email`}>
                        <Form.Label className="text-label-gray">
                            Email Address to notify
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={values.overseasContactEmail}
                            onChange={(e) => {
                                setFieldTouched('overseasContactEmail', true);
                                setFieldValue(
                                    'overseasContactEmail',
                                    e.target.value,
                                );
                            }}
                            error={
                                touched.overseasContactEmail &&
                                errors.overseasContactEmail
                            }
                        />
                        {errors.overseasContactEmail && (
                            <small className="text-danger form-text">
                                {errors.overseasContactEmail}
                            </small>
                        )}
                    </Form.Group>
                </div>

                <div className="col-sm-6">
                    <Form.Group controlId={`overseas-email`}>
                        <Form.Label className="text-label-gray">
                            Mobile number to notify
                        </Form.Label>
                        <Form.Control
                            type="text"
                            onChange={(e) => {
                                setFieldTouched('overseasContactPhone', true);
                                setFieldValue(
                                    'overseasContactPhone',
                                    e.target.value,
                                );
                            }}
                            error={
                                touched.overseasContactPhone &&
                                errors.overseasContactPhone
                            }
                        />
                        {errors.overseasContactPhone && (
                            <small className="text-danger form-text">
                                {errors.overseasContactPhone}
                            </small>
                        )}
                    </Form.Group>
                </div>
            </div>
        </>
    );
};

export default OverseasContact;
