import React from 'react';
import { MdCheck } from 'react-icons/all';

const TickIcon = () => {
    return (
        <span className="text-success">
            <MdCheck />
        </span>
    );
};

export default TickIcon;
