import { Button, Accordion } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/all';
import React, { useState } from 'react';
import gtagEvent from 'libs/gtagEvent';

interface Props {
    eventKey: string;
}

export const ExpandDetails: React.FC<Props> = ({ eventKey }) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <Accordion.Toggle
            as={Button}
            eventKey={eventKey}
            style={{
                paddingTop: '.1rem',
                paddingBottom: '.1rem',
                paddingLeft: '.5rem',
                paddingRight: '.5rem',
                backgroundColor: '#9998A7',
                borderColor: '#9998A7',
                borderRadius: '2px',
                color: '#FFFFFF',
                fontSize: '12px',
                fontWeight: 'bold',
            }}
            onClick={() => {
                gtagEvent({
                    screen: 'overview',
                    action: 'expand',
                    label: 'expanded/contracted Cover at a glance details.',
                });
                setExpanded(!expanded);
            }}>
            <div className="d-flex justify-content-center align-items-center">
                <div className="mr-2">
                    {!expanded ? 'EXPAND DETAILS' : 'LESS DETAILS'}
                </div>
                {!expanded ? <FaChevronDown /> : <FaChevronUp />}
            </div>
        </Accordion.Toggle>
    );
};
