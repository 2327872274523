import React from 'react';

export const Preamble = () => {
    return (
        <div className="col text-black font-14 modal-box-shadow">
            <p>
                If you refer a friend or family member, we&apos;ll send you a{' '}
                <strong>$100 eGift Card</strong> after they&apos;ve joined.
                <span className="text-secondary">^</span> You can refer as many
                people as you wish! We&apos;ll simply ask one of our friendly
                staff members to give them a call to see if we can help get them
                covered.
            </p>
        </div>
    );
};

export default Preamble;
