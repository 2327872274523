import actionTypes from './action-types';
const INITIAL_STATE = {
    status: false,
    confirmed: [],
    confirmFor: '',
};
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.SET_CONFIRMATION_STATUS:
            return {
                ...state,
                status: action.payload,
            };
        case actionTypes.ADD_ID_WITH_EMAIL_CONFIRMED:
            return {
                ...state,
                confirmed: [...state.confirmed, action.payload],
            };
        case actionTypes.SET_CONFIRMING_PERSON:
            return {
                ...state,
                confirmFor: action.payload,
            };
        default:
            return state;
    }
};
