import React from 'react';
import styled from 'styled-components';

import ProgressBar from 'components/elements/etc/ProgressBar';
import { MdChevronRight, MdMoreHoriz } from 'react-icons/all';

import ExtrasTileGeneralDental from 'img/vector/ExtrasTileGeneralDental/comp';
import ExtrasTileMajorDental from 'img/vector/ExtrasTileMajorDental/comp';
import ExtrasTileOptical from 'img/vector/ExtrasTileOptical/comp';
import Checkmark from 'img/vector/Checkmark/comp';

const ExtrasUsageTileContainer = styled.div`
    border: 2px solid #e5e4ed;
    border-radius: 2px;
    cursor: pointer;
    margin: -1px;
`;

const ExtrasUsageValueRemaining = styled.div`
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.44px;
`;

const ExtrasUsageRemainingLabel = styled.div`
    font-size: 11px;
    color: #4a4a4a;
`;

const ExtrasUsageTile = ({
    name,
    benefitLimit,
    benefitUsed,
    onClick,
    size,
    data,
}) => {
    if (data) {
        // name = data?.Description;
        benefitLimit = parseInt(data?.ClaimableTotal);
        benefitUsed = parseInt(data?.ClaimedAmount);
    }

    let classes;
    if (!size || size === 'tile') {
        classes = 'col-12 col-md-4 col-lg-4 col-xl-3 p-3';
        return (
            <ExtrasUsageTileContainer onClick={onClick} className={classes}>
                <div className="d-flex align-items-center flex-wrap justify-content-between">
                    <div className="d-flex align-items-center">
                        <div
                            className="font-feature font-weight-medium"
                            style={{ fontSize: '14px' }}>
                            {name}
                        </div>
                        <MdChevronRight
                            className="text-primary"
                            size={20}
                            style={{ verticalAlign: 'middle' }}
                        />
                    </div>
                    <MdMoreHoriz style={{ color: '#848395' }} />
                </div>
            </ExtrasUsageTileContainer>
        );
    } else {
        // Top Large Tile
        classes = 'col-12 p-3';
        if (benefitLimit === 0) {
            classes = 'col-12 p-3 cursor-auto';
        }
        return (
            <ExtrasUsageTileContainer
                onClick={() => {
                    if (benefitLimit !== 0) {
                        onClick();
                    }
                }}
                className={classes}>
                <div className="row align-items-center">
                    <div className="pl-3 flex-shrink-1">
                        {name === 'General Dental' ? (
                            <ExtrasTileGeneralDental />
                        ) : null}
                        {name === 'Dental' ? <ExtrasTileGeneralDental /> : null}
                        {name === 'Major Dental' ? (
                            <ExtrasTileMajorDental />
                        ) : null}
                        {name === 'Optical' ? <ExtrasTileOptical /> : null}
                    </div>
                    <div className="col">
                        <div>
                            <div
                                className="font-feature font-weight-semibold d-inline-block"
                                style={{ fontSize: '18px' }}>
                                {name}
                            </div>
                            <MdChevronRight
                                className="text-primary"
                                size={20}
                                style={{
                                    verticalAlign: 'middle',
                                }}
                            />
                        </div>
                        <div style={{ fontSize: '11px' }}>
                            Set benefits and sub-limits may apply.
                        </div>
                    </div>
                    <div className="col-6">
                        {benefitLimit !== 0 ? (
                            <div className="mt-3 d-flex justify-content-between">
                                <div className="d-flex align-items-end flex-wrap">
                                    <ExtrasUsageValueRemaining
                                        className="font-feature font-weight-medium mr-1"
                                        style={{
                                            fontSize: '24px',
                                        }}>
                                        $
                                        {(benefitLimit - benefitUsed).toFixed(
                                            2,
                                        )}
                                    </ExtrasUsageValueRemaining>

                                    <ExtrasUsageRemainingLabel
                                        className="font-feature font-weight-medium"
                                        style={{
                                            fontSize: '10px',
                                        }}>
                                        REMAINING
                                    </ExtrasUsageRemainingLabel>
                                </div>
                                <div
                                    style={{
                                        fontSize: '12px',
                                        color: '#848395',
                                    }}>
                                    ${benefitLimit}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {benefitLimit !== 0 ? (
                            <ProgressBar
                                className="mt-2"
                                value={benefitUsed}
                                maxValue={benefitLimit}
                            />
                        ) : (
                            <span style={{ fontSize: '14px' }}>
                                No annual limits{' '}
                                <Checkmark
                                    className="text-primary"
                                    style={{
                                        verticalAlign: 'middle',
                                    }}
                                />
                            </span>
                        )}
                    </div>
                </div>
            </ExtrasUsageTileContainer>
        );
    }
};

{
    /*ExtrasUsageTile.propTypes = {
    name: PropTypes.string,
    benefitLimit: PropTypes.number,
    benefitUsed: PropTypes.number,
};*/
}

export default ExtrasUsageTile;
