import React from "react";
import LinkOutlineText from "./LinkOutlineText";

interface Props {
    onClick: () => void;
    className?: string;
    style?: React.CSSProperties;
}

const Cancel: React.FC<Props> = ({onClick, className, style}) => {
    return (
        <LinkOutlineText
            className={className}
            text="CANCEL"
            onClick={onClick}
            style={style}
        />
    );
};

export default Cancel;

