import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Table, Form, Button, Spinner } from 'react-bootstrap';
import { IoMdDocument } from 'react-icons/all';
import { useAsyncFn } from 'react-use';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';
import { getAccessToken, getMembershipId } from 'libs/auth';
import CardHorizontalRule from 'components/elements/divisions/CardHorizontalRule';
import CardAltDivision from 'components/elements/divisions/CardAltDivision';
import styled from 'styled-components';
import { mainCouple } from 'libs/helpers';
import { Base64 } from 'js-base64';
import Fade from 'react-reveal/Fade';
import { setEmailConfirmationModalVisible, setConfirmingPerson } from 'store';
import { Link } from 'react-router-dom';
import gtagEvent from 'libs/gtagEvent';
import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';

const StyledTable = styled(Table)`
    td:not(:first-child),
    th:not(:first-child) {
        text-align: right;
    }
    td,
    th {
        padding: 0.75rem 2rem;
    }
`;

export const RebateTaxStatements = ({ setConfirmFunc }) => {
    /**
     * setConfirmFunc sends the appropriate getStatement()
     * up to the Statements Tab component which passes it
     * to the confirm email modal to be able to download
     * the right PDF after confirming email. --xxKeefer
     */
    const membership = useSelector((state) => state.membership);
    const emailsConfirmed = useSelector(
        (state) => state.emailConfirmation.confirmed,
    );
    const dispatch = useDispatch();

    const allPeople = mainCouple(membership);

    function getCurrentFiscalYear() {
        //get current date
        var today = new Date();

        //get current month
        var curMonth = today.getMonth();

        var fiscalYr = '';
        if (curMonth > 5) {
            //Check if July
            fiscalYr = today.getFullYear();
        } else {
            fiscalYr = today.getFullYear() - 1;
        }

        return fiscalYr;
    }

    const yearAgoFiscalYear = getCurrentFiscalYear() - 1;
    const twoYearsAgoFiscalYear = getCurrentFiscalYear() - 2;

    const [taxStatementYear, setTaxStatementYear] = useState('');

    const [errorSpace, setErrorSpace] = useState(<>&nbsp;</>);
    const [failedAttempts, setFailedAttempts] = useState(0);

    const [getStatementState, getStatement] = useAsyncFn(
        async (personId, taxStatementYear, personName) => {
            setFailedAttempts(0);

            const access_token = getAccessToken();
            const memberid = getMembershipId();

            const data = {
                personid: personId,
                type: 'tax',
                taxyear: taxStatementYear,
            };
            console.log('DATA:', data);
            const fileName =
                personName.toLowerCase() +
                '_rebate_tax_statement_' +
                personId +
                '_' +
                taxStatementYear +
                '.pdf';

            try {
                const response = await api
                    .user(CONFIG, access_token)
                    .membership.getCommunications(memberid, data);

                if (response?.status === 'error') {
                    console.log(response);
                    throw new Error(response.message);
                } else {
                    console.log('Got a pdf');
                    console.log(response.message);
                    if (response?.Document) {
                        setFailedAttempts(0);
                        var downloadLink = document.createElement('a');
                        downloadLink.target = '_blank';
                        downloadLink.download = fileName;

                        // convert downloaded data to a Blob
                        let unEncodedDoc = Base64.toUint8Array(
                            response.Document,
                        );
                        var blob = new Blob([unEncodedDoc], {
                            type: 'application/pdf',
                        });

                        // create an object URL from the Blob
                        var URL = window.URL || window.webkitURL;
                        var downloadUrl = URL.createObjectURL(blob);

                        // set object URL as the anchor's href
                        downloadLink.href = downloadUrl;

                        // append the anchor to document body
                        document.body.appendChild(downloadLink);

                        // fire a click event on the anchor
                        downloadLink.click();
                        gtagEvent({
                            screen: 'membership',
                            action: 'taxstatement',
                            label: 'Retrieved rebate tax statement',
                        });

                        // cleanup: remove element and revoke object URL
                        document.body.removeChild(downloadLink);
                        URL.revokeObjectURL(downloadUrl);
                    }
                }
            } catch (response) {
                console.log('ERROR');
                console.log(response.message);
                setFailedAttempts(1);
                setErrorSpace(
                    response.message || 'Could not connect to server',
                );
                gtagEvent({
                    screen: 'membership',
                    action: 'taxstatement_error',
                    label: 'Could not retrieve rebate tax statement',
                });
            }
        },
    );

    return (
        <>
            <Card className="py-4">
                <h1 className="px-4 mb-4 text-primary text-uppercase">
                    Rebate Tax Statements
                </h1>
                <div className="my-3">
                    <CardHorizontalRule />
                </div>
                <div className="px-4 mb-4" style={{ fontSize: '14px' }}>
                    <p>
                        A copy of the statement you retrieve will also be
                        emailed to your registered email address automatically.
                    </p>
                    <p>
                        For any previous statements please{' '}
                        <Link
                            to="/member/contact"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'membership',
                                    action: 'contactus',
                                    label: 'clicked contact us link from membership details',
                                });
                            }}>
                            contact us.
                        </Link>
                    </p>
                </div>
                <div className="mx-1">
                    <CardAltDivision>
                        <div className="p-4 d-flex justify-content-between align-items-center">
                            <div className="mr-4 flex-shrink-1">
                                Show Tax Statements for:
                            </div>
                            <div className="w-25">
                                <Form.Control
                                    as="select"
                                    value={taxStatementYear}
                                    disabled={!allPeople}
                                    onChange={(e) => {
                                        gtagEvent({
                                            screen: 'membership',
                                            action: 'dropdown_taxstatement',
                                            label: 'selected statement dropdown',
                                        });
                                        setFailedAttempts(0);
                                        setTaxStatementYear(e.target.value);
                                    }}>
                                    <option value="">
                                        {!allPeople
                                            ? '- Loading... -'
                                            : '- Select Year -'}
                                    </option>
                                    <option value={twoYearsAgoFiscalYear}>
                                        {twoYearsAgoFiscalYear}-
                                        {yearAgoFiscalYear}
                                    </option>
                                    <option value={yearAgoFiscalYear}>
                                        {yearAgoFiscalYear}-
                                        {yearAgoFiscalYear + 1}
                                    </option>
                                </Form.Control>
                            </div>
                        </div>
                    </CardAltDivision>
                </div>
                <div className="mx-1">
                    <StyledTable
                        className=""
                        responsive
                        striped
                        style={{
                            marginBottom: '0px',
                            display: taxStatementYear !== '' ? 'table' : 'none',
                        }}>
                        <thead>
                            <tr>
                                <th className="font-weight-medium text-uppercase">
                                    Person
                                </th>
                                <th className="font-weight-medium text-uppercase">
                                    Retrieve Statement
                                </th>
                            </tr>
                        </thead>
                        <tbody>{allPeople
                                ? allPeople.map((person, idx) => {
                                    return (
                                        <tr
                                            key={`claim_row_${idx}`}
                                            className={'table-link'}
                                        >
                                            <td
                                                className={
                                                    'd-flex align-items-center flex-grow-1 font-weight-medium'
                                                }
                                                style={{
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {person?.Person?.GivenName} {person?.Person?.Surname}
                                            </td>
                                            <td
                                                className=""
                                                style={{
                                                    verticalAlign: 'middle',
                                                }}
                                            >
                                                <Button
                                                    variant="link"
                                                    className="text-primary-dark-1"
                                                    style={{
                                                        padding: '0px',
                                                    }}
                                                    onClick={() => {
                                                        dispatch(
                                                            setConfirmingPerson(person.Person.PersonId.toString()),
                                                        );
                                                        const personName =
                                                            person?.Person?.GivenName +
                                                            '_' +
                                                            person?.Person?.Surname;
                                                        setConfirmFunc(() => {
                                                            return () =>
                                                                getStatement(
                                                                    person?.Person?.PersonId,
                                                                    taxStatementYear,
                                                                    personName,
                                                                );
                                                        });
                                                        if (
                                                            !emailsConfirmed.includes(
                                                                person.Person.PersonId,
                                                            )
                                                        ) {
                                                            gtagEvent({
                                                                screen: 'membership',
                                                                action: 'confirm_email_address',
                                                                label: 'Was prompted to confirm email',
                                                                type: 'modal_open',
                                                            });

                                                            dispatch(
                                                                setEmailConfirmationModalVisible(true),
                                                            );
                                                        } else {
                                                            getStatement(
                                                                person?.Person?.PersonId,
                                                                taxStatementYear,
                                                                personName,
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <IoMdDocument
                                                        style={{
                                                            verticalAlign: 'middle',
                                                        }}
                                                        size={20}
                                                    />{' '}
                                                    {taxStatementYear}-{parseInt(taxStatementYear) + 1}{' '}
                                                    Tax Statement
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })
                                : ''}</tbody>
                    </StyledTable>
                </div>
                <div className="text-center">
                    {getStatementState.loading && (
                        <Spinner
                            animation="border"
                            role="status"
                            as="span"
                            size="lg"
                        />
                    )}
                    <div style={{ paddingInline: '10px' }}>
                        <Fade collapse bottom when={failedAttempts > 0}>
                            <StatusNotificationCard status="error">
                                {errorSpace}
                            </StatusNotificationCard>
                        </Fade>
                    </div>
                </div>
            </Card>
        </>
    );
};
