import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const DepartureDetails = () => {
    const {
        values,
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        getFieldProps,
    } = useFormikContext();

    return (
        <>
            <div className="row frm-brdr">
                <div className="col">
                    <p className="form-heading">Overseas Suspension</p>
                </div>
            </div>
            <div className="row start-date-row">
                <div className="col-sm-6">
                    <Form.Group controlId={`suspend-start-date`}>
                        <Form.Label className="text-label-gray">
                            Departure Date
                        </Form.Label>
                        <Form.Control
                            {...getFieldProps('startdate')}
                            type="date"
                            value={values.startDate}
                            onChange={(e) => {
                                setFieldTouched('startDate', true);
                                setFieldValue('startDate', e.target.value);
                            }}
                            error={touched.startDate && errors.startDate}
                            required
                        />
                        {errors.startDate && (
                            <small className="text-danger form-text">
                                {errors.startDate}
                            </small>
                        )}
                    </Form.Group>
                </div>
                <div className="col-sm-6">
                    <Form.Group
                        controlId={`departing-flight-'departingNumber'`}>
                        <Form.Label className="text-label-gray">
                            Departing Flight or Cruise Number
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={values.departingNumber}
                            onChange={(e) => {
                                setFieldTouched('departingNumber', true);
                                setFieldValue(
                                    'departingNumber',
                                    e.target.value,
                                );
                            }}
                            error={
                                touched.departingNumber &&
                                errors.departingNumber
                            }
                            label={'Departing Flight/Cruise Number'}
                        />
                        {errors.departingNumber && (
                            <small className="text-danger form-text">
                                {errors.departingNumber}
                            </small>
                        )}
                    </Form.Group>
                </div>
            </div>
        </>
    );
};

export default DepartureDetails;
