import React from 'react';
import { MdCheckCircle, MdError, MdInfo } from 'react-icons/all';

const StatusNotificationCard = ({ status, children }) => {
    const iconSize = 23;
    let icon;
    let color;

    switch (status) {
        case 'warn':
        case 'error':
            icon = <MdError size={iconSize} />;
            break;

        case 'success':
            icon = <MdCheckCircle size={iconSize} />;
            break;

        case 'info':
        default:
            icon = <MdInfo size={iconSize} />;
            break;
    }

    switch (status) {
        case 'warn':
            color = 'warning';
            break;
        case 'error':
            color = 'danger';
            break;

        case 'success':
            color = 'success';
            break;

        case 'info':
        default:
            color = 'primary';
            break;
    }

    return (
        <div
            className={`container alert alert-${color}`}
            role="alert"
            style={{ margin: '10px 0px' }}>
            <div className="row">
                <div className="col-1">{icon}</div>
                <div className="col-11">{children}</div>
            </div>
        </div>
    );
};

export default StatusNotificationCard;
