import React from 'react';

const Terms: React.FC = () => {
    return (
        <>
            <a href={'https://tuh.com.au'} className={'text-light-gray'}>
                tuh.com.au
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
                href={'https://tuh.com.au/terms-and-conditions-app-portal'}
                className={'text-light-gray'}>
                Terms & Conditions
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
                href={'https://tuh.com.au/privacy-and-fraud-statement'}
                className={'text-light-gray'}>
                Privacy Policy
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; &copy; {new Date().getFullYear()} Teachers
            Union Health
        </>
    );
};

export default Terms;
