import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { allAdults } from 'libs/helpers';
import { useSelector } from 'react-redux';

export const MyDetails = ({ loading }) => {
    const { touched, errors, getFieldProps } = useFormikContext();
    const membership = useSelector((state) => state.membership);
    const adults = allAdults(membership);

    return (
        <>
            <div className="font-weight-semibold text-black col font-14">
                My Details
            </div>
            <div className="row pt-1 modal-box-shadow">
                <Form.Group
                    controlId={`from`}
                    className="text-label-gray col-12">
                    <Form.Label className="font-12">From</Form.Label>
                    <Form.Control
                        as="select"
                        style={{ width: '100%' }}
                        className={
                            errors.person &&
                            touched.person &&
                            !loading &&
                            'is-invalid'
                        }
                        {...getFieldProps('person')}>
                        {adults &&
                            adults.map((person) => (
                                <option
                                    value={person?.Person?.PersonId}
                                    key={
                                        person?.Person?.PersonId
                                    }>{`${person.Person.GivenName} ${person.Person.Surname}`}</option>
                            ))}
                    </Form.Control>
                    {touched.person && errors.person && !loading && (
                        <Form.Control.Feedback type={'invalid'}>
                            {errors.person}
                        </Form.Control.Feedback>
                    )}
                </Form.Group>
            </div>
        </>
    );
};

export default MyDetails;
