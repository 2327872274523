import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import React from 'react';
export const PhoneField = ({ controlId, label, field, loading }) => {
    const { touched, errors, getFieldProps } = useFormikContext();
    return (
        <Form.Group controlId={controlId} className="col-6 text-label-gray">
            <Form.Label>{label}</Form.Label>
            <Form.Control
                type="text"
                className={
                    errors[field] && touched[field] && loading && 'is-invalid'
                }
                {...getFieldProps(field)}
            />
            {touched[field] && errors[field] && loading && (
                <Form.Control.Feedback type={'invalid'}>
                    {errors[field]}
                </Form.Control.Feedback>
            )}
        </Form.Group>
    );
};

export default PhoneField;
