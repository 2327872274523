import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

export const ExtendedDependent = () => {
    const { values, setFieldValue } = useFormikContext();
    const dependantEndAge = useSelector((state) => state.settings.general.dependant_age);

    function ordinal_suffix_of(iii) {
        var jjj = iii % 10,
            kkk = iii % 100;
        if (jjj == 1 && kkk != 11) {
            return iii + "st";
        }
        if (jjj == 2 && kkk != 12) {
            return iii + "nd";
        }
        if (jjj == 3 && kkk != 13) {
            return iii + "rd";
        }
        return iii + "th";
    }

    return (
        <div
            className="col-12 text-label-gray pt-3"
            style={{
                boxShadow: 'inset 0px 2px 0px 0px #E6E6EE',
            }}>
            <Form.Group controlId={`ExtendedDep`}>
                <Form.Check
                    inline
                    type="checkbox"
                    id={`extendeddep-1`}
                    label={`Register as Extended Dependant`}
                    name={`registerAsExtDep`}
                    className={'text-label-gray font-weight-medium'}
                    style={{
                        fontSize: '13px',
                    }}
                    onChange={(e) => {
                        if (values.schooldeclare && e.target.checked) {
                            setFieldValue('schooldeclare', false);
                        }
                        setFieldValue('registerAsExtDep', e.target.checked);
                    }}
                    checked={values.registerAsExtDep === true}
                />
            </Form.Group>
            <div className="" style={{ fontSize: '14px' }}>
                <ul>
                    <li>
                        Members with selected hospital covers can purchase
                        extended dependant cover to keep non-student dependents
                        on the family cover until the eve of their {ordinal_suffix_of(parseInt(dependantEndAge))} birthday.
                    </li>
                    <li>
                        Not available on Gold Hospital, Basic+ Hospital,
                        or any combination including these covers.
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ExtendedDependent;
