import { apiHelpers } from 'libs/helpers';
import { schoolYearHelpers } from 'libs/schoolYear';
import moment from 'moment';

export const constructSubmittable = {
    new: (values) => {
        const valueCopy = { ...values };
        // If this person is still with their previous fund, set end of cover as today
        if (valueCopy.previousHealthFundStillAMember) {
            valueCopy.previoushealthfundcease = moment().format('YYYY-MM-DD');
        }

        // Set fields for student dependant
        if (valueCopy.schooldeclare) {
            valueCopy.studentYear = String(schoolYearHelpers.getCurrent());
            valueCopy.studentInstitution = 'TUH Student';
        }

        // Extended dep
        if (valueCopy.registerAsExtDep) {
            valueCopy.isChildDependant = true;
        }
        // Error here
        // values.dob = values.dob
        // values.date = values.joindate
        return apiHelpers.propertyTransform(
            [
                { in: 'relationship', out: 'relationship' },
                { in: 'previoushealthfund', out: 'previousfundcode' },
                { in: 'previoushealthfundjoin', out: 'previousfundjoindate' },
                { in: 'previoushealthfundcease', out: 'previousfundenddate' }, // TODO Untested
                { in: 'previoushealthfundno', out: 'previousfundmemberid' },
                { in: 'title', out: 'title' },
                { in: 'firstname', out: 'firstname' },
                { in: 'lastname', out: 'lastname' },
                { in: 'gender', out: 'gender' },
                { in: 'dob', out: 'dob' },
                { in: 'joindate', out: 'date' },
                { in: 'medicareNumber', out: 'medicarenumber' },
                { in: 'medicareReference', out: 'medicareref' },
                { in: 'studentInstitution', out: 'studentinstitution' },
                { in: 'studentYear', out: 'studentyear' },
                { in: 'schoolcoursestartdate', out: 'studentcoursestartdate' },
                { in: 'schoolcourseenddate', out: 'studentcourseenddate' },
                { in: 'isChildDependant', out: 'ischilddependant' },
            ],
            valueCopy,
        );
    },
    edit: (values) => {
        // We're going to be messing with this object a bit,
        // so it's much cleaner to make a copy here.
        const valueCopy = { ...values };

        // Set fields for student dependant
        if (valueCopy.schooldeclare) {
            valueCopy.studentYear = String(schoolYearHelpers.getCurrent());
            valueCopy.studentInstitution = 'TUH Student';
        }

        // Extended dep
        if (valueCopy.registerAsExtDep) {
            valueCopy.isChildDependant = true;
        }

        return apiHelpers.propertyTransform(
            [
                { in: 'personId', out: 'personid' },
                { in: 'title', out: 'title' },
                { in: 'gender', out: 'gender'},
                { in: 'lastname', out: 'lastname' },
                { in: 'medicareno', out: 'medicarenumber' },
                { in: 'medicareref', out: 'medicareref' },
                { in: 'medicareexpirymonth', out: 'medicareexpirymonth' },
                { in: 'medicareexpiryyear', out: 'medicareexpiryyear' },
                { in: 'medicarefirstname', out: 'medicarefirstname' },
                { in: 'medicarelastname', out: 'medicarelastname' },
                { in: 'email', out: 'email' },
                { in: 'studentInstitution', out: 'studentinstitution' },
                { in: 'studentYear', out: 'studentyear' },
                { in: 'schoolcoursestartdate', out: 'studentcoursestartdate' },
                { in: 'schoolcourseenddate', out: 'studentcourseenddate' },
                { in: 'isChildDependant', out: 'ischilddependant' },
            ],
            valueCopy,
        );
    },
};
