import Cancel from 'components/elements/buttons/Cancel';
import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';
import CustomModal from 'components/modals/Modal';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import gtagEvent from 'libs/gtagEvent';
import { allAdults } from 'libs/helpers';
import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { MdCreditCard } from 'react-icons/all';
import { useDispatch, useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { setMembership } from 'store';
import api from '@qldtuh/tuh-uh-js-api';
import { StoreType } from 'store/types';

interface Props {
    gtag: () => void;
}

const LostMemberCard: React.FC<Props> = ({ gtag }) => {
    const membership = useSelector((state: StoreType) => state.membership);
    const dispatch = useDispatch();
    const history = useHistory();

    const adults = allAdults(membership);

    const [modalVisible, setModalVisible] = useState(false);
    const [selectedAdult, setSelectedAdult] = useState("");

    const [message, setMessage] = useState('');
    const [outcome, setOutcome] = useState<string | null>(null);

    useEffect(() => {
        setSelectedAdult("");
    }, [membership]);

    const [onSubmitState, onSubmit] = useAsyncFn<any, any>(async (selectedAdult) => {
        console.log(selectedAdult);

        const access_token = getAccessToken();
        const memberid = getMembershipId();

        const personid = selectedAdult;

        try {
            if(!memberid) {
                throw new Error('No member ID found');
            }
            const response = await api
                .user(CONFIG, access_token)
                .membership.requestNewCard(memberid, personid);

            if (response?.status === 'error') {
                throw new Error(response.message);
            } else {
                console.log(response);
                dispatch(setMembership(response));
                setMessage(
                    'Your new card has been queued. This should be received within the next 7 business days',
                );
                setOutcome('Success');

                setTimeout(() => {
                    setMessage('');
                    setOutcome(null);
                    setModalVisible(false);

                    setTimeout(() => {
                        history.push('/member/cover');
                    }, 500);
                }, 4500);
            }
        } catch (err) {
            if (err instanceof Error) {
                setMessage(err.message);
            }
            setOutcome('fail');
            setTimeout(() => {
                setMessage('');
                setOutcome(null);
            }, 10000);
        }
    });

    return (
        <>
            <Button
                className="ml-0 px-2 flex-shrink-1"
                variant="outline-gray"
                onClick={() => {
                    gtag();
                    setModalVisible(true);
                }}>
                <div className="d-flex align-items-center text-primary-dark-1">
                    <div className="mr-2">
                        <MdCreditCard
                            size={20}
                            style={{ verticalAlign: 'middle' }}
                        />
                    </div>
                    <div style={{ fontSize: '13px' }}>
                        {/*Lost your member card?*/}
                        Need a new member card?
                    </div>
                </div>
            </Button>
            <CustomModal
                handleClose={() => {
                    gtagEvent({
                        screen: 'newcard',
                        action: 'cancel',
                        label: 'Cancelled or closed modal',
                        type: 'modal',
                    });
                    setModalVisible(false);
                }}
                show={modalVisible}
                title={'Are you sure?'}>
                <div className="mb-4 text-center">
                    <p>Requesting a new card for:</p>
                    <p>Please note: Your previous membership card will be expired.</p>
                    <p>
                        <Form.Control
                            as="select"
                            value={selectedAdult}
                            onChange={(e) => {
                                setSelectedAdult(e.target.value);
                            }}>
                            <option value="" disabled>
                            - Select Person -
                            </option>
                            {adults
                                ? adults.map((person) => (
                                      <option
                                          value={person?.Person?.PersonId}
                                          key={
                                              person?.Person?.PersonId
                                          }>{`${person.Person.GivenName} ${person.Person.Surname}`}</option>
                                  ))
                                : ''}
                        </Form.Control>
                    </p>
                </div>
                <div className="d-flex justify-content-center">
                    <Button
                        variant="secondary"
                        style={{
                            padding: '9px',
                            marginRight: '10px',
                        }}
                        onClick={() => {
                            gtagEvent({
                                screen: 'newcard',
                                action: 'submit',
                                label: 'requested new card',
                                type: 'modal',
                            });
                            onSubmit(selectedAdult);
                        }}
                        disabled={onSubmitState.loading || !selectedAdult}>
                        {onSubmitState.loading ? (
                            <>
                                <Spinner
                                    animation="border"
                                    role="status"
                                    as="span"
                                    size="sm"
                                />
                                Request new card
                            </>
                        ) : (
                            'Request new card'
                        )}
                    </Button>
                    <Cancel
                        style={{
                            color: '#9998A8',
                        }}
                        onClick={() => {
                            setModalVisible(false);
                        }}
                    />
                </div>
                <Fade bottom when={outcome !== null}>
                    <StatusNotificationCard
                        status={outcome === 'fail' ? 'error' : 'success'}>
                        {message}
                    </StatusNotificationCard>
                </Fade>
            </CustomModal>
        </>
    );
};

export default LostMemberCard;
