import { Button, Spinner } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import Cancel from 'components/elements/buttons/Cancel';
import React from 'react';

export const SubmitForm = ({ handleClose, loading }) => {
    const { dirty, handleSubmit, isValid } = useFormikContext();
    return (
        <div className="col pt-3">
            <Button
                className="modal-submit"
                variant="secondary"
                onClick={handleSubmit}
                disabled={!isValid || loading || !dirty}>
                <>
                    {loading && (
                        <Spinner
                            animation="border"
                            role="status"
                            as="span"
                            size="sm"
                        />
                    )}
                    Save Changes
                </>
            </Button>

            <Cancel
                style={{
                    color: '#9998A8',
                }}
                onClick={handleClose}
            />
        </div>
    );
};

export default SubmitForm;
