import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import api from '@qldtuh/tuh-uh-js-api';
import { CONFIG } from 'libs/constants';
import { getAccessToken } from 'libs/auth';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import Fade from 'react-reveal/Fade';
import gtagEvent from 'libs/gtagEvent';
import {
    AgreeDisclaimers,
    DepartureDetails,
    OverseasContact,
    ReturnDetails,
    SelectPeople,
    SubmitButton,
    WhoIsTraveling,
} from './components';
import {
    SuspendCoverSchema,
    INITIAL_VALUES,
} from './validation/SuspendCoverSchema';
import { generatePayload } from './helpers';
import StatusNotificationCard from 'components/elements/notifications/StatusNotificationCard';

export const SuspendCoverForm = ({ handleClose }) => {
    const user = useSelector((state) => state.user);
    const membership = useSelector((state) => state.membership);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [outcome, setOutcome] = useState(null);

    const submit = (values, actions, initialValues) => {
        gtagEvent({
            screen: 'suspendcover',
            action: 'submit',
            label: 'Submitted suspend cover while overseas request',
            type: 'modal',
        });
        setLoading(true);
        const memberId = user.memberId;
        const access_token = getAccessToken();
        const payload = generatePayload(values, user, membership);

        api.website(CONFIG, access_token)
            .overseasSuspension(memberId, payload)
            .then((response) => {
                if (response?.status === 200) {
                    //success set
                    setLoading(false);
                    setOutcome('success');
                    setMessage(
                        <span>
                            <strong>Application Submitted Successfully</strong>
                            <br />
                            We will review your application and get back to you
                            soon.
                        </span>,
                    );
                    actions.resetForm({ values: initialValues });
                    setTimeout(() => {
                        setOutcome(null);
                        setMessage('');
                    }, 10000);
                } else {
                    throw new Error(response);
                }
            })
            .catch((err) => {
                //fail message set
                setLoading(false);
                setOutcome('error');
                setMessage(
                    <span>
                        <strong>Could not submit</strong> <br />
                        {err.message}
                    </span>,
                );
                actions.resetForm({ values: initialValues });
                setTimeout(() => {
                    setOutcome(null);
                    setMessage('');
                }, 10000);
            });
    };

    return (
        <>
            <Formik
                validationSchema={SuspendCoverSchema}
                initialValues={INITIAL_VALUES}
                onSubmit={(values, actions) =>
                    submit(values, actions, INITIAL_VALUES)
                }
                validateOnMount={true}
                validateOnChange={true}
                validateOnBlur={true}>
                {() => (
                    <Form className="container">
                        <DepartureDetails />
                        <ReturnDetails />
                        <WhoIsTraveling />
                        <SelectPeople />
                        <OverseasContact />
                        <AgreeDisclaimers />
                        <SubmitButton
                            handleClose={handleClose}
                            loading={loading}
                        />
                    </Form>
                )}
            </Formik>
            <Fade collapse bottom when={outcome !== null}>
                <StatusNotificationCard status={outcome}>
                    <div>{message}</div>
                </StatusNotificationCard>
            </Fade>
        </>
    );
};

export default SuspendCoverForm;
