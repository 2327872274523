import React from 'react';
import ReferFriendPromo from 'components/sections/referfriend/cards/ReferFriendPromo/ReferFriendPromo';

const ReferAFriendPage = () => {
    return (
        <main className="container-fluid p-4">
            <div className="row">
                <div className="col-12 col-xl-7">
                    <ReferFriendPromo />
                </div>
            </div>
        </main>
    );
};

export default ReferAFriendPage;
