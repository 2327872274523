import moment from 'moment';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
// import DatePicker from 'react-datepicker'; // Why is this not being used???
// import 'react-datepicker/dist/react-datepicker.css';

export const JoinDate = () => {
    const { values, getFieldProps } = useFormikContext();

    return (
        <Form.Group
            controlId={`previousfund-join`}
            className="col-6 text-label-gray"
            style={{
                display: values.previoushealthtransfer ? 'block' : 'none',
            }}>
            <Form.Label>Join Date</Form.Label>
            <Form.Control
                type="date"
                max={moment().format('YYYY-MM-DD')}
                {...getFieldProps('previoushealthfundjoin')}
            />
        </Form.Group>
    );
};

export default JoinDate;
