export const hasCover = (product, type) => {
    if (!product?.base?.type) return;
    if (product.base.type === type) return true;
    if (product.base.type === 'bundle') return true;
    if (product.base.type === 'package') return true;
    return false;
};

export const hasBenefitAccount = (membership) => {
    //this returns false if no benefit account is set.
    return !!membership?.CreditAccount?.BankAccount;
};

export const hasStaffBlock = (membership) => {
    //Staff block is a type of custom prop set in HAMBS
    const potentialBlock = membership?.CustomProperties?.CustomProperty;
    //double check if data is an object with find method
    const canFind = typeof potentialBlock?.find === 'function';

    if (potentialBlock && canFind) {
        //check for claiming blocks
        const hasStaffBlock = potentialBlock.find(
            (item) => item.Key === 'Online Claiming Block',
        );
        //if member has staff claiming block return true else false
        return hasStaffBlock?.Value === 'Y';
    }
};
