import moment from 'moment';

export const createYearArray = () => {
    const currentYear = parseInt(moment().format('YYYY'));
    const yearArray = [];
    for (var i = currentYear; i < currentYear + 11; i++) {
        yearArray.push(i);
    }
    return yearArray;
};
