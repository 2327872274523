import React from 'react';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export const CourseStartDate = () => {
    const { values, getFieldProps } = useFormikContext();

    return (
        <Form.Group
            controlId={`schoolcoursestartdate`}
            className="col-6 text-label-gray">
            <Form.Label>Course Start Date</Form.Label>
            <Form.Control
                type="date"
                disabled={!values.schooldeclare}
                max={moment(values.schoolcourseenddate).format('YYYY-MM-DD')}
                {...getFieldProps('schoolcoursestartdate')}
            />
        </Form.Group>
    );
};

export default CourseStartDate;
